import { yupResolver } from "@hookform/resolvers/yup";
import { Button, TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/cjs/accordion";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import ProvedorAjuda from "../../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../../components/botoes/botao-cancelar-mxp";
import {
  FormCheckBox,
  FormGrupo,
  FormNumberBox,
  FormTextBox,
} from "../../../../../../components/formularios";
import FormMxp from "../../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../../components/layout/toolbar-mxp";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../../models/shared/ui/formularios";
import {
  checarErrosDeConfirmacaoResponse,
  checarResponse,
  tratarErroApi,
} from "../../../../../../utils/api/api-utils";
import {
  quantidadeValorMaximo,
  quantidadeValorMinimo,
} from "../../../../../../utils/common/constantes";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../../utils/common/notificacoes-utils";
import {
  FinalidadeTipo,
  FinalidadeTipoHelper,
} from "../../../../../contabilidade/conta-contabil/models/conta-contabil.enum";
import SelecionarEstoqueDestino from "../../../../../estoque/estoque-movimentacao/componentes/partes/selecionar-estoque-destino/selecionar-estoque-destino";
import { EstoqueMovimentacaoTipo } from "../../../../../estoque/estoque-movimentacao/models/estoque-movimentacao.enums";
import RastreabilidadePorLote, {
  novaRastreabilidadeLote,
  yupRastreabilidadeLote,
} from "../../../../../estoque/lote/componentes/formulario-cadastro-de-rastreabilidade";
import { EstocagemTipo } from "../../../../../itens/item/models/item.enums";
import ComboEmpresaMxp from "../../../../../sistema/empresa/componentes/form/select-box-lazy";
import { ConcluirOrdemDeProducaoRequest } from "../../../models/ordem-de-producao.api";
import { OrdemDeProducaoService } from "../../../servicos/ordem-de-producao.service";
import RastreabilidadePorNumeroDeSerie from "./selecionar-numeros-de-serie";

const service = new OrdemDeProducaoService();

const novoRegistro: ConcluirOrdemDeProducaoRequest = {
  idOrdemDeProducao: 0,
  itemId: 0,
  quantidade: 0,
  itemEstocagemTipo: EstocagemTipo.Codigo,
  itemUsaQuantidadeInteira: false,
  fecharOp: false,
  forcarConclusao: false,
  destino: EstoqueMovimentacaoTipo.Estoque,
  idContaContabilDestino: undefined,
  finalidadeContaContabil: FinalidadeTipo.Estoque,
  idDono: undefined,
  idPosse: undefined,
  codigoItem: "",
  codigoOrdemDeProducao: "",
  rastreabilidadeLote: novaRastreabilidadeLote,
  rastreabilidadeNumeroDeSerie: [],
};

let fecharOp: boolean = true;
let quantidadeDaOp: number = 0;

export default function FormConcluirOrdemDeProducao(
  props: IFormularioEditavelBase
) {
  const [carregando, setCarregando] = useState(false);

  const schema = yup.object<ConcluirOrdemDeProducaoRequest>().shape({
    idOrdemDeProducao: yup.number().required().positive().integer(),
    itemId: yup.number().required().positive().integer(),
    quantidade: yup
      .number()
      .required()
      .moreThan(quantidadeValorMinimo.toNumber())
      .lessThan(quantidadeValorMaximo.toNumber())
      .test(
        "is-integer",
        "Quantidade deve ser um número inteiro",
        function (value) {
          const { itemUsaQuantidadeInteira } = this.parent;
          return itemUsaQuantidadeInteira ? Number.isInteger(value) : true;
        }
      ),
    destino: yup
      .number()
      .oneOf([
        EstoqueMovimentacaoTipo.Estoque,
        EstoqueMovimentacaoTipo.ContaContabil,
      ]),
    idContaContabilDestino: yup.number().required().positive().integer(),
    idDono: yup
      .number()
      .notRequired()
      .test(
        "dono-is-required-if-finalidade",
        "Necessário informar o dono",
        function (value) {
          if (
            getValues("finalidadeContaContabil") ===
              FinalidadeTipo.EstoqueDeTerceirosEmNossoPoder ||
            getValues("finalidadeContaContabil") ===
              FinalidadeTipo.EstoqueDeTerceirosEmPoderDeTerceiros
          ) {
            return value !== null && value !== undefined && value > 0;
          }
          return true;
        }
      ),
    idPosse: yup
      .number()
      .notRequired()
      .test(
        "posse-is-required-if-finalidade",
        "Necessário informar a posse",
        function (value) {
          if (
            getValues("finalidadeContaContabil") ===
              FinalidadeTipo.EstoqueEmPoderDeTerceiros ||
            getValues("finalidadeContaContabil") ===
              FinalidadeTipo.EstoqueDeTerceirosEmPoderDeTerceiros
          ) {
            return value !== null && value !== undefined && value > 0;
          }
          return true;
        }
      ),
    rastreabilidadeLote: yupRastreabilidadeLote(
      (): EstocagemTipo => getValues("itemEstocagemTipo")
    ),
  });

  const hookForm = useForm<ConcluirOrdemDeProducaoRequest>({
    resolver: yupResolver(schema),
  });

  const { register, control, getValues, handleSubmit, reset, watch, setValue } =
    hookForm;

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();

    return () => limparTela();
  }, [props.idRegistroEmEdicao]);

  async function preencherTela() {
    if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    } else {
      limparTela();
    }
  }

  async function carregarRegistroDoId() {
    try {
      setCarregando(true);

      // Obter dados para concluir a ordem de produção
      const resposta = await service.ObterDadosParaConcluirOrdemDeProducao(
        props.idRegistroEmEdicao
      );
      checarResponse(resposta);

      if (!resposta.sucesso) {
        return;
      }

      const model = resposta.model;

      // Atualizar rastreabilidade do lote
      model.rastreabilidadeLote = novaRastreabilidadeLote;
      if (model.rastreabilidadeLoteId !== undefined) {
        model.rastreabilidadeLote.criarNovo = false;
        model.rastreabilidadeLote.loteId = model.rastreabilidadeLoteId;
      }

      // Atualizar variáveis globais
      quantidadeDaOp = model.quantidade;
      fecharOp = model.fecharOp;

      // Resetar o formulário com o modelo atualizado
      reset(model);
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  function limparTela() {
    reset(novoRegistro);
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();
    try {
      const podeConcluir =
        await service.VerificarSePodeConcluirOpSemForcarConclusao(model);
      if (!podeConcluir.sucesso) {
        const confirmacaoForcar = await checarErrosDeConfirmacaoResponse(
          podeConcluir
        );
        if (!confirmacaoForcar) {
          return;
        }
        model.forcarConclusao = true;
      }
      const resposta = await service.Concluir(model);
      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });

        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  function callBackUnprocessableEntity() {
    fechar(ResultadoAcaoFormulario.AcaoConcluida);
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  useEffect(() => {
    if (!fecharOp) {
      return;
    }

    setValue("fecharOp", getValues("quantidade") >= quantidadeDaOp);
  }, [setValue, getValues, watch("quantidade")]);

  return (
    <ContainerFormMxp>
      <FormMxp carregando={carregando}>
        <FormProvider {...hookForm}>
          <ProvedorAjuda id="edit-form-concluir-ordem-de-producao">
            <input
              type="hidden"
              {...register("idOrdemDeProducao")}
              defaultValue={0}
            />
            <input type="hidden" {...register("itemId")} defaultValue={0} />
            <FormGrupo>
              <Linha>
                <Coluna md={3}>
                  <FormTextBox
                    name="codigoOrdemDeProducao"
                    titulo="Ordem de produção"
                    control={control}
                    requerido
                    somenteLeitura
                  />
                </Coluna>
                <Coluna md={4}>
                  <FormTextBox
                    name="codigoItem"
                    titulo="Item"
                    control={control}
                    requerido
                    somenteLeitura
                  />
                </Coluna>
                <Coluna md={3}>
                  <FormNumberBox
                    name="quantidade"
                    titulo="Quantidade"
                    control={control}
                    minimo={quantidadeValorMinimo.toNumber()}
                    quantidadeIncrementarDecrementar={1}
                    maximo={quantidadeValorMaximo.toNumber()}
                    somenteLeitura={
                      watch("itemEstocagemTipo") == EstocagemTipo.Serie
                    }
                    requerido
                  />
                </Coluna>
                <Coluna md={2} centralizar>
                  <FormCheckBox
                    name="fecharOp"
                    titulo="Fechar"
                    control={control}
                  />
                </Coluna>
              </Linha>
              <SelecionarEstoqueDestino hookForms={hookForm as any} />
              <Linha>
                {FinalidadeTipoHelper.isFinalidadeDeTerceiros(
                  watch("finalidadeContaContabil")
                ) && (
                  <Coluna md={6}>
                    <ComboEmpresaMxp
                      name="idDono"
                      titulo="Dono"
                      control={control}
                      requerido
                      tituloSeletor="Selecionar empresa dona do estoque"
                    />
                  </Coluna>
                )}
                {FinalidadeTipoHelper.isFinalidadeEmTerceiros(
                  watch("finalidadeContaContabil")
                ) && (
                  <Coluna md={6}>
                    <ComboEmpresaMxp
                      name="idPosse"
                      titulo="Em poder de"
                      control={control}
                      requerido
                      tituloSeletor="Selecionar empresa posse do estoque"
                    />
                  </Coluna>
                )}
              </Linha>
              {(watch("itemEstocagemTipo") == EstocagemTipo.LoteFabricante ||
                watch("itemEstocagemTipo") == EstocagemTipo.Serie) && (
                <TabPanel
                  deferRendering={false}
                  showNavButtons
                  swipeEnabled={false}
                  itemTitleRender={(item) => item.text}
                >
                  {watch("itemEstocagemTipo") ==
                    EstocagemTipo.LoteFabricante && (
                    <Item text="Rastreabilidade">
                      <RastreabilidadePorLote hookForms={hookForm as any} />
                    </Item>
                  )}
                  {watch("itemEstocagemTipo") == EstocagemTipo.Serie && (
                    <Item text="Rastreabilidade">
                      <RastreabilidadePorNumeroDeSerie />
                    </Item>
                  )}
                </TabPanel>
              )}
            </FormGrupo>
          </ProvedorAjuda>
        </FormProvider>
      </FormMxp>
      <ToolbarMxp>
        <Button
          type="success"
          text={"Concluir"}
          icon={"save"}
          onClick={handleSubmit(handleSalvar) as any}
        />
        <BotaoCancelarMxp handleClick={handleCancelar} />
      </ToolbarMxp>
    </ContainerFormMxp>
  );
}
