import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";
import murmurhash3_32_gc from "murmurhash-js/murmurhash3_gc";

export class ServicoCriptografia {
  private hashKey: string;
  private chavePrivada: string;
  private static instancia?: ServicoCriptografia;

  private constructor() {
    this.hashKey = process.env
      .REACT_APP_SECURE_LOCAL_STORAGE_HASH_KEY as string;
    this.chavePrivada = this.gerarChavePrivada();
  }

  static get Instancia() {
    return this.instancia ?? (this.instancia = new ServicoCriptografia());
  }

  public criptografar(dados: string | null) {
    if (!dados) {
      return dados;
    }

    return AES.encrypt(dados, this.chavePrivada).toString();
  }

  public descriptografar(dados: string | null) {
    if (!dados) {
      return dados;
    }

    try {
      const bytes = AES.decrypt(dados, this.chavePrivada);
      return bytes.toString(Utf8) || null;
    } catch {
      return null;
    }
  }

  private gerarChavePrivada(): string {
    const dados = [
      this.hashKey,
      navigator.userAgent,
      location.hostname,
      navigator.language,
      ...navigator.languages,
    ];
    const fingerPrint = dados.join("|");
    const seed = 256;
    return murmurhash3_32_gc(fingerPrint, seed) + this.hashKey;
  }
}
