import { yupResolver } from "@hookform/resolvers/yup";
import { forwardRef, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import FormTextArea from "../../../../../components/formularios/textarea";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import { useCarregarDadosDoModelo } from "../../../../../hooks/form.hooks";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/store.hooks";
import { IFormulario } from "../../../../../models/shared/ui/formularios";
import { MDFeAbaObservacoesViewModel } from "../../../../../models/viewmodels/vendas/mdfe/mdfe-edit-form-view-model";
import { definirDadosObservacoes } from "../../../../../store/mdfe/mdfe.slice";
import { checarSeFormFoiModificado } from "../../../../../utils/common/form-utils";

interface DadosObservacoesProps {
  idRegistro: number;
  abaSomenteLeitura: boolean;
}

const schema = yup.object().shape({
  id: yup.number().required().moreThan(-1).integer(),
  contribuinte: yup.string().nullable().max(5000),
  fisco: yup.string().nullable().max(2000),
  internas: yup.string().nullable().max(5000),
});

export const MDFeAbaObservacoes = forwardRef(
  ({ idRegistro, abaSomenteLeitura }: DadosObservacoesProps, ref) => {
    const model: MDFeAbaObservacoesViewModel = useAppSelector((state) => {
      const doc = state.mdfe.documentoAtual;
      return {
        id: doc.id,
        contribuinte: doc.informacoesAdicionaisContibuinte,
        fisco: doc.informacoesAdicionais,
        internas: doc.observacoesInternas,
      };
    });

    useCarregarDadosDoModelo(idRegistro, carregarTela);

    async function carregarTela() {
      reset(model);
    }

    const dispatch = useAppDispatch();

    const { reset, control, formState, handleSubmit } =
      useForm<MDFeAbaObservacoesViewModel>({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(schema),
      });

    let form: HTMLFormElement | null;

    function handleSubmitInterno(data: MDFeAbaObservacoesViewModel) {
      dispatch(definirDadosObservacoes(data));
    }

    // Repassar referências para componente pai
    useImperativeHandle(
      ref,
      (): IFormulario => ({
        requestSubmit() {
          form?.requestSubmit();
        },
        valido() {
          form?.requestSubmit();
          return Object.keys(formState.errors).length == 0;
        },
        isDirty() {
          return checarSeFormFoiModificado(formState);
        },
      })
    );

    return (
      <form
        ref={(ref) => (form = ref)}
        onSubmit={handleSubmit(handleSubmitInterno)}
      >
        <Linha>
          <Coluna md={6}>
            <FormTextArea
              name={"fisco"}
              titulo={"Informações adicionais de interesse do fisco"}
              valor={""}
              control={control}
              tamanhoMaximo={2000}
              height={25}
              somenteLeitura={abaSomenteLeitura}
            />
          </Coluna>
          <Coluna md={6}>
            <FormTextArea
              name={"contribuinte"}
              titulo={"Informações adicionais de interesse do contribuinte"}
              valor={""}
              control={control}
              tamanhoMaximo={5000}
              height={25}
              somenteLeitura={abaSomenteLeitura}
            />
          </Coluna>
        </Linha>
        <Linha>
          <Coluna md={6}>
            <FormTextArea
              name={"internas"}
              titulo={"Observações internas"}
              valor={""}
              control={control}
              tamanhoMaximo={5000}
              height={25}
              somenteLeitura={abaSomenteLeitura}
            />
          </Coluna>
        </Linha>
      </form>
    );
  }
);
