import { Button } from "devextreme-react";
import { ReactNode, useEffect, useState } from "react";
import * as yup from "yup";
import { FormGrupo, FormTextBox } from "../../../../components/formularios";
import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../components/layout/grid-system";
import { useHookForms } from "../../../../hooks/form.hooks";
import { LinkAjudaRequestDTO } from "../../../../models/api/ajuda/link-ajuda";
import AuditavelDTO from "../../../../models/api/comum/auditavel-dto";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import API from "../../../../services/ajuda/link-ajuda.service";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import NomesModais from "../../../../utils/common/nomes-modais";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";
import { exibirConfirmacao } from "../../../../utils/dialogos";
import { ProvedorToolTip } from "../../../../utils/help/provedor-tooltip";
import { renderToStringClient } from "../../../../utils/react/react-utils";

interface EditFormLinkAjudaProps extends FormularioEdicaoBaseProps {
  codigo: string;
}

const linkhome = process.env.REACT_APP_HELP_HOME as string;

export const EditFormLinkAjuda = (props: EditFormLinkAjudaProps) => {
  const novoRegistro: LinkAjudaRequestDTO = {
    id: 0,
    codigo: props.codigo,
    slug: "",
  };

  const [carregando, setCarregando] = useState(false);
  const [dadosAuditoria, setDadosAuditoria] = useState<AuditavelDTO>();
  const [excluirVisivel, setExcluirVisivel] = useState(false);

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    codigo: yup.string().required().max(100),
    slug: yup.string().required().max(300),
  });

  const hookForm = useHookForms(schema);

  useEffect(() => {
    if (props.visivel) {
      carregarTela();
    }
  }, [props.visivel]);

  const { register, control, handleSubmit, getValues, reset } = hookForm;

  async function carregarTela() {
    try {
      setCarregando(true);
      const resposta = await API.obter(props.codigo);
      checarResponse(resposta);
      if (resposta.model) {
        reset(resposta.model);
        setDadosAuditoria(resposta.model);
      } else {
        reset(novoRegistro);
        setDadosAuditoria(undefined);
      }
      setExcluirVisivel(!!resposta.model);
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  async function handleSalvar() {
    try {
      const model = getValues();
      const resposta = await API.inserirOuAtualizar(
        model as LinkAjudaRequestDTO
      );
      checarResponse(resposta);
      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: "Salvo com sucesso! Favor recarregar a página.",
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({
          concluido: true,
          precisaAtualizar: false,
        });
      }
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    }
  }

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      props.callBackFecharModal(info);
    }
  }

  function handleCancelar() {
    setDadosAuditoria(undefined);
    fechar({ concluido: false, precisaAtualizar: false });
  }

  function callBackUnprocessableEntity() {
    fechar({
      concluido: false,
      precisaAtualizar: true,
    });
  }

  async function onClickExcluir() {
    const confirmacao = await exibirConfirmacao(
      "Confirmar exclusão",
      "Tem certeza de que deseja excluir o link ajuda?"
    );

    if (!confirmacao) {
      return;
    }

    try {
      setCarregando(true);
      const resposta = await API.excluir(props.codigo);
      checarResponse(resposta);
      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: "Excluído com sucesso! Favor recarregar a página.",
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({
          concluido: false,
          precisaAtualizar: true,
        });
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  async function onLimparCacheLocal() {
    const confirmacao = await exibirConfirmacao(
      "Confirmar limpeza",
      renderToStringClient(
        <>
          Tem certeza de que deseja excluir o chache local dos textos de ajuda?
          <br />
          <br />
          <i>
            Essa operação visa apenas facilitar testes internos e apagará apenas
            os dados armazenados localmente.
            <br />
            Se seu intuito é limpar o cache no servidor, basta salvar esse
            registro através do botão <strong>Salvar</strong>.
          </i>
        </>
      )
    );

    if (confirmacao) {
      ProvedorToolTip.limparCache();
      exibirNotificacaoToast({
        mensagem: "Limpeza realizada com sucesso! Favor atualizar a página.",
        tipo: TipoNotificacao.Sucesso,
      });
    }
  }

  const botoesAdicionais: ReactNode[] = [
    <Button
      key="btn-excluir"
      type="danger"
      text="Excluir"
      icon="trash"
      visible={excluirVisivel}
      onClick={onClickExcluir}
    />,
    <Button
      key="btn-limpar"
      type="normal"
      text="Limpar cache local"
      visible
      onClick={onLimparCacheLocal}
    />,
  ];

  return (
    <>
      <FormBase2
        visivel={props.visivel}
        carregando={carregando}
        onClickSalvar={handleSubmit(handleSalvar)}
        onClickCancelar={handleCancelar}
        configuracoesModal={props.configuracoesModal}
        modoEdicao={props.idRegistroEdicao == 0 ? "criar" : "editar"}
        titulo={NomesModais.linkDeAjuda}
        auditoria={dadosAuditoria}
        botoesAdicionais={botoesAdicionais}
      >
        <input type="hidden" {...register("id")} defaultValue={0} />
        <input
          type="hidden"
          {...register("codigo")}
          defaultValue={props.codigo}
        />
        <FormGrupo>
          <Linha>
            <Coluna md={12}>
              <FormTextBox
                name="slug"
                titulo={linkhome}
                toolTip="Slug"
                control={control}
                requerido
                tamanhoMaximo={300}
              />
            </Coluna>
          </Linha>
        </FormGrupo>
      </FormBase2>
    </>
  );
};
