import DataSource from "devextreme/data/data_source";
import {
  assertConfiguracaoExibicaoEBuscaType,
  ConfiguracaoExibicaoEBusca,
} from "../../../../components/formularios/selectbox-lazy-mxp";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import { DataSourceOpcoesBuilder } from "../../../../utils/grid/data-source-factory";
import { PedidoDeVendaGridModel } from "../models/pedidos-de-venda.api";

export default class PedidosDeVendaService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.PedidoDeVenda);
  }

  public GetDadosSelectBoxPedidoDeVenda(
    dataSourceOpcoes?: DataSourceOpcoesBuilder<PedidoDeVendaGridModel>
  ): [
    DataSource<PedidoDeVendaGridModel>,
    ConfiguracaoExibicaoEBusca<PedidoDeVendaGridModel>
  ] {
    const camposDeBusca: (keyof PedidoDeVendaGridModel)[] = [
      "numero",
      "apelidoCliente",
    ];
    const camposRetorno = new Set<keyof PedidoDeVendaGridModel>([
      ...(dataSourceOpcoes?.camposRetorno || []),
      // Adicionar os campos mínimos que precisamos para montar o SelectBoxLazy.
      "id",
      ...camposDeBusca,
    ]);

    const dataSource =
      this.ObterDataSourceParaSelectBoxLazy<PedidoDeVendaGridModel>({
        quantidadeRegistros: dataSourceOpcoes?.quantidadeRegistros,
        camposRetorno: Array.from(camposRetorno),
        camposOrdenacao: dataSourceOpcoes?.camposOrdenacao || [
          { campo: "numero", desc: true },
        ],
        camposFiltro: dataSourceOpcoes?.camposFiltro,
        filtroExato: dataSourceOpcoes?.filtroExato,
      });

    const configs =
      assertConfiguracaoExibicaoEBuscaType<PedidoDeVendaGridModel>({
        nomeCampoChave: "id",
        expressaoDeBusca: camposDeBusca,
        nomeCampoExibicao: (pv) =>
          pv ? `${pv.numero} (${pv.apelidoCliente})` : "",
      });

    return [dataSource, configs];
  }
}
