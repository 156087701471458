import { TextBox } from "devextreme-react";
import { LabelMode } from "devextreme/common";
import { TextBoxType } from "devextreme/ui/text_box";
import {
  Controller,
  FieldError,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";
import IconeAjuda from "../../ajuda/icone-ajuda";
import LinkAjuda from "../../ajuda/link-ajuda";
import { LabelErro } from "../label-erro";

interface FormTextBoxProps<T extends FieldValues>
  extends UseControllerProps<T> {
  tabIndex?: number;
  titulo: string;
  toolTip?: string;
  tipo?: TextBoxType;
  placeholder?: string;
  tipoPlaceholder?: LabelMode;
  exibirBotaoLimpar?: boolean;
  somenteLeitura?: boolean;
  desabilitado?: boolean;
  requerido?: boolean;
  invisivel?: boolean;
  tamanhoMaximo?: number;
  mask?: string;
  maskRules?: any;
  letrasMaiusculas?: boolean;
  mostarMultiplosErros?: boolean;
  transform?: "uppercase" | "lowercase";
  exibirLinkAjuda?: boolean;
  valueChangeEvent?: string;
  autocompletar?: boolean;
  onKeyDown?: (e: any) => void;
  onChange?: (e: any) => void;
  onEnter?: (e: any) => void;
  onContentReady?: (e: any) => void;
}

export default function <T extends FieldValues>(props: FormTextBoxProps<T>) {
  /* eslint-disable */
  const getFieldErros = (error: FieldError | undefined): string[] => {
    if (!error?.types) {
      return [];
    }
    return Object.entries(error.types)
      .map(([type, message]) => {
        if (!message) {
          return [];
        }
        return Array.isArray(message)
          ? message.map((x) => x.toString())
          : [message.toString()];
      })
      .reduce((a, b) => a.concat(b), []);
  };
  /* eslint-enable */

  let errosAtual = 0;
  let errosAnteriores = 0;
  return (
    <Controller
      {...props}
      render={({ field, fieldState }) => {
        const erros = getFieldErros(fieldState.error);
        errosAnteriores = errosAtual;
        errosAtual = erros.length;
        return (
          <>
            <label htmlFor={field.name}>
              {!props.invisivel && (
                <span className="dx-field-item-label-content">
                  <span className="dx-field-item-label-text">
                    {props.titulo}
                  </span>
                  {props.requerido && (
                    <span className="dx-field-item-required-mark">&nbsp;*</span>
                  )}
                  {(props.exibirLinkAjuda ?? true) && (
                    <LinkAjuda keyAjuda={field.name}>
                      <IconeAjuda />
                    </LinkAjuda>
                  )}
                </span>
              )}
            </label>
            <TextBox
              tabIndex={props.tabIndex}
              hint={props.toolTip}
              mode={props.tipo ?? "text"}
              label={props.placeholder}
              labelMode={props.tipoPlaceholder}
              readOnly={props.somenteLeitura}
              disabled={props.desabilitado}
              valueChangeEvent={props.valueChangeEvent ?? "blur"}
              showClearButton={props.exibirBotaoLimpar}
              maxLength={props.tamanhoMaximo}
              onEnterKey={props.onEnter}
              onContentReady={props.onContentReady}
              onValueChange={(value) => {
                let texto: string | null | undefined = value;

                switch (props.transform) {
                  case "uppercase":
                    texto = texto?.toUpperCase();
                    break;
                  case "lowercase":
                    texto = texto?.toLowerCase();
                    break;
                }

                if (props.onChange) {
                  props.onChange(value);
                }

                field.onChange(texto);
              }}
              onFocusOut={() => {
                field.onBlur();
              }}
              onKeyDown={props.onKeyDown}
              visible={!props.invisivel}
              value={field.value}
              validationStatus={fieldState.invalid ? "invalid" : "valid"}
              mask={props.mask}
              maskRules={props.maskRules}
              inputAttr={{
                id: field.name,
                autocomplete: props.autocompletar ? "on" : "off",
                style: props.transform
                  ? `text-transform:${
                      props.transform == "uppercase" ? "uppercase" : "lowercase"
                    };`
                  : undefined,
              }}
            />
            {props.mostarMultiplosErros ? (
              <LabelErro
                $filhos={errosAtual}
                $filhosAnteriores={errosAnteriores}
              >
                {erros.map((erro) => (
                  <li key={erro}>{erro}</li>
                ))}
              </LabelErro>
            ) : (
              <LabelErro>{fieldState.error?.message}</LabelErro>
            )}
          </>
        );
      }}
    />
  );
}
