import { DataGrid } from "devextreme-react";
import { DataGridRef } from "devextreme-react/cjs/data-grid";
import { SingleMultipleOrNone } from "devextreme/common";
import ArrayStore from "devextreme/data/array_store";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import useEffectOnLoad from "../../hooks/effect.hooks";
import { IGridSelecao } from "../../models/shared/ui/formularios";
import GridDefaults from "../../parts/layout/grid-defaults";
import { ColecaoAtalhos } from "../../utils/atalhos/colecao-atalhos";
import {
  ItemContextMenu,
  criarItensMenuContextoPadrao,
  tratarDadosContextMenu,
} from "../../utils/context-menu/context-menu-utils";
import { getGridDefaultProps } from "../../utils/grid/grid-utils";
import {
  getGridSelecaoProps,
  gridOperacoesConstructor,
} from "../../utils/grid/selecao-utils";
import ContextoModal from "../layout/contexto-modal";

/**
 * Propriedades das grids.
 *
 * @interface GridProps
 * @member {string} id? Id da grid.
 * @member {JSX.Element[]} colunas Array com as colunas da grid.
 * @member {ColunasGridProps<T>[]} colunasExistentes? Array com as propriedades das colunas já existentes.
 * @member {CustomStore | ArrayStore} dataSource Dados que compoem a grid.
 * @member {() => void} novoRegistro? Função para a rotina de inserção de um registro.
 * @member {(id: number) => void} editarRegistro? Função para a rotina de edição de um registro.
 * @member {(registro: T) => void} excluirRegistro? Função para a rotina de remoção de um registro.
 * @member {(itensAdicionais: ItemContextMenu[]) => void} definirMenuSuperior? Função parametrizada com o array de itens que representam as opções do menu de contexto que serão definidas no menu superior da grid.
 * @member {string} nomeDoArquivoAoExportar? Nome do arquivo que será utilizado para a exportação dos dados.
 * @member {string} prefixoDoIdDoGrid? Prefixo atribuído ao identificador da grid.
 * @member {Array<any>} valorPadraoDoFiltro? Array representando o valor do filtro padrão aplicado nas colunas da grid.
 * @member {(data: ItemContextMenu[]) => void} definirMenu?  Função parametrizada com o array de itens que representam as opções do menu de contexto que serão definidas no menu
 * @member {boolean} sobreporFiltroSalvoComOFiltroPadrao? Sobrepõe o filtro aplicado pelo filtro padrão da grid.
 * @member {boolean} abrirModalNovoRegistroAoCarregar? Define que ao carregar a grid o modal de inserção de um registro já seja aberto.
 * @member {boolean} executarOperacoesNoServidor? Executar as operações da grid no servidor.
 * @member {React.CSSProperties | undefined} style? Propriedades CSS de estilização da grid.
 * @member {(getData: () => T | undefined, data?: T | undefined, getSelecionados?: () => T[] | undefined) => ItemContextMenu[]} gerarItensAdicionaisDeContexto? Propriedade responsável por gerar as ações adicionais na coluna de contexto.
 * @member {boolean} exibirIconeExcluirMenuAcoes? Define se o ícone 'Excluir' será exibido no menu de contexto.
 * @member {boolean} exibirIconeEditarMenuAcoes? Define se o ícone 'Editar' será exibido no menu de contexto.
 * @member {boolean} permiteSomenteMaster? Define se é permitida a criação somente por usuário master.
 * @member {boolean} isUsuarioMaster? Define se o usuário é master, ele será passado no GridDefaults para configurar a grid.
 * @member {boolean} isGridInterno? Define se a grid é uma grid interna, será passado no GridDefaults para configurar a grid.
 * @member {boolean} limparFiltroAoTrocarFiltroPadrao? Define se a grid irá limpar o filtro sempre for inicialmente carregada.
 * @member {boolean} exibirMenuDeContexto? Define se a grid irá mostrar o menu de contexto nos items.
 */
interface GridProps<T> {
  id: string;
  colunas: JSX.Element[];
  colunasExistentes?: ColunasGridProps<T>[];
  dataSource: CustomStore | ArrayStore | DataSource;
  novoRegistro?: () => void;
  editarRegistro?: (id: number) => void;
  excluirRegistro?: (registro: T) => void;
  definirMenuSuperior?: (itensAdicionais: ItemContextMenu[]) => void;
  nomeDoArquivoAoExportar?: string;
  prefixoDoIdDoGrid?: string;
  valorPadraoDoFiltro?: Array<any>;
  definirMenu?: (data: ItemContextMenu[]) => void;
  sobreporFiltroSalvoComOFiltroPadrao?: boolean;
  abrirModalNovoRegistroAoCarregar?: boolean;
  executarOperacoesNoServidor?: boolean;
  style?: React.CSSProperties | undefined;
  gerarItensAdicionaisDeContexto?: (
    getData: () => T | undefined,
    data?: T | undefined,
    getSelecionados?: () => T[] | undefined
  ) => ItemContextMenu[];
  toolbarItensAdicionais?: () => (JSX.Element | undefined)[];
  selecionavel?: boolean;
  exibirIconeExcluirMenuAcoes?: boolean;
  exibirIconeEditarMenuAcoes?: boolean;
  permiteSomenteMaster?: boolean;
  isUsuarioMaster?: boolean;
  isGridInterno?: boolean;
  esconderAtualizar?: boolean;
  limparFiltroAoTrocarFiltroPadrao?: boolean;
  exibirMenuDeContexto?: boolean;
  propriedadesAdicionaisDeGrid?: any;
  componentesAdicionaisDeGrid?: JSX.Element[];
}

export interface ColunasGridProps<T> {
  nomeCampo: Extract<keyof T, string>;
}

interface IRegistroGrid {
  id: number;
}

function obterColunasExistentes<T>(
  colunas: JSX.Element[],
  colunasExistentes?: ColunasGridProps<T>[]
) {
  const colunasExistentesTemp = colunas;
  if (!colunasExistentes || colunasExistentes.length == 0) {
    return colunasExistentesTemp;
  }

  const nomeColunasVisiveis =
    colunasExistentes?.map((y) => y.nomeCampo.toString()) ?? [];
  return colunasExistentesTemp.filter((x) =>
    nomeColunasVisiveis.includes(x.key?.toString() ?? "")
  );
}

export const MxpGrid = forwardRef(GridInternal) as <T extends IRegistroGrid>(
  props: GridProps<T> & { ref: React.ForwardedRef<IGridSelecao> }
) => ReturnType<typeof GridInternal>;

function GridInternal<T extends IRegistroGrid>(
  props: GridProps<T>,
  ref: ForwardedRef<IGridSelecao>
) {
  const refInternal = useRef<DataGridRef<any, any>>(null);
  const gridId = `${(props.prefixoDoIdDoGrid ?? "") + props.id}`;
  const [acaoDoubleClick, setAcaoDoubleClick] = useState<() => void>();
  const [modoSelecao, setModoSelecao] = useState<SingleMultipleOrNone>("none");

  useEffectOnLoad(() => {
    if (!!props.abrirModalNovoRegistroAoCarregar && props.novoRegistro) {
      props.novoRegistro();
    }

    if (props.definirMenuSuperior) {
      props.definirMenuSuperior(gerarContextMenu(undefined, true));
    }
  });

  useEffect(() => {
    if (props.limparFiltroAoTrocarFiltroPadrao && props.valorPadraoDoFiltro) {
      const instance = getGridRef().instance;
      instance().option("filterValue", []);
    }
  }, [props.limparFiltroAoTrocarFiltroPadrao, props.valorPadraoDoFiltro]);

  function refresh() {
    refInternal.current?.instance()?.refresh();
  }

  function getGridRef() {
    return refInternal.current!;
  }

  useImperativeHandle(
    ref,
    (): IGridSelecao =>
      gridOperacoesConstructor(
        refInternal.current!,
        () => modoSelecao,
        setModoSelecao,
        setAcaoDoubleClick
      )
  );

  const gerarContextMenu = useCallback(
    (data: any, isMenuPrincipal?: boolean) => {
      const [getData, getSelecionados] = tratarDadosContextMenu<T>(
        data,
        refInternal,
        getGridRef,
        isMenuPrincipal
      );

      return criarItensMenuContextoPadrao({
        isMenuPrincipal: isMenuPrincipal,
        editar: () => {
          if (props.editarRegistro) {
            props.editarRegistro(getData()?.id ?? 0);
          }
        },
        excluir: () => {
          const data = getData()!;

          if (props.excluirRegistro) {
            props.excluirRegistro(data);
          }
        },
        exibirIconeEditarMenuAcoes: props.exibirIconeEditarMenuAcoes ?? true,
        exibirIconeExcluirMenuAcoes: props.exibirIconeExcluirMenuAcoes ?? true,
        itensAdicionais: props.gerarItensAdicionaisDeContexto
          ? props.gerarItensAdicionaisDeContexto(
              getData,
              data as T,
              getSelecionados
            )
          : undefined,
      });
    },
    [props]
  );

  const handleDoubleClickGrid = useCallback(
    (gridData: T) => {
      if (props.editarRegistro) {
        props.editarRegistro(gridData.id);
      }
    },
    [props]
  );

  const gridDefaultProps = useMemo(() => {
    return {
      ...getGridDefaultProps({
        nomeDoArquivoAoExportar: props.nomeDoArquivoAoExportar,
        getGridRef: getGridRef,
        executarOperacoesNoServidor: props.executarOperacoesNoServidor,
        gerarOpcoesDoRegistro: gerarContextMenu,
        sobreporFiltroSalvoComOFiltroPadrao:
          props.sobreporFiltroSalvoComOFiltroPadrao ?? false,
        valorPadraoDoFiltro: props.valorPadraoDoFiltro,
        style: props.style,
      }),
    };
  }, [
    props.nomeDoArquivoAoExportar,
    props.executarOperacoesNoServidor,
    props.sobreporFiltroSalvoComOFiltroPadrao,
    props.valorPadraoDoFiltro,
    props.style,
    gerarContextMenu,
  ]);

  const gridSelecaoProps = useMemo(() => {
    return {
      ...getGridSelecaoProps(
        () => modoSelecao,
        () => acaoDoubleClick,
        handleDoubleClickGrid
      ),
    };
  }, [handleDoubleClickGrid, modoSelecao, acaoDoubleClick]);

  const colunas = useMemo(
    () => obterColunasExistentes(props.colunas, props.colunasExistentes),
    [props.colunas, props.colunasExistentes]
  );

  const gridDefaults = useMemo(
    () =>
      GridDefaults({
        gridId,
        atualizarGrid: props.esconderAtualizar ? undefined : refresh,
        novoRegistro: props.novoRegistro,
        gerarContextMenu: gerarContextMenu,
        toolbarItensAdicionais: props.toolbarItensAdicionais,
        getGridRef,
        permiteSomenteMaster: props.permiteSomenteMaster,
        isUsuarioMaster: props.isUsuarioMaster,
        isGridInterno: props.isGridInterno,
        filtravel: !props.isGridInterno,
        paginavel: !props.isGridInterno,
        selecionavel: props.selecionavel ?? !props.isGridInterno,
        registrarAtalhos: false,
        exibirMenuDeContexto: props.exibirMenuDeContexto,
      }),
    [
      gridId,
      props.esconderAtualizar,
      props.novoRegistro,
      props.toolbarItensAdicionais,
      props.permiteSomenteMaster,
      props.isUsuarioMaster,
      props.isGridInterno,
      props.selecionavel,
      props.exibirMenuDeContexto,
      gerarContextMenu,
    ]
  );

  const contextoModal = useContext(ContextoModal);
  if (gerarContextMenu) {
    ColecaoAtalhos.registrarAtalhosGrid(contextoModal, {
      novo: props.novoRegistro,
      contextMenuItens: gerarContextMenu(undefined),
    });
  }

  return (
    <DataGrid
      ref={refInternal}
      dataSource={props.dataSource}
      {...gridDefaultProps}
      {...gridSelecaoProps}
      {...props.propriedadesAdicionaisDeGrid}
    >
      {gridDefaults}
      {props.componentesAdicionaisDeGrid}
      {colunas}
    </DataGrid>
  );
}
