import { HttpStatusCode } from "axios";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { atualizarToken, getHeaders, getUrl } from "../../configs/api";
import { StringsComum } from "../../features/comum/strings";
import store from "../../store";
import { definirMensagemLogoff } from "../../store/ui/ui.slice";
import { tratarErroApi } from "../api/api-utils";
import { aguardar } from "../common/common-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../common/notificacoes-utils";
import { RemoverSessaoReduxELocalStorage } from "../oauth/oauth-utils";

const getEstatoAtualizacaoToken = () =>
  store.getState().estadoUI.atualizandoToken;

export default function criarDatasourceGrid(
  urlRelativa: string,
  key: string = "id"
) {
  return criarDatasourceGridComParametros(urlRelativa, undefined, key);
}

export function criarDatasourceGridComParametros(
  urlRelativa: string,
  loadParams: object | undefined,
  key: string = "id"
) {
  const dataSource = createStore({
    key: key,
    loadUrl: getUrl(urlRelativa),
    loadParams: loadParams,
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = getHeaders();
    },
    onAjaxError: ({ xhr, error }) => {
      // Enum para comparação do erro
      enum serverErrors {
        Locked = 423,
        InternalServerError = 500,
        NetworkConnectTimeoutError = 599,
      }

      // Trata erro na requisição do grid com os valores de erro do servidor
      if (
        xhr.status === HttpStatusCode.Locked &&
        !store.getState().estadoUI.redirecionando
      ) {
        store.dispatch(definirMensagemLogoff(JSON.parse(xhr.response)));
        RemoverSessaoReduxELocalStorage(true);
      } else if (
        xhr.status >= serverErrors.InternalServerError &&
        xhr.status <= serverErrors.NetworkConnectTimeoutError
      ) {
        exibirNotificacaoToast({
          mensagem: StringsComum.falhaCarregarDadosGrade,
          tipo: TipoNotificacao.Erro,
        });
        return;
      } else if (xhr.status === HttpStatusCode.Forbidden) {
        exibirNotificacaoToast({
          mensagem: error.toString() ?? StringsComum.falhaCarregarDadosGrade,
          tipo: TipoNotificacao.Erro,
        });
        return;
      } else if (
        xhr.status === HttpStatusCode.Unauthorized &&
        getEstatoAtualizacaoToken() != "ajax-grid"
      ) {
        if (getEstatoAtualizacaoToken() == "ocioso") {
          atualizarToken("ajax-grid", error, false).then(() => {
            dataSource.load();
          });
        } else {
          aguardar(5000).then(() => {
            dataSource.load();
          });
        }
      } else {
        tratarErroApi(error ?? xhr);
      }
    },
  });

  return dataSource;
}
