import styled from "styled-components";

export const Container = styled.div`
  width: fit-content;
  display: flex;
	align-items: center;
`;

export const Icon = styled.i`
  font-size: 16px;
  padding: 2px;

  &:not(:last-of-type) {
    margin-right: 2px;
  }
`;
