export default class PrefixoESufixoDeLoteConstantes {
  static readonly PrefixoTamanhoMaximo: number = 60;
  static readonly SufixoTamanhoMaximo: number = 60;
  static readonly DigitosNumericosQuantidadePadrao = 6;
  static readonly DigitosNumericos = {
    Minimo: 1,
    Maximo: 15,
    QuantidadeIncrementoDecremento: 1,
  };
  static readonly ProximoSequencial = {
    Minimo: 1,
    Maximo: 9_999_999_999,
    QuantidadeIncrementoDecremento: 1,
  };
}
