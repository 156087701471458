import { DateBox } from "devextreme-react";
import { LabelMode } from "devextreme/common";
import { Format } from "devextreme/localization";
import { DateType, DisabledDate } from "devextreme/ui/date_box";
import { Controller, FieldValues, UseControllerProps } from "react-hook-form";
import IconeAjuda from "../../ajuda/icone-ajuda";
import LinkAjuda from "../../ajuda/link-ajuda";
import { LabelErro } from "../label-erro";

interface FormDateBoxProps<T extends FieldValues>
  extends UseControllerProps<T> {
  tabIndex?: number;
  titulo?: string;
  toolTip?: string;
  placeholder?: string;
  tipoPlaceholder?: LabelMode;
  exibirBotaoLimpar?: boolean;
  somenteLeitura?: boolean;
  desabilitado?: boolean;
  requerido?: boolean;
  formato?: Format;
  maximo?: string | number | Date;
  minimo?: string | number | Date;
  tipo?: DateType;
  visivel?: boolean;
  formatoExibicao?: Format;
  datasDesabilitadas?: Date[] | ((data: DisabledDate) => boolean);
  aceitaValorCustomizado?: boolean;
  exibirLinkAjuda?: boolean;
  aceitaDigitacaoComMascara?: boolean;
  onEnter?: (e: any) => void;
  onChange?: (e: any) => void;
}

export default function <T extends FieldValues>(props: FormDateBoxProps<T>) {
  return props.visivel ?? true ? (
    <Controller
      {...props}
      render={({ field, fieldState }) => (
        <>
          <label htmlFor={field.name}>
            <span className="dx-field-item-label-content">
              <span className="dx-field-item-label-text">
                {props.titulo ? props.titulo : <br />}
              </span>
              {props.requerido && (
                <span className="dx-field-item-required-mark">&nbsp;*</span>
              )}
              {(props.exibirLinkAjuda ?? true) && (
                <LinkAjuda keyAjuda={field.name}>
                  <IconeAjuda />
                </LinkAjuda>
              )}
            </span>
          </label>
          <DateBox
            type={props.tipo ?? "date"}
            tabIndex={props.tabIndex}
            hint={props.toolTip}
            label={props.placeholder}
            labelMode={props.tipoPlaceholder}
            readOnly={props.somenteLeitura}
            disabled={props.desabilitado}
            visible={props.visivel ?? true}
            displayFormat={props.formatoExibicao}
            showClearButton={props.exibirBotaoLimpar}
            max={props.maximo}
            min={props.minimo}
            disabledDates={props.datasDesabilitadas}
            onEnterKey={props.onEnter}
            onValueChange={(value) => {
              field.onChange(value);
              props?.onChange?.(value);
            }}
            onFocusOut={() => {
              field.onBlur();
            }}
            value={field.value ? field.value : undefined}
            validationStatus={fieldState.invalid ? "invalid" : "valid"}
            dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
            inputAttr={{ id: field.name }}
            acceptCustomValue={props.aceitaValorCustomizado}
            useMaskBehavior={props.aceitaDigitacaoComMascara}
          />
          <LabelErro>{fieldState.error?.message}</LabelErro>
        </>
      )}
    />
  ) : null;
}
