import styled from "styled-components";

export const CabecadoMDFe = styled.div`
  padding-bottom: 12px;
`;

export const LinkRejeicao = styled.a`
  &&& {
    font-weight: bold;
    text-decoration-line: underline;
    color: black;
  }

  :hover {
    cursor: pointer;
  }
`;

export const QuadroAmbienteHomologacaoCommon = styled.div`
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 1em;
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: red;
  border: 1px solid;
`;
