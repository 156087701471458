import styled from "styled-components";

export const ContainerOpcoesMaster = styled.div`
  position: relative;
  bottom: 0;
  padding-top: 5px;
  z-index: 1;
`;

export const LinkOpcoesMaster = styled.a`
  float: right;
`;
