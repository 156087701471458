import { Button } from "devextreme-react";
import { useNavigate } from "react-router-dom";

export default function NotFoundPage() {
  const navigate = useNavigate();

  return (
    <div>
      <h1>404</h1>
      <h2>Página não encontrada</h2>

      <Button text="Voltar a página principal" onClick={() => navigate("/")} />
    </div>
  );
}
