import styled from "styled-components";

export const Container = styled.div`
  width: fit-content;
  margin: -5px;
`;

export const LinkButton = styled.a`
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
  vertical-align: middle;
  border-radius: 4px;
  display: inline-flex;
  padding: 1px;

  &:not(:last-of-type) {
    margin-right: 2px;
  }

  &:hover {
    background: lightgray;
  }
`;
