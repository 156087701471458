import {
  type ConfiguracoesErros,
  TipoErro,
} from "../../../../../models/api/comum/erros";
import { formatarNumeroQuantidade } from "../../../../../utils/formatadores/formatador-de-numeros";
import type { ErroReserva } from "./reserva.erros";

export const configuracoesErrosReserva = Object.freeze<
  ConfiguracoesErros<ErroReserva>
>({
  "RSV-0001": {
    tipo: TipoErro.Popup,
    formatador({
      dadosExtras: {
        ordemDeProducao: { numero: numeroOrdemDeProducao },
        itemDaOrdemDeProducao: {
          item: { codigo: codigoItem, descricao: descricaoItem },
        },
        quantidadeDesejada,
        quantidadeDisponivel,
      },
    }) {
      if (numeroOrdemDeProducao) {
        return (
          <>
            Não foi possível criar reserva para o item {codigoItem} (
            {descricaoItem}), pois a quantidade a ser reservada (
            {formatarNumeroQuantidade(quantidadeDesejada)}) ultrapassa a
            quantidade livre ({formatarNumeroQuantidade(quantidadeDisponivel)})
            da ordem de produção {numeroOrdemDeProducao}.
          </>
        );
      } else {
        return (
          <>
            Não foi possível criar reserva para o item {codigoItem} (
            {descricaoItem}), pois a quantidade a ser reservada (
            {formatarNumeroQuantidade(quantidadeDesejada)}) ultrapassa a
            quantidade livre ({formatarNumeroQuantidade(quantidadeDisponivel)})
            da ordem de produção.
          </>
        );
      }
    },
  },
  "RSV-0002": {
    tipo: TipoErro.Toast,
    formatador({ dadosExtras: { numero = "" } }) {
      return `O estado da ordem de produção ${numero} precisa ser "A produzir" ou "Sugerida" para poder criar uma reserva.`;
    },
  },
  "RSV-0003": {
    tipo: TipoErro.Toast,
    formatador({
      dadosExtras: {
        item: { codigo: codigoItem, descricao: descricaoItem },
      },
    }) {
      return `O estado do item ${codigoItem} (${descricaoItem}) precisa ser "Ativo" ou "Digitação" para poder criar uma reserva.`;
    },
  },
  "RSV-0004": {
    tipo: TipoErro.Toast,
    formatador({ dadosExtras: { numero = "" } }) {
      return `O estado do pedido de venda ${numero} precisa ser diferente de "Faturado" e "Cancelado" para poder criar uma reserva.`;
    },
  },
  "RSV-0005": {
    tipo: TipoErro.Toast,
    formatador({
      dadosExtras: {
        numero: numeroItemPedidoDeVenda = "",
        pedidoDeVenda: { numero: numeroPedidoDeVenda = "" },
      },
    }) {
      return `O estado do item ${numeroItemPedidoDeVenda} do pedido de venda ${numeroPedidoDeVenda} precisa ser diferente de "Concluído" e "Cancelado" para poder criar uma reserva.`;
    },
  },
});
