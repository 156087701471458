import PaginaMxp from "../../../../../components/layout/pagina-mxp";
import ProvedorMenus from "../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../components/seguranca/requer-permissao";
import { PermissoesOrdemDeProducao } from "../../../../../models/permissoes/producao/ordem-de-producao/OrdemDeProducaoPermissoes";
import NomesDasCategoriasDoMenuPrincipal from "../../../../../utils/common/nome-categorias-menu-principal";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import GridOrdemDeProducao from "../../componentes/grid-padrao";
import FiltrosGridOrdemDeProducao from "../../utils/filtros/filtros-grid-ordem-de-producao";

export default function OrdemDeProducaoPage() {
  return (
    <ProvedorMenus>
      <PaginaMxp
        id="ordem-de-producao-page"
        titulo={NomesTelas.ordensProducao}
        area={[NomesDasCategoriasDoMenuPrincipal.producao]}
      >
        <RequerPermissao
          codigoPermissoes={[PermissoesOrdemDeProducao.Consultar]}
        >
          <GridOrdemDeProducao
            filtrosNoCliente={FiltrosGridOrdemDeProducao.ordemDeProducaoEstado}
          />
        </RequerPermissao>
      </PaginaMxp>
    </ProvedorMenus>
  );
}
