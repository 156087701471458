import { PermissoesOrdemDeProducao } from "../../../../../models/permissoes/producao/ordem-de-producao/OrdemDeProducaoPermissoes";
import { tratarErroApi } from "../../../../../utils/api/api-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import { OrdemDeProducaoGridModel } from "../../models/ordem-de-producao.api";
import { OrdemDeProducaoService } from "../../servicos/ordem-de-producao.service";
import {
  AcoesOrdensDeProducao,
  handlerAcaoOrdensDeProducaoResponse,
} from "./acoes-ordem-de-producao-helper";

async function confirmarOrdensDeProducaoHandler(
  service: OrdemDeProducaoService,
  registros: OrdemDeProducaoGridModel[],
  handleAtualizarGrid: () => void,
  exibirMensagemDeConfirmacao: boolean
) {
  if (
    !verificaComNotificacaoSeUsuarioPossuiPermissoes([
      PermissoesOrdemDeProducao.InserirEditar,
    ])
  ) {
    return;
  }

  if (exibirMensagemDeConfirmacao) {
    const confirmar = await exibirConfirmacao(
      "Confirmar ordens de produção",
      "Tem certeza de que deseja confirmar as ordens de produção selecionadas?"
    );

    if (!confirmar) {
      return;
    }
  }

  try {
    const response = await service.ConfirmarOrdensDeProducao(
      registros.map((i) => i.id)
    );

    handleAtualizarGrid();

    const acoes: AcoesOrdensDeProducao = {
      acao: "confirmar",
      acaoEstadoDasOps: "confirmadas",
    };
    return handlerAcaoOrdensDeProducaoResponse(response, acoes);
  } catch (erro) {
    tratarErroApi(erro);
  }
}

export { confirmarOrdensDeProducaoHandler };
