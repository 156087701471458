import { CoresChip } from "../../../../../components/mxp/chip/cores";
import SelectItem from "../../../../../models/shared/ui/select-item";
import {
  gerarLookupDoDicionario,
  gerarSelectItemDoDicionario,
} from "../../../../../utils/common/enum-utils";

export enum OrigemInsumo {
  EstruturaDoItem = 1,
  Manual = 2,
}

export enum TipoDoEstoqueInsumo {
  ConsumirEstoque = 1,
  ManterInsumo = 2,
}

export enum EstadoDaTerceirizacao {
  FornecidoPorNos = 1,
  FornecidoPeloCliente = 2,
  SemTerceirizacao = 3,
}

export enum InsumoEstado {
  /// <summary>
  /// A baixar.
  /// </summary>
  ABaixar = 1,

  /// <summary>
  /// Baixa parcial.
  /// </summary>
  BaixadoParcialmente = 2,

  /// <summary>
  /// Baixado.
  /// </summary>
  BaixadoCompletamente = 3,
}

export enum InsumoTipoBaixa {
  /// <summary>
  /// Manual.
  /// </summary>
  Manual = 1,

  /// <summary>
  /// Por explosão.
  /// </summary>
  PorExplosao = 2,

  /// <summary>
  /// Não exige baixa.
  /// </summary>
  NaoExigeBaixa = 3,
}

// Classe que encapsula o enum e suas descrições
export class InsumoEstadoHelper {
  public static readonly tipoDescricao: Record<InsumoEstado, string> = {
    [InsumoEstado.ABaixar]: "A baixar",
    [InsumoEstado.BaixadoParcialmente]: "Baixa parcial",
    [InsumoEstado.BaixadoCompletamente]: "Baixado",
  };

  public static readonly coresPorEstado: Record<InsumoEstado, CoresChip> = {
    [InsumoEstado.ABaixar]: CoresChip.Amarelo,
    [InsumoEstado.BaixadoParcialmente]: CoresChip.Verde,
    [InsumoEstado.BaixadoCompletamente]: CoresChip.Azul,
  };

  public static getDescricao(tipo: InsumoEstado): string {
    return this.tipoDescricao[tipo] || "Descrição desconhecida";
  }

  public static AsSelectItems(): SelectItem[] {
    return gerarSelectItemDoDicionario(this.tipoDescricao);
  }

  public static AsLookup() {
    return gerarLookupDoDicionario(this.tipoDescricao);
  }
}
