import {
  DataSourceFiltragem,
  DataSourceRawFilter,
} from "../../../../utils/grid/data-source-factory";
import { EstadoPedidoDeVenda } from "../../../vendas/pedidos-de-venda/models/pedidos-de-venda.enum";
import { ItemPedidoDeVendaGridModel } from "../models/item-pedido-de-venda.api";

export class FiltrosGridItemPedidoVenda {
  static readonly pedidoNaoFinalizado: DataSourceFiltragem<ItemPedidoDeVendaGridModel>[] =
    [
      {
        campo: "estadoPedidoDeVenda",
        operador: "<>",
        valor: EstadoPedidoDeVenda.Faturado,
      },
      {
        campo: "estadoPedidoDeVenda",
        operador: "<>",
        valor: EstadoPedidoDeVenda.Cancelado,
      },
    ];

  static readonly pedidoNaoFinalizadoRaw: DataSourceRawFilter<ItemPedidoDeVendaGridModel> =
    [
      ["estadoPedidoDeVenda", "<>", EstadoPedidoDeVenda.Faturado],
      "and",
      ["estadoPedidoDeVenda", "<>", EstadoPedidoDeVenda.Cancelado],
    ];
}
