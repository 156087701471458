import { NumberBox, TextBox } from "devextreme-react";
import { NumberBoxTypes } from "devextreme-react/cjs/number-box";
import ArrayStore from "devextreme/data/array_store";
import { useCallback, useContext, useEffect, useState } from "react";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import BotaoConfirmarMxp from "../../../../../components/botoes/botao-confirmar-mxp";
import { FormGrupo } from "../../../../../components/formularios";
import FormMxp from "../../../../../components/layout/form";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import { checarResponse } from "../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import { obterFormatStringNumero } from "../../../../../utils/formatadores/formatador-de-numeros";
import { selecionarInputAoFocarNoElemento } from "../../../../../utils/inputs/inputs-utils";
import { ModalSelecionarMovimentacoesParaEstornar } from "../../../../estoque/estoque-movimentacao/componentes/modal-selecionar-movimentacoes-para-estornar";
import {
  InsumoDaOrdemDeProducaoGridModel,
  ObterDescricaoInsumo,
} from "../../models/insumo-da-ordem-de-producao";
import { InsumoDaOrdemDeProducaoService } from "../../servicos/insumo-da-ordem-de-producao";
import ContextoOperacoesInsumo from "../contexto-funcoes-insumo";

const APIInsumos = new InsumoDaOrdemDeProducaoService();

export const ModalEstornarInsumo = () => {
  const [insumoEstornar, setInsumoEstornar] = useState<
    InsumoDaOrdemDeProducaoGridModel | undefined
  >(undefined);
  const [quantidadeAEstornar, setQuantidadeAEstornar] = useState(0);
  const [dataSourceSelecionarEstoque, setDataSourceSelecionarEstoque] =
    useState<ArrayStore | undefined>(undefined);
  const [carregando, setCarregando] = useState(false);

  const { funcoes } = useContext(ContextoOperacoesInsumo);

  useEffect(() => {
    funcoes.definfirSetRegistroEstornar(
      (insumo: InsumoDaOrdemDeProducaoGridModel) => {
        setInsumoEstornar(insumo);
      }
    );
  }, []);

  useEffect(() => {
    setQuantidadeAEstornar(insumoEstornar?.quantidadeBaixada ?? 0);
  }, [insumoEstornar]);

  const valueChanged = useCallback((e: NumberBoxTypes.ValueChangedEvent) => {
    setQuantidadeAEstornar(e.value);
  }, []);

  function fechar() {
    setInsumoEstornar(undefined);
    funcoes.atualizaGridDeInsumos();
  }

  const fecharModal = () => {
    setDataSourceSelecionarEstoque(undefined);

    fechar();
  };

  async function confirmarEstorno() {
    if (quantidadeAEstornar == 0) {
      exibirNotificacaoToast({
        mensagem: "A quantidade para estornar deve ser maior que zero",
        tipo: TipoNotificacao.Advertencia,
      });
    }

    const quantidadeBaixada = insumoEstornar?.quantidadeBaixada ?? 0;
    if (quantidadeAEstornar > quantidadeBaixada) {
      const confirmacao = await exibirConfirmacao(
        "Aviso",
        `A quantidade a estornar (${quantidadeAEstornar}) é maior que a quantidade total 
            já baixada do insumo (${quantidadeBaixada}). 
            Deseja prosseguir com o estorno da quantidade total já baixada?`
      );

      if (!confirmacao) {
        return;
      }
    }
    try {
      setCarregando(true);
      const resposta = await APIInsumos.EstornarInsumo(
        insumoEstornar?.id ?? 0,
        quantidadeAEstornar ?? 0
      );

      checarResponse(resposta);
      if (resposta.sucesso) {
        if (resposta.precisaSelecionarMovs) {
          setDataSourceSelecionarEstoque(
            new ArrayStore({
              data: resposta.movimentacoes,
              key: "id",
            })
          );
        } else {
          exibirNotificacaoToast({
            mensagem: "Insumo estornado com sucesso",
            tipo: TipoNotificacao.Sucesso,
          });

          fechar();
        }
      }
    } finally {
      setCarregando(false);
    }
  }

  return (
    <>
      <ModalMxp
        titulo={"Estornar baixa de insumo"}
        visivel={insumoEstornar != undefined}
        handleFechar={fechar}
        larguraMaxima={"max(30vw, 400px)"}
      >
        <FormMxp carregando={carregando}>
          <FormGrupo>
            <Linha>
              <Coluna md={12}>
                <TextBox
                  label="Insumo a estornar"
                  labelMode="outside"
                  readOnly
                  style={{ marginBottom: "10px" }}
                  value={`${ObterDescricaoInsumo(insumoEstornar)}`}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={12}>
                <NumberBox
                  name="quantidadeABaixar"
                  labelMode="outside"
                  label="Quantidade a estornar"
                  style={{ marginBottom: "10px" }}
                  onFocusIn={selecionarInputAoFocarNoElemento}
                  format={obterFormatStringNumero(5)}
                  value={quantidadeAEstornar}
                  onValueChanged={valueChanged}
                  min={0}
                />
              </Coluna>
            </Linha>
          </FormGrupo>
        </FormMxp>
        <ToolbarMxp dadosAuditoria={undefined}>
          <BotaoConfirmarMxp handleClick={confirmarEstorno} />
          <BotaoCancelarMxp handleClick={fechar} />
        </ToolbarMxp>
      </ModalMxp>
      <ModalSelecionarMovimentacoesParaEstornar
        dados={dataSourceSelecionarEstoque}
        fecharModal={fecharModal}
        mensagens={{
          erro: "Ocorreu um erro ao estornar o insumo.",
          sucesso: "Insumo estornado com sucesso!",
        }}
      />
    </>
  );
};
