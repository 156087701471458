import {
  AmbienteMDFe,
  SituacaoMDFe,
} from "../../../models/api/mdfe/mdfe-enums";
import { Container, Icon } from "./styles";
import { FormPopover } from "../../formularios";

export default function CelulaSituacaoMdfe(dados: any) {
  const icone = SelecionarIcone(dados);
  let id, target;

  if (icone) {
    icone.icone = `ic-material-symbols-outlined ${icone.icone} icone-linha-grid`;
    id = `icone-situacao-${dados.rowIndex}`;
    target = `#${id}`;
  }

  return (
    <Container>
      <span>{dados.data.situacaoDescricao}</span>
      {icone ? <Icon id={id} className={icone.icone}></Icon> : ""}
      {icone?.tooltip ? (
        <FormPopover
          alvo={target ?? ""}
          texto={icone.tooltip}
          hover={true}
          sombreamento={false}
        />
      ) : (
        ""
      )}
    </Container>
  );
}

function SelecionarIcone(dados: any) {
  const icone = icones[dados.data.situacao];

  if (
    dados.data.situacao === SituacaoMDFe.Autorizado &&
    dados.data.ambiente === AmbienteMDFe.Producao
  ) {
    icone.icone = "ic-done";
    icone.tooltip = null;
  }

  if (
    dados.data.situacao === SituacaoMDFe.Rejeitado &&
    dados.data.motivoRejeicao
  ) {
    icone.tooltip += `\n${dados.data.motivoRejeicao}`;
  }

  return icone;
}

const icones: {
  [key: number]: { icone: string; tooltip: string | null };
} = {
  [SituacaoMDFe.EmEdicao]: { icone: "ic-edit-square", tooltip: null },
  [SituacaoMDFe.Autorizado]: {
    icone: "ic-info",
    tooltip:
      "Documento autorizado em ambiente de homologação. Sem validade fiscal.",
  },
  [SituacaoMDFe.Encerrado]: { icone: "ic-done-all", tooltip: null },
  [SituacaoMDFe.Rejeitado]: {
    icone: "ic-block",
    tooltip: "Documento rejeitado. Motivo da rejeição: ",
  },
  [SituacaoMDFe.Cancelado]: { icone: "ic-cancel", tooltip: null },
};
