import getApi from "../../configs/api";

async function obter(url: string) {
  const api = getApi();
  const response = await api.get<string>(`ProxyHelpExterno?url=${url}`);

  return response.data;
}

export default {
  obter,
};
