import { useCallback, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { FormSelectBox } from "../../../../../../components/formularios";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import SelectItem from "../../../../../../models/shared/ui/select-item";
import { ContaContabilService } from "../../../../../../services/contabilidade/conta-contabil/conta-contabil.service";
import {
  FinalidadeTipo,
  FinalidadeTipoHelper,
} from "../../../../../contabilidade/conta-contabil/models/conta-contabil.enum";
import ComboEnderecoEstoqueMxp from "../../../../endereco-de-estoque/componentes/parts/combo-endereco-estoque";
import {
  EstoqueMovimentacaoTipo,
  EstoqueMovimentacaoTipoHelper,
} from "../../../models/estoque-movimentacao.enums";

export interface SelecionarEstoqueDestinoRequest {
  idEnderecoDeEstoque?: number | null;
  idContaContabilDestino?: number | null;
  destino: EstoqueMovimentacaoTipo;
  finalidadeContaContabil: FinalidadeTipo;
}

interface SelecionarEstoqueDestinoProps<
  T extends SelecionarEstoqueDestinoRequest
> {
  hookForms: UseFormReturn<T>;
  somenteLeitura?: boolean;
}

const apiContaContabil = new ContaContabilService();

const datasouceDestinos = EstoqueMovimentacaoTipoHelper.AsSelectItems().filter(
  (x) =>
    x.valor === EstoqueMovimentacaoTipo.Estoque ||
    x.valor === EstoqueMovimentacaoTipo.ContaContabil
);

const dataSourceContaContabilGrid =
  apiContaContabil.GetDataSourceSelectBoxContasDeEstoque();

let dataSourceContaContabilGridCarregado: any[] = [];

export default function SelecionarEstoqueDestino(
  props: SelecionarEstoqueDestinoProps<SelecionarEstoqueDestinoRequest>
) {
  const [
    dataSourceContaContabilGridFiltrado,
    setDataSourceContaContabilGridFiltrado,
  ] = useState<SelectItem[]>([]);

  const { control, getValues, setValue, watch, register } = props.hookForms;

  useEffect(() => {
    CarregarContasContabeis();
  }, []);

  useEffect(() => {
    filtraContasContabeis();
  }, [watch("destino")]);

  useEffect(() => {
    if (!getValues("idContaContabilDestino")) {
      filtraContasContabeis();
    }

    setValue(
      "finalidadeContaContabil",
      dataSourceContaContabilGridCarregado.filter(
        (y) => y.id == getValues("idContaContabilDestino")
      )[0]?.finalidade ?? FinalidadeTipo.Estoque
    );
  }, [watch("idContaContabilDestino")]);

  async function CarregarContasContabeis() {
    // Carregar opções com limite de 1000
    dataSourceContaContabilGrid.loadOptions().take = 1000;
    await dataSourceContaContabilGrid.load();
    dataSourceContaContabilGridCarregado = dataSourceContaContabilGrid.items();

    // Filtrar contas contábeis
    filtraContasContabeis();
  }

  function filtraContasContabeis() {
    const destino = getValues("destino");
    const isEstoque = destino == EstoqueMovimentacaoTipo.Estoque;

    const contasFiltradas = dataSourceContaContabilGridCarregado.filter((x) =>
      isEstoque
        ? FinalidadeTipoHelper.isFinalidadeDeEstoque(x.finalidade)
        : !FinalidadeTipoHelper.isFinalidadeDeEstoque(x.finalidade)
    );

    const contasFormatadas = contasFiltradas.map((x) => ({
      valor: x.id,
      descricao: x.apelido,
    }));

    setDataSourceContaContabilGridFiltrado(contasFormatadas);
    if (
      contasFiltradas.length > 0 &&
      !contasFiltradas.some((x) => x.id == getValues("idContaContabilDestino"))
    ) {
      setValue("idContaContabilDestino", contasFiltradas[0].id);
    }
  }

  const limparEstoqueCasoDestinoSejaContaContabil = useCallback(
    (x: EstoqueMovimentacaoTipo) => {
      if (x == EstoqueMovimentacaoTipo.ContaContabil) {
        setValue("idEnderecoDeEstoque", null);
      }
    },
    [setValue]
  );

  return (
    <Linha>
      <input
        type="hidden"
        {...register("finalidadeContaContabil")}
        defaultValue={0}
      />
      <Coluna md={3}>
        <FormSelectBox
          name="destino"
          titulo="Destino"
          control={control}
          somenteLeitura={props.somenteLeitura}
          dataSource={datasouceDestinos}
          onValueChange={limparEstoqueCasoDestinoSejaContaContabil}
          requerido
        />
      </Coluna>
      <Coluna md={4}>
        <FormSelectBox
          name="idContaContabilDestino"
          titulo="Conta"
          control={control}
          somenteLeitura={props.somenteLeitura}
          dataSource={dataSourceContaContabilGridFiltrado}
          labelSemDados="Sem dados"
          requerido
        />
      </Coluna>
      <Coluna md={5}>
        <ComboEnderecoEstoqueMxp
          name="idEnderecoDeEstoque"
          titulo="Endereço de estoque"
          control={control}
          somenteLeitura={
            watch("destino") !== EstoqueMovimentacaoTipo.Estoque ||
            props.somenteLeitura
          }
        />
      </Coluna>
    </Linha>
  );
}
