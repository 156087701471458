import { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/store.hooks";
import { EditFormLinkAjuda } from "../../../parts/ajuda/link-ajuda/edit-form";
import { ProvedorToolTip } from "../../../utils/help/provedor-tooltip";
import ContextoAjuda from "../contexto-ajuda";
import { ContainerOpcoesMaster, LinkOpcoesMaster } from "./styles";

interface ProvedorAjudaProps {
  id: string;
  children: React.ReactNode;
}

const Editor = (props: { id: string }) => {
  const [editorSlugVisivel, setEditorSlugVisivel] = useState(false);

  return (
    <ContainerOpcoesMaster>
      <LinkOpcoesMaster href="#" onClick={() => setEditorSlugVisivel(true)}>
        {"Editar link da página de ajuda para tooltips [master]"}
      </LinkOpcoesMaster>
      <EditFormLinkAjuda
        codigo={props.id}
        visivel={editorSlugVisivel}
        idRegistroEdicao={0}
        configuracoesModal={{ altura: "auto" }}
        callBackFecharModal={() => setEditorSlugVisivel(false)}
      />
    </ContainerOpcoesMaster>
  );
};

export default function ProvedorAjuda({ id, children }: ProvedorAjudaProps) {
  const slug = useAppSelector(
    (state) => state.estadoUI.linksAjuda.find((a) => a.valor === id)?.descricao
  );

  const master = useAppSelector(
    (state) => state.sessao.dadosSessao?.usuario.isUsuarioMaster
  );

  const [provedor, setProvedor] = useState<ProvedorToolTip | null>(null);

  useEffect(() => {
    if (slug) {
      ProvedorToolTip.criarProvedorToolTip(slug).then((provedor) => {
        setProvedor(provedor);
      });
    }
  }, [slug]);

  return (
    <>
      <ContextoAjuda.Provider value={provedor}>
        {children}
      </ContextoAjuda.Provider>
      {master && <Editor id={id} />}
    </>
  );
}
