import { useEffect, useState } from "react";
import * as yup from "yup";
import {
  FormGrupo,
  FormTextBoxSimples,
} from "../../../../components/formularios";
import FormTextArea from "../../../../components/formularios/textarea";
import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../components/layout/grid-system";
import { useHookForms } from "../../../../hooks/form.hooks";
import { useAppDispatch, useAppSelector } from "../../../../hooks/store.hooks";
import { MDFeCancelamentoRequestDTO } from "../../../../models/api/mdfe/mdfe-request-response";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import API from "../../../../services/mdfe/mdfe.service";
import { carregar } from "../../../../store/mdfe/mdfe.slice";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";

const novoRegistro: MDFeCancelamentoRequestDTO = {
  justificativa: "",
};

export const EditFormCancelamentoMDFe = (props: FormularioEdicaoBaseProps) => {
  const dispatch = useAppDispatch();

  const [carregando, setCarregando] = useState(false);

  const schema = yup.object().shape({
    justificativa: yup.string().required().min(15),
  });

  const dadosMDFe = useAppSelector((state) => {
    return {
      idMdfeDocumento: state.mdfe.documentoAtual.id,
      numeroMDFe: state.mdfe.documentoAtual.numero,
      serieMDFe: state.mdfe.documentoAtual.serie,
    };
  });

  const hookForm = useHookForms(schema);

  const { control, handleSubmit, getValues, reset } = hookForm;

  useEffect(() => {
    if (props.visivel) {
      carregarTela();
    }
  }, [props.visivel]);

  async function carregarTela() {
    reset(novoRegistro);
  }

  async function handleCancelarMDFe() {
    try {
      setCarregando(true);
      const model = getValues();
      const resposta = await API.cancelarMDFe(
        dadosMDFe.idMdfeDocumento,
        model.justificativa
      );
      checarResponse(resposta);
      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({
          concluido: true,
          precisaAtualizar: false,
        });
        dispatch(carregar(resposta.model.documento));
      }
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    }
    setCarregando(false);
  }

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      props.callBackFecharModal(info);
    }
  }

  function handleCancelar() {
    fechar({ concluido: false, precisaAtualizar: false });
  }

  function callBackUnprocessableEntity() {
    fechar({
      concluido: false,
      precisaAtualizar: true,
    });
  }

  return (
    <>
      <FormBase2
        visivel={props.visivel}
        carregando={carregando}
        onClickSalvar={handleSubmit(handleCancelarMDFe)}
        onClickCancelar={handleCancelar}
        configuracoesModal={props.configuracoesModal}
        modoEdicao={props.idRegistroEdicao == 0 ? "criar" : "editar"}
        titulo={"Cancelamento de MDF-e"}
      >
        <FormGrupo>
          <Linha>
            <Coluna md={6}>
              <FormTextBoxSimples
                titulo="Número"
                valor={dadosMDFe.numeroMDFe?.toString() ?? ""}
                toolTip="Campo referente ao número da MDF-e"
                tipo="text"
                somenteLeitura={true}
              />
            </Coluna>
            <Coluna md={6}>
              <FormTextBoxSimples
                titulo="Série"
                valor={dadosMDFe.serieMDFe}
                toolTip="Campo referente a série do documento MDF-e"
                tipo="text"
                somenteLeitura={true}
              />
            </Coluna>
          </Linha>
          <Linha>
            <Coluna md={12}>
              <FormTextArea
                name={"justificativa"}
                titulo="Justificativa"
                toolTip="Justificativa do cancelamento da MDF-e"
                requerido
                valor=""
                control={control}
                tamanhoMaximo={255}
                height={25}
              />
            </Coluna>
          </Linha>
        </FormGrupo>
      </FormBase2>
    </>
  );
};
