import { PermissoesOrdemDeProducao } from "../../../../../models/permissoes/producao/ordem-de-producao/OrdemDeProducaoPermissoes";
import { tratarErroApi } from "../../../../../utils/api/api-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import { OrdemDeProducaoGridModel } from "../../models/ordem-de-producao.api";
import { OrdemDeProducaoService } from "../../servicos/ordem-de-producao.service";
import {
  AcoesOrdensDeProducao,
  handlerAcaoOrdensDeProducaoResponse,
} from "./acoes-ordem-de-producao-helper";

async function suspenderOrdensDeProducaoHandler(
  service: OrdemDeProducaoService,
  registros: OrdemDeProducaoGridModel[],
  handleAtualizarGrid: () => void,
  exibirMensagemDeConfirmacao: boolean
) {
  if (
    !verificaComNotificacaoSeUsuarioPossuiPermissoes([
      PermissoesOrdemDeProducao.InserirEditar,
    ])
  ) {
    return;
  }

  if (exibirMensagemDeConfirmacao) {
    const confirmar = await exibirConfirmacao(
      "Suspender ordens de produção",
      "Tem certeza de que deseja suspender as ordens de produção selecionadas?"
    );

    if (!confirmar) {
      return;
    }
  }

  try {
    const response = await service.SuspenderOrdensDeProducao(
      registros.map((i) => i.id)
    );

    handleAtualizarGrid();

    const acoes: AcoesOrdensDeProducao = {
      acao: "suspender",
      acaoEstadoDasOps: "suspensas",
    };
    return handlerAcaoOrdensDeProducaoResponse(response, acoes);
  } catch (erro) {
    tratarErroApi(erro);
  }
}

export { suspenderOrdensDeProducaoHandler };
