import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import { useCallback, useEffect, useMemo, useRef } from "react";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useControlarFormDeEdicao } from "../../../../../hooks/form.hooks";
import { useParametroId } from "../../../../../hooks/route.hooks";
import { decodificaBooleanoEmSimNao } from "../../../../../models/api/comum/decodificadores";
import { CertificadoDigitalPermissoes } from "../../../../../models/permissoes/sistema/certificado-digital/CertificadoDigitalPermissoes";
import { ResultadoAcaoFormulario } from "../../../../../models/shared/ui/formularios";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import { checarResponseExibeMensagemExclusaoDeSucesso } from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesModais from "../../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import NormalizaTituloModal from "../../../../../utils/common/normaliza-titulo";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import UrlUtils from "../../../../../utils/url/url-utils";
import { CertificadoDigitalGridModel } from "../../models/certificado-digital";
import CertificadoDigitalService from "../../servicos/certificado-digital.service";
import EditFormCertificadoDigital from "../formulario";

const servico = new CertificadoDigitalService();
const dataSource = servico.ObterDataSourceParaGrid();
const nameOfGridHandler = criarNameof<CertificadoDigitalGridModel>();

export default function GridCertificadoDigital() {
  const gridRef = useRef<DataGridRef>(null);
  const parametroId = useParametroId();

  const {
    idRegistroEmEdicao,
    setIdRegistroEmEdicao,
    modalVisivel,
    encerrarEdicao,
  } = useControlarFormDeEdicao(NaN);

  const handleNovoRegistro = useCallback(() => {
    if (
      verificaComNotificacaoSeUsuarioPossuiPermissoes([
        CertificadoDigitalPermissoes.InserirEditar,
      ])
    ) {
      setIdRegistroEmEdicao(0);
    }
  }, []);

  const handleEditarRegistro = useCallback(
    (registro: CertificadoDigitalGridModel) => {
      if (
        verificaComNotificacaoSeUsuarioPossuiPermissoes([
          CertificadoDigitalPermissoes.InserirEditar,
        ])
      ) {
        setIdRegistroEmEdicao(registro.id);
      }
    },
    []
  );

  const handleExcluirRegistro = useCallback(
    async (registro: CertificadoDigitalGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          CertificadoDigitalPermissoes.Excluir,
        ])
      ) {
        return;
      }

      const excluir = await exibirConfirmacao(
        "Confirmar exclusão",
        obterMensagemExclusao(registro)
      );

      if (excluir) {
        const resposta = await servico.Excluir(registro.id);

        if (resposta) {
          checarResponseExibeMensagemExclusaoDeSucesso(resposta);
          handleAtualizarGrid();
        }
      }
    },
    []
  );

  function obterMensagemExclusao(registro: CertificadoDigitalGridModel) {
    return `Tem certeza que deseja excluir o registro ${registro.descricao}?`;
  }

  const gridController = new GridController<CertificadoDigitalGridModel>(() =>
    gridRef.current?.instance()
  );

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar("certificado-digital", () =>
      gridRef.current?.instance()
    )
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.certificadoDigital)
      .definirBotaoNovo(handleNovoRegistro)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .definirDuploCliqueLinha(handleEditarRegistro)
      .build();
  }, []);

  useEffect(() => {
    if (parametroId) {
      setIdRegistroEmEdicao(parametroId);
    }
  }, []);

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  useRegistrarAtalhosGrid<CertificadoDigitalGridModel>({
    controller: gridController,
    handleNovo: handleNovoRegistro,
    handleEditar: handleEditarRegistro,
    handleExcluir: handleExcluirRegistro,
  });

  const handleCallbackFormulario = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      encerrarEdicao();

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }

      UrlUtils.RemoverParametroId(parametroId);
    },
    [parametroId]
  );

  const handleFecharModal = useCallback(() => {
    encerrarEdicao();
    UrlUtils.RemoverParametroId(parametroId);
  }, [parametroId]);

  return (
    <>
      <DataGrid ref={gridRef} {...configuracoesGrid}>
        {GridColunaAcoes<CertificadoDigitalGridModel>({
          handleEditar: handleEditarRegistro,
          handleExcluir: handleExcluirRegistro,
        })}
        <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
        <Column
          key={nameOfGridHandler("id")}
          dataField={nameOfGridHandler("id")}
          {...obterConfiguracaoColuna("id")}
        />
        <Column
          key={nameOfGridHandler("descricao")}
          dataField={nameOfGridHandler("descricao")}
          {...obterConfiguracaoColuna("stringGG")}
          caption="Descrição"
        />
        <Column
          key={nameOfGridHandler("vencimentoData")}
          dataField={nameOfGridHandler("vencimentoData")}
          {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
          caption="Data de vencimento"
          width={180}
        />
        <Column
          key={nameOfGridHandler("ativo")}
          dataField={nameOfGridHandler("ativo")}
          {...obterConfiguracaoColuna("boolSimNao")}
          caption="Ativo"
          visible={false}
          cellRender={({ data }) => {
            const dados = data as CertificadoDigitalGridModel;
            return decodificaBooleanoEmSimNao(dados.ativo);
          }}
        />
        {GetColunasDeAuditoria()}
      </DataGrid>

      <ModalMxp
        titulo={NormalizaTituloModal.Normalizar(
          idRegistroEmEdicao,
          NomesModais.certificadoDigital
        )}
        visivel={modalVisivel}
        handleFechar={handleFecharModal}
        largura={"max(50vw, 800px)"}
      >
        <EditFormCertificadoDigital
          idRegistroEmEdicao={idRegistroEmEdicao}
          handleCallback={handleCallbackFormulario}
        />
      </ModalMxp>
    </>
  );
}
