export enum OperacaoEstado {
  Iniciada = 73,
  Concluida = 67,
  NaoIniciada = 78,
}

export class EstadoOperacaoHelper {
  public static estadoDescricao: {
    [key in OperacaoEstado]: string;
  } = {
    [OperacaoEstado.Iniciada]: "Iniciada",
    [OperacaoEstado.NaoIniciada]: "Não iniciada",
    [OperacaoEstado.Concluida]: "Concluída",
  };

  public static getDescricao(tipo: OperacaoEstado): string {
    return (
      EstadoOperacaoHelper.estadoDescricao[tipo] || "Descrição desconhecida"
    );
  }
}
