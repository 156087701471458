import Decimal from "decimal.js";

export const simPorExtenso = "Sim";

export const naoPorExtenso = "Não";

export const ativoPorExtenso = "Ativo";

export const inativoPorExtenso = "Inativo";

export const quantidadeValorMinimo: Decimal = new Decimal(0);

export const quantidadeValorMaximo: Decimal = new Decimal(9_999_999_999.99999);

export const ordemValorMinimo = 0;

export const ordemValorMaximo = 999;

export const AssinanteDefaultId = 2;
