import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TiposTokenAcesso } from "../../features/seguranca/login/models/login.enums";
import EstadoTelaUsuarioUI, {
  DadosTokenAcessoUsuario,
} from "../../models/shared/usuario/estado-tela-usuario";

const INITIAL_STATE: EstadoTelaUsuarioUI = {
  modalGerenciamentoTokensAcessoUsuario: {
    visivel: false,
    dadosToken: {
      idUsuario: NaN,
      tipo: TiposTokenAcesso.GraphQl,
    },
  },
};

const estadoTelaUsuarioSlice = createSlice({
  name: "telaUsuario",
  initialState: INITIAL_STATE,
  reducers: {
    exibirModalGerenciamentoTokensAcessoUsuario:
      exibirModalGerenciamentoTokensAcessoUsuarioAction,
    ocultarModalGerenciamentoTokensAcessoUsuario:
      ocultarModalGerenciamentoTokensAcessoUsuarioAction,
  },
});

function ocultarModalGerenciamentoTokensAcessoUsuarioAction(
  state: EstadoTelaUsuarioUI
) {
  state.modalGerenciamentoTokensAcessoUsuario =
    INITIAL_STATE.modalGerenciamentoTokensAcessoUsuario;
}

function exibirModalGerenciamentoTokensAcessoUsuarioAction(
  state: EstadoTelaUsuarioUI,
  action: PayloadAction<DadosTokenAcessoUsuario>
) {
  state.modalGerenciamentoTokensAcessoUsuario.visivel = true;
  state.modalGerenciamentoTokensAcessoUsuario.dadosToken = action.payload;
}

export const {
  exibirModalGerenciamentoTokensAcessoUsuario,
  ocultarModalGerenciamentoTokensAcessoUsuario,
} = estadoTelaUsuarioSlice.actions;
export default estadoTelaUsuarioSlice;
