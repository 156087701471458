import qs from "qs";
import getApi from "../../configs/api";
import { ConjuntoVeiculoResponseDTO } from "../../features/vendas/conjunto-veiculo/models/conjunto-veiculo";
import {
  ListaSimplesResponse,
  ResponseModel,
} from "../../models/api/comum/response-base";
import criarDatasourceGrid from "../../utils/grid/back-end-grid-utils";

// Mantido devido à aba de veículos do MDF-e
const nomeEndpoint: string = "VeiculoConjunto";

function getGridSource() {
  return criarDatasourceGrid(`${nomeEndpoint}/grid`);
}

async function obterPorId(id: number) {
  const api = getApi();
  const response = await api.get<ResponseModel<ConjuntoVeiculoResponseDTO>>(
    `${nomeEndpoint}?id=${id}`
  );

  return response.data;
}

async function obterPorIdComDadosAuditoria(id: number) {
  const api = getApi();
  const response = await api.get<ResponseModel<ConjuntoVeiculoResponseDTO>>(
    `${nomeEndpoint}/ObterPorIdComDadosAuditoria?id=${id}`
  );

  return response.data;
}

async function obterListaSimples() {
  const api = getApi();

  const response = await api.get<ListaSimplesResponse>(
    `${nomeEndpoint}/lista-simples`,
    {
      paramsSerializer: {
        serialize: (x) => {
          return qs.stringify(x);
        },
      },
    }
  );

  return response.data;
}

export default {
  getGridSource,
  obterPorId,
  obterPorIdComDadosAuditoria,
  obterListaSimples,
};
