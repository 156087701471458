import styled from 'styled-components';

export const PdfContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    embed {
        width: 100vw;
        height: 100vh;
    }
`;
