import { DataGridRef } from "devextreme-react/cjs/data-grid";
import { createContext } from "react";

interface SeletorContextModel {
  refGrid: DataGridRef | null | undefined;
  setRefGrid: (func: () => DataGridRef | null | undefined) => void;
  setFiltroPadraoNoCliente: (filtro?: any) => void;
  filtroPadraoNoCliente?: any;
}

export const ContextoSeletor = createContext<SeletorContextModel>({
  refGrid: null,
  setRefGrid: () => {
    // implementação default
  },
  setFiltroPadraoNoCliente: () => {
    // implementação default
  },
  filtroPadraoNoCliente: undefined,
});
