import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../../../../hooks/store.hooks";
import Sessao from "../../../../../models/dto/sessao/sessao";
import {
  bloquearUI,
  definirEstadoRedirecionando,
  desbloquearUI,
} from "../../../../../store/ui/ui.slice";
import { tratarErroApi } from "../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { GravarSessaoReduxELocalStorage } from "../../../../../utils/oauth/oauth-utils";
import { TrocaEmpresaLoginRequest } from "../../models/login.api";
import TokenService from "../../servicos/token.servico";

const tokenService = new TokenService();

export default function TrocaEmpresaRedirect() {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const id = searchParams.get("id");
  const url = searchParams.get("url");
  const [trocando, setTrocando] = useState(false);

  useEffect(() => {
    executarTrocaEmpresa();
  });

  async function executarTrocaEmpresa() {
    try {
      if (trocando) {
        return;
      }

      setTrocando(true);
      dispatch(bloquearUI("Aguarde..."));
      dispatch(definirEstadoRedirecionando(true));

      const trocaEmpresaLoginRequest: TrocaEmpresaLoginRequest = {
        idEmpresa: Number(id),
      };

      const resposta = await tokenService.TrocaEmpresaLogin(
        trocaEmpresaLoginRequest
      );

      if (resposta) {
        const dadosSessaoResposta = await tokenService.getDadosSessao(resposta);
        const sessao: Sessao = {
          dadosSessao: dadosSessaoResposta,
        };
        GravarSessaoReduxELocalStorage(sessao, resposta);
        window.location.href = url!;
      } else {
        exibirNotificacaoToast({
          mensagem: "Não foi possível trocar de empresa",
          tipo: TipoNotificacao.Erro,
        });
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      dispatch(desbloquearUI());
    }
  }

  return <></>;
}
