import PaginaMxp from "../../../../../components/layout/pagina-mxp";
import ProvedorMenus from "../../../../../components/menus/provedor-menus";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import GridUsuario from "../../componentes/grid";

export default function UsuarioPage() {
  return (
    <ProvedorMenus>
      <PaginaMxp
        id="usuario-page"
        titulo={NomesTelas.usuarios}
        area={["Segurança", "Usuários"]}
      >
        <GridUsuario />
      </PaginaMxp>
    </ProvedorMenus>
  );
}
