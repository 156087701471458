import { ItemGridModel } from "../../../../features/itens/item/models/item.api";
import {
  Estado,
  EstocagemTipo,
  ProcedenciaDecodificadaTipo,
} from "../../../../features/itens/item/models/item.enums";
import criarNameof from "../../../common/cria-name-of";
import { DataSourceFiltragem } from "../../../grid/data-source-factory";

export default class FiltrosGridItem {
  static readonly itemLoteOuSerieAtivo: any[] = [
    [
      [
        criarNameof<ItemGridModel>()("estocagemPor"),
        "=",
        EstocagemTipo.LoteFabricante,
      ],
      "or",
      [criarNameof<ItemGridModel>()("estocagemPor"), "=", EstocagemTipo.Serie],
    ],
    "and",
    [
      [criarNameof<ItemGridModel>()("estado"), "=", Estado.Ativo],
      "or",
      [criarNameof<ItemGridModel>()("estado"), "=", Estado.Digitacao],
    ],
  ];

  static readonly itemAtivo: any[] = [
    [criarNameof<ItemGridModel>()("estado"), "=", Estado.Ativo],
  ];

  static readonly itemGridSemProcedenciaAgregadaOuManutencaoOuAlternativo: DataSourceFiltragem<ItemGridModel>[] =
    [
      { campo: "estado", operador: "<>", valor: Estado.Inativo },
      {
        campo: "procedencia",
        operador: "<>",
        valor: ProcedenciaDecodificadaTipo.Conjunto,
      },
      {
        campo: "procedencia",
        operador: "<>",
        valor: ProcedenciaDecodificadaTipo.Alternativo,
      },
      {
        campo: "procedencia",
        operador: "<>",
        valor: ProcedenciaDecodificadaTipo.Agregado,
      },
    ];
}
