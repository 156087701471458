import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../components/botoes/botao-salvar-mxp";
import { FormSelectBoxLazyMxp } from "../../../../../components/formularios";
import { assertConfiguracaoExibicaoEBuscaType } from "../../../../../components/formularios/selectbox-lazy-mxp";
import FormMxp from "../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import AuditavelDTO from "../../../../../models/api/comum/auditavel-dto";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../models/shared/ui/formularios";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import {
  FormataDescricao,
  FormatadoresSelectBox,
} from "../../../../../utils/formatadores/formatador-de-selectbox";
import ComboEnderecoEstoqueMxp from "../../../../estoque/endereco-de-estoque/componentes/parts/combo-endereco-estoque";
import GridCentroDeTrabalho from "../../../centro-de-trabalho/componentes/grid";
import { CentroDeTrabalhoGridModel } from "../../../centro-de-trabalho/models/centro-de-trabalho.api";
import CentroDeTrabalhoServico from "../../../centro-de-trabalho/servicos/centro-de-trabalho.servico";
import {
  VinculoEnderecoEstoqueCentroDeTrabalhoRequestDTO,
  VinculoEnderecoEstoqueCentroDeTrabalhoResponseDTO,
} from "../../models/vinculo-endereco-estoque-centro-de-trabalho.api";
import VinculoEnderecoEstoqueCentroDeTrabalhoServico from "../../servicos/vinculo-endereco-estoque-centro-de-trabalho.service";

let dadosAuditoria: AuditavelDTO | undefined = undefined;

const service = new VinculoEnderecoEstoqueCentroDeTrabalhoServico();
const centroDeTrabalhoService = new CentroDeTrabalhoServico();

const dataSourceCentroDeTrabalho =
  centroDeTrabalhoService.ObterDataSourceParaSelectBoxLazy<CentroDeTrabalhoGridModel>(
    {
      camposRetorno: ["id", "codigo", "descricao"],
      camposOrdenacao: [
        {
          campo: "codigo",
          desc: false,
        },
      ],
    }
  );
const configuracoesExibicaoEBuscaCentroDeTrabalho =
  assertConfiguracaoExibicaoEBuscaType<CentroDeTrabalhoGridModel>({
    nomeCampoChave: "id",
    expressaoDeBusca: ["codigo", "descricao"],
    nomeCampoExibicao: (c) => {
      if (c) {
        return FormataDescricao(
          FormatadoresSelectBox.CodigoDescricaoParenteses,
          c.codigo,
          c.descricao
        );
      }

      return "";
    },
  });

const novoRegistro: VinculoEnderecoEstoqueCentroDeTrabalhoRequestDTO = {
  id: 0,
  idCentroDeTrabalho: null,
  idEnderecoEstoque: null,
};

export default function FormVinculoEnderecoDeEstoqueCentroDeTrabalho(
  props: IFormularioEditavelBase
) {
  const [carregando, setCarregando] = useState(false);

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    idCentroDeTrabalho: yup.number().required().moreThan(0).integer(),
    idEnderecoEstoque: yup.number().required().moreThan(0).integer(),
  });

  const { control, handleSubmit, getValues, reset, register } =
    useForm<VinculoEnderecoEstoqueCentroDeTrabalhoRequestDTO>({
      resolver: yupResolver(schema),
    });

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();

    return () => limparTela();
  }, [props.idRegistroEmEdicao]);

  async function preencherTela() {
    if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    }
  }

  async function carregarRegistroDoId() {
    try {
      setCarregando(true);
      const resposta =
        await service.ObterPorIdComDadosAuditoria<VinculoEnderecoEstoqueCentroDeTrabalhoResponseDTO>(
          props.idRegistroEmEdicao
        );
      checarResponse(resposta);
      dadosAuditoria = resposta.model;
      reset(resposta.model);
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();

    try {
      const resposta =
        props.idRegistroEmEdicao > 0
          ? await service.Atualizar(model)
          : await service.Cadastrar(model);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
      fechar(ResultadoAcaoFormulario.AcaoCancelada);
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function callBackUnprocessableEntity() {
    fechar(ResultadoAcaoFormulario.AcaoConcluida);
  }

  function limparTela() {
    dadosAuditoria = undefined;
    reset(novoRegistro);
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  return (
    <>
      <ContainerFormMxp>
        <FormMxp carregando={carregando}>
          <input type="hidden" {...register("id")} defaultValue={0} />
          <Linha>
            <Coluna md={12}>
              <FormSelectBoxLazyMxp
                name="idCentroDeTrabalho"
                titulo="Centro de trabalho"
                control={control}
                requerido
                dataSource={dataSourceCentroDeTrabalho}
                configuracoesExibicaoEBusca={
                  configuracoesExibicaoEBuscaCentroDeTrabalho
                }
                labelSemDados="Sem dados"
                seletorConfig={{
                  modo: "selecaoUnica",
                  titulo: "Selecionar centro de trabalho",
                  componenteGrid: <GridCentroDeTrabalho />,
                }}
              />
            </Coluna>
          </Linha>
          <Linha>
            <Coluna md={12}>
              <ComboEnderecoEstoqueMxp
                name="idEnderecoEstoque"
                titulo="Endereço de estoque"
                control={control}
                requerido
                tituloSeletor="Selecionar o endereco de estoque do centro de trabalho"
              />
            </Coluna>
          </Linha>
        </FormMxp>
        <ToolbarMxp dadosAuditoria={dadosAuditoria}>
          <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </>
  );
}
