import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ProvedorAjuda from "../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../components/botoes/botao-salvar-mxp";
import {
  FormDateBox,
  FormTextBoxSimples,
} from "../../../../components/formularios";
import FormMxp from "../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../components/layout/toolbar-mxp";
import { useAppSelector } from "../../../../hooks/store.hooks";
import { ApuracaoCiapCadastrarRequest } from "../../../../models/api/apuracao-ciap/apuracao-ciap";
import AuditavelDTO from "../../../../models/api/comum/auditavel-dto";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../models/shared/ui/formularios";
import { ApuracaoDoCiapService } from "../../../../services/apuracao-ciap/apuracao-ciap.service";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";
import { formatarCpfCnpj } from "../../../../utils/formatadores/formatador-de-strings";

const novoRegistro: ApuracaoCiapCadastrarRequest = {
  dataInicial: "",
  dataFinal: "",
};

const service = new ApuracaoDoCiapService();

let dadosAuditoria: AuditavelDTO | undefined = undefined;

export default function EditFormInsercaoApuracaoCiap(
  props: IFormularioEditavelBase
) {
  const [carregando, setCarregando] = useState(false);
  const empresaLogada = useAppSelector((state) => {
    return {
      Apelido: state.sessao.dadosSessao?.empresa.apelido,
      CpfCpj: state.sessao.dadosSessao?.empresa.cnpjCpfCodigo,
    };
  });

  const schema = yup.object().shape({
    dataInicial: yup
      .string()
      .required()
      .test(
        "data_inicial_valida",
        "A data inicial está maior do que a final.",
        function (value, contexto) {
          if (value && contexto.parent.dataFinal) {
            const dataInicial = new Date(value);
            const dataFinal = new Date(contexto.parent.dataFinal);
            return dataInicial <= dataFinal;
          }
          return true;
        }
      ),
    dataFinal: yup
      .string()
      .required()
      .test(
        "data_final_valida",
        "A data final está menor do que a inicial.",
        function (valor, contexto) {
          if (valor && contexto.parent.dataInicial) {
            const dataInicial = new Date(contexto.parent.dataInicial);
            const dataFinal = new Date(valor);
            return dataInicial <= dataFinal;
          }
          return true;
        }
      ),
  });

  const { control, handleSubmit, getValues, reset } =
    useForm<ApuracaoCiapCadastrarRequest>({
      resolver: yupResolver(schema),
    });

  function limparTela() {
    dadosAuditoria = undefined;
    reset(novoRegistro);
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();
    try {
      const resposta = await service.Cadastrar(model);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  return (
    <>
      <ContainerFormMxp>
        <FormMxp carregando={carregando}>
          <ProvedorAjuda id="edit-form-apuracao-ciap">
            <Linha>
              <Coluna md={8}>
                <FormTextBoxSimples
                  titulo={"Minha empresa"}
                  valor={`${empresaLogada.Apelido} - ${formatarCpfCnpj(
                    empresaLogada.CpfCpj!
                  )}`}
                  somenteLeitura
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={3} classe="flex-none">
                <FormDateBox
                  name="dataInicial"
                  titulo="Período de"
                  control={control}
                  requerido
                  exibirBotaoLimpar
                  tipo="date"
                  aceitaValorCustomizado={true}
                  formatoExibicao="dd/MM/yy"
                  aceitaDigitacaoComMascara={true}
                />
              </Coluna>
              <Coluna md={3}>
                <FormDateBox
                  name="dataFinal"
                  titulo="a"
                  control={control}
                  requerido
                  exibirBotaoLimpar
                  tipo="date"
                  aceitaValorCustomizado={true}
                  formatoExibicao="dd/MM/yy"
                  aceitaDigitacaoComMascara={true}
                />
              </Coluna>
            </Linha>
          </ProvedorAjuda>
        </FormMxp>
        <ToolbarMxp dadosAuditoria={dadosAuditoria}>
          <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </>
  );
}
