import { useMemo } from "react";
import { FieldValues } from "react-hook-form";
import { FormSelectBoxLazyMxp } from "../../../../../components/formularios";
import { ComboEntidadePropsBase } from "../../../../../components/formularios/selectbox-lazy-mxp";
import { DataSourceOpcoesBuilder } from "../../../../../utils/grid/data-source-factory";
import { PedidoDeVendaGridModel } from "../../models/pedidos-de-venda.api";
import PedidosDeVendaService from "../../servicos/pedidos-de-venda.servico";
import GridPedidoDeVenda from "../grid";

const service = new PedidosDeVendaService();

export interface ComboPedidoDeVendaProps<T extends FieldValues>
  extends ComboEntidadePropsBase<T, PedidoDeVendaGridModel> {
  tituloSeletor?: string;
  filtrosNoCliente?: any[] | undefined;
  filtrosNoServidor?: any[] | undefined;
  dataSourceOpcoes?: DataSourceOpcoesBuilder<PedidoDeVendaGridModel>;
}

export default function ComboPedidoDeVenda<T extends FieldValues>({
  tituloSeletor,
  filtrosNoCliente,
  filtrosNoServidor,
  dataSourceOpcoes,
  ...selectBoxProps
}: ComboPedidoDeVendaProps<T>) {
  const [
    dataSourceItemPedidoDeVenda,
    configuracoesExibicaoEBuscaItemPedidoDeVenda,
  ] = useMemo(
    () => service.GetDadosSelectBoxPedidoDeVenda(dataSourceOpcoes),
    [dataSourceOpcoes]
  );

  return (
    <FormSelectBoxLazyMxp
      {...selectBoxProps}
      configuracoesExibicaoEBusca={configuracoesExibicaoEBuscaItemPedidoDeVenda}
      dataSource={dataSourceItemPedidoDeVenda}
      seletorConfig={{
        modo: "selecaoUnica",
        titulo: tituloSeletor ?? "Selecionar item",
        componenteGrid: (
          <GridPedidoDeVenda
            filtrosNoServidor={
              filtrosNoServidor ?? dataSourceOpcoes?.camposFiltro
            }
            filtrosNoCliente={filtrosNoCliente}
          />
        ),
      }}
      labelSemDados="Sem dados"
    />
  );
}
