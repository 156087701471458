import PaginaMxp from "../../../../../components/layout/pagina-mxp";
import ProvedorMenus from "../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../components/seguranca/requer-permissao";
import { CertificadoDigitalPermissoes } from "../../../../../models/permissoes/sistema/certificado-digital/CertificadoDigitalPermissoes";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import GridCertificadoDigital from "../../componentes/grid";

export default function CertificadoDigitalPage() {
  return (
    <ProvedorMenus>
      <PaginaMxp
        id="certificado-digital-page"
        titulo={NomesTelas.certificadoDigital}
        area={["Fiscal", "EFD Reinf"]}
      >
        <RequerPermissao
          codigoPermissoes={[CertificadoDigitalPermissoes.Consultar]}
        >
          <GridCertificadoDigital />
        </RequerPermissao>
      </PaginaMxp>
    </ProvedorMenus>
  );
}
