import PaginaMxp from "../../../../../components/layout/pagina-mxp";
import ProvedorMenus from "../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../components/seguranca/requer-permissao";
import { PermissoesContaParteB } from "../../../../../models/permissoes/contabilidade/conta-parte-b/permissoes-conta-parte-b";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import GridContaDaParteB from "../../componentes/grid";
import FiltrosGridContaParteB from "../../models/conta-parte-b.filtros";

export default function ContaDaParteBPage() {
  return (
    <ProvedorMenus>
      <PaginaMxp
        id="conta-da-parte-b-page"
        titulo={NomesTelas.contaDaParteB}
        area={["Contabilidade", "Cadastros"]}
      >
        <RequerPermissao codigoPermissoes={[PermissoesContaParteB.Consultar]}>
          <GridContaDaParteB filtrosNoCliente={FiltrosGridContaParteB.ativo} />
        </RequerPermissao>
      </PaginaMxp>
    </ProvedorMenus>
  );
}
