import DataGrid, { Column, DataGridRef } from "devextreme-react/cjs/data-grid";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import GridColunaAcoes from "../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { ModalMxp } from "../../../../components/layout/modal-mxp";
import { useRegistrarAtalhosGrid } from "../../../../hooks/atalhos.hooks";
import { useParametroId } from "../../../../hooks/route.hooks";
import { ApuracaoDoCiapGridModel } from "../../../../models/api/apuracao-ciap/apuracao-ciap";
import { ResultadoAcaoFormulario } from "../../../../models/shared/ui/formularios";
import { ApuracaoDoCiapService } from "../../../../services/apuracao-ciap/apuracao-ciap.service";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../utils/api/api-utils";
import criarNameof from "../../../../utils/common/cria-name-of";
import NomesModais from "../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../utils/common/nomes-telas";
import NormalizaTituloModal from "../../../../utils/common/normaliza-titulo";
import { exibirConfirmacao } from "../../../../utils/dialogos";
import { formatarData } from "../../../../utils/formatadores/formatador-de-datas";
import GridBuilder from "../../../../utils/grid/grid-builder";
import { GridController } from "../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../utils/grid/padroes-colunas";
import UrlUtils from "../../../../utils/url/url-utils";
import GetColunasDeAuditoria from "../../../layout/grid-defaults/colunasDeAuditoria";
import EditFormAlteracaoApuracaoDoCiap from "../form-edicao";
import EditFormInsercaoApuracaoCiap from "../form-insercao";

const service = new ApuracaoDoCiapService();
const dataSource = service.ObterDataSourceParaGrid<ApuracaoDoCiapGridModel>();
const nameOfGridHandler = criarNameof<ApuracaoDoCiapGridModel>();

export default function GridApuracaoDoCiap() {
  const gridRef = useRef<DataGridRef>(null);
  const parametroId = useParametroId();
  const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);

  const handleNovoRegistro = useCallback(() => {
    setIdRegistroEdicao(0);
  }, []);

  const handleEditarRegistro = useCallback(
    (registro: ApuracaoDoCiapGridModel) => {
      setIdRegistroEdicao(registro.id);
    },
    []
  );

  const handleExcluirRegistro = useCallback(
    async (registro: ApuracaoDoCiapGridModel) => {
      const excluir = await exibirConfirmacao(
        "Confirmar exclusão",
        obterMensagemExclusao(registro)
      );

      if (excluir) {
        try {
          const resposta = await service.Excluir(registro.id);

          if (resposta) {
            checarResponseExibeMensagemExclusaoDeSucesso(resposta);
            handleAtualizarGrid();
          }
        } catch (erro) {
          tratarErroApi(erro);
        }
      }
    },
    []
  );

  function obterMensagemExclusao(registro: ApuracaoDoCiapGridModel) {
    return `Tem certeza que deseja excluir a apuração de ${formatarData(
      registro.dataInicial
    )} a ${formatarData(registro.dataFinal)}?`;
  }

  const gridController = new GridController<ApuracaoDoCiapGridModel>(() =>
    gridRef.current?.instance()
  );

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar("codigo-tributacao-servico", () =>
      gridRef.current?.instance()
    )
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.apuracaoDoCiap)
      .definirBotaoNovo(handleNovoRegistro)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .definirDuploCliqueLinha(handleEditarRegistro)
      .build();
  }, []);

  useEffect(() => {
    if (parametroId) {
      setIdRegistroEdicao(parametroId);
    }
  }, []);

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  useRegistrarAtalhosGrid<ApuracaoDoCiapGridModel>({
    controller: gridController,
    handleNovo: handleNovoRegistro,
    handleEditar: handleEditarRegistro,
    handleExcluir: handleExcluirRegistro,
  });

  const handleCallbackFormulario = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      setIdRegistroEdicao(NaN);

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }

      UrlUtils.RemoverParametroId(parametroId);
    },
    [parametroId]
  );

  const handleFecharModal = useCallback(() => {
    setIdRegistroEdicao(NaN);
    UrlUtils.RemoverParametroId(parametroId);
  }, [parametroId]);

  return (
    <>
      <DataGrid ref={gridRef} {...configuracoesGrid}>
        {GridColunaAcoes<ApuracaoDoCiapGridModel>({
          handleEditar: handleEditarRegistro,
          handleExcluir: handleExcluirRegistro,
        })}
        <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
        <Column
          key={nameOfGridHandler("dataInicial")}
          dataField={nameOfGridHandler("dataInicial")}
          {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
          caption="Data inicial"
          sortIndex={0}
          sortOrder="desc"
          width={140}
        />
        <Column
          key={nameOfGridHandler("dataFinal")}
          dataField={nameOfGridHandler("dataFinal")}
          {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
          caption="Data final"
          sortIndex={1}
          sortOrder="desc"
          width={140}
        />
        <Column
          key={nameOfGridHandler("saldoInicialDoIcms")}
          dataField={nameOfGridHandler("saldoInicialDoIcms")}
          {...obterConfiguracaoColuna("monetario")}
          caption="Saldo inicial do ICMS"
          width={180}
        />
        <Column
          key={nameOfGridHandler("totalDasParcelasDeIcms")}
          dataField={nameOfGridHandler("totalDasParcelasDeIcms")}
          {...obterConfiguracaoColuna("monetario")}
          caption="Total das parcelas do ICMS"
          width={215}
        />
        <Column
          key={nameOfGridHandler("valorDasSaidasTributadasOuExportacao")}
          dataField={nameOfGridHandler("valorDasSaidasTributadasOuExportacao")}
          {...obterConfiguracaoColuna("monetario")}
          caption="Valor das saídas tributadas ou de exportação"
          width={320}
        />
        <Column
          key={nameOfGridHandler("totalDasSaidas")}
          dataField={nameOfGridHandler("totalDasSaidas")}
          {...obterConfiguracaoColuna("monetario")}
          caption="Total das saídas"
          width={150}
        />
        <Column
          key={nameOfGridHandler("indiceDeParticipacao")}
          dataField={nameOfGridHandler("indiceDeParticipacao")}
          {...obterConfiguracaoColuna("monetario")}
          caption="Índice de participação"
          width={190}
        />
        <Column
          key={nameOfGridHandler("parcelaDeIcmsASerApropriada")}
          dataField={nameOfGridHandler("parcelaDeIcmsASerApropriada")}
          {...obterConfiguracaoColuna("monetario")}
          caption="Parcela de ICMS a ser apropriada"
          width={250}
        />
        {GetColunasDeAuditoria()}
      </DataGrid>

      <ModalMxp
        titulo={NormalizaTituloModal.Normalizar(
          idRegistroEdicao,
          NomesModais.apuracaoDoCiap,
          true
        )}
        visivel={!Number.isNaN(idRegistroEdicao) && idRegistroEdicao === 0}
        handleFechar={handleFecharModal}
        largura={"max(30vw, 600px)"}
      >
        <EditFormInsercaoApuracaoCiap
          idRegistroEmEdicao={idRegistroEdicao}
          handleCallback={handleCallbackFormulario}
        />
      </ModalMxp>

      <ModalMxp
        titulo={NormalizaTituloModal.Normalizar(
          idRegistroEdicao,
          NomesModais.apuracaoDoCiap
        )}
        visivel={!Number.isNaN(idRegistroEdicao) && idRegistroEdicao > 0}
        handleFechar={handleFecharModal}
        largura={"max(40vw, 700px)"}
      >
        <EditFormAlteracaoApuracaoDoCiap
          idRegistroEmEdicao={idRegistroEdicao}
          handleCallback={handleCallbackFormulario}
        />
      </ModalMxp>
    </>
  );
}
