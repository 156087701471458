import PaginaMxp from "../../../../../components/layout/pagina-mxp";
import ProvedorMenus from "../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../components/seguranca/requer-permissao";
import { PrefixoESufixoDeLotePermissoes } from "../../../../../models/permissoes/itens/prefixo-e-sufixo-de-lote/PrefixoESufixoDeLotePermissoes";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import GridPrefixoESufixoDeLote from "../../componentes/grid";

export default function PagePrefixoESufixoDeLote() {
  return (
    <ProvedorMenus>
      <PaginaMxp
        id="prefixo-e-sufixo-de-lote-page"
        titulo={NomesTelas.prefixoESufixoDeLote}
        area={["Itens"]}
      >
        <RequerPermissao
          codigoPermissoes={[PrefixoESufixoDeLotePermissoes.Consultar]}
        >
          <GridPrefixoESufixoDeLote />
        </RequerPermissao>
      </PaginaMxp>
    </ProvedorMenus>
  );
}
