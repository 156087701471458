export enum EfdReinfNumeracaoTipo {
  R2010 = "2010",
  R2020 = "2020",
  R4080 = "4080",
  R4020 = "4020",
  R4010 = "4010",
  R4040 = "4040",
}

export enum StatusTransmissaoEfdReinf {
  Sucesso = 83,
  Erro = 69,
  EmProcessamento = 80,
}

export const statusTransmissaoEfdReinfDecodificado: {
  [key in StatusTransmissaoEfdReinf]: string;
} = {
  [StatusTransmissaoEfdReinf.Sucesso]: "Sucesso",
  [StatusTransmissaoEfdReinf.Erro]: "Erro",
  [StatusTransmissaoEfdReinf.EmProcessamento]: "Em processamento",
};
