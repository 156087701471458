import { TipoDeDepreciacao } from "./imobilizado.enums";

export default class ImobilizadoConstantes {
  static readonly NumeroDoPatrimonioTamanhoMaximo = 60;
  static readonly NumeroTamanhoMaximo = 9;
  static readonly SerieTamanhoMaximo = 3;
  static readonly ChaveDeAcessoTamanhoMaximo = 44;
  static readonly DescricaoTamanhoMaximo = 200;
  static readonly UtilizacaoDoBemTamanhoMaximo = 200;
  static readonly PercentualDeDepreciacao = { Minimo: 0, Maximo: 100 };
  static readonly AliquotaICMS = { Minimo: 0, Maximo: 100 };

  static readonly ParcelasEVidaUtilEmMeses = {
    Minimo: 0,
    Maximo: 999,
  };
  //Feito assim devido a diferença nas mensagens de validação no yup entre min() e moreThan(), max() e lessThan()
  static readonly ParcelasEVidaUtilEmMesesYup = {
    MaiorQue: ImobilizadoConstantes.ParcelasEVidaUtilEmMeses.Minimo - 1,
    MenorQue: ImobilizadoConstantes.ParcelasEVidaUtilEmMeses.Maximo + 1,
  };

  static readonly ValoresMinimosDepreciacao: {
    [key in TipoDeDepreciacao]: number;
  } = {
    [TipoDeDepreciacao.Mensal]: 0.31,
    [TipoDeDepreciacao.Anual]: 3.66,
  };
}
