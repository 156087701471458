import { CoresChip } from "../../../../../components/mxp/chip/cores";
import SelectItem from "../../../../../models/shared/ui/select-item";
import { gerarSelectItemDoDicionario } from "../../../../../utils/common/enum-utils";

export enum EstadoDecodificado {
  Sugerida = "Sugerida",
  AProduzir = "A produzir",
  Fechada = "Fechada",
  Cancelada = "Cancelada",
  Suspensa = "Suspensa sugerida",
}

export enum OrdemDeProducaoEstado {
  Sugerida = 83,
  AProduzir = 70,
  Fechada = 67,
  Cancelada = 75,
  Suspensa = 66,
}

export class OrdemDeProducaoEstadoHelper {
  private static readonly tipoDescricao: Record<OrdemDeProducaoEstado, string> =
    {
      [OrdemDeProducaoEstado.Sugerida]: EstadoDecodificado.Sugerida,
      [OrdemDeProducaoEstado.AProduzir]: EstadoDecodificado.AProduzir,
      [OrdemDeProducaoEstado.Fechada]: EstadoDecodificado.Fechada,
      [OrdemDeProducaoEstado.Cancelada]: EstadoDecodificado.Cancelada,
      [OrdemDeProducaoEstado.Suspensa]: EstadoDecodificado.Suspensa,
    };

  public static readonly coresPorEstado: Record<EstadoDecodificado, CoresChip> =
    {
      [EstadoDecodificado.Sugerida]: CoresChip.Cinza,
      [EstadoDecodificado.AProduzir]: CoresChip.Verde,
      [EstadoDecodificado.Fechada]: CoresChip.Azul,
      [EstadoDecodificado.Cancelada]: CoresChip.Amarelo,
      [EstadoDecodificado.Suspensa]: CoresChip.CinzaClaro,
    };

  public static getDescricao(tipo: OrdemDeProducaoEstado): string {
    return (
      OrdemDeProducaoEstadoHelper.tipoDescricao[tipo] ||
      "Descrição desconhecida"
    );
  }

  public static AsSelectItems(): SelectItem[] {
    return gerarSelectItemDoDicionario(this.tipoDescricao);
  }
}
