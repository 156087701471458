import PaginaMxp from "../../../../../../components/layout/pagina-mxp";
import ProvedorMenus from "../../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../../components/seguranca/requer-permissao";
import { PermissoesNaturezaDoRendimento } from "../../../../../../models/permissoes/fiscal/efd-reinf/permissoes-natureza-do-rendimento";
import NomesTelas from "../../../../../../utils/common/nomes-telas";
import GridNaturezaDoRendimento from "../../../componentes/grid/natureza-do-rendimento";

export default function NaturezaDoRendimentoPage() {
  return (
    <ProvedorMenus>
      <PaginaMxp
        id="natureza-do-rendimento-page"
        titulo={NomesTelas.naturezaDoRendimento}
        area={["Fiscal"]}
      >
        <RequerPermissao
          codigoPermissoes={[PermissoesNaturezaDoRendimento.Consultar]}
        >
          <GridNaturezaDoRendimento />
        </RequerPermissao>
      </PaginaMxp>
    </ProvedorMenus>
  );
}
