import { configuracoesErrosGenericos } from "../../features/comum/utils/erros/comum.configuracoes";
import { configuracoesErrosReserva } from "../../features/estoque/reserva/utils/erros/reserva.configuracoes";
import { configuracoesErrosCentroDeTrabalho } from "../../features/producao/centro-de-trabalho/utils/erros/centro-de-trabalho.configuracoes";
import { configuracoesErrosInsumoDaOrdemDeProducao } from "../../features/producao/insumo-da-ordem-de-producao/utils/erros/insumo-da-ordem-de-producao.configuracoes";
import { configuracoesErrosOrdemDeProducao } from "../../features/producao/ordem-de-producao/utils/erros/ordem-de-producao.configuracoes";
import { ConfiguracoesErros } from "../../models/api/comum/erros";

export const configuracoesErros = Object.freeze<ConfiguracoesErros>({
  ...configuracoesErrosCentroDeTrabalho,
  ...configuracoesErrosGenericos,
  ...configuracoesErrosInsumoDaOrdemDeProducao,
  ...configuracoesErrosOrdemDeProducao,
  ...configuracoesErrosReserva,
});
