import { forwardRef, useImperativeHandle } from "react";
import { Key } from "ts-key-enum";

export interface IBotaoDialogo {
  focus: () => void;
}

interface BotaoDialogoProps {
  texto?: string;
  onClick?: () => void;
}

const BotaoDialogo = forwardRef(
  ({ texto, onClick }: BotaoDialogoProps, ref) => {
    let refDiv: HTMLDivElement | null;

    useImperativeHandle(
      ref,
      (): IBotaoDialogo => ({
        focus: () => refDiv?.focus(),
      })
    );

    return (
      <div
        ref={(r) => (refDiv = r)}
        role="button"
        aria-label="OK"
        className="dx-widget dx-button dx-button-mode-text dx-button-default dx-button-has-text dx-dialog-button"
        onClick={onClick}
        tabIndex={0}
        onMouseOver={(e) => {
          e.currentTarget.classList.add("dx-state-hover");
        }}
        onMouseLeave={(e) => {
          e.currentTarget.classList.remove("dx-state-hover");
        }}
        onFocus={(e) => {
          e.currentTarget.classList.add("dx-state-focused");
        }}
        onBlur={(e) => {
          e.currentTarget.classList.remove("dx-state-focused");
        }}
        onKeyDown={(e) => {
          if (e.key == Key.Enter || e.key == " ") {
            if (onClick) {
              onClick();
            }
          }
        }}
      >
        <div className="dx-button-content" tabIndex={0}>
          <span className="dx-button-text">{texto}</span>
        </div>
      </div>
    );
  }
);

export default BotaoDialogo;
