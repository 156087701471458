import { Button } from "devextreme-react";

interface BotaoGerarTokenProps {
  tokenGerado: boolean;
  handleClick: () => void;
}

export default function BotaoGerarToken(props: BotaoGerarTokenProps) {
  return (
    <Button
      type="success"
      text={props.tokenGerado ? "Recriar" : "Gerar"}
      icon={props.tokenGerado ? "refresh" : "save"}
      onClick={props.handleClick}
    />
  );
}
