import PaginaMxp from "../../../../../components/layout/pagina-mxp";
import ProvedorMenus from "../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../components/seguranca/requer-permissao";
import { PermissoesInformativoComposicaoCustosLucroReal } from "../../../../../models/permissoes/fiscal/informativo-composicao-custos-lucro-real/permissoes-informativo-composicao-lucro-real";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import { GridInformativoComposicaoCustosLucroReal } from "../../componentes/grid";

export default function InformativoComposicaoCustosLucroRealPage() {
  return (
    <ProvedorMenus>
      <PaginaMxp
        id="informativo-composicao-custos-lucro-real-page"
        titulo={NomesTelas.informativoDaComposicaoDeCustosDoLucroReal}
        area={["Fiscal", "Apurações"]}
      >
        <RequerPermissao
          codigoPermissoes={[
            PermissoesInformativoComposicaoCustosLucroReal.Consultar,
          ]}
        >
          <GridInformativoComposicaoCustosLucroReal />
        </RequerPermissao>
      </PaginaMxp>
    </ProvedorMenus>
  );
}
