import { EstoqueTipo } from "../../../features/estoque/estoque/models/estoque.enums";
import AuditavelGridModel from "../comum/auditavel-grid";

export interface EstoqueGridModel extends AuditavelGridModel {
  id: number;
  idItem: number;
  codigoItem: string | null;
  descricaoItem: string | null;
  codigoGrupo: string | null;
  descricaoGrupo: string | null;
  quantidade: number;
  unidadeMedidaEngenharia: string | null;
  custoDeAquisicaoOuOrcamentacao: number;
  estoque: string | null;
  enderecoDeEstoque: string | null;
  tipo: EstoqueTipo;
  empresaDonaApelido: string;
  empresaPosseApelido: string;
  quantidadeUnidadeDeVenda: number | null;
  unidadeMedidaVenda: string | null;
  precoVendaUnitario: number;
  precoVendaTotal: number;
  fatorConversaoVenda: number | null;
  qualidade: string | null;
  dataFabricacao: Date | null;
  dataVencimento: Date | null;
  dataReanalise: Date | null;
  loteInterno: string | null;
  loteFabricanteNumeroSerie: string | null;
  observacaoLote: string | null;
  fabricante: string | null;
  custoTotal: number;
  custoUnitario: number;
  pesoLiquidoUnitario: number | null;
  pesoLiquidoTotal: number | null;
  pesoBrutoUnitario: number | null;
  pesoBrutoTotal: number | null;
  estoqueMinimo: number;
  descricaoComplementarItem: string | null;
  criacaoUsuarioApelido: string;
  ultimaAlteracaoUsuarioApelido: string | undefined;
  criacaoData: Date;
  ultimaAlteracaoData: Date | undefined;
}

export interface EstoqueGridModelSelecionar extends EstoqueGridModel {
  quantidadeEscolhida: number;
  situacaoEstoque: EstoqueSituacao;
  situacaoEstoqueDecodificada: string;
  idReserva: number;
  quantidadeMovimentar: number;
}

export enum EstoqueSituacao {
  ReservadoPrioritario = 1,

  ReservadoSecundario = 2,

  Livre = 3,

  ReservadoOutro = 4,

  Indisponivel = 5,
}

export class EstoqueSelecionadoParaBaixaDeInsumo {
  estoqueId: number;
  reservaId: number;
  quantidadeParaBaixar: number;

  constructor(
    estoqueId: number,
    reservaId: number,
    quantidadeParaBaixar: number
  ) {
    this.estoqueId = estoqueId;
    this.reservaId = reservaId;
    this.quantidadeParaBaixar = quantidadeParaBaixar;
  }
}
