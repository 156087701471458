import getApi from "../../../../configs/api";
import type {
  ResponseBase,
  ResponseModel,
} from "../../../../models/api/comum/response-base";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import type { CadastrarReservaParaOrdemDeProducaoRequest } from "../models/reserva.api";

export default class ReservaService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.Reserva);
  }

  public async CadastrarReservaOrdemProducaoAsync(
    request: CadastrarReservaParaOrdemDeProducaoRequest
  ) {
    const api = getApi();
    const response = await api.post<ResponseBase>(
      `${this._nomeEndpoint}/CriarParaOrdemDeProducao`,
      request
    );

    return response.data;
  }

  public async ObterQuantidadeDisponivelParaOrdemDeProducao(
    id: number
  ): Promise<ResponseModel<number>> {
    const api = getApi();
    const response = await api.get<ResponseModel<number>>(
      `${this._nomeEndpoint}/ObterQuantidadeDisponivelParaOrdemDeProducao?id=${id}`
    );

    return response.data;
  }
}
