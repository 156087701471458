import { Button } from "devextreme-react";

interface BotaoConfirmarMxpProps {
  handleClick: () => void;
}

export default function BotaoConfirmarMxp(props: BotaoConfirmarMxpProps) {
  return (
    <Button
      type={"success"}
      text={"Confirmar"}
      icon={"check"}
      onClick={props.handleClick}
    />
  );
}
