import { useFormContext } from "react-hook-form";
import {
  FormNumberBox,
  FormTextBoxSimples,
} from "../../../../../../../components/formularios";
import FormTextArea from "../../../../../../../components/formularios/textarea";
import {
  Coluna,
  Linha,
} from "../../../../../../../components/layout/grid-system";
import TabContainer from "../../../../../../../components/layout/tab-container";
import {
  formatarNumero,
  obterFormatStringNumero,
} from "../../../../../../../utils/formatadores/formatador-de-numeros";
import {
  OrdemDeProducaoRequest,
  quantidadeDePerdaPrevistaMaxima,
  quantidadeDePerdaPrevistaMinima,
} from "../../../../models/ordem-de-producao.api";

export default function OrdemDeProducaoAbaOutrosDados() {
  const { control, watch } = useFormContext<OrdemDeProducaoRequest>();

  const quantidade = watch("quantidade");
  const quantidadeDePerdaPrevista = watch("quantidadeDePerdaPrevista");
  const quantidadeBruta =
    (quantidade == null || Number.isNaN(quantidade) ? 0 : quantidade) +
    (quantidadeDePerdaPrevista == null ||
    Number.isNaN(quantidadeDePerdaPrevista)
      ? 0
      : quantidadeDePerdaPrevista);

  return (
    <TabContainer>
      <Linha>
        <Coluna md={4}>
          <FormNumberBox
            name={"quantidadeDePerdaPrevista"}
            titulo="Perda prevista"
            formato={obterFormatStringNumero(5)}
            defaultValue={0}
            control={control}
            minimo={quantidadeDePerdaPrevistaMinima.toNumber()}
            maximo={quantidadeDePerdaPrevistaMaxima.toNumber()}
          />
        </Coluna>
        <Coluna md={4}>
          <FormTextBoxSimples
            titulo="Quantidade bruta"
            somenteLeitura
            valor={formatarNumero(quantidadeBruta, 5).toString()}
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={12}>
          <FormTextArea
            name="observacoes"
            titulo={"Observações"}
            valor={""}
            control={control}
            height={25}
          />
        </Coluna>
      </Linha>
    </TabContainer>
  );
}
