import getApi from "../../../../configs/api";
import { ListaSimplesResponse } from "../../../../models/api/comum/response-base";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";

export default class CodigoTributacaoServicoServico extends ServiceBase {
  constructor() {
    super(NomesEndpoints.TributacaoDeServicoCodigo);
  }

  public async ObterCodigosServico() {
    const response = await getApi().get<ListaSimplesResponse>(
      `${this._nomeEndpoint}/codigos-servicos`
    );

    return response.data;
  }
}
