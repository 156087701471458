import DataSource from "devextreme/data/data_source";
import {
  assertConfiguracaoExibicaoEBuscaType,
  ConfiguracaoExibicaoEBusca,
} from "../../../../components/formularios/selectbox-lazy-mxp";
import { EnderecoEstoqueGridModel } from "../../../../models/api/endereco-estoque/endereco-estoque";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import { DataSourceOpcoesBuilder } from "../../../../utils/grid/data-source-factory";
import EnderecoEstoqueFiltrosHelpers from "../utils/data-source/EnderecoEstoqueFiltrosHelpers";

export class EnderecoDeEstoqueService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.EnderecoEstoque);
  }

  public GetDadosSelectBoxItem(
    dataSourceOpcoes?: DataSourceOpcoesBuilder<EnderecoEstoqueGridModel>
  ): [
    DataSource<EnderecoEstoqueGridModel, number>,
    ConfiguracaoExibicaoEBusca<EnderecoEstoqueGridModel>
  ] {
    const dataSource =
      this.ObterDataSourceParaSelectBoxLazy<EnderecoEstoqueGridModel>({
        quantidadeRegistros: dataSourceOpcoes?.quantidadeRegistros,
        camposRetorno: dataSourceOpcoes?.camposRetorno || [
          "id",
          "codigo",
          "descricao",
        ],
        camposOrdenacao: dataSourceOpcoes?.camposOrdenacao || [
          { campo: "codigo", desc: false },
          { campo: "descricao", desc: false },
        ],
        camposFiltro:
          dataSourceOpcoes?.camposFiltro ||
          EnderecoEstoqueFiltrosHelpers.FiltrarAtivos,
      });

    const configs =
      assertConfiguracaoExibicaoEBuscaType<EnderecoEstoqueGridModel>({
        expressaoDeBusca: ["codigo", "descricao"],
        nomeCampoChave: "id",
        nomeCampoExibicao: (c) => {
          if (c) {
            return c.descricao == null
              ? `${c.codigo}`
              : `${c.codigo} (${c.descricao})`;
          }

          return "";
        },
      });
    return [dataSource, configs];
  }
}
