import PaginaMxp from "../../../../../components/layout/pagina-mxp";
import ProvedorMenus from "../../../../../components/menus/provedor-menus";
import RequerOpcionalAssinatura from "../../../../../components/seguranca/requer-opcional-assinatura";
import { OpcionaisAssinatura } from "../../../../../models/assinatura/opcionais/opcionais-assinatura";
import { PermissoesLote } from "../../../../../models/direitos/estoque/lote/permissoes-lote";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import GridLote from "../../componentes/grid";

export default function LotePage() {
  return (
    <ProvedorMenus>
      <PaginaMxp id="lote-page" titulo={NomesTelas.lotes} area={["Estoque"]}>
        <RequerOpcionalAssinatura
          codigoPermissoesNaoIncluidas={[PermissoesLote.Consultar]}
          codigoPlano={[OpcionaisAssinatura.Estoque]}
        >
          <GridLote />
        </RequerOpcionalAssinatura>
      </PaginaMxp>
    </ProvedorMenus>
  );
}
