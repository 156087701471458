import PaginaMxp from "../../../../../components/layout/pagina-mxp";
import RequerPermissao from "../../../../../components/seguranca/requer-permissao";
import { PermissoesVinculoEnderecoEstoqueCentroDeTrabalho } from "../../../../../models/permissoes/producao/vinculo-endereco-estoque-centro-de-trabalho/permissoes-vinculo-endereco-estoque-centro-de-trabalho";
import NomesDasCategoriasDoMenuPrincipal from "../../../../../utils/common/nome-categorias-menu-principal";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import GridVinculoEnderecoEstoqueCentroDeTrabalho from "../../componentes/grid";

export default function VinculoEnderecoEstoqueCentroDeTrabalhoPage() {
  return (
    <PaginaMxp
      id="vinculo-endereco-estoque-centro-de-trabalho-page"
      titulo={NomesTelas.vinculosDoCentroDeTrabalhoComEnderecosDeEstoque}
      area={[NomesDasCategoriasDoMenuPrincipal.producao]}
    >
      <RequerPermissao
        codigoPermissoes={[
          PermissoesVinculoEnderecoEstoqueCentroDeTrabalho.Consultar,
        ]}
      >
        <GridVinculoEnderecoEstoqueCentroDeTrabalho />
      </RequerPermissao>
    </PaginaMxp>
  );
}
