import ScrollView, { ScrollViewRef } from "devextreme-react/scroll-view";
import React, { useRef } from "react";

import { useScreenSize } from "../../../utils/template-layout/media-query";

import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import Footer from "../../../components/layout/footer/footer";
import HeaderMenu from "../../../components/layout/header-menu/header-menu";
import { useAppSelector } from "../../../hooks/store.hooks";
import "../../../themes/generated/theme.menu-superior.css";
import gerarItensMenu from "../../../utils/common/menu-utils";
import "./menu-nav.scss";

interface IProps {
  title: string;
  children: any;
}

const MenuPrincipal = () => {
  const { isXSmall } = useScreenSize();

  const isMaster = useAppSelector(
    (state) => state.sessao.dadosSessao?.usuario.isUsuarioMaster
  );

  const mobile = isXSmall || isMobile;

  const navigate = useNavigate();

  function navegarPara(url: string) {
    navigate(url);
  }

  const irParaPaginaInicial = () => {
    navigate("/");
  };

  const itensMenu = gerarItensMenu(navegarPara, mobile, isMaster);

  return (
    <div className="dx-swatch-menu-superior">
      <HeaderMenu
        userMenuItems={itensMenu}
        menuToggleEnabled={mobile}
        title="Tela inicial"
        irParaPaginaInicial={irParaPaginaInicial}
      />
    </div>
  );
};

export default function MenuNav({ children }: IProps) {
  const scrollViewRef = useRef<ScrollViewRef>(null);

  return (
    <div className={"menu-nav"}>
      <MenuPrincipal />
      <div className={"container"}>
        <ScrollView
          height={"calc(100vh - var(--header-menu-height))"}
          ref={scrollViewRef}
          className={"layout-body"}
        >
          <div className={"content"}>
            {React.Children.map(children, (item) => {
              return item.type !== Footer && item;
            })}
          </div>
        </ScrollView>
      </div>
    </div>
  );
}
