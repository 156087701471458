import styled from "styled-components";

interface AccordionWrapperProps {
  itemDoAccordionAltura: number | string;
}

export const AccordionWrapper = styled.div<AccordionWrapperProps>`
  .dx-item-content.dx-accordion-item-title {
    height: ${(props) => props.itemDoAccordionAltura};
  }
`;
