export default class VeiculoConstantes {
  static readonly PlacaTamanho = 7;
  static readonly PlacaMascara = "LLL 0A00";
  static readonly RenavamRegex = /.{9,}/;
  static readonly RenavamTamanhoMaximo = 11;
  static readonly ValorMinimoMaiorQue = -1;
  static readonly TamanhoIncremento = 1;
  static readonly DescricaoTamanhoMaximo = 120;
  static readonly TaraTamanhos = { Minimo: 0, Maximo: 999_999 };
  static readonly CapacidadeKgTamanhos = { Minimo: 0, Maximo: 999_999 };
  static readonly CapacidadeM3Tamanhos = { Minimo: 0, Maximo: 999 };
}
