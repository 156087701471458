import { AxiosResponse, AxiosResponseHeaders, RawAxiosResponseHeaders } from "axios";

export function extractFileNameFromContentDisposition(headers: RawAxiosResponseHeaders | AxiosResponseHeaders) {
    let filename = "";
    
    const disposition = headers["content-disposition"];

    if (disposition && disposition.indexOf("attachment") !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);

        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "");
        }
    }

    return filename;
}

export function createUrlFromBlobAndDownload(resposta: AxiosResponse, fileName: string): void {
    const downloadUrl = URL.createObjectURL(resposta.data);
    
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = fileName;
    a.click();
}
  