import styled from "styled-components";

const BotaoChipMxp = styled.a`
  margin: 0.5em;
`;

interface ChipProps {
  texto: string;
  onClick(): void;
}

export function ChipTarefasEAnotacoes(props: ChipProps) {
  return (
    <BotaoChipMxp onClick={props.onClick} href="#">
      {props.texto}
    </BotaoChipMxp>
  );
}
