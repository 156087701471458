import { PropsWithChildren, useState } from "react";
import { OrdemDeProducaoGridModel } from "../../models/ordem-de-producao.api";
import ContextoOrdemDeProducaoGrid from "./contexto-ordem-de-producao-grid";

export function ProvedorOrdemDeProducaoGrid(props: PropsWithChildren<any>) {
  const [ordemDeProducao, setOrdemDeProducao] = useState<
    OrdemDeProducaoGridModel | undefined
  >(undefined);
  const [atualizaGridDeOrdemDeProducao, setAtualizaGridDeOrdemDeProducao] =
    useState<() => void>(() => {
      () => {
        undefined;
      };
    });

  return (
    <ContextoOrdemDeProducaoGrid.Provider
      value={{
        ordemDeProducaoDesfazerConclusao: ordemDeProducao,
        definfirOrdemDeProducaoDesfazerConclusao: setOrdemDeProducao,
        atualizaGridDeOrdemDeProducao: atualizaGridDeOrdemDeProducao,
        definirAtualizaGridDeOrdemDeProducao: setAtualizaGridDeOrdemDeProducao,
      }}
    >
      {props.children}
    </ContextoOrdemDeProducaoGrid.Provider>
  );
}
