export function adicionarMeses(data: Date, meses: number) {
  const novaData = new Date(data);
  novaData.setMonth(data.getMonth() + meses);
  return novaData;
}

export function obterDataLimite(data: Date, meses: number) {
  return new Date(data.getFullYear(), data.getMonth() + meses, data.getDate());
}

export function mesPorExtenso(mes: number | string) {
  const ordem = typeof mes === "string" ? parseInt(mes) : mes;

  const meses = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  return meses[ordem - 1];
}
