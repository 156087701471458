import { Column } from "devextreme-react/cjs/data-grid";
import DataSource from "devextreme/data/data_source";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { MxpGrid } from "../../../../components/grid";
import { usePropagarReferenciaGrid } from "../../../../hooks/grid.hooks";
import { ContaContabilGridModel } from "../../../../models/api/conta-contabil/conta-contabil";
import { IGridSelecao } from "../../../../models/shared/ui/formularios";
import { ContaContabilService } from "../../../../services/contabilidade/conta-contabil/conta-contabil.service";
import criarNameof from "../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../utils/common/nomes-telas";
import { GridBaseProps } from "../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../utils/grid/padroes-colunas";
import GetColunasDeAuditoria from "../../../layout/grid-defaults/colunasDeAuditoria";

const contaContabilService = new ContaContabilService();
const dataSource = new DataSource({
  store: contaContabilService.GetGridSource(),
});
const nameOfGridHandler = criarNameof<ContaContabilGridModel>();

const colunas = [
  <Column
    key={nameOfGridHandler("codigo")}
    dataField={nameOfGridHandler("codigo")}
    {...obterConfiguracaoColuna("stringP")}
    caption="Código"
  />,
  <Column
    key={nameOfGridHandler("classificacao")}
    dataField={nameOfGridHandler("classificacao")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Classificação"
    sortOrder="asc"
  />,
  <Column
    key={nameOfGridHandler("descricao")}
    dataField={nameOfGridHandler("descricao")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Descrição"
  />,
  ...GetColunasDeAuditoria(),
];

export const GridContaContabil = forwardRef((props: GridBaseProps, ref) => {
  const gridRef = useRef<IGridSelecao>(null);
  useImperativeHandle(ref, () => gridRef.current as IGridSelecao);

  usePropagarReferenciaGrid(ref, gridRef);

  if (props.filtrosRealizadosNoServidor) {
    dataSource.filter(
      props.filtrosRealizadosNoServidor.map((x) => [
        x.nomeCampo,
        x.operador,
        x.valor,
      ])
    );
  }

  return (
    <>
      <MxpGrid<ContaContabilGridModel>
        dataSource={dataSource}
        id={"conta-contabil"}
        ref={gridRef}
        colunas={colunas}
        exibirIconeEditarMenuAcoes={false}
        exibirIconeExcluirMenuAcoes={false}
        nomeDoArquivoAoExportar={NomesTelas.contaContabil}
        sobreporFiltroSalvoComOFiltroPadrao={
          props.sobreporFiltroSalvoComOFiltroPadrao
        }
        valorPadraoDoFiltro={props.valorPadraoDoFiltro}
      />
    </>
  );
});
