import { TextArea } from "devextreme-react";
import { LabelMode } from "devextreme/common";
import { TextBoxType } from "devextreme/ui/text_box";
import { Controller, FieldValues, UseControllerProps } from "react-hook-form";
import IconeAjuda from "../../ajuda/icone-ajuda";
import LinkAjuda from "../../ajuda/link-ajuda";
import { LabelErro } from "../label-erro";

interface FormTextAreaProps<T extends FieldValues>
  extends UseControllerProps<T> {
  tabIndex?: number;
  titulo: string;
  valor: string;
  toolTip?: string;
  tipo?: TextBoxType;
  placeholder?: string;
  tipoPlaceholder?: LabelMode;
  exibirBotaoLimpar?: boolean;
  somenteLeitura?: boolean;
  desabilitado?: boolean;
  requerido?: boolean;
  tamanhoMaximo?: number;
  mask?: string;
  height?: number;
  exibirLinkAjuda?: boolean;
  onEnter?: (e: any) => void;
}

export default function <T extends FieldValues>(props: FormTextAreaProps<T>) {
  return (
    <Controller
      {...props}
      render={({ field, fieldState }) => (
        <div>
          <label>
            <span className="dx-field-item-label-content">
              <span className="dx-field-item-label-text">{props.titulo}</span>
              {props.requerido && (
                <span className="dx-field-item-required-mark">&nbsp;*</span>
              )}
              {(props.exibirLinkAjuda ?? true) && (
                <LinkAjuda keyAjuda={field.name}>
                  <IconeAjuda />
                </LinkAjuda>
              )}
            </span>
          </label>
          <TextArea
            tabIndex={props.tabIndex}
            hint={props.toolTip}
            mode={props.tipo}
            label={props.placeholder}
            labelMode={props.tipoPlaceholder}
            readOnly={props.somenteLeitura}
            disabled={props.desabilitado}
            showClearButton={props.exibirBotaoLimpar}
            maxLength={props.tamanhoMaximo}
            mask={props.mask}
            height={`${props.height}vh`}
            onValueChange={(value) => field.onChange(value)}
            onEnterKey={props.onEnter}
            onFocusOut={() => {
              field.onBlur();
            }}
            value={field.value}
            validationStatus={fieldState.invalid ? "invalid" : "valid"}
            inputAttr={{ id: field.name }}
          />
          <LabelErro>{fieldState.error?.message}</LabelErro>
        </div>
      )}
    />
  );
}
