import criarNameof from "../../../../../utils/common/cria-name-of";
import { EstadoDecodificado } from "../../../ordem-de-producao/utils/enums/ordem-de-producao-enums";
import { OperacaoDaOrdemDeProducaoGridModel } from "../../models/operacao-de-ordem-de-producao";
import {
  EstadoOperacaoHelper,
  OperacaoEstado,
} from "../../models/operacao-de-ordem-de-producao-enums";

const nameof = criarNameof<OperacaoDaOrdemDeProducaoGridModel>();

export default class FiltrosGridOperacaoDeOrdemDeProducao {
  static readonly estadoDaOperacaoEEstadoDaOrdemDeProducao: any[] = [
    [
      nameof("estado"),
      "anyof",
      [
        EstadoOperacaoHelper.getDescricao(OperacaoEstado.NaoIniciada),
        EstadoOperacaoHelper.getDescricao(OperacaoEstado.Iniciada),
      ],
    ],
    "and",
    [nameof("estadoDaOrdemDeProducao"), "=", EstadoDecodificado.AProduzir],
  ];
}
