export enum OpcionaisAssinatura {
  CodigoGrupo = "ASS_OPC",
  GestaoGeral = "OPC003",
  Industrial = "OPC004",
  Estoque = "OPC005",
  MultiCnpj = "OPC001",
  Calculo = "OPC007",
  SpedFiscal = "OPC002",
  SpedContribuicoes = "OPC011",
  SpedContabil = "OPC012",
  Gia = "OPC008",
  Sintegra = "OPC009",
  CodigoProdutoAnp = "OPC013",
  DaycovalCnab400Nfe = "OPC010",
  Cad = "OPC006",
  Ecf = "OPC014",
}
