import { getDataSourceMulltiSelectBox } from "../../../../components/formularios/multiselectbox";
import SelectItem from "../../../../models/shared/ui/select-item";
import { EstadoContaContabil } from "../../conta-contabil/models/conta-contabil.enum";

export enum ColunasOcultaveisBalanceteComparativo {
  SaldoInicial = "saldo_inicial",
  SaldoFinal = "saldo_final",
  Creditos = "creditos",
  Debitos = "debitos",
  Liquido = "liquido",
}

export enum TipoAgrupamentoBalanceteComparativo {
  Mensal = 0,
  Trimestral = 1,
  Anual = 2,
}

export enum ChavesBalanceteComparativoGridModel {
  Id = "Id",
  Codigo = "Codigo",
  Classificacao = "Classificacao",
  Descricao = "Descricao",
  Sintetica = "Sintetica",
  Nivel = "Nivel",
}

export enum PrefixosBalanceteComparativoGridModel {
  SaldoInicial = "SaldoInicial_",
  SaldoFinal = "SaldoFinal_",
  Debitos = "Debitos_",
  Creditos = "Creditos_",
  Liquido = "Liquido_",
}

export const OpcoesEstadosContaContabil = getDataSourceMulltiSelectBox([
  { valor: EstadoContaContabil.Ativas, descricao: "Ativas" },
  { valor: EstadoContaContabil.Inativas, descricao: "Inativas" },
]);

export const OpcoesAgrupamentoBalanceteComparativo: SelectItem[] = [
  { valor: TipoAgrupamentoBalanceteComparativo.Mensal, descricao: "Mensal" },
  {
    valor: TipoAgrupamentoBalanceteComparativo.Trimestral,
    descricao: "Trimestral",
  },
  { valor: TipoAgrupamentoBalanceteComparativo.Anual, descricao: "Anual" },
];

export const OpcoesColunasOcultaveisBalanceteComparativo =
  getDataSourceMulltiSelectBox([
    {
      valor: ColunasOcultaveisBalanceteComparativo.SaldoInicial,
      descricao: "Saldo inicial",
    },
    {
      valor: ColunasOcultaveisBalanceteComparativo.Debitos,
      descricao: "Débitos",
    },
    {
      valor: ColunasOcultaveisBalanceteComparativo.Creditos,
      descricao: "Créditos",
    },
    {
      valor: ColunasOcultaveisBalanceteComparativo.Liquido,
      descricao: "Líquido",
    },
    {
      valor: ColunasOcultaveisBalanceteComparativo.SaldoFinal,
      descricao: "Saldo final",
    },
  ]);
