import { useContext, useEffect } from "react";
import ContextoMenus from "../components/menus/contexto-menus";
import { ItemContextMenuMxp } from "../utils/context-menu/context-menu-utils";

export function useMenuDeContextosGrid(itens: ItemContextMenuMxp[]) {
  const { setItensMenu } = useContext(ContextoMenus);

  useEffect(() => {
    setItensMenu(itens);
  }, []);
}
