import criarNameof from "../../../../../utils/common/cria-name-of";
import { OrdemDeProducaoGridModel } from "../../models/ordem-de-producao.api";
import { EstadoDecodificado } from "../enums/ordem-de-producao-enums";

const nameof = criarNameof<OrdemDeProducaoGridModel>();

export default class FiltrosGridOrdemDeProducao {
  static readonly ordemDeProducaoEstado: any[] = [
    nameof("estadoDecodificado"),
    "anyof",
    [
      EstadoDecodificado.AProduzir,
      EstadoDecodificado.Sugerida,
      EstadoDecodificado.Suspensa,
    ],
  ];

  static readonly ordemDeProducaoEstadoAProduzir: any[] = [
    nameof("estadoDecodificado"),
    "=",
    EstadoDecodificado.AProduzir,
  ];
}
