import { Button, LoadPanel } from "devextreme-react";
import { useCallback, useState } from "react";
import { HomeBackground } from "../../../components/layout/login-bg/styles";
import ApiMrp from "../../../services/producao/mrp/mrp.service";
import { tratarErroApi } from "../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../utils/common/notificacoes-utils";

const position = { of: "#HomeBackgroundMrp" };
export const ModalMrp = () => {
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);

  const rodaMrp = useCallback(() => {
    setLoadPanelVisible(true);
    ApiMrp.executarMrp()
      .catch((erro) => {
        tratarErroApi(erro);
      })
      .then((resultado) => {
        setLoadPanelVisible(false);

        if (resultado?.sucesso) {
          exibirNotificacaoToast({
            mensagem: "MRP concluído com sucesso.",
            tipo: TipoNotificacao.Sucesso,
          });
        }
      });
  }, [setLoadPanelVisible]);

  return (
    <HomeBackground id="HomeBackgroundMrp">
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={position}
        visible={loadPanelVisible}
        shading={true}
      />
      <Button onClick={rodaMrp}>Rodar Mrp</Button>
    </HomeBackground>
  );
};
