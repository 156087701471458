import criarNameof from "../../../../utils/common/cria-name-of";
import { ContaDaParteBGridModel } from "./conta-parte-b";

export default class FiltrosGridContaParteB {
  static readonly ativo: any[] = [
    criarNameof<ContaDaParteBGridModel>()("ativo"),
    "=",
    true,
  ];
}
