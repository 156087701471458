import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Sessao from "../../models/dto/sessao/sessao";
import GestorLocalStorage, {
  ChavesLocalstorage,
} from "../../utils/storage/gestor-storage";

export interface SessaoRedux extends Sessao {
  usuarioAutenticado: boolean;
}

const sessaoSlice = createSlice({
  name: "sessao",
  initialState: lerDadosLocalStorage(),
  reducers: {
    gravarDadosSessao: gravarDadosSessaoAction,
    removerDadosSessao: removerDadosSessaoAction,
  },
});

function removerDadosSessaoAction(state: SessaoRedux) {
  state.dadosSessao = undefined;
  state.usuarioAutenticado = false;
}

function gravarDadosSessaoAction(
  state: SessaoRedux,
  action: PayloadAction<SessaoRedux>
) {
  state.dadosSessao = action.payload.dadosSessao;
  state.usuarioAutenticado = true;
}

function lerDadosLocalStorage(): SessaoRedux {
  return {
    dadosSessao: undefined,
    usuarioAutenticado: GestorLocalStorage.VerificaSePossuiChave(
      ChavesLocalstorage.DadosSessao
    ),
  };
}

export const { gravarDadosSessao, removerDadosSessao } = sessaoSlice.actions;
export default sessaoSlice;
