import GitInfo from "react-git-info/macro";
import getApi from "../../../../configs/api";
import { ResponseModel } from "../../../../models/api/comum/response-base";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import { DetalhesAssinaturaOpcionaisAssinaturaResponse } from "../../login/models/login.api";

const gitInfo = GitInfo();

export default class AssinaturaServico extends ServiceBase {
  constructor() {
    super(NomesEndpoints.Assinatura);
  }

  public async obterDetalhesOpcionais() {
    const api = getApi();
    const response = await api.get<
      ResponseModel<DetalhesAssinaturaOpcionaisAssinaturaResponse[]>
    >(
      `${this._nomeEndpoint}/DetalhesAssinaturaOpcionais?v=${gitInfo.commit.shortHash}`
    );

    return response.data.model;
  }
}
