import type SelectItem from "../../../../models/shared/ui/select-item";
import {
  gerarLookupDoDicionario,
  gerarSelectItemDoDicionario,
} from "../../../../utils/common/enum-utils";

export enum EstadoItemPedidoDeVenda {
  AReceber = "A".charCodeAt(0),
  Concluido = "C".charCodeAt(0),
  Cancelado = "K".charCodeAt(0),
  Entregue = "N".charCodeAt(0),
  EntregueParcial = "P".charCodeAt(0),
  RecebidoParcial = "R".charCodeAt(0),
}

// Classe que encapsula o enum e suas descrições
export class EstadoItemPedidoDeVendaHelper {
  public static readonly descricoesEstadoItemPedidoDeVenda = Object.freeze({
    [EstadoItemPedidoDeVenda.Concluido]: "Faturado com entrega futura",
    [EstadoItemPedidoDeVenda.AReceber]: "A faturar",
    [EstadoItemPedidoDeVenda.Entregue]: "Faturado",
    [EstadoItemPedidoDeVenda.RecebidoParcial]:
      "Parcialmente faturado com entrega futura",
    [EstadoItemPedidoDeVenda.Cancelado]: "Cancelado",
    [EstadoItemPedidoDeVenda.EntregueParcial]: "Faturado parcial",
  });

  public static getDescricao(valor: EstadoItemPedidoDeVenda): string {
    return (
      this.descricoesEstadoItemPedidoDeVenda[valor] || "Estado desconhecido."
    );
  }

  public static asSelectItems(): SelectItem[] {
    return gerarSelectItemDoDicionario(this.descricoesEstadoItemPedidoDeVenda);
  }

  public static asLookup() {
    return gerarLookupDoDicionario(this.descricoesEstadoItemPedidoDeVenda);
  }
}
