import { FieldValues, UseControllerProps } from "react-hook-form";
import { FormSelectBoxLazyMxp } from "../../../../../components/formularios";
import { assertConfiguracaoExibicaoEBuscaType } from "../../../../../components/formularios/selectbox-lazy-mxp";
import GridContato from "../../componentes/grid-padrao";
import { ContatoGridModel } from "../../models/contato";
import ContatoServico from "../../servicos/contato.servico";

interface ComboContatoMxpProps<T extends FieldValues>
  extends UseControllerProps<T> {
  titulo: string;
  tituloSeletor?: string;
  requerido?: boolean;
  somenteLeitura?: boolean;
  filtrosNoCliente?: any[];
}

const apiContato = new ContatoServico();

const dataSourceContatoGrid = apiContato.GetDataSourceSelectBoxLazy({
  camposRetorno: ["id", "nome"],
  camposOrdenacao: [
    {
      nomeCampo: "nome",
      desc: false,
    },
  ],
});

const contatoGridExibicaoEBusca =
  assertConfiguracaoExibicaoEBuscaType<ContatoGridModel>({
    expressaoDeBusca: ["nome"],
    nomeCampoChave: "id",
    nomeCampoExibicao: (c) => {
      if (c) {
        return `${c.nome}`;
      }

      return "";
    },
  });

export default function ComboContatoMxp<T extends FieldValues>(
  props: ComboContatoMxpProps<T>
) {
  return (
    <FormSelectBoxLazyMxp
      name={props.name}
      titulo={props.titulo}
      control={props.control}
      dataSource={dataSourceContatoGrid}
      configuracoesExibicaoEBusca={contatoGridExibicaoEBusca}
      seletorConfig={{
        modo: "selecaoUnica",
        titulo: props.tituloSeletor || "Selecione um contato",
        componenteGrid: <GridContato />,
      }}
      labelSemDados="Sem dados"
      requerido={props.requerido}
      somenteLeitura={props.somenteLeitura}
    />
  );
}
