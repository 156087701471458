import PaginaMxp from "../../../../../components/layout/pagina-mxp";
import ProvedorMenus from "../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../components/seguranca/requer-permissao";
import { PermissoesImobilizado } from "../../../../../models/permissoes/contabilidade/imobilizado/permissoes-imobilizado";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import GridImobilizado from "../../componentes/grid";

export default function ImobilizadoPage() {
  return (
    <ProvedorMenus>
      <PaginaMxp
        id={"imobilizado-page"}
        titulo={NomesTelas.imobilizado}
        area={["Contabilidade"]}
      >
        <RequerPermissao codigoPermissoes={[PermissoesImobilizado.Consultar]}>
          <GridImobilizado />
        </RequerPermissao>
      </PaginaMxp>
    </ProvedorMenus>
  );
}
