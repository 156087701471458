import PaginaMxp from "../../../../../components/layout/pagina-mxp";
import ProvedorMenus from "../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../components/seguranca/requer-permissao";
import { PermissoesCentroDeTrabalhoGrupo } from "../../../../../models/permissoes/producao/grupo-centro-de-trabalho/permissoes-grupo-centro-de-trabalho";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import GridGrupoDeCentroDeTrabalho from "../../componentes/grid";

export default function CentroDeTrabalhoGrupoPage() {
  return (
    <ProvedorMenus>
      <PaginaMxp
        id="grupos-centros-trabalho-page"
        titulo={NomesTelas.gruposCentrosTrabalho}
        area={["Produção"]}
      >
        <RequerPermissao
          codigoPermissoes={[PermissoesCentroDeTrabalhoGrupo.Consultar]}
        >
          <GridGrupoDeCentroDeTrabalho />
        </RequerPermissao>
      </PaginaMxp>
    </ProvedorMenus>
  );
}
