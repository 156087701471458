import { Container, Box, Titulo } from "./styles";

interface TituloSessaoProps {
  titulo?: string;
  children?: React.ReactNode;
}

export default function Sessao({ titulo, children }: TituloSessaoProps) {
  return (
    <Container>
      {titulo && <Titulo>{titulo}</Titulo>}
      <Box>{children}</Box>
    </Container>
  );
}
