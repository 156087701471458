import { Fragment } from "react/jsx-runtime";
import { AccordionModel } from "../../../../../components/dialogos/modal-accordion/modal-accordion";
import { ErroApi } from "../../../../../models/api/comum/erros";
import { ResponseModel } from "../../../../../models/api/comum/response-base";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { exibirAlerta } from "../../../../../utils/dialogos";
import { renderToStringClient } from "../../../../../utils/react/react-utils";
import { AcaoBaseOrdemDeProducaoResponse } from "../../models/ordem-de-producao.api";

export interface AcoesOrdensDeProducao {
  acao: string;
  acaoEstadoDasOps: string;
}

async function handlerNotificacoesUnicoRegistro(
  mensagemInicial: string,
  listaDeErros: ErroApi[]
) {
  if (listaDeErros.length == 1) {
    exibirAlerta("Atenção", listaDeErros[0].mensagem.replace(/\n/g, "<br/>"));

    return;
  }

  const conteudoHtmlComErros = (
    <div style={{ textAlign: "left", marginLeft: "20px" }}>
      <p style={{ fontWeight: "bold" }}>{mensagemInicial}</p>
      <ul>
        {listaDeErros.map((erro, index) => (
          <li key={index} style={{ marginLeft: "20px" }}>
            {erro.mensagem.split("\n").map((line, idx) => (
              <Fragment key={idx}>
                {line}
                <br />
              </Fragment>
            ))}
          </li>
        ))}
      </ul>
    </div>
  );

  exibirAlerta("Atenção", renderToStringClient(conteudoHtmlComErros));
}

function formatarMensagensAcaoOrdensDeProducaoAccordion<
  T extends AcaoBaseOrdemDeProducaoResponse
>(
  ordensDeProducaoAcaoSucesso: T[],
  ordensDeProducaoAcaoSemSucesso: T[],
  acoes: AcoesOrdensDeProducao
): AccordionModel[] {
  const accordionModel: AccordionModel[] = [];

  if (ordensDeProducaoAcaoSucesso.length > 0) {
    accordionModel.push({
      grupoDeMensagensTitulo: `${ordensDeProducaoAcaoSucesso.length} ordens de produção ${acoes.acaoEstadoDasOps} com sucesso. Clique para ver mais:`,
      mensagens: ordensDeProducaoAcaoSucesso.map((x) => {
        return `Ordem de produção ${x.ordemDeProducaoNumero ?? "sem número"}`;
      }),
    });
  }

  if (ordensDeProducaoAcaoSemSucesso.length > 0) {
    accordionModel.push({
      grupoDeMensagensTitulo: `${ordensDeProducaoAcaoSemSucesso.length} ordens de produção não foram ${acoes.acaoEstadoDasOps}. Clique para ver mais:`,
      mensagens: ordensDeProducaoAcaoSemSucesso.map((x) => {
        return `Ordem de produção ${x.ordemDeProducaoNumero.toLowerCase()} - Motivos do erro: ${x.listaDeErros
          .map((y) => y.mensagem)
          .join(" \n")}`;
      }),
    });
  }

  return accordionModel;
}

function handlerAcaoOrdensDeProducaoResponse<
  T extends AcaoBaseOrdemDeProducaoResponse
>(response: ResponseModel<T[]>, acoes: AcoesOrdensDeProducao) {
  if (response.sucesso) {
    const resultadosDaConfirmacao = response.model;
    const ordensDeProducaoAcaoSucesso = resultadosDaConfirmacao.filter(
      (x) => x.valido
    );
    const ordensDeProducaoAcaoSemSucesso = resultadosDaConfirmacao.filter(
      (x) => !x.valido
    );
    if (response.model.length == 1) {
      ordensDeProducaoAcaoSemSucesso.length > 0
        ? handlerNotificacoesUnicoRegistro(
            `Não foi possível ${acoes.acao} a ordem de produção. Veja os motivos abaixo:`,
            ordensDeProducaoAcaoSemSucesso[0].listaDeErros
          )
        : exibirNotificacaoToast({
            mensagem: `Ordem de produção ${acoes.acaoEstadoDasOps} com sucesso.`,
            tipo: TipoNotificacao.Sucesso,
          });
    } else {
      return handlerNotificacoesConfirmarMultiplosRegistros(
        ordensDeProducaoAcaoSucesso,
        ordensDeProducaoAcaoSemSucesso,
        acoes
      );
    }
  }
}

function handlerNotificacoesConfirmarMultiplosRegistros<
  T extends AcaoBaseOrdemDeProducaoResponse
>(
  ordensDeProducaoAcaoSucesso: T[],
  ordensDeProducaoAcaoSemSucesso: T[],
  acoes: AcoesOrdensDeProducao
) {
  const mensagem = formatarMensagensAcaoOrdensDeProducaoAccordion(
    ordensDeProducaoAcaoSucesso,
    ordensDeProducaoAcaoSemSucesso,
    acoes
  );
  return {
    accordionVisivel: true,
    mensagem: mensagem,
  };
}

export {
  handlerAcaoOrdensDeProducaoResponse,
  handlerNotificacoesUnicoRegistro,
};
