import { forwardRef, useImperativeHandle, useRef } from "react";
import { MxpGrid } from "../../../../../components/grid";
import { usePropagarReferenciaGrid } from "../../../../../hooks/grid.hooks";
import { EstoqueGridModel } from "../../../../../models/api/estoque/estoque";
import { IGridSelecao } from "../../../../../models/shared/ui/formularios";
import { EstoqueService } from "../../../../../services/estoque/endereco-estoque/estoque/estoque";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import { GridBaseProps } from "../../../../../utils/grid/grid-utils";
import { colunasEstoques } from "../colunasEstoque";

const APIEstoque = new EstoqueService();
const dataSource = APIEstoque.GetGridSource();

export const GridEstoque = forwardRef((props: GridBaseProps, ref) => {
  const gridRef = useRef<IGridSelecao>(null);
  useImperativeHandle(ref, () => gridRef.current as IGridSelecao);
  usePropagarReferenciaGrid(ref, gridRef);

  return (
    <>
      <MxpGrid<EstoqueGridModel>
        dataSource={dataSource}
        id={"estoque"}
        ref={gridRef}
        colunas={colunasEstoques}
        exibirIconeEditarMenuAcoes={false}
        definirMenuSuperior={props.definirMenu}
        valorPadraoDoFiltro={props.valorPadraoDoFiltro}
        sobreporFiltroSalvoComOFiltroPadrao={
          props.sobreporFiltroSalvoComOFiltroPadrao ?? false
        }
        limparFiltroAoTrocarFiltroPadrao={
          props.limparFiltroAoTrocarFiltroPadrao ?? false
        }
        nomeDoArquivoAoExportar={NomesTelas.estoque}
      />
    </>
  );
});
