import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { format } from "date-fns";
import {
  AmbienteMDFe,
  ModalidadeTransporteMDFe,
  SituacaoMDFe,
  TipoCarregamentoMDFe,
  TipoEmissaoMDFe,
  TipoEmitenteMDFe,
  TipoMDFeVeiculo,
  TipoTransportadorMDFe,
  UnidadeMedidaPesoBrutoMDFe,
} from "../../models/api/mdfe/mdfe-enums";
import {
  MDFeCarregamentoDTO,
  MDFeCompletoDTO,
  MDFePercursoDTO,
} from "../../models/api/mdfe/mdfe-request-response";
import DocumentoFiscalSelecaoMdfeGridModel from "../../models/api/nota-fiscal/nota-fiscal-selecao-mdfe";
import MDFeEditFormViewModel, {
  GridCarregamentoViewModel,
  GridCondutorViewModel,
  GridPercursoViewModel,
  MDFeAbaDocumentosFiscaisViewModel,
  MDFeAbaGeralViewModel,
  MDFeAbaObservacoesViewModel,
  MDFeAbaPercursoViewModel,
  MDFeAbaVeiculoViewModel,
} from "../../models/viewmodels/vendas/mdfe/mdfe-edit-form-view-model";
import {
  calcularPesoBrutoTotalMDFe,
  calcularValorTotalMDFe,
} from "../../utils/especifico/mdfe/mdfe-utils";

const INITIAL_STATE: MDFeEditFormViewModel = {
  documentoAtual: {
    id: 0,
    ambiente: AmbienteMDFe.Homologacao,
    carregamentos: [],
    descarregamentos: [],
    chaveAcesso: null,
    codigoAgendamentoPorto: null,
    cpfCnpjAutorizados: [],
    dataHoraEmissao: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
    dataHoraInicioViagem: null,
    emitente: null,
    autorizacao: null,
    idUfFim: 0,
    idUfInicio: 0,
    informacoesAdicionais: null,
    informacoesAdicionaisContibuinte: null,
    observacoesInternas: null,
    modalidadeTransporte: ModalidadeTransporteMDFe.Rodoviario,
    modelo: "",
    motivoRejeicao: null,
    numero: null,
    serie: "",
    percursos: [],
    pesoBruto: 0,
    unidadePesoBruto: UnidadeMedidaPesoBrutoMDFe.KG,
    produtoPredominante: {
      id: 0,
      descricao: "",
    },
    situacao: SituacaoMDFe.EmEdicao,
    tipoCarregamento: TipoCarregamentoMDFe.CargaGeral,
    tipoEmissao: TipoEmissaoMDFe.Normal,
    tipoEmitente: TipoEmitenteMDFe.TransportadorCargaPropria,
    tipoTransportador: null,
    valorTotalNfe: 0,
    modalRodoviario: {
      id: 0,
      condutores: [],
      contratante: {
        id: 0,
        idContratante: 0,
      },
      veiculos: [],
    },
    idArquivoDamdfeAutorizado: null,
    cepLocalCarregamento: null,
    cepLocalDescarregamento: null,
    criacaoUsuarioApelido: null,
    criacaoData: null,
    alteracaoUsuarioApelido: null,
    ultimaAlteracaoData: null,
  },
};

const mdfeSlice = createSlice({
  name: "mdfe",
  initialState: INITIAL_STATE,
  reducers: {
    carregar: carregarAction,
    novoDocumento: novoDocumentoAction,
    definirDadosGerais: definirDadosGeraisAction,
    definirDadosObservacoes: definirDadosObservacoesAction,
    definirDadosVeiculos: definirDadosVeiculosAction,
    definirDadosNotas: definirDadosNotasAction,
    definirDadosPercurso: definirDadosPercursoAction,
    removerDocumentoFiscal: removerDocumentoFiscalAction,
    adicionarDocumentoFiscal: adicionarDocumentoFiscalAction,
    editarDocumentoFiscal: editarDocumentoFiscalAction,
    recalcularTotaisNotasMDFe: recalcularTotaisNotasMDFeAction,
    adicionarPercurso: adicionarPercursoAction,
    removerPercurso: removerPercursoAction,
    adicionarCarregamento: adicionarCarregamentoAction,
    removerCarregamento: removerCarregamentoAction,
    adicionarCondutor: adicionarCondutorAction,
    removerCondutor: removerCondutorAction,
    definirTipoTransportador: definirTipoTransportadorAction,
    definirDataEmissao: definirDataEmissaoAction,
  },
});

function definirDataEmissaoAction(
  state: MDFeEditFormViewModel,
  action: PayloadAction<string>
) {
  state.documentoAtual.dataHoraEmissao = action.payload;
}

function definirTipoTransportadorAction(
  state: MDFeEditFormViewModel,
  action: PayloadAction<TipoTransportadorMDFe>
) {
  state.documentoAtual.tipoTransportador = action.payload;
}

function carregarAction(
  state: MDFeEditFormViewModel,
  action: PayloadAction<MDFeCompletoDTO>
) {
  state.documentoAtual = action.payload;
}

function novoDocumentoAction(state: MDFeEditFormViewModel) {
  state.documentoAtual = INITIAL_STATE.documentoAtual;
}

function definirDadosGeraisAction(
  state: MDFeEditFormViewModel,
  action: PayloadAction<MDFeAbaGeralViewModel>
) {
  state.documentoAtual.id = action.payload.id;
  state.documentoAtual.serie = action.payload.serie;
  state.documentoAtual.numero = action.payload.numero;
  state.documentoAtual.dataHoraEmissao = action.payload.dataHoraEmissao;
  state.documentoAtual.modalidadeTransporte =
    action.payload.modalidadeTransporte;
  state.documentoAtual.tipoEmitente = action.payload.tipoEmitente;
  state.documentoAtual.dataHoraInicioViagem =
    action.payload.dataHoraInicioViagem;
  state.documentoAtual.modalRodoviario.contratante.idContratante =
    action.payload.idContratante;
  state.documentoAtual.tipoTransportador = action.payload.tipoTransportador;
}

function definirDadosObservacoesAction(
  state: MDFeEditFormViewModel,
  action: PayloadAction<MDFeAbaObservacoesViewModel>
) {
  state.documentoAtual.id = action.payload.id;
  state.documentoAtual.informacoesAdicionaisContibuinte =
    action.payload.contribuinte;
  state.documentoAtual.informacoesAdicionais = action.payload.fisco;
  state.documentoAtual.observacoesInternas = action.payload.internas;
}

function definirDadosVeiculosAction(
  state: MDFeEditFormViewModel,
  action: PayloadAction<MDFeAbaVeiculoViewModel>
) {
  const listaVeiculos = [];

  if (action.payload.idVeiculoTracao) {
    listaVeiculos.push({
      id: action.payload.idTracao ?? 0,
      idVeiculo: action.payload.idVeiculoTracao,
      tipo: TipoMDFeVeiculo.Tracao,
    });
  }

  if (action.payload.idVeiculoReboque1) {
    listaVeiculos.push({
      id: action.payload.idReboque1 ?? 0,
      idVeiculo: action.payload.idVeiculoReboque1,
      tipo: TipoMDFeVeiculo.Reboque,
    });
  }

  if (action.payload.idVeiculoReboque2) {
    listaVeiculos.push({
      id: action.payload.idReboque2 ?? 0,
      idVeiculo: action.payload.idVeiculoReboque2,
      tipo: TipoMDFeVeiculo.Reboque,
    });
  }

  if (action.payload.idVeiculoReboque3) {
    listaVeiculos.push({
      id: action.payload.idReboque3 ?? 0,
      idVeiculo: action.payload.idVeiculoReboque3,
      tipo: TipoMDFeVeiculo.Reboque,
    });
  }

  state.documentoAtual.modalRodoviario.veiculos = listaVeiculos;
}

function definirDadosNotasAction(
  state: MDFeEditFormViewModel,
  action: PayloadAction<MDFeAbaDocumentosFiscaisViewModel>
) {
  state.documentoAtual.unidadePesoBruto = action.payload.unidadeMedidaPesoBruto;
  state.documentoAtual.tipoCarregamento = action.payload.tipoCarregamento;

  if (
    state.documentoAtual.produtoPredominante &&
    action.payload.descricaoProdutoPredominante
  ) {
    state.documentoAtual.produtoPredominante.descricao =
      action.payload.descricaoProdutoPredominante;
  } else if (
    !state.documentoAtual.produtoPredominante &&
    action.payload.descricaoProdutoPredominante
  ) {
    state.documentoAtual.produtoPredominante = {
      id: 0,
      descricao: action.payload.descricaoProdutoPredominante,
    };
  } else {
    state.documentoAtual.produtoPredominante = null;
  }

  state.documentoAtual.pesoBruto = action.payload.pesoBrutoTotal;
  state.documentoAtual.unidadePesoBruto = action.payload.unidadeMedidaPesoBruto;
  state.documentoAtual.valorTotalNfe = action.payload.valorTotal;
}

function definirDadosPercursoAction(
  state: MDFeEditFormViewModel,
  action: PayloadAction<MDFeAbaPercursoViewModel>
) {
  state.documentoAtual.idUfFim = action.payload.idUfFim;
  state.documentoAtual.idUfInicio = action.payload.idUfInicio;
  state.documentoAtual.cepLocalCarregamento =
    action.payload.cepLocalCarregamento;
  state.documentoAtual.cepLocalDescarregamento =
    action.payload.cepLocalDescarregamento;
}

function adicionarCondutorAction(
  state: MDFeEditFormViewModel,
  action: PayloadAction<GridCondutorViewModel>
) {
  state.documentoAtual.modalRodoviario.condutores.push({
    id: 0,
    idCondutor: action.payload.idCondutor,
    condutor: {
      nome: action.payload.nome,
      cpfCnpj: action.payload.cpfCnpj,
      apelido: action.payload.apelido,
    },
  });
}

function removerCondutorAction(
  state: MDFeEditFormViewModel,
  action: PayloadAction<GridCondutorViewModel>
) {
  const i = state.documentoAtual?.modalRodoviario?.condutores?.findIndex(
    (x) =>
      x.idCondutor == action.payload.idCondutor && x.id == action.payload.id
  );

  if (i > -1) {
    state.documentoAtual.modalRodoviario.condutores.splice(i, 1);
  }
}

function adicionarDocumentoFiscalAction(
  state: MDFeEditFormViewModel,
  action: PayloadAction<DocumentoFiscalSelecaoMdfeGridModel>
) {
  if (
    !state.documentoAtual.descarregamentos.some(
      (x) => x.idMunicipio == action.payload.idMunicipioDestino
    )
  ) {
    let proximaOrdem = Math.max(
      ...state.documentoAtual.descarregamentos.map((x) => x.ordem)
    );
    proximaOrdem = proximaOrdem == Infinity * -1 ? 1 : proximaOrdem + 1;
    state.documentoAtual.descarregamentos.push({
      id: 0,
      idMunicipio: action.payload.idMunicipioDestino,
      ordem: proximaOrdem,
      documentosVinculados: [],
    });
  }

  const i = state.documentoAtual.descarregamentos.findIndex(
    (x) => x.idMunicipio == action.payload.idMunicipioDestino
  );

  state.documentoAtual.descarregamentos[i].documentosVinculados!.push({
    id: 0,
    idNotaFiscal: action.payload.id,
    documentoFiscal: {
      chaveAcesso: action.payload.chaveAcesso,
      dataEmissao: action.payload.dataEmissao,
      idDestinatario: action.payload.idDestinatario,
      destinatario: action.payload.destinatario,
      idMunicipioDestino: action.payload.idMunicipioDestino,
      municipioDestino: action.payload.municipioDestino,
      numero: action.payload.numero,
      pesoBruto: action.payload.pesoBruto,
      pesoLiquido: action.payload.pesoLiquido,
      serie: action.payload.serie,
      valor: action.payload.valor,
      idUfDestino: action.payload.idUfDestino,
      ufDestino: action.payload.ufDestino,
    },
  });
}

function removerDocumentoFiscalAction(
  state: MDFeEditFormViewModel,
  action: PayloadAction<DocumentoFiscalSelecaoMdfeGridModel>
) {
  const i = state.documentoAtual.descarregamentos.findIndex(
    (x) => x.idMunicipio == action.payload.idMunicipioDestino
  );

  // Verifica se é um documento fiscal avulso ou possui nota fiscal vinculada.
  if (action.payload.idNotaFiscal > 0) {
    state.documentoAtual.descarregamentos[i].documentosVinculados =
      state.documentoAtual.descarregamentos[i].documentosVinculados!.filter(
        (x) => x.idNotaFiscal != action.payload.idNotaFiscal
      );
  } else {
    state.documentoAtual.descarregamentos[i].documentosVinculados =
      state.documentoAtual.descarregamentos[i].documentosVinculados!.filter(
        (x) => x.documentoFiscal?.chaveAcesso != action.payload.chaveAcesso
      );
  }

  if (!state.documentoAtual.descarregamentos[i].documentosVinculados) {
    state.documentoAtual.descarregamentos =
      state.documentoAtual.descarregamentos.filter(
        (x) => x.idMunicipio != action.payload.idMunicipioDestino
      );
  }

  state.documentoAtual.descarregamentos =
    state.documentoAtual.descarregamentos.filter(
      (x) => (x.documentosVinculados?.length ?? 0) > 0
    );
}

function editarDocumentoFiscalAction(
  state: MDFeEditFormViewModel,
  action: PayloadAction<DocumentoFiscalSelecaoMdfeGridModel>
) {
  if (
    !state.documentoAtual.descarregamentos.some(
      (x) => x.idMunicipio == action.payload.idMunicipioDestino
    )
  ) {
    let proximaOrdem = Math.max(
      ...state.documentoAtual.descarregamentos.map((x) => x.ordem)
    );
    proximaOrdem = proximaOrdem == Infinity * -1 ? 1 : proximaOrdem + 1;
    state.documentoAtual.descarregamentos.push({
      id: 0,
      idMunicipio: action.payload.idMunicipioDestino,
      ordem: proximaOrdem,
      documentosVinculados: [],
    });

    const avulsoIndex = state.documentoAtual.descarregamentos.findIndex((x) =>
      x.documentosVinculados?.some((x) => x.id == action.payload.id)
    );
    if (avulsoIndex !== -1) {
      if (
        state.documentoAtual.descarregamentos[avulsoIndex].documentosVinculados
      ) {
        state.documentoAtual.descarregamentos[
          avulsoIndex
        ].documentosVinculados = state.documentoAtual.descarregamentos[
          avulsoIndex
        ].documentosVinculados!.filter((x) => x.id != action.payload.id);

        state.documentoAtual.descarregamentos[
          avulsoIndex
        ].documentosVinculados = undefined;
      }
    }

    const idMunicipioDestino = action.payload.idMunicipioDestino;
    const descarregamentoIndex =
      state.documentoAtual.descarregamentos.findIndex(
        (x) => x.idMunicipio === idMunicipioDestino
      );

    if (descarregamentoIndex !== -1) {
      state.documentoAtual.descarregamentos[
        descarregamentoIndex
      ].documentosVinculados?.push({
        id: 0,
        idNotaFiscal: action.payload.idNotaFiscal,
        documentoFiscal: {
          chaveAcesso: action.payload.chaveAcesso,
          dataEmissao: action.payload.dataEmissao,
          idDestinatario: action.payload.idDestinatario,
          destinatario: action.payload.destinatario,
          idMunicipioDestino: action.payload.idMunicipioDestino,
          municipioDestino: action.payload.municipioDestino,
          numero: action.payload.numero,
          pesoBruto: action.payload.pesoBruto,
          pesoLiquido: action.payload.pesoLiquido,
          serie: action.payload.serie,
          valor: action.payload.valor,
          idUfDestino: action.payload.idUfDestino,
          ufDestino: action.payload.ufDestino,
        },
      });
    }

    state.documentoAtual.descarregamentos =
      state.documentoAtual.descarregamentos.filter(
        (x) => (x.documentosVinculados?.length ?? 0) > 0
      );
  } else {
    const idMunicipioDestino = action.payload.idMunicipioDestino;
    const descarregamentoIndex =
      state.documentoAtual.descarregamentos.findIndex(
        (x) => x.idMunicipio === idMunicipioDestino
      );

    if (descarregamentoIndex !== -1) {
      const documentosVinculados =
        state.documentoAtual.descarregamentos[descarregamentoIndex]
          .documentosVinculados;

      if (documentosVinculados) {
        const documentoIndex = documentosVinculados.findIndex(
          (x) => x.id === action.payload.id
        );

        if (documentoIndex !== -1) {
          state.documentoAtual.descarregamentos[
            descarregamentoIndex
          ].documentosVinculados![documentoIndex] = {
            id: action.payload.id,
            idNotaFiscal: action.payload.idNotaFiscal,
            documentoFiscal: {
              chaveAcesso: action.payload.chaveAcesso,
              dataEmissao: action.payload.dataEmissao,
              idDestinatario: action.payload.idDestinatario,
              destinatario: action.payload.destinatario,
              idMunicipioDestino: action.payload.idMunicipioDestino,
              municipioDestino: action.payload.municipioDestino,
              numero: action.payload.numero,
              pesoBruto: action.payload.pesoBruto,
              pesoLiquido: action.payload.pesoLiquido,
              serie: action.payload.serie,
              valor: action.payload.valor,
              idUfDestino: action.payload.idUfDestino,
              ufDestino: action.payload.ufDestino,
            },
          };
        }
      }
    }
  }
}

function recalcularTotaisNotasMDFeAction(state: MDFeEditFormViewModel) {
  state.documentoAtual.valorTotalNfe = calcularValorTotalMDFe(
    state.documentoAtual.descarregamentos
  );
  state.documentoAtual.pesoBruto = calcularPesoBrutoTotalMDFe(
    state.documentoAtual.descarregamentos,
    UnidadeMedidaPesoBrutoMDFe.KG
  );
}

function adicionarPercursoAction(
  state: MDFeEditFormViewModel,
  action: PayloadAction<MDFePercursoDTO>
) {
  state.documentoAtual.percursos.push(action.payload);
}

function removerPercursoAction(
  state: MDFeEditFormViewModel,
  action: PayloadAction<GridPercursoViewModel>
) {
  state.documentoAtual.percursos = state.documentoAtual.percursos.filter(
    (x) => x.idUf != action.payload.idUf
  );
}

function adicionarCarregamentoAction(
  state: MDFeEditFormViewModel,
  action: PayloadAction<MDFeCarregamentoDTO>
) {
  state.documentoAtual.carregamentos.push(action.payload);
}

function removerCarregamentoAction(
  state: MDFeEditFormViewModel,
  action: PayloadAction<GridCarregamentoViewModel>
) {
  state.documentoAtual.carregamentos =
    state.documentoAtual.carregamentos.filter(
      (x) => x.idMunicipio != action.payload.idMunicipio
    );
}

export const {
  carregar,
  novoDocumento,
  definirDadosGerais,
  definirDadosVeiculos,
  definirDadosObservacoes,
  definirDadosNotas,
  definirDadosPercurso,
  recalcularTotaisNotasMDFe,
  removerDocumentoFiscal,
  adicionarDocumentoFiscal,
  editarDocumentoFiscal,
  adicionarPercurso,
  removerPercurso,
  adicionarCarregamento,
  removerCarregamento,
  adicionarCondutor,
  removerCondutor,
  definirTipoTransportador,
  definirDataEmissao,
} = mdfeSlice.actions;
export default mdfeSlice;
