import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useParametroId } from "../../../../../hooks/route.hooks";
import { ResultadoAcaoFormulario } from "../../../../../models/shared/ui/formularios";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesModais from "../../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import NormalizaTituloModal from "../../../../../utils/common/normaliza-titulo";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import { formatarPercentual } from "../../../../../utils/formatadores/formatador-de-numeros";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import UrlUtils from "../../../../../utils/url/url-utils";
import { CodigoTributacaoServicoGridModel } from "../../models/codigo-tributacao-servico.api";
import CodigoTributacaoServicoServico from "../../servicos/codigo-tributacao-servico.servico";
import FormCodigoTributacaoServico from "../formulario";

const service = new CodigoTributacaoServicoServico();
const dataSource =
  service.ObterDataSourceParaGrid<CodigoTributacaoServicoGridModel>();

const nameOfGridHandler = criarNameof<CodigoTributacaoServicoGridModel>();

export default function GridCodigoTributacaoServico() {
  const gridRef = useRef<DataGridRef>(null);
  const parametroId = useParametroId();
  const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);

  const handleNovoRegistro = useCallback(() => {
    setIdRegistroEdicao(0);
  }, []);

  const handleEditarRegistro = useCallback(
    (registro: CodigoTributacaoServicoGridModel) => {
      setIdRegistroEdicao(registro.id);
    },
    []
  );

  const handleExcluirRegistro = useCallback(
    async (registro: CodigoTributacaoServicoGridModel) => {
      const excluir = await exibirConfirmacao(
        "Confirmar exclusão",
        obterMensagemExclusao(registro)
      );

      if (excluir) {
        try {
          const resposta = await service.Excluir(registro.id);

          if (resposta) {
            checarResponseExibeMensagemExclusaoDeSucesso(resposta);
            handleAtualizarGrid();
          }
        } catch (erro) {
          tratarErroApi(erro);
        }
      }
    },
    []
  );

  function obterMensagemExclusao(registro: CodigoTributacaoServicoGridModel) {
    return `Tem certeza que deseja excluir o registro ${registro.descricao}?`;
  }

  const gridController = new GridController<CodigoTributacaoServicoGridModel>(
    () => gridRef.current?.instance()
  );

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar("codigo-tributacao-servico", () =>
      gridRef.current?.instance()
    )
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.codigosTributacaoServicos)
      .definirBotaoNovo(handleNovoRegistro)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .definirDuploCliqueLinha(handleEditarRegistro)
      .build();
  }, []);

  useEffect(() => {
    if (parametroId) {
      setIdRegistroEdicao(parametroId);
    }
  }, []);

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  useRegistrarAtalhosGrid<CodigoTributacaoServicoGridModel>({
    controller: gridController,
    handleNovo: handleNovoRegistro,
    handleEditar: handleEditarRegistro,
    handleExcluir: handleExcluirRegistro,
  });

  const handleCallbackFormulario = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      setIdRegistroEdicao(NaN);

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }

      UrlUtils.RemoverParametroId(parametroId);
    },
    [parametroId]
  );

  const handleFecharModal = useCallback(() => {
    setIdRegistroEdicao(NaN);
    UrlUtils.RemoverParametroId(parametroId);
  }, [parametroId]);

  return (
    <>
      <ProvedorAjuda id={"tooltips-grid-codigo-tributacao-servico"}>
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          {GridColunaAcoes<CodigoTributacaoServicoGridModel>({
            handleEditar: handleEditarRegistro,
            handleExcluir: handleExcluirRegistro,
          })}
          <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
          <Column
            key={nameOfGridHandler("codigo")}
            dataField={nameOfGridHandler("codigo")}
            caption="Código"
            allowResizing
            width={80}
          />
          <Column
            key={nameOfGridHandler("descricao")}
            dataField={nameOfGridHandler("descricao")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Descrição"
          />
          <Column
            key={nameOfGridHandler("aliquotaIss")}
            dataField={nameOfGridHandler("aliquotaIss")}
            {...obterConfiguracaoColuna("percentual")}
            caption="Alíquota de ISS"
            width={200}
            cellRender={({ data }) => {
              const dados = data as CodigoTributacaoServicoGridModel;
              return formatarPercentual(dados.aliquotaIss);
            }}
          />
          <Column
            key={nameOfGridHandler("codigoServico")}
            dataField={nameOfGridHandler("codigoServico")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Código do serviço"
          />
          <Column
            key={nameOfGridHandler("descricaoServico")}
            dataField={nameOfGridHandler("descricaoServico")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Descrição (serviço)"
          />
          {GetColunasDeAuditoria()}
        </DataGrid>
      </ProvedorAjuda>

      <ModalMxp
        titulo={NormalizaTituloModal.Normalizar(
          idRegistroEdicao,
          NomesModais.codigoDeTributacaoDeServicos
        )}
        visivel={!Number.isNaN(idRegistroEdicao)}
        handleFechar={handleFecharModal}
        largura={"max(50vw, 800px)"}
      >
        <FormCodigoTributacaoServico
          idRegistroEmEdicao={idRegistroEdicao}
          handleCallback={handleCallbackFormulario}
        />
      </ModalMxp>
    </>
  );
}
