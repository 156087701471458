import { uniqueId } from "lodash";
import { FieldValues, UseFormRegister } from "react-hook-form";
import { Fragment } from "react/jsx-runtime";
import {
  Anexos,
  ArquivoBaseModel,
} from "../../../features/sistema/arquivo/models/arquivo.api";

type ModelComAnexos<T> = T | Anexos;

type UseFormRegisterDosAnexos<T extends FieldValues & Anexos> = UseFormRegister<
  ModelComAnexos<T>
>;

interface CamposOcultosDosAnexosProps<T extends FieldValues & Anexos> {
  register: UseFormRegisterDosAnexos<T>;
  arquivos: ArquivoBaseModel[];
}

export default function CamposOcultosDosAnexos<T extends FieldValues & Anexos>(
  props: CamposOcultosDosAnexosProps<T>
) {
  const { register } = props;

  return (
    <>
      {props.arquivos.map((field, index) => (
        <Fragment key={index}>
          <input
            type="hidden"
            key={uniqueId("anexo_id_")}
            {...register(`arquivos.${index}.id`)}
            value={field.id}
          />
          <input
            type="hidden"
            key={uniqueId("anexo_extensao_")}
            {...register(`arquivos.${index}.extensao`)}
            value={field.extensao}
          />
          <input
            type="hidden"
            key={uniqueId("anexo_nome_")}
            {...register(`arquivos.${index}.nomeOriginalSemExtensao`)}
            value={field.nomeOriginalSemExtensao}
          />
          <input
            type="hidden"
            key={uniqueId("anexo_binario_")}
            {...register(`arquivos.${index}.arquivo`)}
            value={field.arquivo ?? ""}
          />
        </Fragment>
      ))}
    </>
  );
}
