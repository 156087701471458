import { DataGrid } from "devextreme-react";
import { Column, DataGridRef } from "devextreme-react/cjs/data-grid";
import { useMemo, useRef } from "react";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import { useSeletorGridMxp } from "../../../../../hooks/seletor.hooks";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import { ItemGridModel } from "../../models/item.api";
import { EstocagemTipoHelper } from "../../models/item.enums";
import { ItemService } from "../../servicos/item.service";

const service = new ItemService();
const nameOfGridHandler = criarNameof<ItemGridModel>();

export default function GridItem(props: GridMxpProps<ItemGridModel>) {
  const gridRef = useRef<DataGridRef>(null);

  const dataSource = service.ObterDataSourceParaGrid<ItemGridModel>(
    props.filtrosNoServidor
  );

  useSeletorGridMxp(() => gridRef.current, props.filtrosNoCliente);

  const gridController = new GridController<ItemGridModel>(() =>
    gridRef.current?.instance()
  );

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "item",
      () => gridRef.current?.instance(),
      false,
      props.filtrosNoCliente
    )
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.item)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .build();
  }, [props.filtrosNoCliente]);

  return (
    <ProvedorAjuda id={"tooltips-grid-item"}>
      <DataGrid ref={gridRef} {...configuracoesGrid}>
        <Column
          key={nameOfGridHandler("codigo")}
          dataField={nameOfGridHandler("codigo")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Código"
        />
        <Column
          key={nameOfGridHandler("descricao")}
          dataField={nameOfGridHandler("descricao")}
          {...obterConfiguracaoColuna("stringG")}
          caption="Descrição"
        />
        <Column
          key={nameOfGridHandler("grupo")}
          dataField={nameOfGridHandler("grupo")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Grupo"
        />
        <Column
          key={nameOfGridHandler("estado")}
          dataField={nameOfGridHandler("estado")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Estado"
        />
        <Column
          key={nameOfGridHandler("estocagemPor")}
          dataField={nameOfGridHandler("estocagemPor")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Estocagem por"
          lookup={EstocagemTipoHelper.AsLookup()}
          visible={false}
        />
        <Column
          key={nameOfGridHandler("procedencia")}
          dataField={nameOfGridHandler("procedencia")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Procedência"
          visible={false}
        />
        {GetColunasDeAuditoria()}
      </DataGrid>
    </ProvedorAjuda>
  );
}
