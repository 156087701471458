import { LoadIndicator } from "devextreme-react";
import { PropsWithChildren, Suspense, useEffect, useState } from "react";

interface RenderOnDemandProps {
  visivel: boolean;
}

/**
 * Faz com que o elemento seja renderizado apenas depois que ele ficar visível.
 * Util quando o elemento tem algum outro processamento ou animação após ficar invisível.
 */
export default function RenderOnDemand(
  props: PropsWithChildren<RenderOnDemandProps>
) {
  const [seletorVisivel, setSeletorVisivel] = useState(props.visivel);

  useEffect(() => {
    if (props.visivel && !seletorVisivel) {
      setSeletorVisivel(true);
    }
  }, [props.visivel]);

  return <>{seletorVisivel && props.children}</>;
}

interface RenderOnPromisseProps {
  promessa: Promise<void> | undefined | any;
}

const RenderOnPromisseInterno = (
  props: PropsWithChildren<RenderOnPromisseProps>
) => {
  props.promessa.read();
  return <>{props.children}</>;
};

/**
 * Faz com que o elemento seja renderizado apenas depois que a promessa concluir.
 * Util quando precisa fazer chamadas ao back ou processamentos pesados antes de renderizar.
 * Enquanto o processo não terminar vai renderizar o painel de carregamento.
 */
const RenderOnPromisse = (props: PropsWithChildren<RenderOnPromisseProps>) => {
  const promessa = wrapPromise(props.promessa);
  return (
    <Suspense fallback={<LoadIndicator visible={true} />}>
      <RenderOnPromisseInterno promessa={promessa}>
        {props.children}
      </RenderOnPromisseInterno>
    </Suspense>
  );
};

export const RenderOnDemandPromisse = (
  props: PropsWithChildren<RenderOnPromisseProps>
) => {
  if (props.promessa === undefined) {
    <LoadIndicator visible={true} />;
  }

  return (
    <>
      {props.promessa !== undefined && (
        <RenderOnPromisse promessa={props.promessa}>
          {props.children}
        </RenderOnPromisse>
      )}
    </>
  );
};

export const ComponentAsyncLoader = (props: PropsWithChildren) => {
  const [component, setComponent] = useState<React.ReactNode>(undefined);

  useEffect(() => {
    setTimeout(() => setComponent(<>{props.children}</>));
  }, [props.children]);

  return <>{component || <LoadIndicator visible={true} />}</>;
};

/**
 * Transforma um promessa em um objeto compatível com `Suspense`.
 */
const wrapPromise = (promise: any) => {
  let status = "pending";
  let result: any;
  const suspender = promise.then(
    (r: any) => {
      status = "success";
      result = r;
    },
    (e: any) => {
      status = "error";
      result = e;
    }
  );
  return {
    read() {
      if (status === "pending") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    },
  };
};
