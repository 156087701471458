import styled from "styled-components";

export const Container = styled.div`
  margin-top: 1em;
  margin-left: 0.5em;
`;

export const Titulo = styled.span`
  font-weight: 450;
`;
