import DataSource from "devextreme/data/data_source";
import {
  assertConfiguracaoExibicaoEBuscaType,
  ConfiguracaoExibicaoEBusca,
} from "../../../../components/formularios/selectbox-lazy-mxp";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import { DataSourceOpcoesBuilder } from "../../../../utils/grid/data-source-factory";
import { ItemPedidoDeVendaGridModel } from "../models/item-pedido-de-venda.api";

export default class ItemPedidoDeVendaService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.ItemPedidoDeVenda);
  }

  public GetDadosSelectBoxItemPedidoDeVenda(
    dataSourceOpcoes?: DataSourceOpcoesBuilder<ItemPedidoDeVendaGridModel>
  ): [
    DataSource<ItemPedidoDeVendaGridModel, any>,
    ConfiguracaoExibicaoEBusca<ItemPedidoDeVendaGridModel>
  ] {
    const camposBusca: (keyof ItemPedidoDeVendaGridModel)[] = [
      "numeroPedidoDeVenda",
      "numeroItemPedidoDeVenda",
      "codigoItem",
      "descricaoItem",
      "apelidoCliente",
    ];
    const camposRetorno = new Set<keyof ItemPedidoDeVendaGridModel>([
      ...(dataSourceOpcoes?.camposRetorno || []),
      // Adicionar os campos mínimos que precisamos para montar o SelectBoxLazy.
      "id",
      ...camposBusca,
    ]);

    const dataSource =
      this.ObterDataSourceParaSelectBoxLazy<ItemPedidoDeVendaGridModel>({
        quantidadeRegistros: dataSourceOpcoes?.quantidadeRegistros,
        camposRetorno: Array.from(camposRetorno),
        camposOrdenacao: dataSourceOpcoes?.camposOrdenacao || [
          { campo: "numeroPedidoDeVenda", desc: true },
          { campo: "numeroItemPedidoDeVenda", desc: true },
        ],
        camposFiltro: dataSourceOpcoes?.camposFiltro,
        filtroExato: dataSourceOpcoes?.filtroExato,
      });

    const configs =
      assertConfiguracaoExibicaoEBuscaType<ItemPedidoDeVendaGridModel>({
        nomeCampoChave: "id",
        expressaoDeBusca: camposBusca,
        nomeCampoExibicao: (pvit) =>
          pvit
            ? `${pvit.numeroPedidoDeVenda} #${pvit.numeroItemPedidoDeVenda} - ${pvit.codigoItem} (${pvit.descricaoItem}) - ${pvit.apelidoCliente}`
            : "",
      });

    return [dataSource, configs];
  }
}
