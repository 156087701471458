import PaginaMxp from "../../../../../components/layout/pagina-mxp";
import ProvedorMenus from "../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../components/seguranca/requer-permissao";
import { PermissoesAdmin } from "../../../../../models/permissoes/seguranca/usuario/permissoes-admin";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import { GridUsuariosLogados } from "../../componentes/grid";

export default function UsuariosLogadosPage() {
  return (
    <ProvedorMenus>
      <PaginaMxp
        id="usuarios-logados-page"
        titulo={NomesTelas.usuariosLogados}
        area={["Segurança", "Usuários logados"]}
      >
        <RequerPermissao
          codigoPermissoes={[
            PermissoesAdmin.VisualizarUsuariosLogadosNoSistema,
          ]}
          ignoraSeForMaster
        >
          <GridUsuariosLogados />
        </RequerPermissao>
      </PaginaMxp>
    </ProvedorMenus>
  );
}
