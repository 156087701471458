import styled from "styled-components";

export const ColunaFlex = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .dx-tabpanel {
    height: 100%;
    min-height: 10%;
  }
`;
