import { HttpStatusCode } from "axios";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { SortDescriptor } from "devextreme/data";
import DataSource from "devextreme/data/data_source";
import { atualizarToken, getHeaders, getUrl } from "../../configs/api";
import { StringsComum } from "../../features/comum/strings";
import store from "../../store";
import { definirMensagemLogoff } from "../../store/ui/ui.slice";
import { tratarErroApi } from "../api/api-utils";
import { aguardar } from "../common/common-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../common/notificacoes-utils";
import { RemoverSessaoReduxELocalStorage } from "../oauth/oauth-utils";

export interface DataSourceOpcoes<T> {
  quantidadeRegistros?: number;
  camposRetorno: Extract<keyof T, string>[];
  camposOrdenacao: DataSourceOrdenacao<T>[];
  camposFiltro?: DataSourceFiltragem<T>[];
  filtroExato?: DataSourceRawFilter<T>;
}

export interface DataSourceOpcoesBuilder<T> {
  quantidadeRegistros?: number;
  camposRetorno?: Extract<keyof T, string>[];
  camposOrdenacao?: DataSourceOrdenacao<T>[];
  camposFiltro?: DataSourceFiltragem<T>[];
  filtroExato?: DataSourceRawFilter<T>;
}

export interface DataSourceOrdenacao<T> {
  campo: Extract<keyof T, string>;
  desc: boolean;
}

/**
 * O filtro de DataSource do DevExtreme.
 * @example ["campoA", "=", 2]
 * @example [["campoA", "=", 2], "and", ["campoB", "<>", 5]]
 * @example ["!", ["campoA", "=", 2]]
 * @example
 * [
 *   ["campoA", "=", 2],
 *   "and",
 *   [
 *     ["campoB", "=", 4],
 *     "or",
 *     ["campoB", "=", 5]
 *   ]
 * ]
 */
export type DataSourceRawFilter<T> = DataSourceRawFilterElem<T>[];

/**
 * O elemento do filtro, permite combinação entre filtros usando os {@link OperadoresComplexos}, {@link DataSourceRawFieldFilter} ou um array de elementos de filtro.
 * @example ["campoA", "=", 2]
 * @example [["campoA", "=", 2], "and", ["campoB", "<>", 5]]
 * @example ["!", ["campoA", "=", 2]]
 */
type DataSourceRawFilterElem<T> =
  | OperadoresComplexos
  | DataSourceRawFieldFilter<T>
  | DataSourceRawFilterElem<T>[];

/**
 * Operações que permitem combinar múltiplos filtros
 */
type OperadoresComplexos = "or" | "and" | "!";

/**
 * O elemento base do filtro, que permite filtrar um campo por um valor
 * @example ["campo", "contains", "Alguma coisa"]
 */
type DataSourceRawFieldFilter<T> = {
  [K in keyof T]: [K, OperadoresFiltragem, T[K]];
}[keyof T];

type OperadoresFiltragem =
  | "="
  | "<>"
  | ">"
  | ">="
  | "<"
  | "<="
  | "startswith"
  | "endswith"
  | "contains"
  | "notcontains";

export type DataSourceFiltragem<T> = {
  [K in keyof T]: {
    campo: K;
    operador: OperadoresFiltragem;
    valor: T[K];
  };
}[keyof T];

const getEstadoAtualizacaoToken = () =>
  store.getState().estadoUI.atualizandoToken;

/**
 * Classe responsável por criar a instância do data source a ser utilizado pela grid ou pelo select box lazy.
 */
export default class DataSourceFactory {
  /**
   * Método responsável por criar a instância do datasource para grid.
   * @param urlRelativa Path com o caminho relativo do endpoint da grid
   * @param filtroPadrao Parâmetro opcional com configurações do filtro padrão a ser realizado no servidor.
   * @returns DataSource para a grid
   */
  public static CriarParaGrid<TGrid, TKey = number>(
    urlRelativa: string,
    filtroPadrao?: DataSourceFiltragem<TGrid>[],
    filtroPadraoExato?: DataSourceRawFilter<TGrid>
  ) {
    const customStore = this.CriarDataSourceInternal(urlRelativa);

    const ds = new DataSource<TGrid, TKey>({
      store: customStore,
    });

    this.AplicarFiltragem(ds, filtroPadrao, filtroPadraoExato);

    return ds;
  }

  /**
   * Método responsável por criar a instância do datasource para o selectbox lazy.
   * @param urlRelativa Path com o caminho relativo do endpoint da grid
   * @param opcoes Parâmetro com as informações de configurações do datasource
   * @returns DataSource para o selectbox lazy
   */
  public static CriarParaSelectBoxLazy<TGrid, TKey = number>(
    urlRelativa: string,
    opcoes: DataSourceOpcoes<TGrid>
  ) {
    const customStore = this.CriarDataSourceInternal(urlRelativa, "id", {
      select: JSON.stringify(opcoes.camposRetorno),
    });

    const ds = new DataSource<TGrid, TKey>({
      store: customStore,
      pageSize: opcoes.quantidadeRegistros ?? 50,
      requireTotalCount: false,
    });

    this.AplicarSelecao(ds, opcoes.camposRetorno);
    this.AplicarOrdenacao(ds, opcoes.camposOrdenacao);
    this.AplicarFiltragem(ds, opcoes.camposFiltro, opcoes.filtroExato);

    return ds;
  }

  private static AplicarSelecao<TGrid, TKey = number>(
    dataSource: DataSource<TGrid, TKey>,
    campos: string[]
  ) {
    dataSource.select(campos);
  }

  private static AplicarOrdenacao<TGrid, TKey = number>(
    dataSource: DataSource<TGrid, TKey>,
    ordenacao: DataSourceOrdenacao<TGrid>[]
  ) {
    const camposOrdenacao: SortDescriptor<TGrid>[] = [];

    ordenacao.map((x) => {
      camposOrdenacao.push({ selector: x.campo, desc: x.desc });
    });

    dataSource.sort(camposOrdenacao);
  }

  private static AplicarFiltragem<TGrid, TKey = number>(
    dataSource: DataSource<TGrid, TKey>,
    filtros?: DataSourceFiltragem<TGrid>[],
    filtroDireto?: DataSourceRawFilter<TGrid>
  ) {
    if (!filtros && !filtroDireto) {
      return;
    }

    const todosFiltros = [];
    if (filtros && filtros.length) {
      todosFiltros.push(...filtros.map((x) => [x.campo, x.operador, x.valor]));
    }
    if (filtroDireto && filtroDireto.length) {
      todosFiltros.push(...filtroDireto);
    }
    dataSource.filter(todosFiltros);
  }

  private static CriarDataSourceInternal(
    urlRelativa: string,
    key: string = "id",
    loadParams: any | undefined = undefined
  ) {
    const dataSource = createStore({
      key: key,
      loadUrl: getUrl(urlRelativa),
      loadParams: loadParams,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.headers = getHeaders();
      },
      onAjaxError: ({ xhr, error }) => {
        // Enum para comparação do erro
        enum serverErrors {
          Locked = 423,
          InternalServerError = 500,
          NetworkConnectTimeoutError = 599,
        }

        // Trata erro na requisição do grid com os valores de erro do servidor
        if (
          xhr.status === HttpStatusCode.Locked &&
          !store.getState().estadoUI.redirecionando
        ) {
          store.dispatch(definirMensagemLogoff(JSON.parse(xhr.response)));
          RemoverSessaoReduxELocalStorage(true);
        } else if (
          xhr.status >= serverErrors.InternalServerError &&
          xhr.status <= serverErrors.NetworkConnectTimeoutError
        ) {
          exibirNotificacaoToast({
            mensagem: StringsComum.falhaCarregarDadosGrade,
            tipo: TipoNotificacao.Erro,
          });
          return;
        } else if (xhr.status === HttpStatusCode.Forbidden) {
          exibirNotificacaoToast({
            mensagem: error.toString() ?? StringsComum.falhaCarregarDadosGrade,
            tipo: TipoNotificacao.Erro,
          });
          return;
        } else if (
          xhr.status === HttpStatusCode.Unauthorized &&
          getEstadoAtualizacaoToken() != "ajax-grid"
        ) {
          if (getEstadoAtualizacaoToken() == "ocioso") {
            atualizarToken("ajax-grid", error, false).then(() => {
              dataSource.load();
            });
          } else {
            aguardar(5000).then(() => {
              dataSource.load();
            });
          }
        } else {
          tratarErroApi(error ?? xhr);
        }
      },
    });

    return dataSource;
  }
}
