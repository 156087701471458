import styled from "styled-components";

export const Container = styled.div`
  margin-top: 1em;
`;

export const Titulo = styled.span`
  font-weight: 450;
`;

export const Box = styled.div`
  border: 1px solid lightgrey;
  border-radius: 4px;
  padding: 0.2em 0.3em;
`;
