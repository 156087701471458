export enum AmbienteMDFe {
  Producao = 1,
  Homologacao = 2,
}

export const ambienteMDFeDecodificado: {
  [key in AmbienteMDFe]: string;
} = {
  [AmbienteMDFe.Producao]: "Produção",
  [AmbienteMDFe.Homologacao]: "Homologação",
};

export enum SituacaoMDFe {
  EmEdicao = 1,
  Validado = 2,
  Autorizado = 3,
  Encerrado = 4,
  Cancelado = 5,
  Rejeitado = 6,
}

export const situacaoMDFeDecodificada: {
  [key in SituacaoMDFe]: string;
} = {
  [SituacaoMDFe.EmEdicao]: "Em edição",
  [SituacaoMDFe.Validado]: "Validado",
  [SituacaoMDFe.Autorizado]: "Autorizado",
  [SituacaoMDFe.Encerrado]: "Encerrado",
  [SituacaoMDFe.Rejeitado]: "Cancelado",
  [SituacaoMDFe.Cancelado]: "Rejeitado",
};

export enum TipoEmissaoMDFe {
  Normal = 1,
  Contingencia = 2,
}

export const tipoEmissaoMDFeDecodificado: {
  [key in TipoEmissaoMDFe]: string;
} = {
  [TipoEmissaoMDFe.Normal]: "Normal",
  [TipoEmissaoMDFe.Contingencia]: "Contingência",
};

export enum TipoEmitenteMDFe {
  PrestadorServico = 1,
  TransportadorCargaPropria = 2,
  CTeGlobalizado = 3,
}

export const tipoEmitenteMDFeDecodificado: {
  [key in TipoEmitenteMDFe]: string;
} = {
  [TipoEmitenteMDFe.PrestadorServico]: "Prestador de serviço de transporte",
  [TipoEmitenteMDFe.TransportadorCargaPropria]:
    "Transportador de carga própria",
  [TipoEmitenteMDFe.CTeGlobalizado]:
    "Transportador que emitirá CT-e globalizado",
};

export enum TipoTransportadorMDFe {
  ETC = 1,
  TAC = 2,
}

export const tipoTransportadorMDFeDecodificado: {
  [key in TipoTransportadorMDFe]: string;
} = {
  [TipoTransportadorMDFe.ETC]: "Empresa de transporte de cargas (ETC)",
  [TipoTransportadorMDFe.TAC]: "Transportador autônomo de cargas (TAC)",
};

export enum ModalidadeTransporteMDFe {
  Rodoviario = 1,
  Aereo = 2,
  Aquaviario = 3,
  Ferroviario = 4,
}

export const modalidadeTransporteMDFeDecodificado: {
  [key in ModalidadeTransporteMDFe]: string;
} = {
  [ModalidadeTransporteMDFe.Rodoviario]: "Rodoviário",
  [ModalidadeTransporteMDFe.Aereo]: "Aéreo",
  [ModalidadeTransporteMDFe.Aquaviario]: "Aquaviário",
  [ModalidadeTransporteMDFe.Ferroviario]: "Ferroviário",
};

export enum TipoCarregamentoMDFe {
  GranelSolido = 1,
  GranelLiquido = 2,
  Frigorificada = 3,
  Conteinerizada = 4,
  CargaGeral = 5,
  Neogranel = 6,
  PerigosaGranelSolido = 7,
  PerigosaGranelLiquido = 8,
  PerigosaCargaFrigorificada = 9,
  PerigosaConteinerizada = 10,
  PerigosaCargaGeral = 11,
}

export const tipoCarregamentoMDFeDecodificado: {
  [key in TipoCarregamentoMDFe]: string;
} = {
  [TipoCarregamentoMDFe.GranelSolido]: "Granel sólido",
  [TipoCarregamentoMDFe.GranelLiquido]: "Granel líquido",
  [TipoCarregamentoMDFe.Frigorificada]: "Frigorificada",
  [TipoCarregamentoMDFe.Conteinerizada]: "Conteinerizada",
  [TipoCarregamentoMDFe.CargaGeral]: "Carga geral",
  [TipoCarregamentoMDFe.Neogranel]: "Neogranel",
  [TipoCarregamentoMDFe.PerigosaGranelSolido]: "Perigosa granel sólido",
  [TipoCarregamentoMDFe.PerigosaGranelLiquido]: "Perigosa granel líquido",
  [TipoCarregamentoMDFe.PerigosaCargaFrigorificada]:
    "Perigosa carga frigorificada",
  [TipoCarregamentoMDFe.PerigosaConteinerizada]: "Perigosa conteinerizada",
  [TipoCarregamentoMDFe.PerigosaCargaGeral]: "Perigosa carga geral",
};

export enum UnidadeMedidaPesoBrutoMDFe {
  KG = 1,
  TON = 2,
}

export const unidadeMedidaPesoBrutoMDFeDecodificado: {
  [key in UnidadeMedidaPesoBrutoMDFe]: string;
} = {
  [UnidadeMedidaPesoBrutoMDFe.KG]: "KG",
  [UnidadeMedidaPesoBrutoMDFe.TON]: "TON",
};

export enum TipoMDFeVeiculo {
  Tracao = 1,
  Reboque = 2,
}

export const tipoMDFeVeiculoDecodificado: {
  [key in TipoMDFeVeiculo]: string;
} = {
  [TipoMDFeVeiculo.Tracao]: "Tração",
  [TipoMDFeVeiculo.Reboque]: "Reboque",
};

export enum IdUFBanco {
  AC = 4,
  AL = 6,
  AP = 8,
  BA = 10,
  CE = 12,
  DF = 14,
  ES = 16,
  GO = 18,
  MA = 20,
  MG = 22,
  MS = 24,
  MT = 26,
  PA = 28,
  PB = 30,
  PE = 32,
  PI = 34,
  PR = 36,
  RJ = 38,
  RN = 40,
  RO = 42,
  RR = 44,
  RS = 46,
  SC = 48,
  SE = 50,
  SP = 52,
  TO = 54,
  AM = 56,
}

export enum TipoDocumentoFiscal {
  CTe57 = "57",
  CTe67 = "67",
  NFe55 = "55",
}

export const tiposCTe: TipoDocumentoFiscal[] = [
  TipoDocumentoFiscal.CTe57,
  TipoDocumentoFiscal.CTe67,
];
export const tiposNFe: TipoDocumentoFiscal[] = [TipoDocumentoFiscal.NFe55];
