import PaginaMxp from "../../../../../components/layout/pagina-mxp";
import ProvedorMenus from "../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../components/seguranca/requer-permissao";
import { PermissoesSerieFiscal } from "../../../../../models/permissoes/fiscal/serie-fiscal/permissoes-serie-fiscal";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import GridSerieFiscal from "../../componentes/grid";

export default function SerieFiscalPage() {
  return (
    <ProvedorMenus>
      <PaginaMxp
        id="serie-fiscal-page"
        titulo={NomesTelas.seriesDeDocumentosFiscais}
        area={["Fiscal"]}
      >
        <RequerPermissao codigoPermissoes={[PermissoesSerieFiscal.Consultar]}>
          <GridSerieFiscal />
        </RequerPermissao>
      </PaginaMxp>
    </ProvedorMenus>
  );
}
