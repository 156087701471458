import DataSource from "devextreme/data/data_source";
import {
  assertConfiguracaoExibicaoEBuscaType,
  ConfiguracaoExibicaoEBusca,
} from "../../../components/formularios/selectbox-lazy-mxp";
import { FinalidadeTipo } from "../../../features/contabilidade/conta-contabil/models/conta-contabil.enum";
import { EstoqueMovimentacaoTipo } from "../../../features/estoque/estoque-movimentacao/models/estoque-movimentacao.enums";
import { ContaContabilGridModel } from "../../../models/api/conta-contabil/conta-contabil";
import { NomesEndpoints } from "../../comum/nomesEndpoints";
import { ServiceBase } from "../../comum/serviceBase";

export class ContaContabilService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.ContaContabil);
  }

  public GetDataSourceSelectBoxContasDeEstoque(
    tipo?: EstoqueMovimentacaoTipo
  ): DataSource<ContaContabilGridModel, any> {
    const dataSource =
      this.GetDataSourceSelectBoxLazyMxp<ContaContabilGridModel>({
        camposRetorno: ["id", "codigo", "apelido", "finalidade"],
        camposOrdenacao: [
          {
            nomeCampo: "apelido",
            desc: false,
          },
        ],
        filtros: [
          { nomeCampo: "ativo", operador: "=", valor: true },
          { nomeCampo: "podeMovimentarEstoque", operador: "=", valor: true },
        ],
      });

    const filter = dataSource.loadOptions().filter;
    const filtroDeEstoque = [
      ["finalidade", "=", FinalidadeTipo.Estoque],
      "or",
      ["finalidade", "=", FinalidadeTipo.EstoqueDeTerceirosEmNossoPoder],
      "or",
      ["finalidade", "=", FinalidadeTipo.EstoqueDeTerceirosEmPoderDeTerceiros],
      "or",
      ["finalidade", "=", FinalidadeTipo.EstoqueEmPoderDeTerceiros],
    ];
    if (tipo == EstoqueMovimentacaoTipo.Estoque) {
      dataSource.filter([...filter, filtroDeEstoque]);
    } else if (tipo == EstoqueMovimentacaoTipo.ContaContabil) {
      dataSource.filter([...filter, ["!", filtroDeEstoque]]);
    }

    return dataSource;
  }

  public GetDadosSelectBoxContasDeEstoque(): [
    DataSource<ContaContabilGridModel, any>,
    ConfiguracaoExibicaoEBusca<ContaContabilGridModel>
  ] {
    const dataSource = this.GetDataSourceSelectBoxContasDeEstoque();

    const configs =
      assertConfiguracaoExibicaoEBuscaType<ContaContabilGridModel>({
        expressaoDeBusca: ["codigo", "apelido"],
        nomeCampoChave: "id",
        nomeCampoExibicao: (c) => {
          if (c) {
            return c.apelido;
          }

          return "";
        },
      });

    return [dataSource, configs];
  }
}
