import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  DetalhesAssinaturaOpcionaisAssinaturaResponse,
  DetalhesPermissoesResponse,
} from "../../features/seguranca/login/models/login.api";
import {
  ItemListaSimplesStringDTO,
  ItemOpcionalAssinaturaModel,
  NotificationsResponse,
} from "../../models/api/comum/response-base";
import EstadoUI, {
  EstadosAtualizacaoToken,
} from "../../models/shared/ui/estado-ui";

const INITIAL_STATE: EstadoUI = {
  blockUI: {
    bloqueado: false,
    texto: "",
  },
  atualizandoToken: "ocioso",
  loginReturnUrl: null,
  accordionNotifications: {
    visivel: false,
    titulo: "Erro",
    notificacoes: {
      sucesso: false,
      mensagem: "",
      model: [],
      erros: [],
    },
  },
  necessitaOpcionais: {
    visivel: false,
    dados: [],
  },
  linksAjuda: [],
  detalhesPermissoes: [],
  detalhesOpcionaisAssinatura: [],
  modalRequerPermissao: {
    visivel: false,
    permissoes: [],
  },
  mensagemLogoff: null,
  desabilitarToasts: false,
  redirecionando: false,
  modalSobre: {
    visivel: false,
  },
};

const estadoUISlice = createSlice({
  name: "ui",
  initialState: INITIAL_STATE,
  reducers: {
    bloquearUI: bloquearUIAction,
    desbloquearUI: desbloquearUIAction,
    definirEstadoAtualizacaoToken: definirEstadoAtualizacaoTokenAction,
    definirLoginReturnUrl: definirLoginReturnUrlAction,
    exibirAccordionNotifications: exibirAccordionNotificationsAction,
    ocultarAccordionNotifications: ocultarAccordionNotificationsAction,
    exibirNecessitaOpcionais: exibirNecessitaOpcionaisAction,
    ocultarNecessitaOpcionais: ocultarNecessitaOpcionaisAction,
    definirLinksAjuda: definirLinksAjudaAction,
    definirDetalhesPermissoes: definirDetalhesPermissoesAction,
    definirDetalhesOpcionaisAssinatura:
      definirDetalhesOpcionaisAssinaturaAction,
    exibirModalRequerPermissao: exibirModalRequerPermissaoAction,
    ocultarModalRequerPermissao: ocultarModalRequerPermissaoAction,
    definirMensagemLogoff: definirMensagemLogoffAction,
    limparMensagemLogoff: limparMensagemLogoffAction,
    habilitarToasts: habilitarToastsAction,
    desabilitarToasts: desabilitarToastsAction,
    definirEstadoRedirecionando: definirEstadoRedirecionandoAction,
    ocultarModalSobre: ocultarModalSobreAction,
    exibirModalSobre: exibirModalSobreAction,
  },
});

function desabilitarToastsAction(state: EstadoUI) {
  state.desabilitarToasts = true;
}

function habilitarToastsAction(state: EstadoUI) {
  state.desabilitarToasts = false;
}

function ocultarModalRequerPermissaoAction(state: EstadoUI) {
  state.modalRequerPermissao = INITIAL_STATE.modalRequerPermissao;
}

function exibirModalRequerPermissaoAction(
  state: EstadoUI,
  action: PayloadAction<string[]>
) {
  state.modalRequerPermissao.visivel = true;
  state.modalRequerPermissao.permissoes = action.payload;
}

function bloquearUIAction(
  state: EstadoUI,
  action: PayloadAction<string | undefined>
) {
  state.blockUI.bloqueado = true;
  state.blockUI.texto = action.payload ?? "Aguarde...";
}

function desbloquearUIAction(state: EstadoUI) {
  state.blockUI.bloqueado = false;
  state.blockUI.texto = "";
}

function definirEstadoAtualizacaoTokenAction(
  state: EstadoUI,
  action: PayloadAction<EstadosAtualizacaoToken>
) {
  state.atualizandoToken = action.payload;
}

function definirLoginReturnUrlAction(
  state: EstadoUI,
  action: PayloadAction<string | null>
) {
  state.loginReturnUrl = action.payload;
}

function exibirAccordionNotificationsAction(
  state: EstadoUI,
  action: PayloadAction<NotificationsResponse>
) {
  state.accordionNotifications.notificacoes = action.payload;
  state.accordionNotifications.visivel = true;
  state.accordionNotifications.titulo = action.payload.titulo ?? "Erro";
}

function ocultarAccordionNotificationsAction(state: EstadoUI) {
  state.accordionNotifications = INITIAL_STATE.accordionNotifications;
}

function exibirNecessitaOpcionaisAction(
  state: EstadoUI,
  action: PayloadAction<ItemOpcionalAssinaturaModel[]>
) {
  state.necessitaOpcionais.dados = action.payload;
  state.necessitaOpcionais.visivel = true;
}

function ocultarNecessitaOpcionaisAction(state: EstadoUI) {
  state.necessitaOpcionais.visivel = false;
  state.necessitaOpcionais.dados = [];
}

function definirLinksAjudaAction(
  state: EstadoUI,
  action: PayloadAction<ItemListaSimplesStringDTO[]>
) {
  state.linksAjuda = action.payload;
}

function definirDetalhesPermissoesAction(
  state: EstadoUI,
  action: PayloadAction<DetalhesPermissoesResponse[]>
) {
  state.detalhesPermissoes = action.payload;
}

function definirDetalhesOpcionaisAssinaturaAction(
  state: EstadoUI,
  action: PayloadAction<DetalhesAssinaturaOpcionaisAssinaturaResponse[]>
) {
  state.detalhesOpcionaisAssinatura = action.payload;
}

function definirMensagemLogoffAction(
  state: EstadoUI,
  action: PayloadAction<string>
) {
  state.mensagemLogoff = action.payload;
}

function limparMensagemLogoffAction(state: EstadoUI) {
  state.mensagemLogoff = null;
}

function definirEstadoRedirecionandoAction(
  state: EstadoUI,
  action: PayloadAction<boolean>
) {
  state.redirecionando = action.payload;
}

function ocultarModalSobreAction(state: EstadoUI) {
  state.modalSobre = INITIAL_STATE.modalSobre;
}

function exibirModalSobreAction(state: EstadoUI) {
  state.modalSobre.visivel = true;
}

export const {
  bloquearUI,
  desbloquearUI,
  definirEstadoAtualizacaoToken,
  definirLoginReturnUrl,
  exibirAccordionNotifications,
  ocultarAccordionNotifications,
  exibirNecessitaOpcionais,
  ocultarNecessitaOpcionais,
  definirLinksAjuda,
  definirDetalhesPermissoes,
  definirDetalhesOpcionaisAssinatura,
  exibirModalRequerPermissao,
  ocultarModalRequerPermissao,
  definirMensagemLogoff,
  limparMensagemLogoff,
  habilitarToasts,
  desabilitarToasts,
  definirEstadoRedirecionando,
  ocultarModalSobre,
  exibirModalSobre,
} = estadoUISlice.actions;
export default estadoUISlice;
