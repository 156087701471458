/* eslint-disable */
export const traducoesYup = {
  mixed: {
    default: "${path} é inválido",
    required: "Campo obrigatório.",
    oneOf: "${path} deve ser um dos seguintes valores: ${values}",
    notOneOf: "${path} não pode ser um dos seguintes valores: ${values}",
    notType: "Campo obrigatório.",
  },
  string: {
    length: "${path} deve ter exatamente ${length} caracteres",
    min: "${path} deve ter pelo menos ${min} caracteres",
    max: "Máximo de ${max} caracteres",
    email: "E-mail inválido",
    url: "${path} deve ter um formato de URL válida",
    trim: "${path} não deve conter espaços no início ou no fim.",
    lowercase: "${path} deve estar em maiúsculo",
    uppercase: "${path} deve estar em minúsculo",
  },
  number: {
    min: "Deve ser maior ou igual a ${min}",
    max: "Deve ser menor ou igual a ${max}",
    lessThan: "${path} deve ser menor que ${less}",
    moreThan: "Valor deve ser maior que ${more}.",
    notEqual: "${path} não pode ser igual a ${notEqual}",
    positive: "${path} deve ser um número positivo",
    negative: "${path} deve ser um número negativo",
    integer: "Valor deve ser inteiro.",
  },
  date: {
    min: "${path} deve ser maior que a data ${min}",
    max: "${path} deve ser menor que a data ${max}",
  },
  array: {
    min: "${path} deve ter no mínimo ${min} itens",
    max: "${path} deve ter no máximo ${max} itens",
  },
};
