import { ReactElement } from "react";
import { BotaoLadoDireitoInputStyle } from "./styles";

interface BotaoLadoDireitoInputProps {
  children: ReactElement;
  onClick: () => void;
  textoTooltip?: string;
  icone: string;
  visivel?: boolean;
}

export default function BotaoLadoDireitoInput({
  children,
  onClick,
  textoTooltip,
  icone,
  visivel = true,
}: BotaoLadoDireitoInputProps) {
  return (
    <>
      {!visivel && <div style={{ marginRight: "21px" }}>{children}</div>}
      {visivel && (
        <div style={{ display: "flex", alignItems: "end" }}>
          <div style={{ flexGrow: 1 }}>{children}</div>
          <div style={{ marginLeft: "4px" }}>
            <BotaoLadoDireitoInputStyle onClick={onClick} title={textoTooltip}>
              <span className={`ic-material-symbols-outlined ${icone}`}></span>
            </BotaoLadoDireitoInputStyle>
          </div>
        </div>
      )}
    </>
  );
}
