import { EmpresaGridModel } from "../../../../../models/api/empresa/empresa-grid";
import { DataSourceFiltragem } from "../../../../../utils/grid/data-source-factory";

export default class EmpresaFiltrosHelpers {
  static readonly FiltrarAtivos: DataSourceFiltragem<EmpresaGridModel>[] = [
    {
      campo: "ativo",
      operador: "=",
      valor: true,
    },
  ];
  static readonly FiltrarClientes: DataSourceFiltragem<EmpresaGridModel>[] = [
    {
      campo: "cliente",
      operador: "=",
      valor: true,
    },
  ];
}
