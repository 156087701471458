import { useCallback } from "react";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import CamposOcultosDosAnexos from "../../../../../../components/arquivo/campos-hidden";
import TabContainer from "../../../../../../components/layout/tab-container";
import ProvedorMenus from "../../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../../components/seguranca/requer-permissao";
import { PermissoesTarefaOuAnotacao } from "../../../../../../models/permissoes/crm/tarefa-ou-anoracao/permissoes-tarefa-ou-anotacao";
import GridAnexos from "../../../../../sistema/arquivo/componentes/grid";
import { TarefaOuAnotacaoRequest } from "../../../models/tarefa-ou-anotacao.api";
import TarefaOuAnotacaoServico from "../../../servicos/tarefa-ou-anotacao.servico";

const tarefaOuAnotacaoServico = new TarefaOuAnotacaoServico();

interface TarefaOuAnotacaoAnexosProps {
  hookForm: UseFormReturn<TarefaOuAnotacaoRequest, any, undefined>;
  idRegistro: number;
}

export default function TarefaOuAnotacaoAbaAnexos(
  props: TarefaOuAnotacaoAnexosProps
) {
  const { control, register, getValues } = props.hookForm;

  const fieldArrayAnexos = useFieldArray({
    control,
    name: "arquivos",
  });

  const funcaoParaBaixarAnexo = useCallback(
    (idAnexo: number) => {
      return tarefaOuAnotacaoServico.anexoObterDadosParaDownload(
        getValues("id"),
        idAnexo
      );
    },
    [getValues]
  );

  return (
    <TabContainer>
      <ProvedorMenus>
        <CamposOcultosDosAnexos
          register={register}
          arquivos={fieldArrayAnexos.fields}
          key={"campos-ocultos-anexo"}
        />

        <RequerPermissao
          codigoPermissoes={[
            PermissoesTarefaOuAnotacao.EditarSomenteDoUsuario,
            PermissoesTarefaOuAnotacao.EditarTodos,
          ]}
        >
          <GridAnexos
            idRegistroRelacionado={props.idRegistro}
            operacoesFieldArray={fieldArrayAnexos}
            permissaoIncluir={PermissoesTarefaOuAnotacao.EditarSomenteDoUsuario}
            permissaoExcluir={PermissoesTarefaOuAnotacao.EditarTodos}
            permissaoVisualizarEBaixar={PermissoesTarefaOuAnotacao.EditarTodos}
            funcaoParaBaixarAnexo={funcaoParaBaixarAnexo}
            arquivos={getValues("arquivos")}
          />
        </RequerPermissao>
      </ProvedorMenus>
    </TabContainer>
  );
}
