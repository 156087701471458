import { useCallback } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import CamposOcultosDosAnexos from "../../../../../../../components/arquivo/campos-hidden";
import TabContainer from "../../../../../../../components/layout/tab-container";
import ProvedorMenus from "../../../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../../../components/seguranca/requer-permissao";
import { PermissoesOrdemDeProducao } from "../../../../../../../models/permissoes/producao/ordem-de-producao/OrdemDeProducaoPermissoes";
import GridAnexos from "../../../../../../sistema/arquivo/componentes/grid";
import { OrdemDeProducaoRequest } from "../../../../models/ordem-de-producao.api";
import { OrdemDeProducaoService } from "../../../../servicos/ordem-de-producao.service";

const ordemDeProducaoService = new OrdemDeProducaoService();

interface OrdemDeProducaoAnexosProps {
  idRegistro: number;
}

export default function OrdemDeProducaoAbaAnexos(
  props: OrdemDeProducaoAnexosProps
) {
  const { control, register, getValues } =
    useFormContext<OrdemDeProducaoRequest>();

  const fieldArrayAnexos = useFieldArray({
    control,
    name: "arquivos",
  });

  const funcaoParaBaixarAnexo = useCallback(
    (idAnexo: number) => {
      return ordemDeProducaoService.anexoObterDadosParaDownload(
        getValues("id"),
        idAnexo
      );
    },
    [getValues]
  );

  return (
    <TabContainer>
      <ProvedorMenus>
        <CamposOcultosDosAnexos
          register={register}
          arquivos={fieldArrayAnexos.fields}
          key={"campos-ocultos-anexo"}
        />

        <RequerPermissao
          codigoPermissoes={[PermissoesOrdemDeProducao.ConsultarAnexos]}
        >
          <GridAnexos
            idRegistroRelacionado={props.idRegistro}
            operacoesFieldArray={fieldArrayAnexos}
            permissaoIncluir={PermissoesOrdemDeProducao.InserirAnexos}
            permissaoExcluir={PermissoesOrdemDeProducao.ExcluirAnexos}
            permissaoVisualizarEBaixar={
              PermissoesOrdemDeProducao.ConsultarAnexos
            }
            funcaoParaBaixarAnexo={funcaoParaBaixarAnexo}
            arquivos={getValues("arquivos")}
          />
        </RequerPermissao>
      </ProvedorMenus>
    </TabContainer>
  );
}
