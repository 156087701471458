import PaginaMxp from "../../../../../components/layout/pagina-mxp";
import ProvedorMenus from "../../../../../components/menus/provedor-menus";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import GridCodigoTributacaoServico from "../../componentes/grid";

export default function CodigoTributacaoServicoPage() {
  return (
    <ProvedorMenus>
      <PaginaMxp
        id="codigo-tributacao-servico-page"
        titulo={NomesTelas.codigosTributacaoServicos}
        area={["Fiscal", "Cadastros"]}
      >
        <GridCodigoTributacaoServico />
      </PaginaMxp>
    </ProvedorMenus>
  );
}
