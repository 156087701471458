import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ProvedorAjuda from "../../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../../components/botoes/botao-salvar-mxp";
import { FormNumberBox } from "../../../../../../components/formularios";
import FormTextArea from "../../../../../../components/formularios/textarea";
import FormMxp from "../../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../../components/layout/toolbar-mxp";
import AuditavelDTO from "../../../../../../models/api/comum/auditavel-dto";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../../models/shared/ui/formularios";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../../utils/common/notificacoes-utils";
import { NUMERO_INTEIRO_FORMATADOR } from "../../../../../../utils/formatadores/formatador-de-numeros";
import {
  NaturezaDoRendimentoRequestDTO,
  NaturezaDoRendimentoResponseDTO,
} from "../../../models/natureza-do-rendimento/natureza-do-rendimento";
import NaturezaDoRendimentoConstantes from "../../../models/natureza-do-rendimento/natureza-do-rendimento.constantes";
import NaturezaDoRendimentoService from "../../../servicos/natureza-do-rendimento/natureza-do-rendimento.service";

const novoRegistro: NaturezaDoRendimentoRequestDTO = {
  id: 0,
  codigo: "",
  descricao: null,
};

const service = new NaturezaDoRendimentoService();

let dadosAuditoria: AuditavelDTO | undefined = undefined;
export default function EditFormNaturezaDoRendimento(
  props: IFormularioEditavelBase
) {
  const [carregando, setCarregando] = useState(false);

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    codigo: yup
      .number()
      .required()
      .min(NaturezaDoRendimentoConstantes.Codigo.Minimo)
      .max(NaturezaDoRendimentoConstantes.Codigo.Maximo)
      .integer(),
    descricao: yup
      .string()
      .nullable()
      .max(NaturezaDoRendimentoConstantes.Descricao.Maximo),
  });

  const { register, control, handleSubmit, getValues, reset } =
    useForm<NaturezaDoRendimentoRequestDTO>({
      resolver: yupResolver(schema),
    });

  useEffect(() => {
    limparTela();
  }, []);

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();

    return () => limparTela();
  }, [props.idRegistroEmEdicao]);

  async function preencherTela() {
    if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    }
  }

  async function carregarRegistroDoId() {
    try {
      setCarregando(true);
      const resposta =
        await service.ObterPorIdComDadosAuditoria<NaturezaDoRendimentoResponseDTO>(
          props.idRegistroEmEdicao
        );
      checarResponse(resposta);
      dadosAuditoria = resposta.model;
      reset(resposta.model);
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  function limparTela() {
    dadosAuditoria = undefined;
    reset(novoRegistro);
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();
    try {
      const resposta =
        props.idRegistroEmEdicao > 0
          ? await service.Atualizar(model)
          : await service.Cadastrar(model);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  return (
    <>
      <ContainerFormMxp>
        <FormMxp carregando={carregando}>
          <ProvedorAjuda id="edit-form-natureza-do-rendimento">
            <input type="hidden" {...register("id")} defaultValue={0} />
            <Linha>
              <Coluna md={3}>
                <FormNumberBox
                  name="codigo"
                  titulo={"Código"}
                  requerido
                  control={control}
                  formato={NUMERO_INTEIRO_FORMATADOR}
                  minimo={NaturezaDoRendimentoConstantes.Codigo.Minimo}
                  maximo={NaturezaDoRendimentoConstantes.Codigo.Maximo}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={12}>
                <FormTextArea
                  name="descricao"
                  titulo="Descrição"
                  valor={""}
                  control={control}
                  tamanhoMaximo={
                    NaturezaDoRendimentoConstantes.Descricao.Maximo
                  }
                  height={20}
                />
              </Coluna>
            </Linha>
          </ProvedorAjuda>
        </FormMxp>
        <ToolbarMxp dadosAuditoria={dadosAuditoria}>
          <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </>
  );
}
