import AuditavelDTO from "../../../../models/api/comum/auditavel-dto";
import AuditavelGridModel from "../../../../models/api/comum/auditavel-grid";
import { CadastrarEmMassaExcelBase } from "../../../../models/api/comum/cadastrar-varios";
import {
  Anexos,
  ArquivoBaseModel,
} from "../../../sistema/arquivo/models/arquivo.api";

export interface LoteGridModel extends AuditavelGridModel {
  id: number;
  itemId: number | null;
  item: string | null;
  descricao: string | null;
  fabricante: string | null;
  loteDoFabricanteOuNumeroDeSerie: string | null;
  estocagem: string | null;
  dono: string;
  reanalise: Date | null;
  vencimento: Date | null;
  fabricacao: Date | null;
  observacoes: string | null;
  observacoesInternas: string | null;
  estadoDecodificado: string | null;
}

export interface LoteRequestBase {
  itemId: number | null;
  loteDoFabricanteOuNumeroDeSerie: string | null;
  fabricanteId: number | null;
  fabricacaoData: Date | null;
  vencimentoData: Date | null;
  reanaliseData: Date | null;
  observacoes: string | null;
  observacoesInternas: string | null;
  loteDataRequerido?: boolean;
  controleLoteFabricacao?: boolean;
  utilizaPrefixo: boolean;
}

export interface DadosRastreabilidadeLoteRequest {
  criarNovo: boolean;
  loteId: number | null;
  cadastroLote: LoteRequestBase | null | undefined;
}

export interface LoteRequestDTO extends LoteRequestBase, Anexos {
  id: number;
  historico: string | null;
  arquivos: Array<ArquivoBaseModel>;
}

export const NovoRegistroLoteRequest: LoteRequestDTO = {
  id: 0,
  itemId: null,
  fabricanteId: null,
  loteDoFabricanteOuNumeroDeSerie: null,
  historico: null,
  reanaliseData: null,
  vencimentoData: null,
  fabricacaoData: null,
  observacoes: null,
  observacoesInternas: null,
  arquivos: [],
  utilizaPrefixo: false,
};

export interface LoteCadastrarVariosExcelRequest
  extends CadastrarEmMassaExcelBase {
  loteDoFabricanteOuNumeroDeSerie: string;
  codigoItem: string;
  codigoFabricante: string;
  fabricacaoData: Date | null;
  vencimentoData: Date | null;
  reanaliseData: Date | null;
  observacoes: string | null;
  observacoesInternas: string | null;
  utilizaPrefixo: boolean;
}

export interface LoteResponseDTO extends LoteRequestDTO, AuditavelDTO {}
