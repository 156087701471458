import SelectItem from "../../../../../models/shared/ui/select-item";
import { gerarSelectItemDoDicionario } from "../../../../../utils/common/enum-utils";

export enum TipoTarefaOuAnotacao {
  Anotacao = 65,
  Tarefa = 84,
}

// Classe que encapsula o enum e suas descrições
export class TipoTarefaOuAnotacaoHelper {
  // Criando um "dicionário" com Map
  public static tipoTarefaOuAnotacaoDescricao: {
    [key in TipoTarefaOuAnotacao]: string;
  } = {
    [TipoTarefaOuAnotacao.Anotacao]: "Anotação",
    [TipoTarefaOuAnotacao.Tarefa]: "Tarefa",
  };

  // Função para obter a descrição
  public static getDescricao(tipo: TipoTarefaOuAnotacao): string {
    return (
      TipoTarefaOuAnotacaoHelper.tipoTarefaOuAnotacaoDescricao[tipo] ||
      "Descrição desconhecida"
    );
  }

  public static AsSelectItems(): SelectItem[] {
    return gerarSelectItemDoDicionario(
      TipoTarefaOuAnotacaoHelper.tipoTarefaOuAnotacaoDescricao
    );
  }
}

export enum EstadoTarefaOuAnotacao {
  Finalizada = 70,
  Pendente = 80,
  Cancelada = 75,
}

// Classe que encapsula o enum e suas descrições
export class EstadoTarefaOuAnotacaoHelper {
  // Criando um "dicionário" com Map
  public static estadoTarefaOuAnotacaoDescricao: {
    [key in EstadoTarefaOuAnotacao]: string;
  } = {
    [EstadoTarefaOuAnotacao.Finalizada]: "Finalizada",
    [EstadoTarefaOuAnotacao.Pendente]: "Pendente",
    [EstadoTarefaOuAnotacao.Cancelada]: "Cancelada",
  };

  // Função para obter a descrição
  public static getDescricao(tipo: EstadoTarefaOuAnotacao): string {
    return (
      EstadoTarefaOuAnotacaoHelper.estadoTarefaOuAnotacaoDescricao[tipo] ||
      "Descrição desconhecida"
    );
  }

  public static AsSelectItems(): SelectItem[] {
    return gerarSelectItemDoDicionario(
      EstadoTarefaOuAnotacaoHelper.estadoTarefaOuAnotacaoDescricao
    );
  }
}

export enum TipoVinculoTarefaOuAnotacao {
  OrdemDeProducao = 1,
  PropostaDeVenda = 2,
  PedidoDeVenda = 3,
  NotaFiscal = 4,
  Cotacao = 5,
  PedidoDeCompra = 6,
  NotaFiscalRecebida = 7,
  Titulo = 8,
}

// Classe que encapsula o enum e suas descrições
export class TipoVinculoTarefaOuAnotacaoHelper {
  // Criando um "dicionário" com Map
  public static tipoVinculoTarefaOuAnotacaoDescricao: {
    [key in TipoVinculoTarefaOuAnotacao]: string;
  } = {
    [TipoVinculoTarefaOuAnotacao.OrdemDeProducao]: "OrdemDeProducao",
    [TipoVinculoTarefaOuAnotacao.PropostaDeVenda]: "Proposta",
    [TipoVinculoTarefaOuAnotacao.PedidoDeVenda]: "PedidoVenda",
    [TipoVinculoTarefaOuAnotacao.NotaFiscal]: "NotaFiscal",
    [TipoVinculoTarefaOuAnotacao.Cotacao]: "Cotacao",
    [TipoVinculoTarefaOuAnotacao.PedidoDeCompra]: "PedidoCompra",
    [TipoVinculoTarefaOuAnotacao.NotaFiscalRecebida]: "Recebida",
    [TipoVinculoTarefaOuAnotacao.Titulo]: "Titulo",
  };

  // Função para obter a descrição
  public static getDescricao(tipo: TipoVinculoTarefaOuAnotacao): string {
    return (
      TipoVinculoTarefaOuAnotacaoHelper.tipoVinculoTarefaOuAnotacaoDescricao[
        tipo
      ] || "Descrição desconhecida"
    );
  }
}
