import PaginaMxp from "../../../../../components/layout/pagina-mxp";
import RequerPermissao from "../../../../../components/seguranca/requer-permissao";
import { PermissoesInsumosDasOrdensDeProducao } from "../../../../../models/permissoes/producao/insumo-da-ordem-de-producao/InsumoDaOrdemDeProducaoPermissoes";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import GridInsumoDaOrdemDeProducao from "../../componentes/grid-padrao";

export default function InsumosDasOrdensDeProducaoPage() {
  return (
    <PaginaMxp
      id="insumo-da-ordem-de-producao-page"
      titulo={NomesTelas.insumosDasOrdensDeProducao}
      area={["Produção"]}
    >
      <RequerPermissao
        codigoPermissoes={[PermissoesInsumosDasOrdensDeProducao.Consultar]}
      >
        <GridInsumoDaOrdemDeProducao />
      </RequerPermissao>
    </PaginaMxp>
  );
}
