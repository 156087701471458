import { useEffect, useState } from "react";
import * as yup from "yup";
import ProvedorAjuda from "../../../../../../../../components/ajuda/provedor-ajuda";
import {
  FormCheckBox,
  FormNumberBox,
  FormSelectBox,
  FormSelectBoxLazy,
} from "../../../../../../../../components/formularios";
import FormTextArea from "../../../../../../../../components/formularios/textarea";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import BotaoCancelarMxp from "../../../../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../../../../components/botoes/botao-salvar-mxp";
import FormMxp from "../../../../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../../../../components/layout/form/styles";
import {
  Coluna,
  Linha,
} from "../../../../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../../../../components/layout/toolbar-mxp";
import { AdicaoExclusaoCompensacaoLacsGridModel } from "../../../../../../../../models/api/adicao-exclusao-compensacao/adicao-exclusao-compensacao-lacs-csll/adicao-exclusao-compensacao-lacs-csll";
import AuditavelDTO from "../../../../../../../../models/api/comum/auditavel-dto";
import { IndicadorDoLancamento } from "../../../../../../../../models/api/lancamento-parte-b/lancamento-parte-b-comum";
import {
  LancamentoNaParteBLacsRequest,
  LancamentoNaParteBLacsResponse,
} from "../../../../../../../../models/api/lancamento-parte-b/lancamento-parte-b-lacs/lancamento-parte-b-lacs";
import { SelectItemEnumIndicadorDoLancamentoLancamentoNaParteB } from "../../../../../../../../models/const/dicionario-combos/lancamento-parte-b";
import { PermissoesApuracaoLacsCsll } from "../../../../../../../../models/permissoes/fiscal/apuracao-lacs-csll/permissoes-apuracao-lacs-csll";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../../../../models/shared/ui/formularios";
import { GridAdicaoExclusaoCompensacaoLacs } from "../../../../../../../../parts/fiscal/adicao-exclusao-compensacao-lacs-csll/grid";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../../../../utils/api/api-utils";
import criarNameof from "../../../../../../../../utils/common/cria-name-of";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../../../../utils/common/permissoes-utils";
import { obterFormatStringNumero } from "../../../../../../../../utils/formatadores/formatador-de-numeros";
import {
  FormataDescricao,
  FormatadoresSelectBox,
} from "../../../../../../../../utils/formatadores/formatador-de-selectbox";
import GridContaDaParteB from "../../../../../../../contabilidade/conta-parte-b/componentes/grid";
import { ContaDaParteBGridModel } from "../../../../../../../contabilidade/conta-parte-b/models/conta-parte-b";
import { TipoTributoContaParteB } from "../../../../../../../contabilidade/conta-parte-b/models/conta-parte-b.enums";
import FiltrosGridContaParteB from "../../../../../../../contabilidade/conta-parte-b/models/conta-parte-b.filtros";
import { ContaParteBService } from "../../../../../../../contabilidade/conta-parte-b/servicos/conta-parte-b.service";
import LancamentoDaParteBDoLacsConstantes from "../../../../../models/lancamento-parte-b-lacs.constantes";
import AdicaoExclusaoCompensacaoLacsCsllService from "../../../../../servicos/adicao-exclusao-compensacao-lacs-csll.service";
import { LancamentoNaParteBDoLacsService } from "../../../../../servicos/lancamento-parte-b-lacs.service";

const LancamentoNaParteBLacsService = new LancamentoNaParteBDoLacsService();
const contaParteBService = new ContaParteBService();
const adicaoExclusaoCompensacaoLacsService =
  new AdicaoExclusaoCompensacaoLacsCsllService();
const nameofContaParteBGrid = criarNameof<ContaDaParteBGridModel>();
const nameofAdicaoExclusaoCompensacaoGrid =
  criarNameof<AdicaoExclusaoCompensacaoLacsGridModel>();

let dadosAuditoria: AuditavelDTO | undefined = undefined;

function carregaContaParteB() {
  return contaParteBService.GetDataSourceSelectBoxLazy({
    camposRetorno: [
      nameofContaParteBGrid("id"),
      nameofContaParteBGrid("codigo"),
      nameofContaParteBGrid("descricao"),
    ],
    filtros: [
      { nomeCampo: nameofContaParteBGrid("ativo"), operador: "=", valor: true },
      {
        nomeCampo: nameofContaParteBGrid("tipoTributo"),
        operador: "=",
        valor: TipoTributoContaParteB.CSLL,
      },
    ],
    camposOrdenacao: [
      {
        nomeCampo: nameofContaParteBGrid("codigo"),
        desc: false,
      },
    ],
  });
}

function carregaAdicoesExclusoesCompensacoesLacs() {
  return adicaoExclusaoCompensacaoLacsService.ObterDataSourceSelectBoxLazy({
    camposRetorno: [
      nameofAdicaoExclusaoCompensacaoGrid("id"),
      nameofAdicaoExclusaoCompensacaoGrid("codigo"),
      nameofAdicaoExclusaoCompensacaoGrid("descricao"),
      nameofAdicaoExclusaoCompensacaoGrid("tipoDecodificado"),
    ],
    camposOrdenacao: [
      {
        nomeCampo: nameofAdicaoExclusaoCompensacaoGrid("codigo"),
        desc: false,
      },
    ],
  });
}

interface EditFormLancamentoNaParteBLacsProps extends IFormularioEditavelBase {
  idApuracaoDoLacsEDaCsll: number;
}

const contaParteBExpressaoBusca = [
  nameofContaParteBGrid("codigo"),
  nameofContaParteBGrid("descricao"),
];
const exibeContaParteB = (c: ContaDaParteBGridModel | null) => {
  if (c) {
    return c.descricao == null ? `${c.codigo}` : `${c.codigo} (${c.descricao})`;
  }

  return "";
};

const adicaoExclusaoCompensacaoExpressaoBusca = [
  nameofAdicaoExclusaoCompensacaoGrid("codigo"),
  nameofAdicaoExclusaoCompensacaoGrid("descricao"),
];
const exibeAdicaoExclusaoCompensacao = (
  c: AdicaoExclusaoCompensacaoLacsGridModel | null
) => {
  if (c) {
    return c.descricao == null
      ? `${c.codigo}`
      : FormataDescricao(
          FormatadoresSelectBox.TipoCodigoDescricaoTracoEspaco,
          c.tipoDecodificado,
          c.codigo,
          c.descricao
        );
  }

  return "";
};

export default function EditFormLancamentoNaParteBLacs(
  props: EditFormLancamentoNaParteBLacsProps
) {
  const novoRegistro: LancamentoNaParteBLacsRequest = {
    id: 0,
    apuracaoDoLacsEDaCsllId: props.idApuracaoDoLacsEDaCsll,
    contaDaParteBDoLalurOuDoLacsId: 0,
    incluirNaParteA: false,
    adicaoOuExclusaoOuCompensacaoDoLacsId: undefined,
    indicadorDoLancamento: undefined,
    historico: "",
    tributacaoDiferida: false,
    valor: 0,
  };
  const [carregando, setCarregando] = useState(false);
  const [contaParteB] = useState(carregaContaParteB);
  const [adicoesExclusoesCompensacoes] = useState(
    carregaAdicoesExclusoesCompensacoesLacs
  );
  const [incluirNaParteA, setIncluirNaParteA] = useState(false);

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    contaDaParteBDoLalurOuDoLacsId: yup
      .number()
      .required("Campo obrigatório.")
      .moreThan(0, "Campo obrigatório.")
      .integer(),
    valor: yup.number().moreThan(0).required(),
    indicadorDoLancamento: yup
      .mixed<IndicadorDoLancamento>()
      .transform((v) => (v ? v : null))
      .oneOf(
        Object.values(IndicadorDoLancamento).map((x) => x as number),
        "Valor inválido"
      )
      .test("valida-indicador-do-lancamento", "Indicador inválido", (v) => {
        return incluirNaParteA
          ? v == IndicadorDoLancamento.CR || v == IndicadorDoLancamento.DB
          : true;
      })
      .required(),
    historico: yup
      .string()
      .required()
      .max(LancamentoDaParteBDoLacsConstantes.HistoricoTamanhoMaximo),
    tributacaoDiferida: yup.bool().oneOf([true, false]),
    incluirNaParteA: yup.bool().required(),
    adicaoOuExclusaoOuCompensacaoDoLacsId: yup
      .number()
      .integer()
      .nullable()
      .test(
        "valida-adicaoOuExclusaoOuCompensacaoDoLacsId",
        "Campo obrigatório.",
        (v) => {
          return incluirNaParteA ? v != null : true;
        }
      ),
    apuracaoDoLacsEDaCsllId: yup.number().moreThan(-1).integer().required(),
  });

  const { register, control, handleSubmit, getValues, reset } =
    useForm<LancamentoNaParteBLacsRequest>({
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: yupResolver(schema),
    });

  useEffect(() => limparTela(), []);

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();

    return () => limparTela();
  }, [props.idRegistroEmEdicao]);

  async function preencherTela() {
    if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    } else {
      limparTela();
    }
  }

  async function carregarRegistroDoId() {
    try {
      setCarregando(true);
      const resposta =
        await LancamentoNaParteBLacsService.ObterPorIdComDadosAuditoria<LancamentoNaParteBLacsResponse>(
          props.idRegistroEmEdicao
        );
      checarResponse(resposta);
      dadosAuditoria = resposta.model;
      reset(resposta.model);
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  function limparTela() {
    dadosAuditoria = undefined;
    reset(novoRegistro);
  }

  async function handleSalvar() {
    try {
      setCarregando(true);
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesApuracaoLacsCsll.InserirEditar,
        ])
      ) {
        return;
      }
      const model = getValues();
      const resposta =
        props.idRegistroEmEdicao > 0
          ? await LancamentoNaParteBLacsService.Atualizar(model)
          : await LancamentoNaParteBLacsService.Cadastrar(model);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  async function onChangeIncluirNaParteA(e: any) {
    setIncluirNaParteA(e.value);
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  return (
    <>
      <ContainerFormMxp>
        <FormMxp carregando={carregando}>
          <input type="hidden" {...register("id")} defaultValue={0} />
          <ProvedorAjuda id="edit-form-lancamento-parte-b-lacs">
            <Linha>
              <Coluna md={12}>
                <FormSelectBoxLazy
                  name="contaDaParteBDoLalurOuDoLacsId"
                  titulo="Conta da parte B"
                  control={control}
                  requerido
                  nomeCampoChave={nameofContaParteBGrid("id")}
                  nomeCampoExibicao={exibeContaParteB}
                  expressaoDeBusca={contaParteBExpressaoBusca}
                  dataSource={contaParteB}
                  lupaConfig={{
                    modo: "selecaoUnica",
                    titulo: "Selecionar conta da parte B",
                    componente: () => (
                      <GridContaDaParteB
                        filtrosNoCliente={FiltrosGridContaParteB.ativo}
                      />
                    ),
                  }}
                  labelSemDados="Sem dados"
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={3}>
                <FormNumberBox
                  name="valor"
                  titulo="Valor"
                  minimo={0}
                  formato={obterFormatStringNumero(2)}
                  control={control}
                  requerido
                />
              </Coluna>
              <Coluna md={5}>
                <FormSelectBox
                  name="indicadorDoLancamento"
                  titulo="Indicador do lançamento"
                  control={control}
                  requerido
                  dataSource={
                    SelectItemEnumIndicadorDoLancamentoLancamentoNaParteB
                  }
                  defaultValue={null}
                />
              </Coluna>
              <Coluna md={4} centralizar>
                <FormCheckBox
                  name="tributacaoDiferida"
                  titulo="Realização de valores cuja tributação tenha sido diferida"
                  control={control}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={12}>
                <FormTextArea
                  name="historico"
                  titulo="Histórico"
                  control={control}
                  requerido
                  tamanhoMaximo={
                    LancamentoDaParteBDoLacsConstantes.HistoricoTamanhoMaximo
                  }
                  valor={""}
                  height={10}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={3} centralizar>
                <FormCheckBox
                  name="incluirNaParteA"
                  titulo="Tem reflexo na parte A"
                  control={control}
                  onValueChanged={onChangeIncluirNaParteA}
                />
              </Coluna>
              <Coluna md={9}>
                <FormSelectBoxLazy
                  name="adicaoOuExclusaoOuCompensacaoDoLacsId"
                  titulo="Adição, exclusão ou compensação de prejuizo"
                  control={control}
                  nomeCampoChave={nameofAdicaoExclusaoCompensacaoGrid("id")}
                  nomeCampoExibicao={exibeAdicaoExclusaoCompensacao}
                  expressaoDeBusca={adicaoExclusaoCompensacaoExpressaoBusca}
                  dataSource={adicoesExclusoesCompensacoes}
                  lupaConfig={{
                    modo: "selecaoUnica",
                    titulo:
                      "Selecionar adição, exclusão ou compensação do LACS",
                    componente: (r) => (
                      <GridAdicaoExclusaoCompensacaoLacs ref={r} />
                    ),
                  }}
                  labelSemDados="Sem dados"
                  desabilitado={!incluirNaParteA}
                  requerido={incluirNaParteA}
                />
              </Coluna>
            </Linha>
          </ProvedorAjuda>
        </FormMxp>
        <ToolbarMxp dadosAuditoria={dadosAuditoria}>
          <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </>
  );
}
