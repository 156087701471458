import Notify from "devextreme/ui/notify";
import store from "../../store";

export enum TipoNotificacao {
  Erro = "error",
  Informacao = "info",
  Sucesso = "success",
  Advertencia = "warning",
}

export enum PosicaoNotificacao {
  SuperiorEsquerdo = "top left",
  SuperiorCentro = "top center",
  SuperiorDireito = "top right",
  InferiorEsquerdo = "bottom left",
  InferiorCentro = "bottom center",
  InferiorDireito = "bottom right",
  EsquerdaCentro = "left center",
  Centro = "center",
  DireitaCentro = "right center",
}

export enum DirecaoPilha {
  EmpurrarParaBaixo = "down-push",
  EmpilharParaBaixo = "down-stack",
  EmpurrarParaCima = "up-push",
  EmpilharParaCima = "up-stack",
  EmpurrarParaEsquerda = "left-push",
  EmpilharParaEsquerda = "left-stack",
  EmpurrarParaDireita = "right-push",
  EmpilharParaDireita = "right-stack",
}

export interface OpcoesNotificao {
  mensagem: string;
  tipo?: TipoNotificacao;
  posicao?: PosicaoNotificacao;
  direcao?: DirecaoPilha;
  tempo?: number;
  largura?: number | string;
  altura?: number | string;
}

export enum MensagensParaNotificacao {
  ExcluidoComSucesso = "Excluído com sucesso",
}

export enum JanelasDeNotificacaoTitulos {
  Atencao = "Atenção",
}

export default function exibirNotificacaoToast(opcoes: OpcoesNotificao) {
  if (store.getState().estadoUI.desabilitarToasts || !opcoes.mensagem) {
    return;
  }

  const dados = {
    message: opcoes.mensagem,
    height: opcoes.altura,
    width: opcoes.largura,
    type: opcoes.tipo ?? TipoNotificacao.Informacao,
    displayTime: opcoes.tempo ?? 5000,
  };

  Notify(dados, {
    position: opcoes.posicao ?? { top: 50, right: 10 },
    direction: opcoes.direcao ?? DirecaoPilha.EmpurrarParaBaixo,
  });
}

export function exibirListaDeMensagensEmNotificacoesToast(
  mensagens: string[],
  tipoDeNotificacao: TipoNotificacao
) {
  mensagens.forEach((mensagem) =>
    exibirNotificacaoToast({
      mensagem: mensagem,
      tipo: tipoDeNotificacao,
    })
  );
}
