interface QuebrarLinhasProps {
  texto: string;
}

export function QuebrarLinhas({ texto }: QuebrarLinhasProps) {
  const linhas = texto.split("\n");

  return (
    <>
      {linhas.map((texto, indice) => {
        return (
          <span key={String(indice)}>
            {indice > 0 && <br />}
            {texto}
          </span>
        );
      })}
    </>
  );
}
