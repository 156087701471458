export enum PermissoesMDF {
  Consultar = "MDF000",
  InserirEditar = "MDF001",
  Excluir = "MDF002",
  Validar = "MDF003",
  EnviarEmProducao = "MDF004",
  EnviarEmHomologacao = "MDF005",
  Encerrar = "MDF006",
  Cancelar = "MDF007",
}
