import { NumberBoxTypes } from "devextreme-react/cjs/number-box";

export function focaNoInputAoRenderizarComponente(e: any) {
  e.component.focus();
}

export function selecionarInputAoFocarNoElemento(
  e: NumberBoxTypes.FocusInEvent
) {
  const input = e?.element.querySelector(
    "input:not([type=hidden])"
  ) as HTMLInputElement;
  input?.select();
}
