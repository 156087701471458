import { createContext, PropsWithChildren, useContext } from "react";
import { FieldPath, FieldValues, UseFormReturn } from "react-hook-form";

export const ObterNomeDaPropriedadeBase = <T1 extends FieldValues>(
  propriedade: FieldPath<T1>
) => propriedade as FieldPath<T1>;

// Defina a estrutura do estado do contexto
export interface SubFormContextProps<
  T1 extends FieldValues,
  T2 extends FieldValues
> {
  hookForms: UseFormReturn<T1 | T2>;
  isSubform?: boolean;
  obterNomeDaPropriedade: (
    propriedade: FieldPath<T1>
  ) => FieldPath<T1> | FieldPath<T2>;
}

interface GetSubFormContextProps {
  getContext: <
    T1 extends FieldValues,
    T2 extends FieldValues
  >() => SubFormContextProps<T1, T2>;
}

// Crie o contexto com um valor padrão
const SubFormContext = createContext<GetSubFormContextProps | undefined>(
  undefined
);

// Crie o Provider
export const SubFormProvider = <
  T1 extends FieldValues,
  T2 extends FieldValues
>({
  children,
  ...props
}: PropsWithChildren<SubFormContextProps<T1, T2>>) => {
  const valor = {
    getContext: () => props as any,
  } as GetSubFormContextProps;
  return (
    <SubFormContext.Provider value={valor}>{children}</SubFormContext.Provider>
  );
};

export const useSubFormContext = <
  T1 extends FieldValues,
  T2 extends FieldValues
>() => {
  const context = useContext(SubFormContext);
  if (context === undefined) {
    throw new Error("useSubFormContext must be used within a SubFormProvider");
  }
  return context.getContext<T1, T2>();
};

export const useSubFormContextGetName = <T1 extends FieldValues>(
  nome: FieldPath<T1>
) => {
  const context = useContext(SubFormContext);
  if (context === undefined) {
    return nome;
  }
  return context.getContext().obterNomeDaPropriedade(nome);
};
