import { Button } from "devextreme-react";

interface BotaoGerarEcfMxpProps {
  handleClick: () => void;
}

export default function BotaoGerarEcfMxp(props: BotaoGerarEcfMxpProps) {
  return (
    <Button
      type="success"
      text={"Gerar ECF"}
      icon={"ic-material-symbols-outlined ic-file-save icone-linha-grid"}
      onClick={props.handleClick}
    />
  );
}
