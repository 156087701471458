// SL-55496_reabertura-SL-51754-OP-MXP2-Grid-e-acao-de-retirada-de-materiais

import {
  DeepPartial,
  FieldError,
  FieldPath,
  FieldPathValue,
  FieldPathValues,
  FieldValues,
  FormState,
  Path,
  RegisterOptions,
  SetValueConfig,
  UseFormRegisterReturn,
  UseFormResetField,
  UseFormReturn,
  UseFormSetError,
  UseFormSetFocus,
  UseFormTrigger,
  UseFormUnregister,
} from "react-hook-form";

export type UseFormSubRegister<
  TSubForm extends FieldValues,
  TFieldValues extends FieldValues
> = <
  TSubFormFieldName extends FieldPath<TSubForm> = FieldPath<TSubForm>,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  name: TSubFormFieldName,
  options?: RegisterOptions<TFieldValues, TFieldName>
) => UseFormRegisterReturn<TFieldName>;

export type UseFormSubSetValue<
  TSubForm extends FieldValues,
  TFieldValues extends FieldValues
> = <
  TSubFormFieldName extends FieldPath<TSubForm> = FieldPath<TSubForm>,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  name: TSubFormFieldName,
  value: FieldPathValue<TFieldValues, TFieldName>,
  options?: SetValueConfig
) => void;

export type UseFormSubGetFieldState<
  TSubForm extends FieldValues,
  TFieldValues extends FieldValues
> = <TSubFormFieldName extends FieldPath<TSubForm> = FieldPath<TSubForm>>(
  name: TSubFormFieldName,
  formState?: FormState<TFieldValues>
) => {
  invalid: boolean;
  isDirty: boolean;
  isTouched: boolean;
  isValidating: boolean;
  error?: FieldError;
};
export type UseFormSubWatch<
  TSubForm extends FieldValues,
  TFieldValues extends FieldValues
> = {
  (): TFieldValues;

  <
    TSubFormFieldName extends readonly FieldPath<TSubForm>[],
    TFieldNames extends readonly FieldPath<TFieldValues>[]
  >(
    names: readonly [...TSubFormFieldName],
    defaultValue?: DeepPartial<TFieldValues>
  ): FieldPathValues<TFieldValues, TFieldNames>;

  <
    TSubFormFieldName extends FieldPath<TSubForm>,
    TFieldName extends FieldPath<TFieldValues>
  >(
    name: TSubFormFieldName,
    defaultValue?: FieldPathValue<TFieldValues, TFieldName>
  ): FieldPathValue<TFieldValues, TFieldName>;
};

export type UseFormSubGetValues<
  TSubForm extends FieldValues,
  TFieldValues extends FieldValues
> = {
  <
    TSubFormFieldName extends FieldPath<TSubForm>,
    TFieldName extends FieldPath<TFieldValues>
  >(
    name: TSubFormFieldName
  ): FieldPathValue<TFieldValues, TFieldName>;

  <
    TSubFormFieldName extends readonly FieldPath<TSubForm>[],
    TFieldNames extends readonly FieldPath<TFieldValues>[]
  >(
    names: readonly [...TSubFormFieldName]
  ): [...FieldPathValues<TFieldValues, TFieldNames>];
};

export const useFormSubContext = <
  TSubForm extends FieldValues,
  TFieldValues extends FieldValues = FieldValues,
  TContext = any
>(
  contextPath: Path<TFieldValues>,
  hookForms: UseFormReturn<TFieldValues, TContext>
) => {
  const {
    register,
    watch,
    setValue,
    getValues,
    getFieldState,
    setError,
    trigger,
    resetField,
    unregister,
    setFocus,
    ...methods
  } = hookForms;

  const converterNomes = (
    names: Path<TSubForm>[] | Path<TSubForm> | readonly Path<TSubForm>[]
  ) => {
    if (Array.isArray(names)) {
      return names.map((name) => `${contextPath}.${name.toString()}` as any);
    } else {
      return `${contextPath}.${names.toString()}` as any;
    }
  };

  const subwatch: UseFormSubWatch<TSubForm, TFieldValues> = ((...args: any) => {
    if (args.length === 0) {
      return watch(contextPath);
    } else if (typeof args[0] === "function") {
      return watch(args[0], args[1]);
    } else {
      const name = converterNomes(args[0]);
      const defaultValue = args[1];
      // Retorna o valor de um campo específico
      return watch(name, defaultValue);
    }
  }) as UseFormSubWatch<TSubForm, TFieldValues>;

  const subgetValues: UseFormSubGetValues<TSubForm, TFieldValues> = ((
    names: any
  ) => {
    const name = converterNomes(names);
    // Retorna o valor de um campo específico
    return getValues(name);
  }) as UseFormSubGetValues<TSubForm, TFieldValues>;

  const subgetFieldState: UseFormSubGetFieldState<TSubForm, TFieldValues> = ((
    name,
    formState
  ) => {
    const name1 = converterNomes(name);
    // Retorna o valor de um campo específico
    return getFieldState(name1, formState);
  }) as UseFormSubGetFieldState<TSubForm, TFieldValues>;
  const subsetError: UseFormSetError<TSubForm> = ((name, error, options) => {
    const name1 =
      name.startsWith("root.") || name === "root"
        ? name
        : converterNomes(name as Path<TSubForm>);
    // Retorna o valor de um campo específico
    return setError(name1, error, options);
  }) as UseFormSetError<TSubForm>;

  const subsetValue: UseFormSubSetValue<TSubForm, TFieldValues> = ((
    name,
    value,
    options
  ) => {
    const name1 = converterNomes(name);
    // Retorna o valor de um campo específico
    return setValue(name1, value, options);
  }) as UseFormSubSetValue<TSubForm, TFieldValues>;

  const subtrigger: UseFormTrigger<TSubForm> = ((name, options) => {
    const name1 = name == undefined ? undefined : converterNomes(name);
    // Retorna o valor de um campo específico
    return trigger(name1, options);
  }) as UseFormTrigger<TSubForm>;

  const subresetField: UseFormResetField<TSubForm> = ((name, options) => {
    const name1 = converterNomes(name);
    // Retorna o valor de um campo específico
    return resetField(name1, options);
  }) as UseFormResetField<TSubForm>;

  const subunregister: UseFormUnregister<TSubForm> = ((name, options) => {
    const name1 = name == undefined ? undefined : converterNomes(name);
    // Retorna o valor de um campo específico
    return unregister(name1, options);
  }) as UseFormUnregister<TSubForm>;

  const subregister: UseFormSubRegister<TSubForm, TFieldValues> = ((
    name,
    options
  ) => {
    const name1 = name == undefined ? undefined : converterNomes(name);
    // Retorna o valor de um campo específico
    return register(name1, options);
  }) as UseFormSubRegister<TSubForm, TFieldValues>;

  const subsetFocus: UseFormSetFocus<TSubForm> = ((name, options) => {
    const name1 = name == undefined ? undefined : converterNomes(name);
    // Retorna o valor de um campo específico
    return setFocus(name1, options);
  }) as UseFormSetFocus<TSubForm>;

  return {
    ...methods,
    register: subregister,
    watch: subwatch,
    getValues: subgetValues,
    getFieldState: subgetFieldState,
    setError: subsetError,
    setValue: subsetValue,
    trigger: subtrigger,
    resetField: subresetField,
    unregister: subunregister,
    setFocus: subsetFocus,
  };
};
