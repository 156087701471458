import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import RedirecionadorLogin from "../../components/navegacao/login-redirect";
import Autologin from "../../features/seguranca/login/componentes/autologin";
import LogoutRedirect from "../../features/seguranca/login/componentes/logout";
import RecuperacaoDeSenhaViaEmailPage from "../../features/seguranca/login/componentes/recuperacao-senha-via-email";
import SingleSignOn from "../../features/seguranca/login/componentes/SingleSignOn";
import LoginPage from "../../features/seguranca/login/paginas/principal";
import DefineTituloDaAba from "../../utils/header/define-titulo-da-aba";

export default function RotasAnonimo() {
  DefineTituloDaAba();
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/oauth" element={<LoginPage oAuth />} />
        <Route path="/logout" element={<LogoutRedirect />} />
        <Route path="/singlesignon" element={<SingleSignOn />} />
        <Route path="/autologin" element={<Autologin />} />
        <Route
          path="/RecuperarSenha"
          element={<RecuperacaoDeSenhaViaEmailPage />}
        />
        <Route path="*" element={<RedirecionadorLogin />} />
      </Routes>
    </Router>
  );
}
