export const letrasDigitosSimbolosSemEspacos = /^[a-zA-ZÇ0-9_\-.$@/\\]+$/;

export const letrasApenasMaiusculasDigitosSimbolosSemEspacos =
  /^[A-ZÇ0-9_\-.$@/\\]+$/;

export const digitosNumericosSemEspaco = /^[0-9]+$/;

export const regexAlfanumerico = /^[a-zA-Z0-9]*$/;

export const letrasDigitosSimbolosSemEspacosComColchetes =
  /^[a-zA-ZÇ0-9_\-.[\]$@/\\]+$/;

export const letrasDigitosSimbolosSemEspacosComColchetesPermiteStringVazia =
  /^[a-zA-ZÇ0-9_\-.[\]$@/\\]*$/;
