import criarNameof from "../../../../../utils/common/cria-name-of";
import { EstadoConfiguravelDaOrdemDeProducaoGridModel } from "../../models/estado-configuravel-da-ordem-de-producao";

export default class FiltrosGridEstadoConfiguravelDaOrdemDeProducao {
  static readonly apenasAtivos: any[] = [
    criarNameof<EstadoConfiguravelDaOrdemDeProducaoGridModel>()("ativo"),
    "=",
    true,
  ];
}
