import React, { useCallback, useEffect, useState } from "react";
import { IFormularioEditavelComRegistro } from "../../../../../models/shared/ui/formularios";
import { RenderOnDemandPromisse } from "../../../../../parts/utils/load-on-demand";

interface FormCarregarRegistroProps<T> {
  idRegistroEmEdicao: number;
  getRegistroEmEdicao: () => Promise<T>;
  children: React.ReactNode;
}
export function FormCarregarRegistro<T>(props: FormCarregarRegistroProps<T>) {
  const [registroEmEdicao, setRegistroEmEdicao] = useState<T | undefined>(
    undefined
  );
  const [promessa, setPromessa] = useState<Promise<void> | undefined>(
    undefined
  );

  const carregarRegistro = useCallback(async () => {
    const model = await props.getRegistroEmEdicao();
    setRegistroEmEdicao(model);
  }, [props.getRegistroEmEdicao]);

  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      setRegistroEmEdicao(undefined);
      setPromessa(undefined);
      return;
    }

    if (props.idRegistroEmEdicao <= 0) {
      setRegistroEmEdicao(undefined);
      setPromessa(Promise.resolve());
      return;
    }

    const novaPromessa = carregarRegistro();
    setPromessa(novaPromessa);
  }, [props.idRegistroEmEdicao, props.getRegistroEmEdicao]);

  return (
    <RenderOnDemandPromisse promessa={promessa}>
      {React.Children.map(props.children, (child) => {
        if (React.isValidElement<IFormularioEditavelComRegistro<T>>(child)) {
          return React.createElement(child.type, {
            ...child.props, // Mantém as props originais
            registroEmEdicao,
            atualizarRegistro: carregarRegistro,
          });
        }
        return null;
      })}
    </RenderOnDemandPromisse>
  );
}
