import criarNameof from "../../../../../utils/common/cria-name-of";
import { EstacaoDeTrabalhoGridModel } from "../../models/estacao-de-trabalho";

const nameof = criarNameof<EstacaoDeTrabalhoGridModel>();

export default class FiltrosGridEstacaoDeTrabalho {
  static readonly estacaoDeTrabalhoAtivo: any[] = [
    [nameof("ativo"), "=", true],
  ];
}
