import { Popover } from "devextreme-react";
import { PositionConfig } from "devextreme/animation/position";
import { PositionAlignment } from "devextreme/ui/popup";

interface FormPopoverProps {
  tabIndex?: number;
  alvo: string;
  posicao?: PositionAlignment | PositionConfig;
  largura?: number | string | (() => number | string);
  sombreamento?: boolean;
  hover?: boolean;
  texto: string;
}

export default function FormPopover(props: FormPopoverProps) {
  return (
    <Popover
      tabIndex={props.tabIndex}
      target={props.alvo}
      position={"top"}
      width={props.largura}
      showEvent={props.hover ? "dxhoverstart" : "click"}
      hideEvent={props.hover ? "dxhoverend" : undefined}
      shading={props.sombreamento ?? true}
      shadingColor="rgba(0, 0, 0, 0.5)"
      showCloseButton={true}
      maxWidth={props.largura ?? 500}
    >
      {props.texto}
    </Popover>
  );
}
