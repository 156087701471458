import AuditavelGridModel from "../../../../models/api/comum/auditavel-grid";

export interface ValorOpcaoGridModel extends AuditavelGridModel {
  id: number;
  codigoValorOpcao: string;
  descricaoValorOpcao: string;
  codigoConjuntoOpcao: string;
  descricaoConjuntoOpcao: string;
  codigoOutroValor: string;
  descricaoOutroValor: string;
}

export enum TipoValorOpcao {
  AssuntoAnotacao = 1,
  AcaoAnotacao = 2,
}
