import { UseFormReturn } from "react-hook-form";
import { FormNumberBox } from "../../../../../../components/formularios";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import TabContainer from "../../../../../../components/layout/tab-container";
import {
  OperacaoDaOrdemDeProducaoRequestDTO,
  tamanhoMaximo,
  tamanhoMinimo,
} from "../../../models/operacao-de-ordem-de-producao";

interface OperacaoDaOrdemDeProducaoAbaCapacidadeFinitaProps {
  hookForms: UseFormReturn<OperacaoDaOrdemDeProducaoRequestDTO>;
  isSomenteLeitura: boolean;
}

export default function OperacaoDaOrdemDeProducaoAbaCapacidadeFinita(
  props: OperacaoDaOrdemDeProducaoAbaCapacidadeFinitaProps
) {
  const { control } = props.hookForms;
  return (
    <TabContainer>
      <Linha>
        <Coluna md={4}>
          <FormNumberBox
            name="estacaoDeTrabalhoOrdemEmCapacidadeFinita"
            titulo="Ordem na estação de trabalho"
            control={control}
            minimo={tamanhoMinimo.toNumber()}
            maximo={tamanhoMaximo.toNumber()}
            somenteLeitura={!props.isSomenteLeitura}
            quantidadeIncrementarDecrementar={1}
          />
        </Coluna>
      </Linha>
    </TabContainer>
  );
}
