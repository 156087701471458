import { ContextMenu } from "devextreme-react";
import { ReactNode, useState } from "react";
import { v4 } from "uuid";
import {
  ItemContextMenuMxp,
  ItemContextMenuNativo,
  eventoTooltipContextMenu,
} from "../../../utils/context-menu/context-menu-utils";
import { Container, LinkButton } from "./styles";

interface CelulaControleEdicaoProps<T> {
  editar?: (dados: any) => void;
  excluir?: (dados: any) => void;
  gerarBotoesAdicionais?: (dados: any) => ReactNode[];
  itens?: ItemContextMenuMxp[];
  dados: T;
}

function aplicarCssCorIcones(itens: ItemContextMenuMxp[]) {
  for (const item of itens) {
    item.icon += " icone-linha-grid";
    item.name = item.text;

    if (item.disabledIcon) {
      item.icon += " ic-material-disabled";
    }

    if (item.items) {
      item.items = aplicarCssCorIcones(item.items);
    }
  }

  return itens;
}

export default function CelulaControleEdicaoMxp<T>({
  editar,
  excluir,
  gerarBotoesAdicionais,
  itens,
  dados,
}: CelulaControleEdicaoProps<T>) {
  const [contextMenuVisivel, setContextMenuVisivel] = useState(false);
  const linkId = v4();

  const itensTratados = aplicarCssCorIcones(itens ?? []);

  const itensColunaAcoes = itensTratados
    ?.filter((x) => x.exibirNaLinhaDaGrid == "sempre")
    .map(convertItemContextMenu);

  const itensMenuDeContexto = itensTratados
    ?.filter((x) => x.exibirNaLinhaDaGrid == "menuDeContexto")
    .map(convertItemContextMenu);

  function convertItemContextMenu(x: ItemContextMenuMxp) {
    const item: ItemContextMenuNativo = {
      ...x,
      onClick: x.gestorEventoClick?.eventoParaMenuContextoBotao(dados),
      items: x.items?.map(convertItemContextMenu),
    };

    return item;
  }

  return (
    <Container>
      {editar && (
        <LinkButton
          onClick={() => editar(dados)}
          title="Editar"
          className="btn-editar-linha"
        >
          <i className="ic-material-symbols-outlined ic-edit icone-linha-grid"></i>
        </LinkButton>
      )}
      {excluir && (
        <LinkButton
          onClick={() => excluir(dados)}
          title="Excluir"
          className="btn-excluir-linha"
        >
          <i className="ic-material-symbols-outlined ic-delete icone-linha-grid"></i>
        </LinkButton>
      )}
      {gerarBotoesAdicionais && gerarBotoesAdicionais(dados)}
      {itensColunaAcoes?.map((element, index) => {
        if (element.visivel && !element.visivel(dados)) {
          return;
        }

        return (
          <LinkButton
            key={`${index}-${element.text}`}
            onClick={element.onClick}
            title={element.hint}
          >
            <i className={element.icon}></i>
          </LinkButton>
        );
      })}

      {itensMenuDeContexto?.length != 0 && (
        <>
          <LinkButton
            id={`opc-row-${linkId}`}
            onClick={() => setContextMenuVisivel(!contextMenuVisivel)}
            title="Mais opções"
          >
            <i className="ic-material-symbols-outlined ic-vertical icone-linha-grid"></i>
          </LinkButton>
          <ContextMenu
            items={itensMenuDeContexto}
            target={`#opc-row-${linkId}`}
            visible={contextMenuVisivel}
            onHidden={() => setContextMenuVisivel(false)}
            onItemRendered={eventoTooltipContextMenu}
          />
        </>
      )}
    </Container>
  );
}
