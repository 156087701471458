/**
 * Descreve um erro genérico retornado pela API.
 */
export interface Erro<TCodigo extends string = string, TDadosExtras = any> {
  /**
   * O código do erro gerado pela API.
   */
  codigoErro: TCodigo;

  /**
   * O tipo de erro gerado.
   */
  tipoErro: TipoErro;

  /**
   * A mensagem de erro gerada pela API (**atenção:** mensagem gerada para consumidores da API, pode não ser apropriada para ser exibida em tela).
   */
  mensagem: string;

  /**
   * Link para página de ajuda sobre o erro, se houver.
   */
  uriLinkAjuda?: string;

  /**
   * Dados extras para ajudar a formatar e/ou exibir a mensagem de erro em tela.
   */
  dadosExtras: TDadosExtras;
}

/**
 * Os tipos de erro retornados pela API.
 */
export enum TipoErro {
  /**
   * Um erro causado por dados imputados pelo usuário.
   */
  ErroRequest = "ErroRequest",

  /**
   * Algo que o usuário solicitou não foi encontrado.
   */
  NaoEncontrado = "NaoEncontrado",

  /**
   * Indica que não houveram erros no request porém a operação não pode ser realizada por algum motivo.
   */
  OperacaoNaoRealizavel = "OperacaoNaoRealizavel",

  /**
   * Alguma condição necessária para realizar a operação não foi atingida.
   */
  CondicaoNaoAtendida = "CondicaoNaoAtendida",

  /**
   * Um erro causado por instabilidades de outros sistemas ou outras coisas não relacionadas ao que foi imputado pelo usuário.
   */
  ErroServidor = "ErroServidor",
}
