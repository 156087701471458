import { Popover } from "devextreme-react";
import { one } from "devextreme/events";
import parseToHtml from "html-react-parser";
import { useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { v4 } from "uuid";
import ContextoAjuda from "../contexto-ajuda";
import { Container } from "./styles";

interface LinkAjudaProps {
  children: React.ReactNode;
  keyAjuda: string;
  modoExibicao?: "mouseenter" | "mousehover" | "click";
}

export default function LinkAjuda({
  children,
  keyAjuda,
  modoExibicao,
}: LinkAjudaProps) {
  const [idElemento] = useState(`icone-ajuda-${v4()}`);

  const [eventoShow, eventoHide] = useMemo(() => {
    switch (modoExibicao) {
      case "mouseenter":
        return ["mouseenter", "mouseleave"];
      case "click" || isMobile:
        return ["click", undefined];
      case "mousehover":
      default:
        return ["dxhoverstart", "dxhoverend"];
    }
  }, [modoExibicao]);

  const onHiding = (e: any) => {
    e.cancel = new Promise((cancel) => {
      setTimeout(() => {
        const popupContainer = e.component.content().parentElement;
        const isHover = popupContainer.parentNode.matches(":hover");
        if (isHover) {
          one(popupContainer, "mouseleave", () => {
            cancel(false);
          });
        } else {
          cancel(false);
        }
      }, 150);
    });
  };

  return (
    <ContextoAjuda.Consumer>
      {(provedor) => {
        if (provedor != undefined) {
          const htmlAjuda = provedor?.obterTexto(keyAjuda);

          if (!htmlAjuda) {
            return <></>;
          }

          return (
            <Container className="icone-tooltip">
              <span id={idElemento}>{children}</span>
              <Popover
                target={`#${idElemento}`}
                showEvent={eventoShow}
                hideEvent={eventoHide}
                onHiding={onHiding}
                maxWidth={300}
              >
                {parseToHtml(htmlAjuda)}
              </Popover>
            </Container>
          );
        } else {
          return <></>;
        }
      }}
    </ContextoAjuda.Consumer>
  );
}
