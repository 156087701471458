import styled from "styled-components";

export const HeaderCardLogin = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
  flex-wrap: wrap;

  @media (max-width: 498px) {
    justify-content: center;
  }
`;

export const ContainerCardLogin = styled.div`
  padding: 55px;
  border-radius: 20px;
`;

export const ContainerRodape = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 40px;
`;

export const ContainerBotao = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
