import { Menu } from "devextreme-react";
import { ToolbarItem } from "devextreme-react/cjs/autocomplete";
import { useContext, useMemo } from "react";
import { convertItemContextMenu } from "../../../utils/grid/grid-builder";
import ContextoMenuEdicaoOrdemDeProducao from "../contexto-menu-op";

const MenuEdicaoOrdemDeProducaoComContexto = () => {
  const { menu } = useContext(ContextoMenuEdicaoOrdemDeProducao);
  const itens = useMemo(() => {
    return menu.itens.map((x) => convertItemContextMenu(x));
  }, [menu.itens]);

  return (
    <Menu
      className="dx-menu"
      items={itens}
      displayExpr="name"
      showFirstSubmenuMode="onHover"
      showSubmenuMode="onHover"
      orientation="horizontal"
      hideSubmenuOnMouseLeave={false}
    ></Menu>
  );
};

export const MenuEdicaoOrdemDeProducao = () => {
  return [
    <ToolbarItem
      key={"menu-edicao-ordem-de-producao-separador"}
      toolbar="top"
      location="before"
      visible={true}
    >
      |
    </ToolbarItem>,
    <ToolbarItem
      key={"menu-edicao-ordem-de-producao-menu"}
      cssClass="dx-menu"
      toolbar="top"
      location="before"
      visible={true}
    >
      <MenuEdicaoOrdemDeProducaoComContexto />
    </ToolbarItem>,
  ];
};

export const NomesMenuEdicaoOrdemDeProducao = {
  insumos: {
    name: "Insumos",
    estornarSelecionados: "Estornar insumos selecionados",
  },
};
