import getApi from "../../configs/api";
import { ResponseModel } from "../../models/api/comum/response-base";
import { BuildInformacoes } from "../../models/api/versao/versao";
import { NomesEndpoints } from "../comum/nomesEndpoints";

export const nomeEndpointVersao = NomesEndpoints.Versao;

async function obterVersao() {
  const api = getApi();

  const response = await api.get<ResponseModel<BuildInformacoes>>(
    `${nomeEndpointVersao}`
  );

  return response.data;
}

export default {
  obterVersao,
};
