import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import { useMemo, useRef } from "react";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useSeletorGridMxp } from "../../../../../hooks/seletor.hooks";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import criarNameof from "../../../../../utils/common/cria-name-of";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import { ContatoGridModel } from "../../models/contato";
import ContatoServico from "../../servicos/contato.servico";

const service = new ContatoServico();

const nameOfGridHandler = criarNameof<ContatoGridModel>();

export default function GridContato(props: GridMxpProps<ContatoGridModel>) {
  const gridRef = useRef<DataGridRef>(null);

  useSeletorGridMxp(() => gridRef.current, props.filtrosNoCliente);

  const dataSource = service.ObterDataSourceParaGrid<ContatoGridModel>(
    props.filtrosNoServidor
  );

  const gridController = new GridController<ContatoGridModel>(() =>
    gridRef.current?.instance()
  );

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  useRegistrarAtalhosGrid<ContatoGridModel>({
    controller: gridController,
  });

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "contato",
      () => gridRef.current?.instance(),
      false,
      props.filtrosNoCliente
    )
      .definirStyles(props.style)
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .build();
  }, [props.filtrosNoCliente, props.style, dataSource]);

  return (
    <>
      <DataGrid ref={gridRef} {...configuracoesGrid}>
        <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
        {GridColunaAcoes<ContatoGridModel>({})}
        <Column
          key={nameOfGridHandler("nome")}
          dataField={nameOfGridHandler("nome")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Contato"
        />
        <Column
          key={nameOfGridHandler("apelido")}
          dataField={nameOfGridHandler("apelido")}
          {...obterConfiguracaoColuna("stringG")}
          caption="Empresa"
        />
        <Column
          key={nameOfGridHandler("descricaoMunicipioEmpresa")}
          dataField={nameOfGridHandler("descricaoMunicipioEmpresa")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Município"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("descricaoUfEmpresa")}
          dataField={nameOfGridHandler("descricaoUfEmpresa")}
          {...obterConfiguracaoColuna("stringM")}
          caption="UF"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("cargo")}
          dataField={nameOfGridHandler("cargo")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Cargo"
        />
        <Column
          key={nameOfGridHandler("email")}
          dataField={nameOfGridHandler("email")}
          {...obterConfiguracaoColuna("stringG")}
          caption="E-mail"
        />
        <Column
          key={nameOfGridHandler("telefone")}
          dataField={nameOfGridHandler("telefone")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Telefone 1"
        />
        <Column
          key={nameOfGridHandler("telefoneComercial")}
          dataField={nameOfGridHandler("telefoneComercial")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Telefone 2"
        />
        <Column
          key={nameOfGridHandler("telefone3")}
          dataField={nameOfGridHandler("telefone3")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Telefone 3"
        />
        <Column
          key={nameOfGridHandler("regiaoContato")}
          dataField={nameOfGridHandler("regiaoContato")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Regiões de clientes"
        />
        <Column
          key={nameOfGridHandler("perfilContato")}
          dataField={nameOfGridHandler("perfilContato")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Perfil de clientes"
        />
        {GetColunasDeAuditoria(true, false, [
          { ordenarPor: "criacaoData", ordem: "desc" },
        ])}
      </DataGrid>
    </>
  );
}
