import { Column } from "devextreme-react/data-grid";
import { forwardRef, useCallback, useRef, useState } from "react";
import PdfViewer from "../../../../components/arquivo/pdf-viewer";
import { MxpGrid } from "../../../../components/grid";
import { obterTituloTela } from "../../../../components/layout/form-base2";
import { Modal } from "../../../../components/layout/modal";
import CelulaSituacaoMdfe from "../../../../components/templates-celulas-grid/celula-situacao-mdfe";
import {
  useEditarRegistroGrid,
  useExcluirRegistroComPermissaoGrid,
  useGerarItensAdicionais,
  useNovoRegistroGrid,
  usePropagarReferenciaGrid,
} from "../../../../hooks/grid.hooks";
import { useAppDispatch } from "../../../../hooks/store.hooks";
import { MDFeGridModel } from "../../../../models/api/mdfe/mdfe";
import {
  AmbienteMDFe,
  SituacaoMDFe,
  situacaoMDFeDecodificada,
} from "../../../../models/api/mdfe/mdfe-enums";
import { PermissoesMDF } from "../../../../models/permissoes/fiscal/mdfe/permissoes-mdf";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import { IGridSelecao } from "../../../../models/shared/ui/formularios";
import EditFormMDFe from "../../../../pages/vendas/mdfe/edit-form";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../services/comum/serviceBase";
import API from "../../../../services/mdfe/mdfe.service";
import { bloquearUI, desbloquearUI } from "../../../../store/ui/ui.slice";
import {
  checarResponse,
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../utils/api/api-utils";
import criarNameof from "../../../../utils/common/cria-name-of";
import NomesModais from "../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../utils/common/nomes-telas";
import exibirNotificaoToast, {
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";
import {
  baixarXmlMdfe,
  toastSituacaoInvalida,
  verificaSituacaoMDFeParaImpressaoDamdfeBaixarXml,
  visualizarImpressaoPdfDamdfe,
} from "../../../../utils/especifico/mdfe/mdfe-utils";
import obterConfiguracaoColuna from "../../../../utils/grid/padroes-colunas";
import GetColunasDeAuditoria from "../../../layout/grid-defaults/colunasDeAuditoria";

const nomeEndpoint = NomesEndpoints.MDFe;
const dataSource = APIBase.getGridSource(nomeEndpoint);
const nameOfGridHandler = criarNameof<MDFeGridModel>();

const colunas = [
  <Column
    key={nameOfGridHandler("dataEmissao")}
    dataField={nameOfGridHandler("dataEmissao")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    sortIndex={0}
    sortOrder="asc"
    caption="Emissão"
  />,
  <Column
    key={nameOfGridHandler("numeroMdfe")}
    dataField={nameOfGridHandler("numeroMdfe")}
    {...obterConfiguracaoColuna("codigo")}
    caption="Nº"
  />,
  <Column
    key={nameOfGridHandler("serieFiscal")}
    dataField={nameOfGridHandler("serieFiscal")}
    {...obterConfiguracaoColuna("serie")}
  />,
  <Column
    key={nameOfGridHandler("contratantes")}
    dataField={nameOfGridHandler("contratantes")}
    {...obterConfiguracaoColuna("razaoSocial")}
    caption="Contratante"
  />,
  <Column
    key={nameOfGridHandler("contratantesCnpj")}
    dataField={nameOfGridHandler("contratantesCnpj")}
    {...obterConfiguracaoColuna("cpfCnpj")}
    caption="CNPJ/CPF do contratante"
  />,
  <Column
    key={nameOfGridHandler("chaveDeAcessoMdfe")}
    dataField={nameOfGridHandler("chaveDeAcessoMdfe")}
    {...obterConfiguracaoColuna("chaveAcesso")}
    caption="Chave de acesso"
  />,
  <Column
    key={nameOfGridHandler("situacaoDescricao")}
    dataField={nameOfGridHandler("situacaoDescricao")}
    {...obterConfiguracaoColuna("stringP")}
    caption="Situação"
    cellRender={CelulaSituacaoMdfe}
  />,
  <Column
    key={nameOfGridHandler("siglaUfOrigem")}
    dataField={nameOfGridHandler("siglaUfOrigem")}
    {...obterConfiguracaoColuna("uf")}
    caption="UF de origem"
  />,
  <Column
    key={nameOfGridHandler("siglaUfDestino")}
    dataField={nameOfGridHandler("siglaUfDestino")}
    {...obterConfiguracaoColuna("uf")}
    caption="UF de destino"
  />,
  <Column
    key={nameOfGridHandler("placasVeiculosPrincipais")}
    dataField={nameOfGridHandler("placasVeiculosPrincipais")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Placa da tração"
    width={140}
  />,
  <Column
    key={nameOfGridHandler("placaReboque1")}
    dataField={nameOfGridHandler("placaReboque1")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Placa do reboque 1"
    visible={false}
    width={160}
  />,
  <Column
    key={nameOfGridHandler("placaReboque2")}
    dataField={nameOfGridHandler("placaReboque2")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Placa do reboque 2"
    visible={false}
    width={160}
  />,
  <Column
    key={nameOfGridHandler("placaReboque3")}
    dataField={nameOfGridHandler("placaReboque3")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Placa do reboque 3"
    visible={false}
    width={160}
  />,
  <Column
    key={nameOfGridHandler("nomesCondutores")}
    dataField={nameOfGridHandler("nomesCondutores")}
    caption="Condutores"
    {...obterConfiguracaoColuna("stringG")}
    width={180}
  />,
  ...GetColunasDeAuditoria(),
];

export const GridMDFe = forwardRef((props, ref) => {
  const gridRef = useRef<IGridSelecao>(null);
  const [modalPdfViewerVisivel, setModalPdfViewerVisivel] = useState(false);
  const [documentoPdfDamdfe, setDocumentoPdfDamdfe] = useState("");
  const [modalEdicaoVisivel, setModalEdicaoVisivel] = useState(false);
  const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);

  const dispatch = useAppDispatch();

  const handleModalCallback = useCallback((info: CallBackModal) => {
    setModalEdicaoVisivel(false);
    setIdRegistroEdicao(NaN);

    if (info.precisaAtualizar) {
      handleAtualizarGrid();
    }
  }, []);

  usePropagarReferenciaGrid(ref, gridRef);

  function handleAtualizarGrid() {
    if (gridRef.current?.atualizarGrid) {
      gridRef.current?.atualizarGrid();
    }
  }

  const handleNovoRegistro = useNovoRegistroGrid(() => {
    setIdRegistroEdicao(0);
    setModalEdicaoVisivel(true);
  });

  const handleEditarRegistro = useEditarRegistroGrid((id: number) => {
    setIdRegistroEdicao(id);
    setModalEdicaoVisivel(true);
  });

  const handleExcluirRegistro = useExcluirRegistroComPermissaoGrid(
    obterMensagemExclusao,
    async (registro?: MDFeGridModel) => {
      if (registro?.id && registro?.dataEmissao) {
        if (
          registro?.situacao != SituacaoMDFe.EmEdicao &&
          registro?.ambiente === AmbienteMDFe.Producao
        ) {
          exibirNotificaoToast({
            mensagem: `Não é possível excluir MDF-e no estado diferente de "${
              situacaoMDFeDecodificada[SituacaoMDFe.EmEdicao]
            }}".`,
            tipo: TipoNotificacao.Erro,
          });
          return;
        }

        const resposta = await APIBase.excluir(registro.id, nomeEndpoint);

        if (resposta) {
          checarResponseExibeMensagemExclusaoDeSucesso(resposta);
          handleAtualizarGrid();
        }
      }
    },
    PermissoesMDF.Excluir
  );

  function obterMensagemExclusao(registro: MDFeGridModel) {
    const dataLocalizada = new Date(registro.dataEmissao).toLocaleString();
    const mensagemNumeroMdfe = registro.numeroMdfe
      ? `Nº ${registro.numeroMdfe}`
      : "sem N°";
    return `Tem certeza de que deseja excluir o MDF-e ${mensagemNumeroMdfe} de ${dataLocalizada}?`;
  }

  function toastElementoNaoEncontrado() {
    exibirNotificaoToast({
      mensagem: "Elemento não encontrado.",
      tipo: TipoNotificacao.Erro,
    });
  }

  async function downloadXmlMdfe(id?: number, situacao?: SituacaoMDFe) {
    if (id && situacao) {
      if (!verificaSituacaoMDFeParaImpressaoDamdfeBaixarXml(situacao)) {
        toastSituacaoInvalida();
        return;
      }

      await baixarXmlMdfe(id, situacao);
    } else {
      toastElementoNaoEncontrado();
    }
  }

  async function imprimirDamdfeMdfe(id?: number, situacao?: SituacaoMDFe) {
    if (id && situacao) {
      if (!verificaSituacaoMDFeParaImpressaoDamdfeBaixarXml(situacao)) {
        toastSituacaoInvalida();
        return;
      }

      await visualizarImpressaoPdfDamdfe(
        id,
        situacao,
        setModalPdfViewerVisivel,
        setDocumentoPdfDamdfe
      );
    } else {
      toastElementoNaoEncontrado();
    }
  }

  async function consultarSituacao(id?: number) {
    if (id) {
      try {
        dispatch(bloquearUI());
        const resposta = await API.consultarSituacao(id);
        checarResponse(resposta);

        if (resposta.sucesso) {
          handleAtualizarGrid();

          exibirNotificaoToast({
            mensagem: resposta.mensagem,
            tipo: TipoNotificacao.Sucesso,
          });
        }
      } catch (erro) {
        tratarErroApi(erro);
      } finally {
        dispatch(desbloquearUI());
      }
    }
  }

  async function duplicar(id?: number) {
    if (id) {
      try {
        dispatch(bloquearUI());
        const resposta = await API.duplicar(id);
        checarResponse(resposta);

        if (resposta.sucesso) {
          handleAtualizarGrid();

          exibirNotificaoToast({
            mensagem: resposta.mensagem,
            tipo: TipoNotificacao.Sucesso,
          });
        }
      } catch (erro) {
        tratarErroApi(erro);
      } finally {
        dispatch(desbloquearUI());
      }
    }
  }

  const handlePdfViewerModalCallBack = useCallback(() => {
    setModalPdfViewerVisivel(false);
    setDocumentoPdfDamdfe("");
  }, []);

  const handleGerarItensAdicionais = useGerarItensAdicionais(
    (
      getData: () => MDFeGridModel | undefined,
      data?: MDFeGridModel | undefined
    ) => {
      const itensAdicionais = [
        {
          text: "Consultar situação",
          icon: "ic-material-symbols-outlined ic-refresh",
          mostraNaColunaDeAcoes: false,
          onClick: () => {
            consultarSituacao(getData()?.id);
          },
          hint: "Consultar situação",
        },
        {
          text: "Visualizar PDF",
          icon: "ic-material-symbols-outlined ic-print",
          onClick: () => {
            const data = getData();
            imprimirDamdfeMdfe(data?.id, data?.situacao);
          },
          mostraNaColunaDeAcoes: true,
          disabledIcon: !verificaSituacaoMDFeParaImpressaoDamdfeBaixarXml(
            data?.situacao
          ),
          hint: "Visualizar PDF",
        },
        {
          text: "Baixar XML",
          icon: "ic-material-symbols-outlined ic-download",
          onClick: () => {
            const data = getData();
            downloadXmlMdfe(data?.id, data?.situacao);
          },
          mostraNaColunaDeAcoes: true,
          disabledIcon: !verificaSituacaoMDFeParaImpressaoDamdfeBaixarXml(
            data?.situacao
          ),
          hint: "Baixar XML",
        },
        {
          text: "Duplicar",
          icon: "fa-regular fa-clone",
          onClick: () => {
            duplicar(getData()?.id);
          },
          mostraNaColunaDeAcoes: false,
          hint: "Duplicar",
        },
      ];

      return itensAdicionais;
    }
  );

  return (
    <>
      <MxpGrid<MDFeGridModel>
        id={"mdfe"}
        ref={gridRef}
        dataSource={dataSource}
        colunas={colunas}
        nomeDoArquivoAoExportar={NomesTelas.mdfe}
        novoRegistro={handleNovoRegistro}
        editarRegistro={handleEditarRegistro}
        excluirRegistro={handleExcluirRegistro}
        gerarItensAdicionaisDeContexto={handleGerarItensAdicionais}
      />

      <Modal
        titulo={obterTituloTela(
          idRegistroEdicao == 0 ? "criar" : "editar",
          NomesModais.mdfe
        )}
        visivel={modalEdicaoVisivel}
        altura="96vh"
        alturaMaxima={"96vh"}
        onFechar={() =>
          handleModalCallback({ concluido: false, precisaAtualizar: true })
        }
      >
        <EditFormMDFe
          isModal
          idRegistro={idRegistroEdicao}
          callBackFecharModal={handleModalCallback}
        />
      </Modal>

      <Modal
        titulo="Visualizar PDF"
        visivel={modalPdfViewerVisivel}
        onFechar={handlePdfViewerModalCallBack}
        altura="92vh"
        largura="90vw"
      >
        <PdfViewer documento={documentoPdfDamdfe} />
      </Modal>
    </>
  );
});
