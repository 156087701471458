import { SelectBox } from "devextreme-react";
import { LabelMode, SimplifiedSearchMode } from "devextreme/common";
import SelectItem from "../../../models/shared/ui/select-item";
import IconeAjuda from "../../ajuda/icone-ajuda";
import LinkAjuda from "../../ajuda/link-ajuda";

enum OrdenacaoSelectBox {
  Asc = 1,
  Desc = 2,
}

interface FormSelectBoxProps {
  idAjuda?: string;
  tabIndex?: number;
  titulo?: string;
  toolTip?: string;
  placeholder?: string;
  tipoPlaceholder?: LabelMode;
  exibirBotaoLimpar?: boolean;
  somenteLeitura?: boolean;
  desabilitado?: boolean;
  requerido?: boolean;
  labelSemDados?: string;
  dataSource: SelectItem[];
  habilitaBusca?: boolean;
  tipoBusca?: SimplifiedSearchMode;
  onValueChange?: (value: any) => void;
  onEnter?: (e: any) => void;
  ordenacaoDosDados?: OrdenacaoSelectBox;
  valor: any;
}

function obterDataSourceOrdenado(
  dataSource: SelectItem[],
  ordenacao?: OrdenacaoSelectBox
) {
  const ordenacaoAsc = dataSource.sort((a, b) =>
    a.descricao.localeCompare(b.descricao)
  );

  if (!ordenacao || ordenacao == OrdenacaoSelectBox.Asc) {
    return ordenacaoAsc;
  }

  return dataSource.sort((a, b) => b.descricao.localeCompare(a.descricao));
}

export default function FormSelectBoxSimples(props: FormSelectBoxProps) {
  return (
    <>
      <label>
        <span className="dx-field-item-label-content">
          <span className="dx-field-item-label-text">
            {props.titulo ? props.titulo : <br />}
          </span>
          {props.requerido && (
            <span className="dx-field-item-required-mark">&nbsp;*</span>
          )}
          {props.idAjuda && (
            <LinkAjuda keyAjuda={props.idAjuda}>
              <IconeAjuda />
            </LinkAjuda>
          )}
        </span>
      </label>
      <SelectBox
        tabIndex={props.tabIndex}
        dataSource={obterDataSourceOrdenado(
          props.dataSource,
          props.ordenacaoDosDados
        )}
        valueExpr="valor"
        displayExpr="descricao"
        noDataText={props.labelSemDados}
        hint={props.toolTip}
        label={props.placeholder}
        labelMode={props.tipoPlaceholder}
        readOnly={props.somenteLeitura}
        disabled={props.desabilitado}
        showClearButton={props.exibirBotaoLimpar}
        onEnterKey={props.onEnter}
        onValueChange={(value) => {
          props.onValueChange?.(value);
        }}
        value={props.valor}
        searchEnabled={props.habilitaBusca}
        searchMode={props.tipoBusca ?? "startswith"}
        searchTimeout={0}
        inputAttr={{ id: props.idAjuda }}
      />
    </>
  );
}
