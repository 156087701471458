import { createContext } from "react";
import { OrdemDeProducaoGridModel } from "../../models/ordem-de-producao.api";

export type FuncaoDeOrdemDeProducaoGrid = (
  ordemDeProducao?: OrdemDeProducaoGridModel
) => void;

interface ContextoOrdemDeProducaoGridProps {
  ordemDeProducaoDesfazerConclusao?: OrdemDeProducaoGridModel;
  definfirOrdemDeProducaoDesfazerConclusao: FuncaoDeOrdemDeProducaoGrid;
  definirAtualizaGridDeOrdemDeProducao: (x: () => void) => void;
  atualizaGridDeOrdemDeProducao: () => void;
}

const ContextoOrdemDeProducaoGrid =
  createContext<ContextoOrdemDeProducaoGridProps>({
    ordemDeProducaoDesfazerConclusao: undefined,
    definfirOrdemDeProducaoDesfazerConclusao: () => {
      // implementação default
    },
    definirAtualizaGridDeOrdemDeProducao: () => {
      // implementação default
    },
    atualizaGridDeOrdemDeProducao: () => {
      undefined;
    },
  });

export default ContextoOrdemDeProducaoGrid;
