enum ModoEdicao {
  Criar,
  Editar,
  NaoAplicavel,
}

export default class NormalizaTituloModal {
  public static Normalizar(
    idRegistroEmEdicao: number,
    titulo: string,
    telaEmNomeFeminino = false
  ) {
    const genero = telaEmNomeFeminino ? "a" : "o";

    switch (this.ObterModo(idRegistroEmEdicao)) {
      case ModoEdicao.Criar:
        return `Nov${genero} ${titulo}`;
      case ModoEdicao.Editar:
        return `Editar ${titulo}`;
      case ModoEdicao.NaoAplicavel:
      default:
        return titulo;
    }
  }

  private static ObterModo(idRegistroEmEdicao: number) {
    if (idRegistroEmEdicao == 0) {
      return ModoEdicao.Criar;
    } else if (idRegistroEmEdicao > 0) {
      return ModoEdicao.Editar;
    }

    return ModoEdicao.NaoAplicavel;
  }
}
