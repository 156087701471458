import { useAppSelector } from "../../../hooks/store.hooks";

interface PainelFiltrosProps {
  children: React.ReactNode;
}

export default function PainelFiltros({ children }: PainelFiltrosProps) {
  const master = useAppSelector(
    (state) => state.sessao.dadosSessao?.usuario.isUsuarioMaster
  );

  /* Margin destinado ao link "Editar link da página de ajuda para tooltips [master]",
   disponível apenas para usuários master.*/
  return (
    <div style={{ marginBottom: master ? 20 : undefined }}>{children}</div>
  );
}
