import qs from "qs";
import getApi from "../../configs/api";
import { ListaSimplesResponse } from "../../models/api/comum/response-base";
import { TipoTributoContaPadraoParteB } from "../../models/api/conta-padrao-parte-b/conta-padrao-parte-b";
import { NomesEndpoints } from "../comum/nomesEndpoints";
import { ServiceBase } from "../comum/serviceBase";

export class ContaPadraoParteBService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.ContaPadraoParteB);
  }

  public async ObterListaSimplesDosTipos(
    tipos: TipoTributoContaPadraoParteB[]
  ) {
    const api = getApi();
    const response = await api.get<ListaSimplesResponse>(
      `${this._nomeEndpoint}/lista-simples`,
      {
        params: {
          tipos: tipos,
        },
        paramsSerializer: {
          serialize: (x) => {
            return qs.stringify(x);
          },
        },
      }
    );

    return response.data;
  }
}
