import { formatarTemplate } from "../../../utils/strings/string-utils";
import comum_json from "./comum.json";

export const StringsComum = comum_json;

export function obterMensagemQuantidadeMinimaCaracteres(min: number) {
  return formatarTemplate(StringsComum.quantidadeMinimaCaracteres, min);
}

export function obterMensagemExclusao(
  artigo: "o" | "a",
  sujeito: string,
  detalhamento: string
) {
  return formatarTemplate(
    StringsComum.exclusaoMensagem,
    ...[artigo, sujeito, detalhamento]
  );
}
