import qs from "qs";
import getApi from "../../configs/api";
import { TipoUnidadeTransporte } from "../../features/vendas/veiculo/models/veiculo.enums";
import { ListaSimplesResponse } from "../../models/api/comum/response-base";

// Mantido devido à aba de veículos do MDF-e
async function obterListaSimples(
  unidadesTransportes?: TipoUnidadeTransporte[]
) {
  const api = getApi();

  const response = await api.get<ListaSimplesResponse>(
    "Veiculo/lista-simples",
    {
      params: {
        unidadesTransporte: unidadesTransportes,
      },
      paramsSerializer: {
        serialize: (x) => {
          return qs.stringify(x);
        },
      },
    }
  );

  return response.data;
}

export default {
  obterListaSimples,
};
