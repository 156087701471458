import PaginaMxp from "../../../../../components/layout/pagina-mxp";
import ProvedorMenus from "../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../components/seguranca/requer-permissao";
import { PermissoesApuracaoLacsCsll } from "../../../../../models/permissoes/fiscal/apuracao-lacs-csll/permissoes-apuracao-lacs-csll";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import GridApuracaoLacsCsll from "../../componentes/grid";

export default function ApuracaoLacsCsllPage() {
  return (
    <ProvedorMenus>
      <PaginaMxp
        id="apuracao-lacs-css-page"
        titulo={NomesTelas.apuracaoLacsCsll}
        area={["Fiscal", "Apurações"]}
      >
        <RequerPermissao
          codigoPermissoes={[PermissoesApuracaoLacsCsll.Consultar]}
        >
          <GridApuracaoLacsCsll />
        </RequerPermissao>
      </PaginaMxp>
    </ProvedorMenus>
  );
}
