import {
  ativoPorExtenso,
  inativoPorExtenso,
  naoPorExtenso,
  simPorExtenso,
} from "../../../utils/common/constantes";

export function decodificaBooleanoEmSimNao(valor: boolean) {
  return valor ? simPorExtenso : naoPorExtenso;
}

export function decodificaBooleanoEmAtivoInativo(valor: boolean) {
  return valor ? ativoPorExtenso : inativoPorExtenso;
}
