import getApi from "../../../../configs/api";
import { ListaSimplesResponse } from "../../../../models/api/comum/response-base";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import { TipoDocumentoEletronico } from "../models/serie-fiscal.enums";

export default class SerieFiscalService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.SerieFiscal);
  }

  public async obterListaSimples(tipo: TipoDocumentoEletronico) {
    const api = getApi();

    const response = await api.get<ListaSimplesResponse>(
      `${this._nomeEndpoint}/lista-simples`,
      {
        params: {
          tipo: tipo,
        },
      }
    );

    return response.data;
  }
}
