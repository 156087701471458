export enum NotificacaoNivel {
  Nenhuma = 0,
  Baixa = 10,
  Media = 20,
  Alta = 30,
}

export enum NotificacaoTipo {
  Informacao = 10,
  Alerta = 20,
}
