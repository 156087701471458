import { Container } from "./styles";
import "./tab-container.scss";

interface TabContainerProps {
  children: React.ReactNode;
}

export default function TabContainer({ children }: TabContainerProps) {
  return <Container>{children}</Container>;
}
