import SelectItem from "../../../../models/shared/ui/select-item";
import { gerarSelectItemDoDicionario } from "../../../../utils/common/enum-utils";

export enum EstoqueMovimentacaoTipo {
  Estoque = 69,
  ContaContabil = 66,
  EstoqueProducao = 80,
  EstoqueConsumido = 67,
  EstoqueManutencao = 77,
  SemEstoque = 78,
  ValorEstoqueNaoProducao = 86,
  ValorEstoqueProducao = 87,
  MaterialEstoqueProducao = 84,
}

// Classe que encapsula o enum e suas descrições
export class EstoqueMovimentacaoTipoHelper {
  // Criando um "dicionário" com Map
  public static estoqueMovimentacaoTipoDescricao: {
    [key in EstoqueMovimentacaoTipo]: string;
  } = {
    [EstoqueMovimentacaoTipo.Estoque]: "Estoque",
    [EstoqueMovimentacaoTipo.ContaContabil]: "Conta contábil",
    [EstoqueMovimentacaoTipo.EstoqueProducao]: "Estoque produção",
    [EstoqueMovimentacaoTipo.EstoqueConsumido]: "Estoque consumido",
    [EstoqueMovimentacaoTipo.EstoqueManutencao]: "Estoque manutenção",
    [EstoqueMovimentacaoTipo.SemEstoque]: "Sem estoque",
    [EstoqueMovimentacaoTipo.ValorEstoqueNaoProducao]:
      "Valor estoque não produção",
    [EstoqueMovimentacaoTipo.ValorEstoqueProducao]: "Valor estoque produção",
    [EstoqueMovimentacaoTipo.MaterialEstoqueProducao]:
      "Material estoque produção",
  };

  // Função para obter a descrição
  public static getDescricao(tipo: EstoqueMovimentacaoTipo): string {
    return (
      EstoqueMovimentacaoTipoHelper.estoqueMovimentacaoTipoDescricao[tipo] ||
      "Descrição desconhecida"
    );
  }

  public static AsSelectItems(): SelectItem[] {
    return gerarSelectItemDoDicionario(
      EstoqueMovimentacaoTipoHelper.estoqueMovimentacaoTipoDescricao
    );
  }
}
