import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/store.hooks";
import { definirLoginReturnUrl } from "../../../store/ui/ui.slice";

export default function RedirecionadorLogin() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(definirLoginReturnUrl(`${location.pathname}${location.search}`));
    navigate("/");
  }, []);

  return <></>;
}
