import Pagina from "../../../components/layout/pagina";
import RequerPermissao from "../../../components/seguranca/requer-permissao";
import { PermissoesMDF } from "../../../models/permissoes/fiscal/mdfe/permissoes-mdf";
import { GridMDFe } from "../../../parts/fiscal/mdfe/grid";
import NomesTelas from "../../../utils/common/nomes-telas";

export default function MdfePage() {
  return (
    <Pagina id="mdfe-page" titulo={NomesTelas.mdfe} area={"Fiscal"}>
      <RequerPermissao codigoPermissoes={[PermissoesMDF.Consultar]}>
        <GridMDFe />
      </RequerPermissao>
    </Pagina>
  );
}
