import { Column } from "devextreme-react/cjs/data-grid";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { MxpGrid } from "../../../../components/grid";
import { usePropagarReferenciaGrid } from "../../../../hooks/grid.hooks";
import { ItemNotaFiscalRecebidaGridComImpostosGridModel } from "../../../../models/api/item-nota-fiscal-recebida/item-nota-fiscal-recebida";
import { IGridSelecao } from "../../../../models/shared/ui/formularios";
import ItemNotaFiscalRecebidaService from "../../../../services/item-nota-fiscal-recebida/item-nota-fiscal-recebida.service";
import criarNameof from "../../../../utils/common/cria-name-of";
import { GridBaseProps } from "../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../utils/grid/padroes-colunas";

const service = new ItemNotaFiscalRecebidaService();
const nameOfGridHandler =
  criarNameof<ItemNotaFiscalRecebidaGridComImpostosGridModel>();
const dataSource = service.GetGridDataSource();
const colunas = [
  <Column
    key={nameOfGridHandler("dataEmissaoNota")}
    dataField={nameOfGridHandler("dataEmissaoNota")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    caption="Emissão"
    sortOrder="desc"
  />,
  <Column
    key={nameOfGridHandler("numeroNota")}
    dataField={nameOfGridHandler("numeroNota")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Nota fiscal"
  />,
  <Column
    key={nameOfGridHandler("serieNota")}
    dataField={nameOfGridHandler("serieNota")}
    {...obterConfiguracaoColuna("serie")}
  />,
  <Column
    key={nameOfGridHandler("apelidoFornecedorNota")}
    dataField={nameOfGridHandler("apelidoFornecedorNota")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Remetente"
  />,
  <Column
    key={nameOfGridHandler("situacaoNotaDecodificada")}
    dataField={nameOfGridHandler("situacaoNotaDecodificada")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Estado"
  />,
  <Column
    key={nameOfGridHandler("sequenciaDoItemNaNota")}
    dataField={nameOfGridHandler("sequenciaDoItemNaNota")}
    {...obterConfiguracaoColuna("stringPP")}
    caption="#"
  />,
  <Column
    key={nameOfGridHandler("codigoItem")}
    dataField={nameOfGridHandler("codigoItem")}
    {...obterConfiguracaoColuna("stringP")}
    caption="Item"
  />,
  <Column
    key={nameOfGridHandler("descricao")}
    dataField={nameOfGridHandler("descricao")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Descrição"
  />,
  <Column
    key={nameOfGridHandler("quantidade")}
    dataField={nameOfGridHandler("quantidade")}
    {...obterConfiguracaoColuna("quantidade")}
    caption="Quantidade"
  />,
  <Column
    key={nameOfGridHandler("unidade")}
    dataField={nameOfGridHandler("unidade")}
    {...obterConfiguracaoColuna("unidade")}
    caption="Unid"
  />,
  <Column
    key={nameOfGridHandler("valorUnitario")}
    dataField={nameOfGridHandler("valorUnitario")}
    {...obterConfiguracaoColuna("monetario")}
    caption="Valor un"
  />,
  <Column
    key={nameOfGridHandler("valorTotal")}
    dataField={nameOfGridHandler("valorTotal")}
    {...obterConfiguracaoColuna("monetario")}
    caption="Valor total"
  />,
  <Column
    key={nameOfGridHandler("valorIcms")}
    dataField={nameOfGridHandler("valorIcms")}
    {...obterConfiguracaoColuna("monetario")}
    caption="Valor do ICMS"
  />,
  <Column
    key={nameOfGridHandler("valorIcmsSt")}
    dataField={nameOfGridHandler("valorIcmsSt")}
    {...obterConfiguracaoColuna("monetario")}
    caption="Valor do ICMS ST"
  />,
  <Column
    key={nameOfGridHandler("valorIcmsDifal")}
    dataField={nameOfGridHandler("valorIcmsDifal")}
    {...obterConfiguracaoColuna("monetario")}
    caption="Valor do ICMS DIFAL comprador"
  />,
];

export const GridItemNotaFiscalRecebidaComImpostos = forwardRef(
  (props: GridBaseProps, ref) => {
    const gridRef = useRef<IGridSelecao>(null);
    useImperativeHandle(ref, () => gridRef.current as IGridSelecao);

    usePropagarReferenciaGrid(ref, gridRef);

    if (props.filtrosRealizadosNoServidor) {
      dataSource.filter(
        props.filtrosRealizadosNoServidor.map((x) => [
          x.nomeCampo,
          x.operador,
          x.valor,
        ])
      );
    }

    return (
      <>
        <MxpGrid<ItemNotaFiscalRecebidaGridComImpostosGridModel>
          dataSource={dataSource}
          id={"item-nota-fiscal-recebida-grid"}
          ref={gridRef}
          colunas={colunas}
          exibirIconeEditarMenuAcoes={false}
          exibirIconeExcluirMenuAcoes={false}
        />
      </>
    );
  }
);
