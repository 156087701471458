import PaginaMxp from "../../../../../components/layout/pagina-mxp";
import ProvedorMenus from "../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../components/seguranca/requer-permissao";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import GridEstadoConfiguravelDaOrdemDeProducao from "../../componentes/grid";
import { PermissoesEstadoConfiguravelDaOrdemDeProducao } from "../../permissoes/permissoes-estado-configuravel-da-ordem-de-producao";
import FiltrosGridEstadoConfiguravelDaOrdemDeProducao from "../../utils/filtros-grid/filtros";

export default function EstadoConfiguravelDaOrdemDeProducaoPage() {
  return (
    <ProvedorMenus>
      <PaginaMxp
        id="estado-configuravel-da-ordem-de-producao-page"
        titulo={NomesTelas.estadoConfiguravelDaOrdemDeProducao}
        area={["Produção"]}
      >
        <RequerPermissao
          codigoPermissoes={[
            PermissoesEstadoConfiguravelDaOrdemDeProducao.Consultar,
          ]}
        >
          <GridEstadoConfiguravelDaOrdemDeProducao
            filtrosNoCliente={
              FiltrosGridEstadoConfiguravelDaOrdemDeProducao.apenasAtivos
            }
          />
        </RequerPermissao>
      </PaginaMxp>
    </ProvedorMenus>
  );
}
