import { useMemo } from "react";
import { FieldValues, UseControllerProps } from "react-hook-form";
import { FormSelectBoxLazyMxp } from "../../../../../components/formularios";
import { ItemSelectionChangedType } from "../../../../../components/formularios/selectbox-lazy-mxp";
import { DataSourceOpcoesBuilder } from "../../../../../utils/grid/data-source-factory";
import { ItemGridModel } from "../../models/item.api";
import { ItemService } from "../../servicos/item.service";
import GridItem from "../grid";

interface ComboItemMxpProps<T extends FieldValues>
  extends UseControllerProps<T> {
  titulo: string;
  tituloSeletor?: string;
  requerido?: boolean;
  filtrosNoCliente?: any[] | undefined;
  dataSourceOpcoes?: DataSourceOpcoesBuilder<ItemGridModel>;
  somenteLeitura?: boolean;
  onSelectionChanged?:
    | ((e: ItemSelectionChangedType) => Promise<void>)
    | (() => void);
}

const itemService = new ItemService();

export default function ComboItemMxp<T extends FieldValues>(
  props: ComboItemMxpProps<T>
) {
  const [dataSourceItem, configuracoesExibicaoEBuscaItem] = useMemo(
    () => itemService.GetDadosSelectBoxItem(props.dataSourceOpcoes),
    [props.dataSourceOpcoes]
  );

  return (
    <FormSelectBoxLazyMxp
      name={props.name}
      titulo={props.titulo}
      control={props.control}
      requerido={props.requerido}
      configuracoesExibicaoEBusca={configuracoesExibicaoEBuscaItem}
      somenteLeitura={props.somenteLeitura}
      onSelectionChanged={props.onSelectionChanged}
      dataSource={dataSourceItem}
      seletorConfig={{
        modo: "selecaoUnica",
        titulo: props.tituloSeletor ?? "Selecionar item",
        componenteGrid: (
          <GridItem
            filtrosNoServidor={props.dataSourceOpcoes?.camposFiltro}
            filtrosNoCliente={props.filtrosNoCliente}
          />
        ),
      }}
      labelSemDados="Sem dados"
    />
  );
}
