import { FileUploader } from "devextreme-react";
import { FileUploaderRef } from "devextreme-react/cjs/file-uploader";
import { useRef } from "react";
import { Controller, FieldValues, UseControllerProps } from "react-hook-form";
import IconeAjuda from "../../ajuda/icone-ajuda";
import LinkAjuda from "../../ajuda/link-ajuda";
import { LabelErro } from "../label-erro";

interface FormFileProps<T extends FieldValues> extends UseControllerProps<T> {
  tabIndex?: number;
  titulo?: string;
  toolTip?: string;
  somenteLeitura?: boolean;
  desabilitado?: boolean;
  requerido?: boolean;
  exibirLinkAjuda?: boolean;
  extensoesAceitas: string;
  limparArquivosSelecionados?: boolean;
}

export default function <T extends FieldValues>(props: FormFileProps<T>) {
  const ref = useRef<FileUploaderRef>(null);

  if (props.limparArquivosSelecionados) {
    ref?.current?.instance().reset();
  }

  return (
    <Controller
      {...props}
      render={({ field, fieldState }) => (
        <>
          <label htmlFor={field.name}>
            <span className="dx-field-item-label-content">
              {props.titulo && (
                <>
                  <span className="dx-field-item-label-text">
                    {props.titulo}
                  </span>
                  {props.requerido && (
                    <span className="dx-field-item-required-mark">&nbsp;*</span>
                  )}
                </>
              )}

              {(props.exibirLinkAjuda ?? true) && (
                <LinkAjuda keyAjuda={field.name}>
                  <IconeAjuda />
                </LinkAjuda>
              )}
            </span>
          </label>
          <FileUploader
            ref={ref}
            name={props.name}
            tabIndex={props.tabIndex}
            hint={props.toolTip}
            readOnly={props.somenteLeitura}
            disabled={props.desabilitado}
            onValueChange={(e) => {
              field.onChange(e[0]);
            }}
            validationStatus={fieldState.invalid ? "invalid" : "valid"}
            inputAttr={{ id: field.name }}
            selectButtonText="Selecionar arquivo..."
            labelText=""
            accept={props.extensoesAceitas}
            uploadMode="useForm"
            allowCanceling={true}
            onContentReady={(e) => {
              const buttonContent =
                e.element.querySelector(".dx-button-content");

              if (buttonContent) {
                const iconElement = document.createElement("i");
                iconElement.className =
                  "dx-icon ic-material-symbols-outlined ic-file-upload";

                buttonContent.insertBefore(
                  iconElement,
                  buttonContent.firstChild
                );
              }
            }}
          />
          <LabelErro>{fieldState.error?.message}</LabelErro>
        </>
      )}
    />
  );
}
