import { PdfContainer } from "./styles";

interface PdfViewerProps {
    documento: string | Blob;
}

export default function PdfViewer({
    documento,
}: PdfViewerProps) {    
    const urlPdf = typeof(documento) == "string" ? documento as string : window.URL.createObjectURL(documento as Blob);
    return (
    <>
        <PdfContainer>
            <embed 
                src={urlPdf}
                className="centered-embed"
                type="application/pdf"
            />
        </PdfContainer>
    </>
    );
}
