import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  FormNumberBox,
  FormSelectBoxLazy,
} from "../../../../../components/formularios";
import FormBase from "../../../../../components/layout/form-base";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/store.hooks";
import { MDFeCarregamentoDTO } from "../../../../../models/api/mdfe/mdfe-request-response";
import { AdicionarCarregamentoViewModel } from "../../../../../models/viewmodels/vendas/mdfe/mdfe-edit-form-view-model";
import APIMunicipio from "../../../../../services/municipio/municipio.service";
import store from "../../../../../store";
import { adicionarCarregamento } from "../../../../../store/mdfe/mdfe.slice";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { obterFormatStringNumero } from "../../../../../utils/formatadores/formatador-de-numeros";
import {
  FormataDescricao,
  FormatadoresSelectBox,
} from "../../../../../utils/formatadores/formatador-de-selectbox";

interface ModalAdicionarCarregamentoProps {
  visivel: boolean;
  fecharCallback: () => void;
}

const exibeMunicipio = (c: any) => {
  if (c) {
    return FormataDescricao(
      FormatadoresSelectBox.CodigoDescricaoParenteses,
      c.Descricao,
      c.SiglaUf
    );
  }

  return "";
};

const municipioExpressaoDeBusca = ["Descricao", "SiglaUf"];

export default function ModalAdicionarCarregamento({
  fecharCallback,
  visivel,
}: ModalAdicionarCarregamentoProps) {
  const idUfOrigem = useAppSelector(
    (state) => state.mdfe.documentoAtual.idUfInicio
  );

  const dataSourceMunicipio = APIMunicipio.getDataSourceSelectBoxLazy({
    camposRetorno: ["Id", "Descricao", "SiglaUf"],
    filtros: idUfOrigem
      ? [{ nomeCampo: "IdUf", operador: "=", valor: idUfOrigem.toString() }]
      : undefined,
    camposOrdenacao: [
      {
        nomeCampo: "Descricao",
        desc: false,
      },
    ],
  });

  const dataSourceMunicipioConsulta = APIMunicipio.getDataSourceSelectBoxLazy({
    camposRetorno: ["Id", "Descricao", "SiglaUf"],
    filtros: idUfOrigem
      ? [{ nomeCampo: "IdUf", operador: "=", valor: idUfOrigem.toString() }]
      : undefined,
    camposOrdenacao: [
      {
        nomeCampo: "Descricao",
        desc: false,
      },
    ],
  });

  const [carregando, setCarregando] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    reset();
    let novaOrdem =
      Math.max(
        ...store
          .getState()
          .mdfe.documentoAtual.carregamentos.map((x) => x.ordem)
      ) + 1;

    novaOrdem = novaOrdem == Infinity * -1 ? 1 : novaOrdem;

    setValue("ordem", novaOrdem);
  }, [visivel]);

  async function handleSalvar() {
    try {
      setCarregando(true);
      const model = getValues();
      dataSourceMunicipioConsulta.filter(["Id", "=", model.idMunicipio]);
      await dataSourceMunicipioConsulta.load();
      const dado = dataSourceMunicipioConsulta.items()[0];

      const addmodel: MDFeCarregamentoDTO = {
        id: 0,
        idMunicipio: model.idMunicipio,
        ordem: model.ordem,
        municipio: {
          uf: dado.SiglaUf,
          nome: dado.Descricao,
        },
      };

      dispatch(adicionarCarregamento(addmodel));
      exibirNotificacaoToast({
        mensagem: `Município ${addmodel.municipio.nome} adicionado com sucesso. Clique em "Salvar" para confirmar a operação.`,
        tipo: TipoNotificacao.Advertencia,
      });
      fecharCallback();
    } finally {
      setCarregando(false);
    }
  }

  const schema = yup.object().shape({
    idMunicipio: yup
      .number()
      .required()
      .positive()
      .integer()
      .transform((v) => (v ? v : null))
      .test({
        message: "O município informado já existe na lista de carregamentos.",
        test: (v) => {
          return store
            .getState()
            .mdfe.documentoAtual.carregamentos.every((x) => x.idMunicipio != v);
        },
      }),
    ordem: yup
      .number()
      .required()
      .integer()
      .moreThan(0)
      .max(255)
      .test({
        message: "A ordem já existe na lista de carregamentos.",
        test: (v) => {
          return store
            .getState()
            .mdfe.documentoAtual.carregamentos.every((x) => x.ordem != v);
        },
      }),
  });

  const { getValues, setValue, reset, control, handleSubmit } =
    useForm<AdicionarCarregamentoViewModel>({
      mode: "all",
      reValidateMode: "onChange",
      resolver: yupResolver(schema),
    });

  let form: HTMLFormElement | null;

  return (
    <>
      <FormBase
        formId="edit-form-add-carregamento"
        carregando={carregando}
        titulo="Adicionar carregamento"
        modal
        onClickSalvar={() => form?.requestSubmit()}
        onClickCancelar={() => fecharCallback()}
      >
        <form ref={(ref) => (form = ref)} onSubmit={handleSubmit(handleSalvar)}>
          <Linha>
            <Coluna md={9}>
              <FormSelectBoxLazy
                name="idMunicipio"
                titulo="Município"
                toolTip="Município"
                control={control}
                dataSource={dataSourceMunicipio}
                nomeCampoChave="Id"
                nomeCampoExibicao={exibeMunicipio}
                expressaoDeBusca={municipioExpressaoDeBusca}
              />
            </Coluna>
            <Coluna md={3}>
              <FormNumberBox
                name="ordem"
                titulo="Ordem"
                toolTip="Ordem"
                control={control}
                minimo={1}
                maximo={255}
                formato={obterFormatStringNumero(0)}
              />
            </Coluna>
          </Linha>
        </form>
      </FormBase>
    </>
  );
}
