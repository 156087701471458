import { SelectBoxLazyOpcoes } from "../../models/api/comum/selectboxlazy-options";
import { criarDataSourceSelectBoxLazy } from "../../utils/api/api-utils";
import criarDatasourceGrid from "../../utils/grid/back-end-grid-utils";

function getDataSourceSelectBoxLazy(opcoes: SelectBoxLazyOpcoes) {
  const camposSelect = JSON.stringify(opcoes.camposRetorno);

  const dataSource = criarDatasourceGrid(
    `Municipio/grid-cache-ignoraexterior?select=${camposSelect}`
  );

  return criarDataSourceSelectBoxLazy(dataSource, opcoes);
}

export default {
  getDataSourceSelectBoxLazy,
};
