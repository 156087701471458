import FormTextArea from "../../../../../../components/formularios/textarea";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import TabContainer from "../../../../../../components/layout/tab-container";
import { useSubFormContext } from "../../../../../../utils/form/subformContext";

export default function LoteAbaObservacoes() {
  const { hookForms, isSubform, obterNomeDaPropriedade } = useSubFormContext();
  const { control } = hookForms;

  return (
    <TabContainer>
      <Linha>
        <Coluna md={6}>
          <FormTextArea
            name={obterNomeDaPropriedade("observacoes")}
            titulo={"Observações"}
            valor={""}
            control={control}
            height={isSubform ? 15 : 25}
          />
        </Coluna>
        <Coluna md={6}>
          <FormTextArea
            name={obterNomeDaPropriedade("observacoesInternas")}
            titulo={"Observações internas"}
            valor={""}
            control={control}
            height={isSubform ? 15 : 25}
          />
        </Coluna>
      </Linha>
    </TabContainer>
  );
}
