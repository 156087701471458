import GitInfo from "react-git-info/macro";
import getApi from "../../../../configs/api";
import { ResponseModel } from "../../../../models/api/comum/response-base";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import { DetalhesPermissoesResponse } from "../../login/models/login.api";

const gitInfo = GitInfo();

export default class PermissoesServico extends ServiceBase {
  constructor() {
    super(NomesEndpoints.Permissoes);
  }

  public async obterDetalhesPermissoes() {
    const api = getApi();
    const response = await api.get<ResponseModel<DetalhesPermissoesResponse[]>>(
      `${this._nomeEndpoint}/DetalhesPermissoes?v=${gitInfo.commit.shortHash}`
    );

    return response.data.model;
  }
}
