import { useContext, useEffect } from "react";
import { Key } from "ts-key-enum";
import ContextoModal from "../components/layout/contexto-modal";
import { ColecaoAtalhos } from "../utils/atalhos/colecao-atalhos";
import { ItemContextMenuMxp } from "../utils/context-menu/context-menu-utils";
import { GestorEventoClickUnicaLinha } from "../utils/context-menu/gestor-evento-click";
import { GridController } from "../utils/grid/grid-controller";

interface RegistrarAtalhosGrid<T> {
  controller: GridController<T>;
  handleNovo?: () => void;
  handleEditar?: (registro: T) => void;
  handleExcluir?: (registro: T) => void;
  itensAdicionais?: ItemContextMenuMxp[];
}

export function useRegistrarAtalhosGrid<T>(model: RegistrarAtalhosGrid<T>) {
  const modalId = useContext(ContextoModal);

  useEffect(() => {
    const itensDeContexto: ItemContextMenuMxp[] = [];

    if (model.handleEditar) {
      itensDeContexto.push({
        text: "Editar",
        name: "Editar",
        icon: "ic-material-symbols-outlined ic-edit",
        gestorEventoClick: new GestorEventoClickUnicaLinha(
          model.handleEditar,
          () => model.controller
        ),
        exibirNaLinhaDaGrid: "sempre",
        exibirNoMenuPrincipal: false,
        hint: "Editar",
        atalho: { tecla: "E", ctrl: true },
      });
    }

    if (model.handleExcluir) {
      itensDeContexto.push({
        text: "Excluir",
        icon: "ic-material-symbols-outlined ic-delete",
        gestorEventoClick: new GestorEventoClickUnicaLinha(
          model.handleExcluir,
          () => model.controller
        ),
        exibirNaLinhaDaGrid: "sempre",
        exibirNoMenuPrincipal: false,
        hint: "Excluir",
        atalho: { tecla: Key.Delete, conflitaComInputs: true },
      });
    }

    if (model.itensAdicionais) {
      itensDeContexto.push(...model.itensAdicionais);
    }

    ColecaoAtalhos.registrarAtalhosGridMxp(
      modalId,
      model.handleNovo,
      itensDeContexto
    );
  }, []);
}
