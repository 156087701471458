import Pagina from "../../../components/layout/pagina";
import RequerPermissao from "../../../components/seguranca/requer-permissao";
import { PermissoesApuracaoDoCiap } from "../../../models/permissoes/fiscal/apuracao-ciap/permissoes-apuracao-ciap";
import GridApuracaoDoCiap from "../../../parts/fiscal/apuracao-ciap/grid";
import NomesTelas from "../../../utils/common/nomes-telas";

export default function ApuracaoDoCiapPage() {
  return (
    <Pagina
      id="apuracao-lacs-css-page"
      area={"Fiscal > Apurações"}
      titulo={NomesTelas.apuracaoDoCiap}
    >
      <RequerPermissao codigoPermissoes={[PermissoesApuracaoDoCiap.Consultar]}>
        <GridApuracaoDoCiap />
      </RequerPermissao>
    </Pagina>
  );
}
