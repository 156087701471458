export interface iconeAjudaProps {
  linhaUnica?: boolean;
}

export default function IconeAjuda(props: iconeAjudaProps) {
  return (
    <span
      style={{
        color: "#878787",
        paddingLeft: props.linhaUnica ? "10px" : undefined,
      }}
      className="fa-regular fa-circle-question"
    ></span>
  );
}
