import { AxiosRequestConfig, AxiosResponse } from "axios";
import getApi from "../../../configs/api";
import { ArquivoBaseModel } from "../../../models/api/arquivos/arquivo";
import {
  ResponseBase,
  ResponseBaseArquivo,
  ResponseModel,
} from "../../../models/api/comum/response-base";
import { NomesEndpoints } from "../nomesEndpoints";

async function obterAnexos(id: number, nomeEndpoint: NomesEndpoints) {
  const api = getApi();
  const response = await api.get<ResponseModel<ArquivoBaseModel[]>>(
    `${nomeEndpoint}/ObterAnexos?id=${id}`
  );

  return response.data.model;
}

async function anexoObterDadosParaDownload(
  id: number,
  idAnexo: number,
  nomeEndpoint: NomesEndpoints
) {
  const api = getApi();

  const resquestConfig: AxiosRequestConfig = {
    responseType: "blob",
  };

  const response = await api.get<ResponseBaseArquivo>(
    `${nomeEndpoint}/AnexoObterDadosParaDownload?id=${id}&idAnexo=${idAnexo}`,
    resquestConfig
  );
  return response;
}

async function anexoAlterarLista(
  id: number,
  anexos: ArquivoBaseModel[],
  nomeEndpoint: NomesEndpoints
) {
  const api = getApi();
  anexos.forEach((x) => (x.arquivo ??= ""));
  const response = await api.put<ResponseBase>(
    `${nomeEndpoint}/AnexoAlterarLista`,
    { id: id, anexos: anexos }
  );

  return response.data;
}

export default {
  obterAnexos,
  anexoAlterarLista,
  anexoObterDadosParaDownload,
};

export interface IAnexosService {
  obterAnexos(id: number): Promise<ArquivoBaseModel[]>;
  anexoAlterarLista(
    id: number,
    anexos: ArquivoBaseModel[]
  ): Promise<ResponseBase>;
  anexoObterDadosParaDownload(
    id: number,
    idAnexo: number
  ): Promise<AxiosResponse<ResponseBaseArquivo, any>>;
}
