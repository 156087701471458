import { Template } from "devextreme-react";
import SelectBox, { Button, SelectBoxRef } from "devextreme-react/select-box";
import { LabelMode, SimplifiedSearchMode } from "devextreme/common";
import DataSource from "devextreme/data/data_source";
import { ReactNode, useRef, useState } from "react";
import { Controller, FieldValues, UseControllerProps } from "react-hook-form";
import IconeAjuda from "../../ajuda/icone-ajuda";
import LinkAjuda from "../../ajuda/link-ajuda";
import ProvedorSeletor from "../../selecao/provedor-seletor";
import SeletorMxp, { ModoDeSelecaoSeletor } from "../../selecao/seletor-mxp";
import { LabelErro } from "../label-erro";

export type ItemSelectionChangedType<T = any> = {
  readonly component: SelectBoxRef;

  readonly element: HTMLElement;

  readonly selectedItem?: T;
};

export interface ConfiguracaoExibicaoEBusca<T> {
  nomeCampoChave: Extract<keyof T, string>;
  nomeCampoExibicao:
    | Extract<keyof T, string>
    | ((item: T) => string)
    | undefined;
  expressaoDeBusca?: Extract<keyof T, string>[];
}

export function assertConfiguracaoExibicaoEBuscaType<T>(
  config: ConfiguracaoExibicaoEBusca<T>
): ConfiguracaoExibicaoEBusca<T> {
  return config;
}

interface SeletorConfig {
  componenteGrid: JSX.Element;
  modo: ModoDeSelecaoSeletor;
  titulo: string;
}

interface FormSelectBoxLazyProps<T extends FieldValues, TGrid>
  extends UseControllerProps<T> {
  tabIndex?: number;
  titulo: string;
  toolTip?: string;
  placeholder?: string;
  tipoPlaceholder?: LabelMode;
  somenteLeitura?: boolean;
  desabilitado?: boolean;
  requerido?: boolean;
  labelSemDados?: string;
  dataSource: DataSource;
  tipoBusca?: SimplifiedSearchMode;
  configuracoesExibicaoEBusca: ConfiguracaoExibicaoEBusca<TGrid>;
  tamanhoMinimoParaPesquisa?: number;
  tempoMsParaPesquisa?: number;
  onchange?: (object: any) => void;
  onSelectionChanged?: (e: any) => void;
  exibirLinkAjuda?: boolean;
  seletorConfig?: SeletorConfig;
  inputPersonalizado?: (e: any) => ReactNode;
}

/**
 * Tipo que pode ser extendido para criar uma interface de props de um SelectBox customizado.
 *
 * Exemplo de componente utilizando: src/features/itens/item-pedido-de-venda/componentes/select-box-lazy/index.tsx
 *
 * @description
 * Igual ao FormSelectBoxLazyProps<T, TGrid> sem os campos dataSource, configuracoesExibicaoEBusca
 * e seletorConfig pois nós que setamos ele no componente.
 *
 * Também removemos o onSelectionChanged para que possamos dar uma assinatura melhor para ele.
 */
export type ComboEntidadePropsBase<T extends FieldValues, TGrid> = {
  [K in keyof FormSelectBoxLazyProps<T, TGrid> as Exclude<
    K,
    | "dataSource"
    | "configuracoesExibicaoEBusca"
    | "seletorConfig"
    | "onSelectionChanged"
  >]: FormSelectBoxLazyProps<T, TGrid>[K];
} & {
  onSelectionChanged?(e: ItemSelectionChangedType<TGrid>): void;
};

const dropDownIconRender = () => {
  return <div className="dx-dropdowneditor-icon"></div>;
};

export default function <T extends FieldValues, TGrid>(
  props: FormSelectBoxLazyProps<T, TGrid>
) {
  const ref = useRef<SelectBoxRef>(null);
  const [seletorVisivel, setSeletorVisivel] = useState(false);

  return (
    <ProvedorSeletor>
      <Controller
        {...props}
        render={({ field, fieldState }) => (
          <>
            <label htmlFor={field.name}>
              <span className="dx-field-item-label-content">
                <span className="dx-field-item-label-text">{props.titulo}</span>
                {props.requerido && (
                  <span className="dx-field-item-required-mark">&nbsp;*</span>
                )}
                {(props.exibirLinkAjuda ?? true) && (
                  <LinkAjuda keyAjuda={field.name}>
                    <IconeAjuda />
                  </LinkAjuda>
                )}
              </span>
            </label>
            <SelectBox
              ref={ref}
              tabIndex={props.tabIndex}
              dataSource={props.dataSource}
              valueExpr={props.configuracoesExibicaoEBusca.nomeCampoChave}
              displayExpr={props.configuracoesExibicaoEBusca.nomeCampoExibicao}
              noDataText={props.labelSemDados}
              hint={props.toolTip}
              label={props.placeholder}
              labelMode={props.tipoPlaceholder}
              readOnly={props.somenteLeitura}
              disabled={props.desabilitado}
              searchExpr={props.configuracoesExibicaoEBusca.expressaoDeBusca}
              onValueChange={function (value: any) {
                if (props.onchange) {
                  props.onchange(value);
                }
                field.onChange(value);
              }}
              onFocusOut={() => {
                field.onBlur();
              }}
              value={field.value}
              showDropDownButton={true}
              validationStatus={fieldState.invalid ? "invalid" : "valid"}
              searchEnabled={true}
              searchMode={props.tipoBusca ?? "contains"}
              searchTimeout={props.tempoMsParaPesquisa ?? 500}
              minSearchLength={props.tamanhoMinimoParaPesquisa ?? 0}
              onSelectionChanged={props.onSelectionChanged}
              inputAttr={{ id: field.name }}
              fieldRender={props.inputPersonalizado}
            >
              {props.seletorConfig && (
                <Button
                  name="dropDownIconCustom"
                  location="after"
                  options={{
                    stylingMode: "text",
                    template: "dropDownIcon",
                    activeStateEnabled: false,
                    elementAttr: {
                      class: "dx-dropdowneditor-button",
                      style: "z-index: -1",
                    },
                  }}
                >
                  <Template name="dropDownIcon" render={dropDownIconRender} />
                </Button>
              )}
              {props.seletorConfig && (
                <Button
                  name="prevDate"
                  location="after"
                  options={{
                    icon: "search",
                    type: "normal",
                    stylingMode: "outlined",
                    elementAttr: {
                      class: "dx-shape-standard mxp-botao-pesquisa",
                    },
                    onClick: () => setSeletorVisivel(true),
                  }}
                />
              )}
            </SelectBox>
            <LabelErro>{fieldState.error?.message}</LabelErro>
          </>
        )}
      />
      {props.seletorConfig && (
        <SeletorMxp
          modo={props.seletorConfig.modo}
          visivel={seletorVisivel}
          handleConfirmar={(sucesso, dados) => {
            if (!sucesso) {
              return;
            }

            ref.current?.instance().option("value", dados[0].id);
            setSeletorVisivel(false);
          }}
          handleFechar={() => setSeletorVisivel(false)}
          componenteGrid={props.seletorConfig.componenteGrid}
          titulo={props.seletorConfig.titulo}
        />
      )}
    </ProvedorSeletor>
  );
}
