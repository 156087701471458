import getApi from "../../configs/api";
import { ListaSimplesResponse } from "../../models/api/comum/response-base";
import { SelectBoxLazyOpcoes } from "../../models/api/comum/selectboxlazy-options";
import { criarDataSourceSelectBoxLazy } from "../../utils/api/api-utils";
import criarDatasourceGrid from "../../utils/grid/back-end-grid-utils";

async function obterListagemSimples(ignorarUfsExterior: boolean = true) {
  const api = getApi();
  const response = await api.get<ListaSimplesResponse>(
    `Uf/lista-simples${ignorarUfsExterior ? "?ignorarUfsExterior=true" : ""}`
  );

  return response.data;
}

function getDataSourceSelectBoxLazy(opcoes: SelectBoxLazyOpcoes) {
  const camposSelect = JSON.stringify(opcoes.camposRetorno);

  const dataSource = criarDatasourceGrid(
    `UF/grid-cache-ignoraexterior?select=${camposSelect}`
  );

  return criarDataSourceSelectBoxLazy(dataSource, opcoes);
}

export default {
  obterListagemSimples,
  getDataSourceSelectBoxLazy,
};
