export enum FinalidadeTipo {
  NaoUtilizado = 0,
  Direto = 1,
  Indireto = 2,
  Nenhuma = 3,
  AdiantamentoPago = 4,
  AdiantamentoRecebido = 5,
  CaixaBanco = 6,
  Despesa = 7,
  Receita = 8,
  Estoque = 9,
  EstoqueEmPoderDeTerceiros = 10,
  EstoqueDeTerceirosEmNossoPoder = 11,
  EstoqueDeTerceirosEmPoderDeTerceiros = 12,
  CompraParaEstoque = 13,
  Imobilizado = 14,
  TituloPagar = 15,
  TituloReceber = 16,
  DevolucaoCliente = 17,
  DevolucaoFornecedor = 18,
  Retencao = 19,
}

export class FinalidadeTipoHelper {
  // Criando um "dicionário" com Map
  public static finalidadeTipoDescricao: { [key in FinalidadeTipo]: string } = {
    [FinalidadeTipo.NaoUtilizado]: "Não utilizado",
    [FinalidadeTipo.Direto]: "Direto",
    [FinalidadeTipo.Indireto]: "Indireto",
    [FinalidadeTipo.Nenhuma]: "Nenhuma",
    [FinalidadeTipo.AdiantamentoPago]: "Adiantamento pago",
    [FinalidadeTipo.AdiantamentoRecebido]: "Adiantamento recebido",
    [FinalidadeTipo.CaixaBanco]: "Caixa/banco",
    [FinalidadeTipo.Despesa]: "Despesa",
    [FinalidadeTipo.Receita]: "Receita",
    [FinalidadeTipo.Estoque]: "Estoque",
    [FinalidadeTipo.EstoqueEmPoderDeTerceiros]: "Estoque em poder de terceiros",
    [FinalidadeTipo.EstoqueDeTerceirosEmNossoPoder]:
      "Estoque de terceiros em nosso poder",
    [FinalidadeTipo.EstoqueDeTerceirosEmPoderDeTerceiros]:
      "Estoque de terceiros em poder de terceiros",
    [FinalidadeTipo.CompraParaEstoque]: "Compra para estoque",
    [FinalidadeTipo.Imobilizado]: "Imobilizado",
    [FinalidadeTipo.TituloPagar]: "Título a pagar",
    [FinalidadeTipo.TituloReceber]: "Título a receber",
    [FinalidadeTipo.DevolucaoCliente]: "Devolução de cliente",
    [FinalidadeTipo.DevolucaoFornecedor]: "Devolução a fornecedor",
    [FinalidadeTipo.Retencao]: "Retenção",
  };

  // Função para obter a descrição
  public static getDescricao(tipo: FinalidadeTipo): string {
    return (
      FinalidadeTipoHelper.finalidadeTipoDescricao[tipo] ||
      "Descrição desconhecida"
    );
  }

  public static isFinalidadeDeTerceiros(tipo: FinalidadeTipo): boolean {
    return (
      tipo === FinalidadeTipo.EstoqueDeTerceirosEmNossoPoder ||
      tipo === FinalidadeTipo.EstoqueDeTerceirosEmPoderDeTerceiros
    );
  }

  public static isFinalidadeDeEstoque(tipo: FinalidadeTipo): boolean {
    return (
      tipo === FinalidadeTipo.Estoque ||
      tipo === FinalidadeTipo.EstoqueDeTerceirosEmNossoPoder ||
      tipo === FinalidadeTipo.EstoqueDeTerceirosEmPoderDeTerceiros ||
      tipo === FinalidadeTipo.EstoqueEmPoderDeTerceiros
    );
  }

  public static isFinalidadeEmTerceiros(tipo: FinalidadeTipo): boolean {
    return (
      tipo === FinalidadeTipo.EstoqueEmPoderDeTerceiros ||
      tipo === FinalidadeTipo.EstoqueDeTerceirosEmPoderDeTerceiros
    );
  }
}

export enum TipoContaContabil {
  Analitica = 65,
  Sintetica = 83,
}

export enum EstadoContaContabil {
  Ativas = 0,
  Inativas = 1,
  Ambas = 2,
}
