import PaginaMxp from "../../../../../../components/layout/pagina-mxp";
import ProvedorMenus from "../../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../../components/seguranca/requer-permissao";
import { PermissoesEfdReinf } from "../../../../../../models/permissoes/fiscal/efd-reinf/permissoes-efd-reinf";
import NomesTelas from "../../../../../../utils/common/nomes-telas";
import GridEfdReinf4010 from "../../../componentes/grid/efd-reinf-4010";

export default function EfdReinf4010Page() {
  return (
    <ProvedorMenus>
      <PaginaMxp
        id="efd-reinf-4010-page"
        titulo={NomesTelas.efdReinf4010}
        area={["Fiscal"]}
      >
        <RequerPermissao codigoPermissoes={[PermissoesEfdReinf.Consultar]}>
          <GridEfdReinf4010 />
        </RequerPermissao>
      </PaginaMxp>
    </ProvedorMenus>
  );
}
