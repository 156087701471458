import { configureStore } from "@reduxjs/toolkit";
import sessaoSlice from "./sessao/sessao.slice";
import estadoUISlice from "./ui/ui.slice";
import mdfeSlice from "./mdfe/mdfe.slice";
import estadoTelaUsuarioSlice from "./usuario/usuario.slice";

const store = configureStore({
  reducer: {
    sessao: sessaoSlice.reducer,
    estadoUI: estadoUISlice.reducer,
    mdfe: mdfeSlice.reducer,
    estadoTelaUsuario: estadoTelaUsuarioSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
