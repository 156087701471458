import getApi from "../../../../configs/api";
import { ResponseBase } from "../../../../models/api/comum/response-base";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import { CertificadoDigitalRequestDTO } from "../models/certificado-digital";

export default class CertificadoDigitalService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.CertificadoDigital);
  }

  public async uploadAsync(model: CertificadoDigitalRequestDTO) {
    const api = getApi();
    const response = await api.post<ResponseBase>(
      `${NomesEndpoints.CertificadoDigital}/upload`,
      model,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  }
}
