import { createContext } from "react";
import { ItemContextMenuMxp } from "../../../utils/context-menu/context-menu-utils";

interface ContextoMenusProps {
  itensMenu: ItemContextMenuMxp[];
  setItensMenu: (itensMenu: ItemContextMenuMxp[]) => void;
}

const ContextoMenus = createContext<ContextoMenusProps>({
  itensMenu: [],
  setItensMenu: () => {
    /* implementação padrão */
  },
});

export default ContextoMenus;
