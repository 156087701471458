import { Menu } from "devextreme-react";
import { useContext, useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/store.hooks";
import AjudaPaginasPage from "../../../pages/ajuda/ajuda-paginas";
import { navegarParaPaginaExterna } from "../../../utils/common/menu-utils";
import NomesTelas from "../../../utils/common/nomes-telas";
import {
  ItemContextMenuMxp,
  ItemContextMenuNativo,
} from "../../../utils/context-menu/context-menu-utils";
import DefineTituloDaAba from "../../../utils/header/define-titulo-da-aba";
import { ProvedorLinkAjuda } from "../../../utils/help/provedor-links";
import ContextoMenus from "../../menus/contexto-menus";
import { Modal } from "../modal";
import "./index.scss";
import {
  Area,
  Conteudo,
  Header,
  Separador,
  Titulo,
  TituloContainer,
  TituloWrapper,
} from "./styles";

interface PaginaMxpProps {
  id: string | null;
  children: React.ReactNode;
  titulo?: string;
  area?: string[];
  omitirLinksDeAjuda?: boolean;
}

const SeparadorArea = " > ";

export default function PaginaMxp({
  id,
  children,
  area,
  titulo,
  omitirLinksDeAjuda,
}: PaginaMxpProps) {
  DefineTituloDaAba(titulo);
  const [editorVisivel, setEditorVisivel] = useState(false);
  const [menus, setMenus] = useState<ItemContextMenuNativo[]>([]);
  const master = useAppSelector(
    (state) => state.sessao.dadosSessao?.usuario.isUsuarioMaster
  );

  const linkHomeHelp = process.env.REACT_APP_HELP_HOME as string;
  const { itensMenu } = useContext(ContextoMenus);

  useEffect(() => {
    carregarMenus();
  }, [itensMenu]);

  async function carregarMenus() {
    let exibirAjuda = false;
    const links: ItemContextMenuNativo[] = [];

    if (id && !omitirLinksDeAjuda) {
      const provedor = await ProvedorLinkAjuda.criarProvedor();
      const dados = provedor.obterLinks(id);
      exibirAjuda = !!dados.length;

      for (const link of dados) {
        links.push({
          text: link.textoExibicao,
          hint: link.textoExibicao,
          name: link.textoExibicao,
          onClick: () =>
            navegarParaPaginaExterna(`${linkHomeHelp}${link.slug}`),
        });
      }
    }

    if (master && !omitirLinksDeAjuda) {
      links.push({
        text: "Editar links de ajuda [master]",
        name: "Editar links de ajuda [master]",
        onClick: editarLinks,
      });
      exibirAjuda = true;
    }

    const itensAdicionar = itensMenu
      ?.filter((x) => x.exibirNoMenuPrincipal)
      .map(convertItemContextMenu);

    const itens = [...(itensAdicionar ?? [])];

    if (exibirAjuda) {
      const sessaoAjuda: ItemContextMenuNativo = {
        icon: "help",
        hint: "Ajuda",
        text: "\0",
        name: "\0",
        items: links,
      };

      itens.push(sessaoAjuda);
    }

    setMenus(itens);
  }

  function convertItemContextMenu(x: ItemContextMenuMxp) {
    const novoItem: ItemContextMenuNativo = {
      ...x,
      onClick: x.gestorEventoClick?.eventoParaMenuSuperior(),
      items: x?.items?.map(convertItemContextMenu),
    };
    return novoItem;
  }

  function editarLinks() {
    setEditorVisivel(true);
  }

  function handleModalFechado() {
    setEditorVisivel(false);
  }

  return (
    <>
      <Header data-testid="sub-header-component">
        {titulo && (
          <TituloContainer>
            {area && <Area>{area.join(SeparadorArea)}</Area>}
            <TituloWrapper>
              <Titulo>{titulo}</Titulo>
            </TituloWrapper>
          </TituloContainer>
        )}
        {titulo && !!menus.length && <Separador>|</Separador>}

        <Menu
          items={menus}
          displayExpr="text"
          showFirstSubmenuMode="onHover"
          showSubmenuMode="onHover"
          orientation={"horizontal"}
          hideSubmenuOnMouseLeave={false}
        />
      </Header>
      <Conteudo>{children}</Conteudo>
      {master && !!id && (
        <Modal
          titulo={NomesTelas.linkAjudaPaginaMaster}
          visivel={editorVisivel}
          mostrarSombreado
          fecharAoClicarFora={false}
          onFechar={handleModalFechado}
        >
          <AjudaPaginasPage
            callBackFecharModal={handleModalFechado}
            codigo={id}
          />
        </Modal>
      )}
    </>
  );
}
