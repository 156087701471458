import { Button } from "devextreme-react";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../utils/common/notificacoes-utils";

interface BotaoCopiarMxpProps {
  valor: string | undefined;
  visivel?: boolean;
}

export default function BotaoCopiarMxp(props: BotaoCopiarMxpProps) {
  return (
    <Button
      type="normal"
      text={"Copiar"}
      icon={"ic-copy-content ic-material-symbols-outlined"}
      onClick={() => {
        navigator.clipboard.writeText(props.valor ?? "");
        exibirNotificacaoToast({
          mensagem: "Copiado com sucesso!",
          tipo: TipoNotificacao.Sucesso,
        });
      }}
      visible={props.visivel ?? true}
    />
  );
}
