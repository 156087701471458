import { Template } from "devextreme-react";
import SelectBox, {
  Button as SelectBoxButton,
  SelectBoxRef,
} from "devextreme-react/select-box";
import { LabelMode, SimplifiedSearchMode } from "devextreme/common";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import { ReactNode, RefObject, memo, useRef, useState } from "react";
import { Controller, FieldValues, UseControllerProps } from "react-hook-form";
import { IGridSelecao } from "../../../models/shared/ui/formularios";
import { UnidadeTamanhoElementos } from "../../../utils/tipos/unidade-tamanho";
import IconeAjuda from "../../ajuda/icone-ajuda";
import LinkAjuda from "../../ajuda/link-ajuda";
import { Seletor } from "../../selecao/seletor";
import { LabelErro } from "../label-erro";

interface LupaSeletorProps {
  modo: "selecaoUnica" | "selecaoMultipla";
  titulo: string;
  larguraMaxima?: UnidadeTamanhoElementos;
  alturaMaxima?: UnidadeTamanhoElementos;
  callBack?: (concluido: boolean, dados: any[]) => void;
  onClose?: () => void;
  componente: (refGrid: RefObject<IGridSelecao>) => ReactNode;
}

interface FormSelectBoxLazyProps<T extends FieldValues>
  extends UseControllerProps<T> {
  tabIndex?: number;
  titulo: string;
  toolTip?: string;
  placeholder?: string;
  tipoPlaceholder?: LabelMode;
  somenteLeitura?: boolean;
  desabilitado?: boolean;
  requerido?: boolean;
  visivel?: boolean;
  lupaConfig?: LupaSeletorProps;
  labelSemDados?: string;
  dataSource?: CustomStore | DataSource;
  tipoBusca?: SimplifiedSearchMode;
  nomeCampoChave: string;
  nomeCampoExibicao: string | ((item: any) => string) | undefined;
  expressaoDeBusca?: string | Array<string>;
  tamanhoMinimoParaPesquisa?: number;
  tempoMsParaPesquisa?: number;
  onchange?: (object: any) => void;
  onSelectionChanged?: (e: any) => void;
  exibirLinkAjuda?: boolean;
}

const LinkAjudaMemo = memo(
  LinkAjuda,
  (prev, next) => prev.keyAjuda == next.keyAjuda
);

const renderListItem = () => {
  return <div className="dx-dropdowneditor-icon"></div>;
};

export default function <T extends FieldValues>(
  props: FormSelectBoxLazyProps<T>
) {
  const ref = useRef<SelectBoxRef>(null);
  const [seletorVisivel, setSeletorVisivel] = useState(false);

  return props.visivel ?? true ? (
    <Controller
      {...props}
      render={({ field, fieldState }) => (
        <>
          <label htmlFor={field.name}>
            <span className="dx-field-item-label-content">
              <span className="dx-field-item-label-text">{props.titulo}</span>
              {props.requerido && (
                <span className="dx-field-item-required-mark">&nbsp;*</span>
              )}
              {(props.exibirLinkAjuda ?? true) && (
                <LinkAjudaMemo keyAjuda={field.name}>
                  <IconeAjuda />
                </LinkAjudaMemo>
              )}
            </span>
          </label>
          <SelectBox
            ref={ref}
            tabIndex={props.tabIndex}
            dataSource={props.dataSource}
            valueExpr={props.nomeCampoChave}
            displayExpr={props.nomeCampoExibicao}
            noDataText={props.labelSemDados}
            hint={props.toolTip}
            label={props.placeholder}
            labelMode={props.tipoPlaceholder}
            readOnly={props.somenteLeitura}
            disabled={props.desabilitado}
            searchExpr={props.expressaoDeBusca}
            visible={props.visivel ?? true}
            onValueChange={function (value: any) {
              if (props.onchange) {
                props.onchange(value);
              }
              field.onChange(value);
            }}
            onFocusOut={() => {
              field.onBlur();
            }}
            value={field.value}
            showDropDownButton={true}
            validationStatus={fieldState.invalid ? "invalid" : "valid"}
            searchEnabled={true}
            searchMode={props.tipoBusca ?? "contains"}
            searchTimeout={props.tempoMsParaPesquisa ?? 500}
            minSearchLength={props.tamanhoMinimoParaPesquisa ?? 0}
            onSelectionChanged={props.onSelectionChanged}
            inputAttr={{ id: field.name }}
          >
            {props.lupaConfig && (
              <SelectBoxButton
                name="Selecione"
                location="after"
                options={{
                  stylingMode: "text",
                  template: "loadIndicator",
                  activeStateEnabled: false,
                  elementAttr: {
                    class: "dx-dropdowneditor-button",
                    style: "z-index: -1",
                  },
                }}
              >
                <Template name="loadIndicator" render={renderListItem} />
              </SelectBoxButton>
            )}
            {props.lupaConfig && (
              <SelectBoxButton
                name="prevDate"
                location="after"
                options={{
                  icon: "search",
                  type: "normal",
                  stylingMode: "outlined",
                  elementAttr: {
                    class: "dx-shape-standard mxp-botao-pesquisa",
                  },
                  onClick: () => setSeletorVisivel(true),
                }}
              />
            )}
          </SelectBox>
          {props.lupaConfig && (
            <Seletor
              titulo={props.lupaConfig?.titulo}
              modo={props.lupaConfig?.modo}
              visivel={seletorVisivel}
              larguraMaxima={props.lupaConfig?.larguraMaxima ?? "90%"}
              alturaMaxima={props.lupaConfig?.alturaMaxima}
              callBack={
                props.lupaConfig?.callBack ??
                ((ok, dados) => {
                  setSeletorVisivel(false);

                  if (ok) {
                    ref.current?.instance().option("value", dados[0].id);
                  }
                })
              }
              onClose={() => {
                props.lupaConfig?.onClose ?? setSeletorVisivel(false);
              }}
              componente={props.lupaConfig?.componente}
            />
          )}
          <LabelErro>{fieldState.error?.message}</LabelErro>
        </>
      )}
    />
  ) : null;
}
