import DataSource from "devextreme/data/data_source";
import { useState } from "react";
import * as yup from "yup";
import ProvedorAjuda from "../../../../components/ajuda/provedor-ajuda";
import {
  FormDateBox,
  FormGrupo,
  FormNumberBox,
  FormSelectBox,
  FormSelectBoxLazy,
  FormTextBox,
} from "../../../../components/formularios";
import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../components/layout/grid-system";
import PainelCarregamento from "../../../../components/layout/painel-carregamento";
import { ComboClienteEmpresaMxp } from "../../../../features/sistema/empresa/componentes/form/select-box-lazy";
import useEffectOnLoad from "../../../../hooks/effect.hooks";
import {
  useCarregarRegistro,
  useHookForms,
  useLimparFormSeIdForNaN,
} from "../../../../hooks/form.hooks";
import { useAppDispatch } from "../../../../hooks/store.hooks";
import { tiposCTe, tiposNFe } from "../../../../models/api/mdfe/mdfe-enums";
import DocumentoFiscalSelecaoMdfeGrid, {
  novoRegistroDocumentoFiscalSelecaoMdfeGrid,
} from "../../../../models/api/nota-fiscal/nota-fiscal-selecao-mdfe";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import SelectItem from "../../../../models/shared/ui/select-item";
import APIMunicipio from "../../../../services/municipio/municipio.service";
import APIUF from "../../../../services/uf/uf.service";
import store from "../../../../store";
import {
  adicionarDocumentoFiscal,
  editarDocumentoFiscal,
  recalcularTotaisNotasMDFe,
  removerDocumentoFiscal,
} from "../../../../store/mdfe/mdfe.slice";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import { previneDigitacaoDeCaracteres } from "../../../../utils/common/common-utils";
import NomesModais from "../../../../utils/common/nomes-modais";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";
import { letrasDigitosSimbolosSemEspacos } from "../../../../utils/common/regex-padrao";
import { extraiTipoDocumentoChaveAcesso } from "../../../../utils/especifico/mdfe/mdfe-utils";
import { obterFormatStringNumero } from "../../../../utils/formatadores/formatador-de-numeros";
import {
  FormataDescricao,
  FormatadoresSelectBox,
} from "../../../../utils/formatadores/formatador-de-selectbox";
import { pesoTamanhoMaximo, valorTamanhoMaximo } from "./constantes";

interface EditFormDocumentoFiscalAvulsoProps extends FormularioEdicaoBaseProps {
  documentoFiscal?: DocumentoFiscalSelecaoMdfeGrid | undefined;
}

const exibeMunicipioDeDestino = (c: any) => {
  if (c) {
    return FormataDescricao(
      FormatadoresSelectBox.CodigoDescricaoParenteses,
      c.Descricao,
      c.SiglaUf
    );
  }

  return "";
};

const municipioDeDestinoExpressaoDeBusca = ["Descricao", "SiglaUf"];

const previneDigitacaoDeCaracteresHandler = (e: any) =>
  previneDigitacaoDeCaracteres(e, letrasDigitosSimbolosSemEspacos);

const SelecaoUfMunicipioDestino = (props: {
  control: any;
  documentoFiscal: DocumentoFiscalSelecaoMdfeGrid | undefined;
}) => {
  const [municipios, setMunicipios] = useState<DataSource | undefined>();
  const [ufs, setUfs] = useState<SelectItem[]>([]);
  const model =
    props.documentoFiscal ?? novoRegistroDocumentoFiscalSelecaoMdfeGrid;

  useEffectOnLoad(() => {
    carregarUfs();
    carregarMunicipios(model?.idUfDestino ?? 0);
  });

  async function carregarUfs() {
    try {
      const resposta = await APIUF.obterListagemSimples();
      checarResponse(resposta);
      setUfs(
        resposta.model.map((x) => ({
          valor: x.valor,
          descricao: x.descricao,
        }))
      );
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  function carregarMunicipios(idUfDestino: any) {
    setMunicipios(
      APIMunicipio.getDataSourceSelectBoxLazy({
        camposRetorno: ["Id", "Descricao", "SiglaUf"],
        filtros: idUfDestino
          ? [{ nomeCampo: "IdUf", operador: "=", valor: idUfDestino }]
          : undefined,
        camposOrdenacao: [
          {
            nomeCampo: "Descricao",
            desc: false,
          },
        ],
      })
    );
  }

  const handleUfDestinoChange = (ufDestino: any) => {
    carregarMunicipios(ufDestino);
  };

  return (
    <>
      <Coluna md={3}>
        <FormSelectBox
          name="idUfDestino"
          titulo="UF de destino"
          control={props.control}
          exibirBotaoLimpar
          dataSource={ufs}
          habilitaBusca
          tipoBusca="contains"
          onValueChange={handleUfDestinoChange}
          requerido
        />
      </Coluna>
      <Coluna md={9}>
        <FormSelectBoxLazy
          name="idMunicipioDestino"
          titulo="Município de destino"
          control={props.control}
          dataSource={municipios}
          nomeCampoChave="Id"
          nomeCampoExibicao={exibeMunicipioDeDestino}
          expressaoDeBusca={municipioDeDestinoExpressaoDeBusca}
          requerido
        />
      </Coluna>
    </>
  );
};

const novoRegistro = novoRegistroDocumentoFiscalSelecaoMdfeGrid;

const CHAVE_DE_ACESSO_TAMANHO = 44;

const dataSourceMunicipioConsulta = APIMunicipio.getDataSourceSelectBoxLazy({
  camposRetorno: ["Id", "Descricao", "SiglaUf"],
  camposOrdenacao: [
    {
      nomeCampo: "Descricao",
      desc: false,
    },
  ],
});

export const EditFormDocumentoFiscalAvulso = (
  props: EditFormDocumentoFiscalAvulsoProps
) => {
  const [carregando, setCarregando] = useState(false);

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    chaveAcesso: yup
      .string()
      .required()
      .typeError("O código tem que ser numérico")
      .min(CHAVE_DE_ACESSO_TAMANHO)
      .max(CHAVE_DE_ACESSO_TAMANHO)
      .test(
        "chaveAcesso_necessarioSerCTeNFe",
        "É necessário que a chave de acesso seja de uma NF-e ou CT-e",
        function (value) {
          return (
            tiposNFe.includes(extraiTipoDocumentoChaveAcesso(value)) ||
            tiposCTe.includes(extraiTipoDocumentoChaveAcesso(value))
          );
        }
      )
      .test(
        "chaveAcesso_chaveAcessoNaoDuplicada",
        "O documento fiscal com essa chave de acesso já foi adicionado.",
        function (value) {
          return !store
            .getState()
            .mdfe.documentoAtual.descarregamentos.some(
              (x) =>
                x.documentosVinculados?.some(
                  (y) =>
                    y.documentoFiscal?.chaveAcesso === value &&
                    y.id !== this.parent.id
                ) ?? false
            );
        }
      ),
    numero: yup.string().required().typeError("O código tem que ser numérico"),
    serie: yup.string().required().typeError("O código tem que ser numérico"),
    dataEmissao: yup.string().required(),
    IdEmpresaDestinataria: yup.number().notRequired().nullable().positive(),
    idUfDestino: yup.number().moreThan(0).required().integer(),
    idMunicipioDestino: yup
      .number()
      .required()
      .positive()
      .integer()
      .transform((v) => (v ? v : null)),
    valor: yup.number().moreThan(0).required(),
    pesoLiquido: yup
      .number()
      .notRequired()
      .nullable()
      .positive()
      .transform((v) => (v ? v : null)),
    pesoBruto: yup.number().moreThan(0).required(),
  });

  const hookForm = useHookForms(schema);
  useCarregarRegistro(props.idRegistroEdicao, carregarTela);
  useLimparFormSeIdForNaN(hookForm, novoRegistro, props.idRegistroEdicao);

  const { register, control, handleSubmit, getValues, reset, setValue } =
    hookForm;

  const dispatch = useAppDispatch();

  const handleChaveAcessoChange = (e: any) => {
    const chaveAcesso = e;

    if (chaveAcesso.length >= 22) {
      setValue("serie", chaveAcesso.substring(22, 25));
    }

    if (chaveAcesso.length >= 25) {
      setValue("numero", chaveAcesso.substring(25, 34));
    }
  };

  async function carregarTela() {
    try {
      setCarregando(true);
      const model = props.documentoFiscal ?? novoRegistro;
      reset(model);
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      props.callBackFecharModal(info);
    }
  }

  function exibeMensagemInsercaoDocumentoFiscal(
    model: DocumentoFiscalSelecaoMdfeGrid
  ) {
    exibirNotificacaoToast({
      mensagem: `Documento Nº ${model.numero} adicionado com sucesso. Clique em "Salvar" para confirmar a operação.`,
      tipo: TipoNotificacao.Advertencia,
    });
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues() as DocumentoFiscalSelecaoMdfeGrid;

    dataSourceMunicipioConsulta.filter(["Id", "=", model.idMunicipioDestino]);
    await dataSourceMunicipioConsulta.load();
    const dado = dataSourceMunicipioConsulta.items()[0];

    model.ufDestino = dado.SiglaUf;
    model.municipioDestino = dado.Descricao;

    try {
      if (props.documentoFiscal?.id ?? 0 > 0) {
        dispatch(editarDocumentoFiscal(model));
      } else if (props.documentoFiscal?.chaveAcesso) {
        dispatch(removerDocumentoFiscal(props.documentoFiscal));
        dispatch(adicionarDocumentoFiscal(model));
        exibeMensagemInsercaoDocumentoFiscal(model);
      } else {
        dispatch(adicionarDocumentoFiscal(model));
        exibeMensagemInsercaoDocumentoFiscal(model);
      }

      dispatch(recalcularTotaisNotasMDFe());

      fechar({
        concluido: true,
        precisaAtualizar: true,
      });
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function callBackUnprocessableEntity() {
    fechar({
      concluido: false,
      precisaAtualizar: true,
    });
  }

  async function handleCancelar() {
    fechar({ concluido: false, precisaAtualizar: false });
  }

  return (
    <>
      <div id="edit-form-documento-fiscal">
        {carregando && <PainelCarregamento />}
        <FormBase2
          visivel={props.visivel}
          carregando={carregando}
          onClickSalvar={handleSubmit(handleSalvar)}
          onClickCancelar={handleCancelar}
          configuracoesModal={props.configuracoesModal}
          modoEdicao={props.idRegistroEdicao == 0 ? "criar" : "editar"}
          titulo={NomesModais.documentoAvulso}
        >
          <ProvedorAjuda id="edit-form-documento-fiscal">
            <input type="hidden" {...register("id")} defaultValue={0} />
            <FormGrupo>
              <Linha>
                <Coluna md={8}>
                  <FormTextBox
                    name="chaveAcesso"
                    titulo="Chave de acesso"
                    control={control}
                    requerido
                    tamanhoMaximo={44}
                    onKeyDown={previneDigitacaoDeCaracteresHandler}
                    onChange={handleChaveAcessoChange}
                  />
                </Coluna>
                <Coluna md={2}>
                  <FormTextBox
                    name="numero"
                    titulo="Número"
                    requerido
                    desabilitado
                    control={control}
                    tamanhoMaximo={10}
                    onKeyDown={previneDigitacaoDeCaracteresHandler}
                  />
                </Coluna>
                <Coluna md={2}>
                  <FormTextBox
                    name="serie"
                    titulo="Série"
                    requerido
                    desabilitado
                    control={control}
                    tamanhoMaximo={3}
                    onKeyDown={previneDigitacaoDeCaracteresHandler}
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={3}>
                  <FormDateBox
                    name="dataEmissao"
                    titulo="Emissão"
                    toolTip="Emissão"
                    control={control}
                    requerido
                    exibirBotaoLimpar
                    tipo="date"
                    aceitaValorCustomizado={true}
                    formatoExibicao="dd/MM/yy"
                    aceitaDigitacaoComMascara={true}
                  />
                </Coluna>
                <Coluna md={9}>
                  <ComboClienteEmpresaMxp
                    name="idDestinatario"
                    titulo="Destinatário"
                    control={control}
                  />
                </Coluna>
              </Linha>
              <Linha>
                <SelecaoUfMunicipioDestino
                  control={control}
                  documentoFiscal={props.documentoFiscal}
                />
              </Linha>
              <Linha>
                <Coluna md={3}>
                  <FormNumberBox
                    name="valor"
                    titulo="Valor"
                    control={control}
                    requerido
                    formato={obterFormatStringNumero(2)}
                    maximo={valorTamanhoMaximo}
                  />
                </Coluna>
                <Coluna md={3}>
                  <FormNumberBox
                    name="pesoLiquido"
                    titulo="Peso líquido"
                    control={control}
                    formato={obterFormatStringNumero(4)}
                    maximo={pesoTamanhoMaximo}
                  />
                </Coluna>
                <Coluna md={3}>
                  <FormNumberBox
                    name="pesoBruto"
                    titulo="Peso bruto"
                    toolTip="Peso bruto"
                    control={control}
                    requerido
                    formato={obterFormatStringNumero(4)}
                    maximo={pesoTamanhoMaximo}
                  />
                </Coluna>
              </Linha>
            </FormGrupo>
          </ProvedorAjuda>
        </FormBase2>
      </div>
    </>
  );
};

export default EditFormDocumentoFiscalAvulso;
