import { LoadOptions } from "devextreme/data";
import dxDataGrid from "devextreme/ui/data_grid";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../common/notificacoes-utils";

export class GridController<T> {
  private _ref: () => dxDataGrid | null | undefined;

  constructor(ref: () => dxDataGrid | null | undefined) {
    this._ref = ref;
  }

  public obterSelecao(): T[] {
    return this._ref()?.getSelectedRowsData() ?? [];
  }

  public limparSelecao() {
    this._ref()?.clearSelection();
  }

  public atualizar() {
    this._ref()?.refresh();
  }

  public obterStringFiltros() {
    const loadOptions = this._ref()
      ?.instance()
      ?.getDataSource()
      ?.loadOptions() as LoadOptions<any>;

    if (!loadOptions) {
      return "";
    }

    const obj = {
      ...loadOptions,
      filter: this._ref()?.instance().getCombinedFilter(),
    };

    const loadOptionsUri = Object.entries(obj)
      .filter(
        ([key, val]) => val != null && val != undefined && key != undefined
      )
      .map(([key, val]) => `${key}=${encodeURIComponent(JSON.stringify(val))}`)
      .join("&");

    const loadOptionsSearchParams = new URLSearchParams(
      loadOptionsUri
    ).toString();

    return loadOptionsSearchParams;
  }

  public requerUmRegistrosSelecionado(action: (x: T) => void) {
    const selecao = this.obterSelecao();

    if (selecao.length == 0) {
      exibirNotificacaoToast({
        mensagem: "É necessário selecionar um registro.",
        tipo: TipoNotificacao.Advertencia,
      });
      return;
    }

    if (selecao.length > 1) {
      exibirNotificacaoToast({
        mensagem: "É necessário selecionar apenas um registro.",
        tipo: TipoNotificacao.Advertencia,
      });
      return;
    }

    action(selecao[0]);
  }

  public requerUmOuMaisRegistrosSelecionado(action: (x: T[]) => void) {
    const selecao = this.obterSelecao();

    if (selecao.length == 0) {
      exibirNotificacaoToast({
        mensagem: "É necessário selecionar ao menos um registro.",
        tipo: TipoNotificacao.Advertencia,
      });
      return;
    }

    action(selecao);
  }
}
