import { EnderecoEstoqueGridModel } from "../../../../../models/api/endereco-estoque/endereco-estoque";
import { DataSourceFiltragem } from "../../../../../utils/grid/data-source-factory";

export default class EnderecoEstoqueFiltrosHelpers {
  static readonly FiltrarAtivos: DataSourceFiltragem<EnderecoEstoqueGridModel>[] =
    [
      {
        campo: "ativo",
        operador: "=",
        valor: true,
      },
    ];
}
