import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import criarDatasourceGrid from "../../../../utils/grid/back-end-grid-utils";

export class LancamentoNaParteBDoLalurService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.LancamentoNaParteBDoLalur);
  }

  public GetGridSourceFiltradaPorApuracao(idApuracao: number) {
    return criarDatasourceGrid(
      `${this._nomeEndpoint}/GridFiltradaPorApuracao?idApuracao=${idApuracao}`
    );
  }
}
