import { Export } from "devextreme-react/bar-gauge";
import {
  Column,
  ColumnChooser,
  ColumnChooserSearch,
  ColumnChooserSelection,
  ColumnFixing,
  DataGridRef,
  ExportTexts,
  FilterPanel,
  FilterRow,
  HeaderFilter,
  Item,
  Pager,
  Paging,
  Position,
  Scrolling,
  Selection,
  Sorting,
  StateStoring,
  Toolbar,
} from "devextreme-react/data-grid";
import { useContext } from "react";
import { v4 as uuid } from "uuid";
import ContextoModal from "../../../components/layout/contexto-modal";
import CelulaControleEdicao from "../../../components/templates-celulas-grid/celula-controle-edicao";
import { ColecaoAtalhos } from "../../../utils/atalhos/colecao-atalhos";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../utils/common/notificacoes-utils";
import { ItemContextMenu } from "../../../utils/context-menu/context-menu-utils";
import { exibirConfirmacao } from "../../../utils/dialogos";
import { PropriedadesExportacaoExtendida } from "../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../utils/grid/padroes-colunas";
import "./grid-defaults.scss";

interface GridDefaultsProps {
  gridId: string;
  novoRegistro?: () => void;
  atualizarGrid?: () => void;
  permiteSomenteMaster?: boolean;
  isUsuarioMaster?: boolean;
  isGridInterno?: boolean;
  textoBotaoNovo?: string;
  exportavel?: boolean;
  filtravel?: boolean;
  paginavel?: boolean;
  selecionavel?: boolean;
  ordenavel?: boolean;
  registrarAtalhos?: boolean;
  exibirMenuDeContexto?: boolean;
  gerarContextMenu?: (data: any) => ItemContextMenu[];
  toolbarItensAdicionais?: () => (JSX.Element | undefined)[];
  getGridRef?: () => DataGridRef<any, any>;
  dropDownItems?: Array<any>;
}

const botaoNovoGridPadrao = "Novo";
const botaoNovoGridInterno = "Adicionar";
const sufixoMaster = " [master]";

function dropdownWidth(dropDownItems?: Array<any>) {
  // Calcula a largura com base no texto mais longo
  const maxWidth = dropDownItems
    ? dropDownItems.reduce((max, item) => {
        const itemText = item.text || "";
        return Math.max(max, itemText.length);
      }, 0)
    : 0;

  return `${maxWidth * 7}px`;
}

function calculaLarguraDaColunaDeAcoes(itens: ItemContextMenu[]) {
  return Math.max(
    65,
    10 +
      (itens.filter((x) => x.mostraNaColunaDeAcoes).length +
        (itens.some((x) => !x.mostraNaColunaDeAcoes) ? 1 : 0)) *
        20
  );
}

function definirTextoBotaoNovo(
  textoBotao: string | undefined,
  somenteMaster: boolean,
  isGridInterno: boolean
): string {
  let texto =
    textoBotao || (isGridInterno ? botaoNovoGridInterno : botaoNovoGridPadrao);
  texto += somenteMaster ? sufixoMaster : "";
  return texto;
}

function definirVisibilidadeTipoUsuario(
  permiteSomenteMaster: boolean,
  isUsuarioMaster: boolean
): boolean {
  return !permiteSomenteMaster || (permiteSomenteMaster && isUsuarioMaster);
}

export default function GridDefaults({
  gridId,
  novoRegistro,
  atualizarGrid,
  permiteSomenteMaster = false,
  isUsuarioMaster = false,
  isGridInterno = false,
  textoBotaoNovo,
  exportavel,
  filtravel,
  paginavel,
  selecionavel,
  ordenavel = true,
  gerarContextMenu,
  toolbarItensAdicionais,
  getGridRef,
  dropDownItems,
  registrarAtalhos,
  exibirMenuDeContexto = true,
}: GridDefaultsProps) {
  const PermiteExportarDadosSelecionados: any = {
    allowExportSelectedData: exportavel ?? true,
  } as PropriedadesExportacaoExtendida;

  const iconeSalvarId = `iconeSalvar${gridId}${uuid()}`;
  const idLocalSalvamentoPreferencias = `grid:${gridId}`;

  async function onStateResetClick() {
    if (!getGridRef) {
      return;
    }

    const restaurar = await exibirConfirmacao(
      "Confirmar restauração",
      "Tem certeza de que deseja restaurar os ordenamentos, colunas e filtros da tela para as definições padrões do sistema?"
    );

    if (restaurar) {
      //Remove as preferências antigas e salva as definições padrões pegando o filtro padrão
      localStorage.removeItem(idLocalSalvamentoPreferencias);
      getGridRef().instance().state(null);
      const estadoDaGrid = await getGridRef?.().instance().state();
      estadoDaGrid.filterValue = getGridRef().instance().option("filterValue");

      salvaPreferenciasDaGrid(estadoDaGrid);
    }
  }

  const contextoModal = useContext(ContextoModal);
  if (gerarContextMenu && getGridRef && (registrarAtalhos ?? true)) {
    ColecaoAtalhos.registrarAtalhosGrid(contextoModal, {
      novo: novoRegistro,
      contextMenuItens: gerarContextMenu(undefined),
    });
  }

  async function carregarPreferenciasDaGrid() {
    const preferenciasSalvas = localStorage.getItem(
      idLocalSalvamentoPreferencias
    );
    const state = preferenciasSalvas ? JSON.parse(preferenciasSalvas) : null;

    return state;
  }

  //Função que será chamada ao clicar no botão de salvar as preferências
  async function salvarPreferenciasDaGrid() {
    const estadoDaGrid = await getGridRef?.().instance().state();

    if (
      getClassesIconeSalvarPreferencias()?.contains("mxp-toolbar-ic-enabled")
    ) {
      salvaPreferenciasDaGrid(estadoDaGrid);
    }
  }

  //Função para salvar de fato as preferências da grid
  async function salvaPreferenciasDaGrid(estadoDaGrid: any) {
    if (estadoDaGrid) {
      //As seleções das linhas não serão salvas e um valor padrão [] será inserido
      estadoDaGrid.selectedRowKeys = [];
      localStorage.setItem(
        idLocalSalvamentoPreferencias,
        JSON.stringify(estadoDaGrid)
      );

      deixarIconeSalvarPreferenciasInativo();

      exibirNotificacaoToast({
        mensagem: "Preferências salvas com sucesso",
        tipo: TipoNotificacao.Sucesso,
      });
    } else {
      exibirNotificacaoToast({
        mensagem: "Grade não possui alterações",
        tipo: TipoNotificacao.Informacao,
      });
    }
  }

  async function onStateChange(stateAtual: any) {
    //Compara as preferencias antigas com as novas e verifica se houve alterações
    const preferenciasSalvas = localStorage.getItem(
      idLocalSalvamentoPreferencias
    );
    const novasPreferencias = JSON.stringify(stateAtual);

    if (preferenciasSalvas !== novasPreferencias) {
      deixarIconeSalvarPreferenciasAtivo();
    }
  }

  function getClassesIconeSalvarPreferencias() {
    const iconeSalvar = document.getElementById(iconeSalvarId);

    return iconeSalvar?.classList;
  }

  function deixarIconeSalvarPreferenciasAtivo() {
    getClassesIconeSalvarPreferencias()?.add("mxp-toolbar-ic-enabled");
    getClassesIconeSalvarPreferencias()?.remove("ic-material-disabled");
  }

  function deixarIconeSalvarPreferenciasInativo() {
    getClassesIconeSalvarPreferencias()?.add("ic-material-disabled");
    getClassesIconeSalvarPreferencias()?.remove("mxp-toolbar-ic-enabled");
  }

  const habilitarSelecao: boolean = selecionavel ?? true;
  const novoBotaoText = definirTextoBotaoNovo(
    textoBotaoNovo,
    permiteSomenteMaster,
    isGridInterno
  );

  const defaultsGrid = [
    <StateStoring
      key="StateStoring"
      enabled={true}
      type="custom"
      customLoad={carregarPreferenciasDaGrid}
      customSave={onStateChange}
      savingTimeout={500}
      storageKey={idLocalSalvamentoPreferencias}
    />,
    <Sorting key="SortingPanel" mode={ordenavel ? "multiple" : "none"} />,
    <FilterPanel
      texts={{ clearFilter: "Limpar filtros" }}
      key="FilterPanel"
      visible={filtravel ?? true}
    />,
    <HeaderFilter key="HeaderFilter" visible={filtravel ?? true} />,
    <FilterRow key="FilterRow" visible={filtravel ?? true} />,
    <ColumnChooser key="ColumnChooser" enabled mode="select">
      <Position
        my="right top"
        at="right bottom"
        of=".dx-datagrid-column-chooser-button"
      />

      <ColumnChooserSearch
        enabled={true}
        editorOptions={{ placeholder: "Buscar coluna" }}
      />

      <ColumnChooserSelection selectByClick={true} />
    </ColumnChooser>,
    <Export
      key="Export"
      enabled={exportavel ?? true}
      {...PermiteExportarDadosSelecionados}
      allowExportSelectedData={exportavel ?? true}
    >
      <ExportTexts
        key="ExportTexts"
        exportAll="Exportar todos os dados para o Excel"
        exportSelectedRows="Exportar os dados selecionados para o Excel"
      />
    </Export>,
    <Scrolling useNative={true} key="Scrolling" rowRenderingMode="standard" />,
    <Paging key="Paging" defaultPageSize={50} enabled={paginavel ?? true} />,
    <Selection
      key="Selection"
      mode={habilitarSelecao ? "multiple" : "none"}
      selectAllMode="allPages"
      showCheckBoxesMode="onClick"
    />,
    <Pager
      key="Pager"
      visible={paginavel ?? true}
      allowedPageSizes={[50, 100, 200]}
      displayMode="full"
      showPageSizeSelector
      showInfo
      showNavigationButtons
    />,
    <ColumnFixing key="ColumnFixing" enabled={true} />,
    <Toolbar key="Toolbar">
      <Item
        location="before"
        widget={dropDownItems ? "dxDropDownButton" : "dxButton"}
        visible={
          !!novoRegistro &&
          definirVisibilidadeTipoUsuario(permiteSomenteMaster, isUsuarioMaster)
        }
        options={{
          type: "success",
          stylingMode: "contained",
          icon: "add",
          text: novoBotaoText,
          onClick: dropDownItems ? null : novoRegistro,
          items: dropDownItems,
          useItemTextAsTitle: false,
          dropDownOptions: {
            width: dropdownWidth(dropDownItems),
          },
        }}
      />
      {toolbarItensAdicionais && toolbarItensAdicionais()}
      <Item
        location="before"
        widget="dxButton"
        visible={!!atualizarGrid}
        options={{
          icon: "refresh",
          hint: "Recarregar dados da grid",
          onClick: atualizarGrid,
        }}
      />
      <Item name="exportButton" locateInMenu="auto" location="before" />
      <Item name="columnChooserButton" locateInMenu="auto" location="after" />
      <Item
        location="after"
        widget="dxButton"
        visible={getGridRef != undefined && (filtravel ?? true)}
        options={{
          icon: "ic-material-symbols-outlined ic-save",
          hint: "Salvar as alterações de exibição da tela",
          onClick: salvarPreferenciasDaGrid,
          elementAttr: { id: iconeSalvarId, class: "ic-material-disabled" },
        }}
      />
      <Item
        location="after"
        widget="dxButton"
        visible={getGridRef != undefined && (filtravel ?? true)}
        options={{
          icon: "ic-material-symbols-outlined ic-restore-page",
          hint: "Restaurar tela para as definições padrões",
          onClick: onStateResetClick,
        }}
      />
    </Toolbar>,
    <Column
      key="ColunaDeEspaco"
      width="100%"
      name="colunaDeEspaco"
      cssClass="coluna-de-espaco"
      visibleIndex={1000}
      allowReordering={false}
      showInColumnChooser={false}
      allowHiding={false}
      allowResizing={false}
      allowSorting={false}
      allowSearch={false}
      allowFixing={false}
      fixedPosition="right"
    />,
    gerarContextMenu && exibirMenuDeContexto && (
      <Column
        key="ColunaDeAcoes"
        name="acoes"
        {...obterConfiguracaoColuna("acoes")}
        minWidth={calculaLarguraDaColunaDeAcoes(gerarContextMenu(undefined))}
        cellRender={({ data }) => {
          return <CelulaControleEdicao itens={gerarContextMenu(data)} />;
        }}
      />
    ),
  ];

  return defaultsGrid;
}
