import type SelectItem from "../../../../models/shared/ui/select-item";
import {
  gerarLookupDoDicionario,
  gerarSelectItemDoDicionario,
} from "../../../../utils/common/enum-utils";

export enum EstadoPedidoDeVenda {
  Digitacao = 0,
  Cancelado = 2,
  Aaprovar = 5,
  Aprovado = 6,
  Faturado = 7,
  FaturadoEntregaFutura = 8,
}

// Classe que encapsula o enum e suas descrições
export class EstadoPedidoDeVendaHelper {
  public static readonly descricoesEstadoPedidoDeVenda = Object.freeze({
    [EstadoPedidoDeVenda.Digitacao]: "Digitação",
    [EstadoPedidoDeVenda.Cancelado]: "Cancelado",
    [EstadoPedidoDeVenda.Aaprovar]: "A aprovar",
    [EstadoPedidoDeVenda.Aprovado]: "Aprovado",
    [EstadoPedidoDeVenda.Faturado]: "Faturado",
    [EstadoPedidoDeVenda.FaturadoEntregaFutura]: "Faturado com entrega futura",
  });

  public static getDescricao(valor: EstadoPedidoDeVenda): string {
    return this.descricoesEstadoPedidoDeVenda[valor] || "Estado desconhecido.";
  }

  public static asSelectItems(): SelectItem[] {
    return gerarSelectItemDoDicionario(this.descricoesEstadoPedidoDeVenda);
  }

  public static asLookup() {
    return gerarLookupDoDicionario(this.descricoesEstadoPedidoDeVenda);
  }
}
