import SelectItem from "../../../../../models/shared/ui/select-item";
import { gerarSelectItemDoDicionario } from "../../../../../utils/common/enum-utils";

export enum RetiradaDeMaterialValorTipo {
  CadastroDoItem = 1, // "Cadastrado no item"
  Manual = 2, // "Manual"
  MediaDasBaixas = 3, // "Média das baixas de insumo"
}

export class RetiradaDeMaterialValorTipoHelper {
  public static tipoDescricao: {
    [key in RetiradaDeMaterialValorTipo]: string;
  } = {
    [RetiradaDeMaterialValorTipo.CadastroDoItem]: "Cadastrado no item",
    [RetiradaDeMaterialValorTipo.Manual]: "Manual",
    [RetiradaDeMaterialValorTipo.MediaDasBaixas]: "Média das baixas de insumo",
  };

  public static getDescricao(tipo: RetiradaDeMaterialValorTipo): string {
    return this.tipoDescricao[tipo] || "Descrição desconhecida";
  }

  public static AsSelectItems(): SelectItem[] {
    return gerarSelectItemDoDicionario(this.tipoDescricao);
  }
}
