import AuditavelDTO from "../../../models/api/comum/auditavel-dto";
import { formatarDataHora } from "../../../utils/formatadores/formatador-de-datas";
import { AcaoContainer, RodapeAuditoriaContainer } from "./styles";

interface RodapeAuditoriaProps {
  auditoria: AuditavelDTO;
}

export default function RodapeAuditoria({ auditoria }: RodapeAuditoriaProps) {
  return (
    <RodapeAuditoriaContainer>
      {auditoria.criacaoData && (
        <AcaoContainer>
          Criado em {formatarDataHora(auditoria.criacaoData)} por{" "}
          {auditoria.criacaoUsuarioApelido}.
        </AcaoContainer>
      )}
      {auditoria.ultimaAlteracaoData && (
        <AcaoContainer>
          Alterado em {formatarDataHora(auditoria.ultimaAlteracaoData)} por{" "}
          {auditoria.alteracaoUsuarioApelido}.
        </AcaoContainer>
      )}
    </RodapeAuditoriaContainer>
  );
}
