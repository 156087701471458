import SelectItem from "../../../../models/shared/ui/select-item";
import { gerarSelectItemDoDicionario } from "../../../../utils/common/enum-utils";

export enum TipoTributoContaParteB {
  IRPJ = 1,
  CSLL = 2,
}

export const tipoTributoContaParteBDecodificado: {
  [key in TipoTributoContaParteB]: string;
} = {
  [TipoTributoContaParteB.IRPJ]: "IRPJ",
  [TipoTributoContaParteB.CSLL]: "CSLL",
};

export const SelectItemEnumTipoTributoContaParteB: SelectItem[] =
  gerarSelectItemDoDicionario(tipoTributoContaParteBDecodificado);
