import SelectItem from "../../../../models/shared/ui/select-item";

export enum TipoCentroDeTrabalho {
  SemEstacoes = 72,
  ComEstacoes = 77,
  Externo = 69,
}

export enum TipoSequenciamento {
  Livre = 0,
  Fixo = 1,
}

export class TipoCentroDeTrabalhoHelper {
  public static tipoDescricao: {
    [key in TipoCentroDeTrabalho]: string;
  } = {
    [TipoCentroDeTrabalho.SemEstacoes]: "Sem estações",
    [TipoCentroDeTrabalho.ComEstacoes]: "Com estações",
    [TipoCentroDeTrabalho.Externo]: "Externo",
  };

  public static getDescricao(tipo: TipoCentroDeTrabalho): string {
    return (
      TipoCentroDeTrabalhoHelper.tipoDescricao[tipo] || "Descrição desconhecida"
    );
  }

  public static tipoCentroDeTrabalhoAsSelectItem: SelectItem[] = Object.keys(
    TipoCentroDeTrabalho
  )
    .map((x) => parseInt(x))
    .filter((x) => !isNaN(x))
    .map((valor) => ({
      valor,
      descricao: this.getDescricao(valor as TipoCentroDeTrabalho),
    }));
}

export class TipoSequenciamentoHelper {
  public static tipoDescricao: {
    [key in TipoSequenciamento]: string;
  } = {
    [TipoSequenciamento.Livre]: "Livre",
    [TipoSequenciamento.Fixo]: "Fixo",
  };

  public static getDescricao(tipo: TipoSequenciamento): string {
    return (
      TipoSequenciamentoHelper.tipoDescricao[tipo] || "Descrição desconhecida"
    );
  }

  public static tipoSequenciamentoAsSelectItem: SelectItem[] = Object.keys(
    TipoSequenciamento
  )
    .map((x) => parseInt(x))
    .filter((x) => !isNaN(x))
    .map((valor) => ({
      valor,
      descricao: this.getDescricao(valor as TipoSequenciamento),
    }));
}
