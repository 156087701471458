import PaginaMxp from "../../../../../../components/layout/pagina-mxp";
import ProvedorMenus from "../../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../../components/seguranca/requer-permissao";
import { PermissoesEfdReinf } from "../../../../../../models/permissoes/fiscal/efd-reinf/permissoes-efd-reinf";
import NomesTelas from "../../../../../../utils/common/nomes-telas";
import GridEfdReinf4040 from "../../../componentes/grid/efd-reinf-4040";

export default function EfdReinf4040Page() {
  return (
    <ProvedorMenus>
      <PaginaMxp
        id="efd-reinf-4040-page"
        titulo={NomesTelas.efdReinf4040}
        area={["Fiscal"]}
      >
        <RequerPermissao codigoPermissoes={[PermissoesEfdReinf.Consultar]}>
          <GridEfdReinf4040 />
        </RequerPermissao>
      </PaginaMxp>
    </ProvedorMenus>
  );
}
