import { Container, Background } from "./styles";

interface CardCentralizadoProps {
  children: React.ReactNode;
}

export default function LoginBg({ children }: CardCentralizadoProps) {
  return (
    <Background>
      <Container>{children}</Container>
    </Background>
  );
}
