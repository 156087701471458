import TabContainer from "../../../../../../../components/layout/tab-container";
import GridEmbutidaTarefasEAnotacoes from "../../../grid-embutida/tarefas-e-anotacoes";

interface OrdemDeProducaoAbaTarefasEAnotacoesProps {
  ordemDeProducaoId: number;
}

export default function OrdemDeProducaoAbaTarefasEAnotacoes(
  props: OrdemDeProducaoAbaTarefasEAnotacoesProps
) {
  return (
    <TabContainer>
      <GridEmbutidaTarefasEAnotacoes {...props} />
    </TabContainer>
  );
}
