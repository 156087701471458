import { PermissoesOrdemDeProducao } from "../../../../../models/permissoes/producao/ordem-de-producao/OrdemDeProducaoPermissoes";
import { tratarErroApi } from "../../../../../utils/api/api-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import { OrdemDeProducaoGridModel } from "../../models/ordem-de-producao.api";
import { OrdemDeProducaoService } from "../../servicos/ordem-de-producao.service";
import {
  AcoesOrdensDeProducao,
  handlerAcaoOrdensDeProducaoResponse,
} from "./acoes-ordem-de-producao-helper";

async function cancelarOrdensDeProducaoHandler(
  service: OrdemDeProducaoService,
  registros: OrdemDeProducaoGridModel[],
  handleAtualizarGrid: () => void
) {
  if (
    !verificaComNotificacaoSeUsuarioPossuiPermissoes([
      PermissoesOrdemDeProducao.InserirEditar,
    ])
  ) {
    return;
  }

  const ops =
    registros.length > 1
      ? "as ordens de produção selecionadas"
      : `a ordem de produção ${registros[0].numero}`;
  const confimar = await exibirConfirmacao(
    "Confirmar cancelamento",
    `Tem certeza de que deseja cancelar ${ops}? Ao cancelar, as reservas existentes para essas ordens de produção serão excluídas.
          <br>Deseja continuar?`
  );

  if (!confimar) {
    return;
  }

  try {
    const response = await service.CancelarOrdensDeProducao(
      registros.map((i) => i.id)
    );

    handleAtualizarGrid();

    const acoes: AcoesOrdensDeProducao = {
      acao: "cancelar",
      acaoEstadoDasOps: "canceladas",
    };
    return handlerAcaoOrdensDeProducaoResponse(response, acoes);
  } catch (erro) {
    tratarErroApi(erro);
  }
}

export { cancelarOrdensDeProducaoHandler };
