import PaginaMxp from "../../../../../components/layout/pagina-mxp";
import ProvedorMenus from "../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../components/seguranca/requer-permissao";
import { PermissoesConjuntoVeiculo } from "../../../../../models/permissoes/vendas/conjunto-veiculo/permissoes-conjunto-veiculo";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import GridConjuntoVeiculo from "../../componentes/grid";

export default function ConjuntoVeiculoPage() {
  return (
    <ProvedorMenus>
      <PaginaMxp
        id="conjunto-veiculo-page"
        titulo={NomesTelas.conjuntoVeiculos}
        area={["Fiscal", "Cadastros"]}
      >
        <RequerPermissao
          codigoPermissoes={[PermissoesConjuntoVeiculo.Consultar]}
        >
          <GridConjuntoVeiculo />
        </RequerPermissao>
      </PaginaMxp>
    </ProvedorMenus>
  );
}
