import { DataGridRef } from "devextreme-react/cjs/data-grid";
import { useContext, useEffect } from "react";
import { ContextoSeletor } from "../components/selecao/contexto-seletor";

export function useSeletorGridMxp(
  func: () => DataGridRef | null | undefined,
  filtroPadraoNoCliente?: any
) {
  const { setRefGrid, setFiltroPadraoNoCliente } = useContext(ContextoSeletor);

  useEffect(() => {
    setRefGrid(func);
    setFiltroPadraoNoCliente(filtroPadraoNoCliente);
  }, []);
}
