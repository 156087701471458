import { useParams } from "react-router-dom";

export function useParametroId() {
  const param = useParams();

  if (!param["id"]) {
    return undefined;
  }

  return Number(param["id"]);
}
