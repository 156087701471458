import styled from "styled-components";

export const BotaoLadoDireitoInputStyle = styled.a`
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
  display: inline-flex;
  padding: 1px;
  color: black !important;

  &:hover {
    background: lightgray;
  }
`;
