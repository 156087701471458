import SelectItem from "../../../../models/shared/ui/select-item";
import { gerarSelectItemDoDicionario } from "../../../../utils/common/enum-utils";

export enum TipoDocumentoEletronico {
  Nenhum = 0,
  NFe = 1,
  NFSe = 2,
  NFCe = 3,
  ConformeOperacaoFiscal = 4,
  MDFe = 5,
}

export const tipoDocumentoEletronicoDecodificado: {
  [key in TipoDocumentoEletronico]: string;
} = {
  [TipoDocumentoEletronico.Nenhum]: "Nenhum",
  [TipoDocumentoEletronico.NFe]: "NF-e",
  [TipoDocumentoEletronico.NFSe]: "NFS-e",
  [TipoDocumentoEletronico.NFCe]: "NFC-e",
  [TipoDocumentoEletronico.ConformeOperacaoFiscal]: "Conforme operação fiscal",
  [TipoDocumentoEletronico.MDFe]: "MDF-e",
};

export const SelectItemEnumTipoDocumentoEletronico: SelectItem[] =
  gerarSelectItemDoDicionario(tipoDocumentoEletronicoDecodificado);
