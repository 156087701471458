export default class UrlUtils {
  public static RemoverParametroId(id?: number) {
    const matches = location.pathname.match(/[^/]+$/);

    if (Number(matches?.[0]) == id) {
      window.history.replaceState(
        null,
        "",
        location.pathname.substring(0, location.pathname.lastIndexOf("/"))
      );
    }
  }
}
