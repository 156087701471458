import dxPopup from "devextreme/ui/popup";

type ItemPilha = { key: string; popup: dxPopup };

export class PilhaModais {
  private static pilha: ItemPilha[] = [];

  static adicionar(key: string, popup: dxPopup) {
    PilhaModais.pilha.push({ key, popup });
    this.focarTopo();
  }

  static remover(key: string) {
    const index = PilhaModais.pilha.findIndex((item) => item.key === key);
    if (index > -1) {
      PilhaModais.pilha.splice(index, 1);
      this.focarTopo();
    }
  }

  static focarTopo() {
    if (PilhaModais.pilha.length > 0) {
      PilhaModais.pilha[this.pilha.length - 1].popup.focus();
    }
  }

  static obterIdTopo() {
    if (PilhaModais.pilha.length > 0) {
      return PilhaModais.pilha[this.pilha.length - 1].key;
    }

    return "";
  }
}
