import { ReactNode } from "react";
import { flushSync } from "react-dom";
import { createRoot } from "react-dom/client";

const div = document.createElement("div");
const root = createRoot(div);

/**
 * Uma função que renderiza um elemento do react para o seu HTML.
 * **ATENÇÃO:** Utilizar isso ao invés do {@link renderToString}.
 * Motivo: https://react.dev/reference/react-dom/server/renderToString#removing-rendertostring-from-the-client-code
 * @param elem
 * @returns
 */
export function renderToStringClient(elem: ReactNode) {
  flushSync(() => {
    root.render(elem);
  });
  return div.innerHTML;
}
