import PaginaMxp from "../../../../../components/layout/pagina-mxp";
import ProvedorMenus from "../../../../../components/menus/provedor-menus";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import FiltrosGridCfop from "../../../../../utils/filtros-grid/fiscal/cfop/filtros-grid-cfop";
import GridCFOP from "../../componentes/grid/index";

export default function CfopPage() {
  return (
    <ProvedorMenus>
      <PaginaMxp
        id="cfop-page"
        titulo={NomesTelas.cfop}
        area={["Fiscal", "Cadastros"]}
      >
        <GridCFOP filtrosNoCliente={FiltrosGridCfop.cfopAtivoETipoNacional} />
      </PaginaMxp>
    </ProvedorMenus>
  );
}
