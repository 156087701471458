import { Button } from "devextreme-react";

interface BotaoSalvarMxpProps {
  handleClick: () => void;
}

export default function BotaoSalvarMxp(props: BotaoSalvarMxpProps) {
  return (
    <Button
      type="success"
      text={"Salvar"}
      icon={"save"}
      onClick={props.handleClick}
    />
  );
}
