import DataSource from "devextreme/data/data_source";
import {
  assertConfiguracaoExibicaoEBuscaType,
  ConfiguracaoExibicaoEBusca,
} from "../../../../components/formularios/selectbox-lazy-mxp";
import { EstoqueGridModel } from "../../../../models/api/estoque/estoque";
import { DataSourceFiltragem } from "../../../../utils/grid/data-source-factory";
import { NomesEndpoints } from "../../../comum/nomesEndpoints";
import { ServiceBase } from "../../../comum/serviceBase";

export class EstoqueService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.Estoque);
  }

  public GetDadosSelectBoxEstoque(
    filtros: DataSourceFiltragem<EstoqueGridModel>[] | undefined = undefined
  ): [
    DataSource<EstoqueGridModel, any>,
    ConfiguracaoExibicaoEBusca<EstoqueGridModel>
  ] {
    const dataSource = this.ObterDataSourceParaSelectBoxLazy<EstoqueGridModel>({
      camposRetorno: [
        "id",
        "codigoItem",
        "estoque",
        "idItem",
        "loteFabricanteNumeroSerie",
        "enderecoDeEstoque",
      ],
      camposOrdenacao: [{ campo: "codigoItem", desc: false }],
      camposFiltro: filtros,
    });

    const configs = assertConfiguracaoExibicaoEBuscaType<EstoqueGridModel>({
      nomeCampoChave: "id",
      expressaoDeBusca: ["codigoItem", "loteFabricanteNumeroSerie"],
      nomeCampoExibicao: (c) => {
        if (c) {
          const loteOuNumeroDeSerie = c.loteFabricanteNumeroSerie
            ? `- ${c.loteFabricanteNumeroSerie}`
            : "";

          const codigoEnderecoDeEstoque = c.enderecoDeEstoque
            ? `- ${c.enderecoDeEstoque}`
            : "";

          return `${c.codigoItem} ${loteOuNumeroDeSerie} ${codigoEnderecoDeEstoque} - ${c.estoque}`;
        }

        return "";
      },
    });

    return [dataSource, configs];
  }
}
