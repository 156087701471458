import TabContainer from "../../../../../../../components/layout/tab-container";
import ProvedorMenus from "../../../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../../../components/seguranca/requer-permissao";
import { PermissoesEstoqueMovimentacao } from "../../../../../../../models/direitos/estoque/estoque-movimentacao/permissoes-estoque-movimentacao";
import GridEmbutidaRetiradaDeMaterial from "../../../../../../estoque/estoque-movimentacao/componentes/grids/grid-retirada-de-material";
import { OrdemDeProducaoEstado } from "../../../../utils/enums/ordem-de-producao-enums";

interface OrdemDeProducaoAbaRetiradaDeMaterialProps {
  ordemDeProducaoId: number;
  ordemDeProducaoEstado?: OrdemDeProducaoEstado;
}

export default function OrdemDeProducaoAbaRetiradaDeMaterial(
  props: OrdemDeProducaoAbaRetiradaDeMaterialProps
) {
  return (
    <TabContainer>
      <ProvedorMenus>
        <RequerPermissao
          codigoPermissoes={[PermissoesEstoqueMovimentacao.Consultar]}
        >
          <GridEmbutidaRetiradaDeMaterial
            {...props}
            style={{ height: "100%" }}
          />
        </RequerPermissao>
      </ProvedorMenus>
    </TabContainer>
  );
}
