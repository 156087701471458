export default class NomesTelas {
  static centrosDeTrabalho = "Centros de trabalho";

  static centrosCusto = "Centros de custos";

  static calendarioCTs = "Calendario do Grupo de CTs";

  static gruposCentrosTrabalho = "Grupos de centros de trabalho";

  static ordensProducao = "Ordens de produção";

  static prefixoESufixoDeLote = "Prefixo e sufixo de lotes e números de série";

  static usuarios = "Usuários";

  static codigosTributacaoServicos = "Códigos de tributação de serviços";

  static conjuntoVeiculos = "Conjunto de veículos";

  static veiculos = "Veículos";

  static mdfe = "MDF-e";

  static vinculosDoCentroDeTrabalhoComEnderecosDeEstoque =
    "Vínculos entre centros de trabalho e endereços de estoque";

  static vinculosEnderecoEstoque = "Vínculo com endereços de estoque";

  static enderecoEstoque = "Endereços de estoque";

  static empresas = "Empresas";

  static cfop = "CFOPs";

  static seriesDeDocumentosFiscais = "Séries de documentos fiscais";

  static serieDeDocumentosFiscais = "Série de documentos fiscais";

  static linkAjudaPaginaMaster = "Link de ajuda da página [master]";

  static notasFiscais = "Notas Fiscais";

  static condutores = "Condutores";

  static certificadoDigital = "Configuração do certificado digital";

  static lotes = "Lotes";

  static itens = "Itens";

  static item = "Item";

  static naturezaDoRendimento = "Naturezas do rendimento";

  static usuariosLogados = "Usuários logados";

  static balanceteComparativo = "Balancete comparativo";

  static efdReinfApuracao = "Apuração do EFD Reinf";

  static efdReinf2010 =
    "R-2010 - Retenção contribuição previdenciária (INSS) - Tomadores de serviços";

  static efdReinf2020 =
    "R-2020 - Retenção contribuição previdenciária (INSS) - Prestadores de serviços";

  static efdReinf4080 = "R-4080 - Retenção no recebimento";

  static efdReinf4020 =
    "R-4020 - Pagamentos/créditos a beneficiário pessoa jurídica";

  static efdReinf4010 =
    "R-4010 - Pagamentos/créditos a beneficiário pessoa física";

  static efdReinf4040 =
    "R-4040 - Pagamentos/créditos a beneficiários não identificados";

  static ecf = "ECF";

  static contaDaParteB = "Conta da parte B do Lucro Real";

  static lancancamentoDaParteB = "Lançamento da parte B";

  static apuracaoLacsCsll = "Apuração do LACS e CSLL do Lucro Real";

  static apuracaoLalurIrpj = "Apuração do LALUR e IRPJ do Lucro Real";

  static informativoComposicaoCustosLucroReal =
    "Informativo da composição de custos do Lucro Real";

  static adicaoExclusaoCompensacaoLalurIrpj =
    "Adição, exclusão, compensação do LALUR e do IRPJ";

  static adicaoExclusaoCompensacaoLacsCsll =
    "Adição, exclusão, compensação do LACS e da CSLL";

  static informativoDaComposicaoDeCustosDoLucroReal =
    "Informativo da composição de custos do Lucro Real";

  static insumosDasOrdensDeProducao = "Insumos das ordens de produção";

  static estoque = "Estoque";

  static estoqueMovimentacao = "Estoque movimentação";

  static imobilizado = "Imobilizado";

  static contaContabil = "Conta contábil";

  static apuracaoDoCiap = "CIAP";

  static retiradaDeMaterial = "Retirada de material";

  static conclusoesOrdemDeProducao = "Conclusões";

  static operacoesDeOrdensDeProducao = "Operações de ordens de produção";

  static estacaoDeTrabalho = "Estação de trabalho";

  static estadoConfiguravelDaOrdemDeProducao =
    "Estados configuráveis de ordem de produção";

  static pedidosDeVenda = "Pedidos de venda";
}
