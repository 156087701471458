import { PropsWithChildren, useState } from "react";
import ContextoOperacoesInsumo, {
  FuncoesColunaQuantidadeABaixarInsumo,
  ReferenciasGridsSelecaoInsumo,
} from "../contexto-funcoes-insumo";

export function ProvedorInsumo(props: PropsWithChildren<any>) {
  const [funcoesColunaQuantidadeABaixarInsumo] =
    useState<FuncoesColunaQuantidadeABaixarInsumo>(
      new FuncoesColunaQuantidadeABaixarInsumo()
    );

  const [referenciasGridInsumo] = useState<ReferenciasGridsSelecaoInsumo>(
    new ReferenciasGridsSelecaoInsumo()
  );
  return (
    <ContextoOperacoesInsumo.Provider
      value={{
        funcoes: funcoesColunaQuantidadeABaixarInsumo,
        referenciasGridInsumos: referenciasGridInsumo,
      }}
    >
      {props.children}
    </ContextoOperacoesInsumo.Provider>
  );
}
