import PaginaMxp from "../../../../../../components/layout/pagina-mxp";
import ProvedorMenus from "../../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../../components/seguranca/requer-permissao";
import { PermissoesEfdReinfApuracao } from "../../../../../../models/permissoes/fiscal/efd-reinf/permissoes-apuracao-efd-reinf";
import NomesTelas from "../../../../../../utils/common/nomes-telas";
import GridEfdReinfApuracao from "../../../componentes/grid/apuracao";

export default function EfdReinfApuracaoPage() {
  return (
    <ProvedorMenus>
      <PaginaMxp
        id="efd-reinf-apuracao-page"
        titulo={NomesTelas.efdReinfApuracao}
        area={["Fiscal", "EFD REINF"]}
      >
        <RequerPermissao
          codigoPermissoes={[PermissoesEfdReinfApuracao.Consultar]}
        >
          <GridEfdReinfApuracao />
        </RequerPermissao>
      </PaginaMxp>
    </ProvedorMenus>
  );
}
