import { PropsWithChildren, useCallback, useState } from "react";
import { ItemContextMenuMxp } from "../../../utils/context-menu/context-menu-utils";
import ContextoMenuEdicaoOrdemDeProducao, {
  ItensMenuEdicaoOrdemDeProducao,
} from "../contexto-menu-op";

export function ProvedorMenuEdicaoOrdemDeProducao(
  props: PropsWithChildren<any>
) {
  const [menu, setMenu] = useState<ItensMenuEdicaoOrdemDeProducao>(
    new ItensMenuEdicaoOrdemDeProducao()
  );

  const addItensMenu = useCallback((itens: ItemContextMenuMxp[]) => {
    setMenu((menu) => {
      // Remover dos itens existentes os itens que vamos adicionar
      const itensParaManter = menu.itens.filter(
        (itemExistente) =>
          !itens.some((item) => item.name === itemExistente.name)
      );

      // Combinar os itens a manter e os novos.
      const itensFinais = [...itensParaManter, ...itens];
      return { itens: itensFinais };
    });
  }, []);

  return (
    <ContextoMenuEdicaoOrdemDeProducao.Provider
      value={{
        menu,
        addItensMenu,
      }}
    >
      {props.children}
    </ContextoMenuEdicaoOrdemDeProducao.Provider>
  );
}
