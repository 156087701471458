import { GridComponentSimples } from "../../../../../components/componentes-simples/grid-simples";
import {
  ConfiguracoesErros,
  TipoErro,
} from "../../../../../models/api/comum/erros";
import { ErroOrdemDeProducao } from "./ordem-de-producao.erros";

export const configuracoesErrosOrdemDeProducao = Object.freeze<
  ConfiguracoesErros<ErroOrdemDeProducao>
>({
  "OP-0001": {
    tipo: TipoErro.Confirmacao,
    titulo: "Não foi possível concluir a ordem de produção",
    formatador({ dadosExtras }) {
      return (
        <div>
          <p>
            Existem insumos baixados com quantidade proporcionalmente
            insuficiente para a quantidade que será concluída.
          </p>
          {dadosExtras && dadosExtras.length > 0 && (
            <GridComponentSimples
              dados={dadosExtras}
              nomesColunas={[
                "Código",
                "Descrição",
                "Quantidade baixada",
                "Quantidade necessária",
              ]}
              colunasParaIgnorar={["itemId"]}
            />
          )}

          <p>Tem certeza de que deseja prosseguir?</p>
        </div>
      );
    },
  },
  "OP-0002": {
    tipo: TipoErro.Confirmacao,
    formatador() {
      return (
        <div>
          <p>
            Não é possível fechar a ordem de produção sem concluir todas as
            baixas de insumos.
          </p>
          <p>
            Deseja considerar o estado dos insumos remanescentes como
            &quot;Baixado&quot;?
          </p>
          <p>Esta operação não gera movimentações dos insumos restantes.</p>
        </div>
      );
    },
  },

  "OP-0003": {
    tipo: TipoErro.Confirmacao,
    formatador() {
      return (
        <div>
          <p>Quantidade a concluir maior que a quantidade prevista.</p>
          <p>Deseja forçar a conclusão da ordem de produção?</p>
        </div>
      );
    },
  },
});
