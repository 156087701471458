import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import { FormGrupo, FormTextBox } from "../../../../../components/formularios";
import FormMxp from "../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import {
  IFormularioBase,
  ResultadoAcaoFormulario,
} from "../../../../../models/shared/ui/formularios";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { RecuperarSenha } from "../../models/login.api";
import LoginConstantes from "../../models/login.constantes";
import ControleAcessoService from "../../servicos/controle-acesso.servico";
import BotaoRecuperarMinhaSenha from "./botao-recupar-minha-senha";

interface ModalRecuperacaoSenhaProps extends IFormularioBase {
  email: string;
  visivel: boolean;
}

const controleAcessoService = new ControleAcessoService();

export default function ModalRecuperacaoSenha(
  props: ModalRecuperacaoSenhaProps
) {
  const [carregando, setCarregando] = useState(false);

  const schema = yup.object().shape({
    usuarioEmail: yup
      .string()
      .email()
      .required()
      .max(LoginConstantes.EmailDeRecuperacaoTamanhoMaximo),
  });

  const { getValues, control, handleSubmit, setValue } =
    useForm<RecuperarSenha>({ resolver: yupResolver(schema) });

  useEffect(() => {
    setValue("usuarioEmail", props.email);
  }, [props.email]);

  function fechar(resultado: ResultadoAcaoFormulario) {
    if (props.handleCallback) {
      props.handleCallback(resultado);
    }
  }

  async function handleRecuperar() {
    setCarregando(true);
    const model = getValues();
    try {
      const resposta = await controleAcessoService.enviarEmailRecuperacaoSenha(
        model.usuarioEmail
      );
      checarResponse(resposta);
      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  function handleEnter() {
    document.activeElement?.dispatchEvent(new Event("blur"));
    form?.requestSubmit();
  }

  let form: HTMLFormElement | null;
  return (
    <ModalMxp
      titulo={"Esqueci minha senha"}
      visivel={props.visivel}
      handleFechar={handleCancelar}
      largura={"30rem"}
    >
      <ContainerFormMxp>
        <FormMxp carregando={carregando}>
          <FormGrupo>
            <Linha>
              <Coluna md={12}>
                <FormTextBox
                  name={"usuarioEmail"}
                  titulo="E-mail"
                  control={control}
                  requerido
                  tamanhoMaximo={
                    LoginConstantes.EmailDeRecuperacaoTamanhoMaximo
                  }
                  onEnter={handleEnter}
                  tipo="email"
                />
              </Coluna>
            </Linha>
            <Linha style={{ marginTop: "5px" }}>
              <Coluna md={12}>
                <span>
                  Um e-mail com instruções será enviado para o seu e-mail.
                </span>
              </Coluna>
            </Linha>
          </FormGrupo>
        </FormMxp>
        <ToolbarMxp>
          <BotaoRecuperarMinhaSenha
            handleClick={handleSubmit(handleRecuperar)}
          />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </ModalMxp>
  );
}
