import { Column, IColumnProps } from "devextreme-react/data-grid";
import obterConfiguracaoColuna from "../../../utils/grid/padroes-colunas";
import "./grid-defaults.scss";

export type OrdenacaoAuditoria = {
  ordenarPor:
    | "criacaoData"
    | "criacaoUsuarioApelido"
    | "ultimaAlteracaoData"
    | "ultimaAlteracaoUsuarioApelido";
  ordem: "asc" | "desc";
};

/**
 * @params {OrdenacaoAuditoria[]}: ordenacao - opções de ordenação pelas colunas
 * de auditoria. O índice desse array será o índice usado na ordenação.
 *  */
export default function GetColunasDeAuditoria(
  exibeCriacao?: boolean,
  exibeAlteracao?: boolean,
  ordenacao?: OrdenacaoAuditoria[]
) {
  const definirOrdenacao = (key: string): IColumnProps => {
    if (ordenacao === undefined) {
      return {};
    }

    const index = ordenacao?.findIndex((x) => x.ordenarPor === key);
    if (index === -1 || index === undefined) {
      return {};
    }

    return {
      sortOrder: ordenacao[index].ordem,
      sortIndex: index,
    };
  };

  const defaultsGrid = [
    <Column
      key="criacaoData"
      dataField="criacaoData"
      {...obterConfiguracaoColuna("criadoEm")}
      visible={!!exibeCriacao}
      {...definirOrdenacao("criacaoData")}
    />,
    <Column
      key="criacaoUsuarioApelido"
      dataField="criacaoUsuarioApelido"
      {...obterConfiguracaoColuna("criadoPor")}
      visible={!!exibeCriacao}
      {...definirOrdenacao("criacaoUsuarioApelido")}
    />,
    <Column
      key="ultimaAlteracaoData"
      dataField="ultimaAlteracaoData"
      {...obterConfiguracaoColuna("alteradoEm")}
      visible={!!exibeAlteracao}
      {...definirOrdenacao("ultimaAlteracaoData")}
    />,
    <Column
      key="ultimaAlteracaoUsuarioApelido"
      dataField="ultimaAlteracaoUsuarioApelido"
      {...obterConfiguracaoColuna("alteradoPor")}
      visible={!!exibeAlteracao}
      {...definirOrdenacao("ultimaAlteracaoUsuarioApelido")}
    />,
  ];

  return defaultsGrid;
}
