import { FieldValues } from "react-hook-form";
import styled from "styled-components";

export type GridComponentSimplesProps<T extends FieldValues> = {
  dados: T[];
  nomesColunas?: string[];
  colunasParaIgnorar?: Array<keyof T>; // Array de chaves de T
};

const DivTabela = styled.div`
  max-height: 400px; /* Defina a altura máxima que você deseja para a tabela */
  overflow-y: auto; /* Permite rolagem vertical */
  border-radius: 5px;
`;

const Tabela = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse; /* Remove o espaço entre as bordas das células */
`;

const CabecalhoTabela = styled.thead`
  background: rgba(0, 0, 0, 0.05);
  font-weight: bold;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
  position: sticky; /* Fixa o cabeçalho */
  top: 0; /* Posiciona o cabeçalho no topo do contêiner */
  z-index: 1; /* Garante que o cabeçalho fique sobre as linhas */
`;

export function GridComponentSimples<T extends FieldValues>({
  dados,
  nomesColunas,
  colunasParaIgnorar = [],
}: GridComponentSimplesProps<T>) {
  // Verifica se o array está vazio
  if (!dados || dados.length === 0) {
    return <p>Nenhum dado disponível.</p>;
  }

  // Obtém as chaves do primeiro objeto para usar como cabeçalhos
  const camposBase: Array<keyof T> = Object.keys(dados[0]) as Array<keyof T>;

  // Filtra os campos que não estão em colunasParaIgnorar
  const campos = camposBase.filter(
    (campo) => !colunasParaIgnorar.includes(campo)
  );

  const colunas = nomesColunas || campos;

  return (
    <DivTabela className="dx-widget">
      <Tabela className="dx-datagrid">
        <CabecalhoTabela>
          <tr
            role="columnheader"
            className="dx-row dx-column-lines dx-list-border-visible"
          >
            {colunas.map((header) => (
              <td key={String(header)}>{String(header)}</td>
            ))}
          </tr>
        </CabecalhoTabela>
        <tbody className="">
          {dados.map((item, index) => (
            <tr
              key={index}
              className="dx-row dx-column-lines dx-list-border-visible"
            >
              {campos.map((campo) => (
                <td key={String(campo)}>{item[campo]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Tabela>
    </DivTabela>
  );
}
