import { Body } from "../../atencao/index.styles";

export interface PartialOpcionaisAssinaturaFaltandoProps {
  permissoesNaoIncluidasFormatadas: string[];
  opcionaisAssinaturaFormatados: string[];
  alinhamentoTexto?: "left" | "center" | "right";
}

export default function PartialOpcionaisAssinaturaFaltando({
  permissoesNaoIncluidasFormatadas,
  opcionaisAssinaturaFormatados: opcionaisAssinaturaFormatados,
  alinhamentoTexto,
}: PartialOpcionaisAssinaturaFaltandoProps) {
  return (
    <Body style={{ textAlign: alinhamentoTexto }}>
      <p>
        <b>
          O plano contratado pela sua empresa não inclui a(s) funcionalidade(s)
          de:
        </b>
      </p>
      {permissoesNaoIncluidasFormatadas.map((registro, index) => (
        <p key={index}>{`${registro}`}</p>
      ))}

      <br />

      <p>
        <b>
          Para incluir esta funcionalidade, a empresa deve contratar um dos
          seguintes planos ou opcionais:
        </b>
      </p>
      {opcionaisAssinaturaFormatados.map((registro, index) => (
        <p key={index}>{`${registro}`}</p>
      ))}
    </Body>
  );
}
