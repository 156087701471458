import { useState } from "react";
import * as yup from "yup";
import {
  FormGrupo,
  FormNumberBox,
  FormTextBox,
} from "../../../../components/formularios";
import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../components/layout/grid-system";
import {
  useCarregarRegistro,
  useHookForms,
  useLimparFormSeIdForNaN,
} from "../../../../hooks/form.hooks";
import { LinkAjudaRequestDTO } from "../../../../models/api/ajuda/link-ajuda";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import API from "../../../../services/ajuda/link-ajuda.service";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import NomesModais from "../../../../utils/common/nomes-modais";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";

interface EditFormLinkAjudaPaginaProps extends FormularioEdicaoBaseProps {
  codigo: string;
}

const linkhome = process.env.REACT_APP_HELP_HOME as string;

export const EditFormLinkAjudaPagina = (
  props: EditFormLinkAjudaPaginaProps
) => {
  const novoRegistro: LinkAjudaRequestDTO = {
    id: 0,
    codigo: props.codigo,
    slug: "",
    textoExibicao: "",
    ordemExibicao: 0,
  };

  const [carregando, setCarregando] = useState(false);

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    codigo: yup.string().required().max(100),
    slug: yup.string().required().max(300),
    textoExibicao: yup.string().required().max(60),
    ordemExibicao: yup
      .number()
      .required()
      .moreThan(-1, "Mínimo de 0 e máximo de 999")
      .lessThan(1000, "Mínimo de 0 e máximo de 999")
      .integer(),
  });

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      props.callBackFecharModal(info);
    }
  }

  const hookForm = useHookForms<LinkAjudaRequestDTO>(schema);
  useCarregarRegistro(props.idRegistroEdicao, carregarTela);
  useLimparFormSeIdForNaN(hookForm, novoRegistro, props.idRegistroEdicao);

  const { register, getValues, reset, control, handleSubmit } = hookForm;

  async function carregarTela() {
    try {
      setCarregando(true);
      reset(novoRegistro);
      if (props.idRegistroEdicao > 0) {
        await carregarModel();
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  async function carregarModel() {
    try {
      const resposta = await API.obterPorId(props.idRegistroEdicao);
      checarResponse(resposta);
      reset(resposta.model);
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  function handleCancelar() {
    fechar({ concluido: false, precisaAtualizar: false });
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();
    try {
      const resposta =
        props.idRegistroEdicao > 0
          ? await API.alterarPorId(model as LinkAjudaRequestDTO)
          : await API.inserirPorId(model as LinkAjudaRequestDTO);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({
          concluido: true,
          precisaAtualizar: true,
        });
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  return (
    <FormBase2
      visivel={props.visivel}
      carregando={carregando}
      onClickSalvar={handleSubmit(handleSalvar)}
      onClickCancelar={handleCancelar}
      configuracoesModal={props.configuracoesModal}
      modoEdicao={props.idRegistroEdicao == 0 ? "criar" : "editar"}
      titulo={NomesModais.linkDeAjuda}
    >
      <input type="hidden" {...register("id")} defaultValue={0} />
      <input
        type="hidden"
        {...register("codigo")}
        defaultValue={props.codigo}
      />
      <FormGrupo>
        <Linha>
          <Coluna md={12}>
            <FormTextBox
              name="textoExibicao"
              titulo="Texto de exibição"
              toolTip="Texto de exibição"
              control={control}
              requerido
              tamanhoMaximo={60}
            />
          </Coluna>
        </Linha>
        <Linha>
          <Coluna md={12}>
            <FormTextBox
              name="slug"
              titulo={linkhome}
              toolTip={linkhome}
              control={control}
              requerido
              tamanhoMaximo={300}
            />
          </Coluna>
        </Linha>
        <Linha>
          <Coluna md={4}>
            <FormNumberBox
              name="ordemExibicao"
              titulo="Ordem de exibição"
              toolTip="Ordem de exibição"
              control={control}
              requerido
              minimo={0}
              maximo={999}
            />
          </Coluna>
        </Linha>
      </FormGrupo>
    </FormBase2>
  );
};
