import { Button, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { ReactNode, memo, useEffect, useState } from "react";
import AuditavelDTO from "../../../models/api/comum/auditavel-dto";
import { CallBackModal } from "../../../models/shared/ui/callback-modal";
import { UnidadeTamanhoElementos } from "../../../utils/tipos/unidade-tamanho";
import { Modal } from "../modal";
import { PainelCarregando } from "../painel-carregamento";
import RodapeAuditoria from "../rodape-auditoria";

export interface FormularioEdicaoBaseProps {
  callBackFecharModal?: (info: CallBackModal) => void;
  idRegistroEdicao: number;
  visivel: boolean;
  configuracoesModal?: ConfiguracoesModalFormProps;
}

type ModoEdicao = "criar" | "editar" | "não aplicável";

interface Form2Props {
  carregando: boolean;
  onClickSalvar?: () => void;
  children: React.ReactNode;
  titulo: string;
  onClickCancelar: () => void;
  visivel: boolean;
  botaoSalvar?: {
    texto?: string;
    icon?: string;
  };
  botaoCancelar?: {
    texto?: string;
    icon?: string;
  };
  botoesAdicionais?: ReactNode[];
  componentesAdicionais?: () => React.ReactNode;
  auditoria?: AuditavelDTO;
  isNomeTelaFeminino?: boolean;
  modoEdicao: ModoEdicao;
  configuracoesModal?: ConfiguracoesModalFormProps;
  dataTestId?: string;
}

export interface ConfiguracoesModalFormProps {
  mostrarSombreado?: boolean;
  fecharAoClicarFora?: boolean | null;
  larguraMaxima?: UnidadeTamanhoElementos;
  alturaMaxima?: UnidadeTamanhoElementos;
  largura?: string | number | (() => string | number);
  altura?: string | number | (() => string | number);
  arrastarAtivado?: boolean;
}

export function obterTituloTela(
  modoEdicao: ModoEdicao,
  titulo: string,
  isNomeTelaFeminino?: boolean
) {
  const genero = isNomeTelaFeminino ? "a" : "o";

  switch (modoEdicao) {
    case "criar":
      return `Nov${genero} ${titulo}`;
    case "editar":
      return `Editar ${titulo}`;
    case "não aplicável":
      return titulo;
    default:
      return "";
  }
}

export const FormBase2 = memo((props: Form2Props) => {
  const [dadosAuditoria, setDadosAuditoria] = useState(props.auditoria);

  useEffect(() => {
    setDadosAuditoria(props.auditoria);
  }, [props.auditoria]);

  return (
    <>
      <Modal
        titulo={
          obterTituloTela(
            props.modoEdicao,
            props.titulo,
            props.isNomeTelaFeminino
          ) ?? ""
        }
        visivel={props.visivel}
        onFechar={props.onClickCancelar}
        largura={props.configuracoesModal?.largura}
        altura={props.configuracoesModal?.altura}
        larguraMaxima={props.configuracoesModal?.larguraMaxima}
        alturaMaxima={props.configuracoesModal?.alturaMaxima}
        arrastarAtivado={props.configuracoesModal?.arrastarAtivado}
        fecharAoClicarFora={props.configuracoesModal?.fecharAoClicarFora}
        mostrarSombreado={props.configuracoesModal?.mostrarSombreado}
        componentesAdicionais={props.componentesAdicionais}
      >
        <PainelCarregando visivel={props.carregando ?? false} />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
          data-testid={props.dataTestId}
        >
          <form
            style={{
              overflow: "auto",
              padding: "0px 15px 20px 15px",
              margin: "0px -15px",
              flexGrow: 2,
              flexShrink: 2,
            }}
            onSubmit={(e) => e.preventDefault()}
          >
            {props.children}
          </form>

          <Toolbar>
            {props.onClickSalvar && (
              <Item location="before">
                <Button
                  type="success"
                  text={props.botaoSalvar?.texto ?? "Salvar"}
                  icon={props.botaoSalvar?.icon ?? "save"}
                  onClick={props.onClickSalvar}
                />
              </Item>
            )}
            <Item location="before">
              <Button
                type="normal"
                text={props.botaoCancelar?.texto ?? "Cancelar"}
                icon={props.botaoCancelar?.icon ?? "close"}
                onClick={props.onClickCancelar}
              />
            </Item>
            {props.botoesAdicionais &&
              props.botoesAdicionais.map((item, ix) => (
                <Item key={`tb-${ix}`} location="before">
                  {item}
                </Item>
              ))}
            {dadosAuditoria && (
              <Item location="after">
                <RodapeAuditoria auditoria={dadosAuditoria} />
              </Item>
            )}
          </Toolbar>
        </div>
      </Modal>
    </>
  );
});
