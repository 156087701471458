import { parse } from "date-fns";
import { DataGrid, FileUploader, Popover } from "devextreme-react";
import {
  DataGridRef,
  Editing,
  Export,
  ExportTexts,
  HeaderFilter,
  LoadPanel,
  Pager,
  Paging,
  Scrolling,
  Selection,
} from "devextreme-react/cjs/data-grid";
import { FileUploaderTypes } from "devextreme-react/cjs/file-uploader";
import { PopoverRef } from "devextreme-react/cjs/popover";
import { ToolbarItem } from "devextreme-react/cjs/popup";
import {
  Column,
  DataType,
  Row,
  RowPreparedEvent,
} from "devextreme/ui/data_grid";
import { useCallback, useRef, useState } from "react";
import readXlsxFile, { parseExcelDate } from "read-excel-file";
import { BasicType, Type } from "read-excel-file/types";
import * as yup from "yup";
import {
  CadastrarEmMassaExcelBase,
  CadastrarEmMassaResponse,
} from "../../../models/api/comum/cadastrar-varios";
import { ResponseModel } from "../../../models/api/comum/response-base";
import ConfiguracoesServico from "../../../services/configuracoes/configuracoes.service";
import criarNameof from "../../../utils/common/cria-name-of";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../utils/common/notificacoes-utils";
import { exibirAlerta, exibirConfirmacao } from "../../../utils/dialogos";
import { exportarGrid } from "../../../utils/grid/grid-utils";
import { renderToStringClient } from "../../../utils/react/react-utils";
import { Modal } from "../../layout/modal";
import "./importador.css";

export const colunaUtcParaData = (valor: any): Date => {
  try {
    if (typeof valor === "string") {
      return parse(valor, "dd/MM/yyyy", new Date());
    }

    if (typeof valor === "number") {
      // 25567 is the difference between Excel start date (1900/01/01) and Javascript start date (1970/01/01)
      const diff = 25567.0;
      const valorData = parseExcelDate(diff + valor).toString();
      return new Date(
        new Date(valorData).toLocaleString("en-US", { timeZone: "UTC" })
      );
    }

    return new Date(valor.toLocaleString("en-US", { timeZone: "UTC" }));
  } catch {
    throw new Error(
      "Não foi possível converter o valor '{0}' da coluna '{1}' para uma data"
    );
  }
};

export const colunaParaNumero = (valor: any): number => {
  try {
    if (typeof valor === "string") {
      // Converte do formato brasileiro (150.124.124,22) para o formato do js (150124124.22)
      // TODO: Achar um jeito mais confiável de fazer isso (talvez com alguma biblioteca de
      //       globalização. Tentamos fazer isso mas o consumo de memória do build ficou muito
      //       alto.)
      valor = valor.replace(/\./g, "").replace(",", ".");
    }

    const numero = typeof valor === "number" ? valor : Number(valor);
    if (isNaN(numero)) {
      throw new Error(
        "Não foi possível converter o valor '{0}' da coluna '{1}' para um número"
      );
    }

    return numero;
  } catch {
    throw new Error(
      "Não foi possível converter o valor '{0}' da coluna '{1}' para um número"
    );
  }
};

const nameOfCadastrarVarios = criarNameof<CadastrarEmMassaExcelBase>();

const podeAlterarLinha = (e: { row: Row }) => {
  return (
    (e.row.data as CadastrarEmMassaExcelBase).estadoDaImportacao !=
    "Importado com sucesso"
  );
};

interface MxpSchemaImportarEntry<Dto, T> {
  prop: Extract<keyof Dto, string>;
  type: BasicType | Type<T>;
  dataType: DataType;
}

export type MxpSchemaImportar<T extends CadastrarEmMassaExcelBase> = Record<
  string,
  MxpSchemaImportarEntry<T, any>
>;

interface ImportadorDeRegistrosProps<T extends CadastrarEmMassaExcelBase> {
  visivel: boolean;
  titulo: string;
  nomeDaPlanilha: string;
  onFechar: () => void;
  schema: MxpSchemaImportar<T>;
  schemaYup: yup.AnyObject;
  modeloUrl: string;
  onImportar: (
    registros: T[]
  ) => Promise<ResponseModel<CadastrarEmMassaResponse[]>>;
}

const ApiConfiguracoes = new ConfiguracoesServico();

export default function ImportadorDeRegistros<
  T extends CadastrarEmMassaExcelBase
>(props: ImportadorDeRegistrosProps<T>) {
  const refGrid = useRef<DataGridRef<T, number>>(null);
  const refPopover = useRef<PopoverRef>(null);
  const [dadosDaGrid, setDadosDaGrid] = useState<T[] | undefined>(undefined);

  const onSelectedFilesChanged = useCallback(
    async (e: FileUploaderTypes.ValueChangedEvent) => {
      if (!e.value || e.value.length === 0) {
        return;
      }

      if (
        e.value[0].type !=
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        exibirNotificacaoToast({
          mensagem: "Formato selecionado inválido. Formatos aceitos: .xlsx",
          tipo: TipoNotificacao.Erro,
        });
        return;
      }

      const taskConfiguracoesEmpresa =
        ApiConfiguracoes.ObterConfiguracoesDeEmpresa();

      if (dadosDaGrid?.length ?? 0 > 0) {
        const confirmacao = await exibirConfirmacao(
          "Aviso",
          "Carregar um novo arquivo irá sobrescrever os dados atuais. Deseja continuar?"
        );

        if (!confirmacao) {
          return;
        }
      }

      const configuracoesEmpresa = await taskConfiguracoesEmpresa;

      e.value.forEach(async (anexo) => {
        readXlsxFile(anexo, {
          schema: props.schema,
          sheet: props.nomeDaPlanilha,
        })
          .then(({ rows, errors }) => {
            if (
              rows.length >
              configuracoesEmpresa.numeroMaximoLinhasImportacaoPlanilha
            ) {
              exibirNotificacaoToast({
                mensagem: `Erro: Tentando importar ${rows.length} registros, mas seu limite atual é de ${configuracoesEmpresa.numeroMaximoLinhasImportacaoPlanilha}.`,
                tipo: TipoNotificacao.Erro,
              });
              return;
            }

            const linhas = rows.map(
              (x, i) =>
                ({
                  linha: i + 1,
                  estadoDaImportacao: "Ainda não importado",
                  ...x,
                  erros: "",
                  resultadoDaImportacao: "",
                } as T)
            );
            linhas.forEach((x) => {
              validarValor(x);
              x.resultadoDaImportacao = errors
                .filter((y) => y.row - 1 == x.linha)
                .map((y) =>
                  y.error.replace("{0}", y.value).replace("{1}", y.column)
                )
                .join("; \n");
              x.erros += x.resultadoDaImportacao;
            });

            setDadosDaGrid(linhas);
          })
          .catch(() => {
            exibirNotificacaoToast({
              mensagem: `Não foi possível importar: Planilha "${props.nomeDaPlanilha}" não encontrada no arquivo *.xlsx anexado.`,
              tipo: TipoNotificacao.Erro,
            });
          });
      });

      // Para poder carregar o mesmo arquivo novamente.
      e.component.resetOption("value");
    },
    [dadosDaGrid]
  );

  const validarValor = (data: any) => {
    try {
      props.schemaYup.validateSync(data, { abortEarly: false });
      data.erros = "";
    } catch (err: any) {
      data.erros = err.inner
        .map((e1: any) => `'${e1.params.label}': ${e1.message}`)
        .join(" \n");
    }

    const dado = dadosDaGrid?.find((x) => x.linha == data.linha);
    if (dado) {
      dado.erros = data.erros;
    }
  };

  const onRowPrepared = useCallback(async (e: RowPreparedEvent) => {
    if (e.rowType !== "data") {
      return;
    }

    PintarErros(e.data, e.rowElement);
  }, []);

  function PintarErros<T extends CadastrarEmMassaExcelBase>(
    linha: T,
    rowElement: HTMLElement
  ) {
    if (linha.estadoDaImportacao == "Importado com sucesso") {
      rowElement.style.backgroundColor = "rgba(40,167,69,.1)";
      return;
    }

    if (linha.erros?.length > 0) {
      rowElement.style.backgroundColor = "rgba(217,83,79,.1)";
    } else {
      rowElement.style.backgroundColor = "transparent";
    }
  }

  const customizeColumns = (columns: Column<any, number>[]) => {
    Object.keys(props.schema).forEach((x) => {
      const prop = props.schema[x].prop as string;
      const coluna = columns.find((t) => t.dataField === prop);
      if (coluna) {
        coluna.caption = x;
        coluna.dataType = props.schema[x].dataType;
      } else {
        columns.push({
          dataField: prop,
          caption: x,
          dataType: props.schema[x].dataType,
        } as Column<any, any>);
      }
    });

    const colunaLinha = columns.find(
      (t) => t.dataField === nameOfCadastrarVarios("linha")
    );
    const colunaErros = columns.find(
      (t) => t.dataField === nameOfCadastrarVarios("erros")
    );
    const colunaResultadoImportacao = columns.find(
      (t) => t.dataField === nameOfCadastrarVarios("resultadoDaImportacao")
    );
    const colunaEstadoImportacao = columns.find(
      (t) => t.dataField === nameOfCadastrarVarios("estadoDaImportacao")
    );

    if (colunaLinha) {
      colunaLinha.visible = false;
      colunaLinha.allowEditing = false;
      colunaLinha.allowExporting = false;
    }

    if (colunaErros) {
      colunaErros.visible = false;
      colunaErros.allowExporting = false;
    }

    if (colunaResultadoImportacao) {
      colunaResultadoImportacao.caption = "Resultado da importação";
      colunaResultadoImportacao.allowEditing = false;
      colunaResultadoImportacao.visibleIndex = 500;
      colunaResultadoImportacao.cssClass = "ic-material-disabled";
    }

    if (colunaEstadoImportacao) {
      colunaEstadoImportacao.caption = "Estado da importação";
      colunaEstadoImportacao.allowEditing = false;
      colunaEstadoImportacao.allowExporting = false;
      colunaEstadoImportacao.cssClass = "ic-material-disabled";
    }
  };

  const ImportarDados = useCallback(async () => {
    if (!dadosDaGrid) {
      exibirNotificacaoToast({
        mensagem: "Nenhum dado para importar.",
        tipo: TipoNotificacao.Erro,
      });
      return;
    }

    if (refGrid.current?.instance().hasEditData()) {
      const confirmacao = await exibirConfirmacao(
        "Aviso",
        "Linhas em edição serão salvas. Deseja continuar?"
      );

      if (!confirmacao) {
        return;
      }
      await refGrid.current?.instance().saveEditData();
    }

    refGrid.current?.instance().beginCustomLoading("Importando dados...");

    dadosDaGrid.forEach((linha) => {
      validarValor(linha);
    });

    // Dados que estão sem erro ou ainda não foram importados com sucesso.
    const dadosvalidos =
      dadosDaGrid.filter(
        (x) =>
          x.erros?.length <= 0 &&
          x.estadoDaImportacao != "Importado com sucesso"
      ) ?? [];

    const resultado = await props.onImportar(dadosvalidos);

    if (resultado.sucesso) {
      const resultados = resultado.model;

      const linhaQuejaHaviamSidoImportadas = dadosDaGrid.filter(
        (x) => x?.estadoDaImportacao == "Importado com sucesso"
      ).length;

      dadosDaGrid.forEach((linha) => {
        if (linha?.erros.length > 0) {
          linha.estadoDaImportacao = "Erro ao importar";
          linha.resultadoDaImportacao = linha.erros;
          return;
        }

        const linhaResultado = resultados.find(
          (x) => x.indiceDoRegistro == linha.linha
        );
        if (linhaResultado) {
          if (linhaResultado.erros.length > 0) {
            linha.estadoDaImportacao = "Erro ao importar";
          } else {
            linha.estadoDaImportacao = "Importado com sucesso";
          }
          linha.erros = linhaResultado.erros;
          linha.resultadoDaImportacao = linhaResultado.erros;
        }
      });

      const importadosComSucesso =
        dadosDaGrid.filter(
          (x) => x?.estadoDaImportacao == "Importado com sucesso"
        ).length - linhaQuejaHaviamSidoImportadas;
      const importadosComErro = dadosDaGrid.filter(
        (x) => x?.estadoDaImportacao == "Erro ao importar"
      ).length;
      const totalDeRegistros = dadosDaGrid.length;
      const mensagem = renderToStringClient(
        <>
          <ul>
            {linhaQuejaHaviamSidoImportadas > 0 && (
              <li>
                {linhaQuejaHaviamSidoImportadas} registros de {totalDeRegistros}{" "}
                já haviam sido importados.
              </li>
            )}
            {importadosComSucesso > 0 && (
              <li>
                {importadosComSucesso} registros de {totalDeRegistros} foram
                importados com sucesso.
              </li>
            )}
            {importadosComErro > 0 && (
              <li>
                {importadosComErro} registros de {totalDeRegistros} não foram
                importados.
              </li>
            )}
          </ul>
          <p>
            <small>
              O resultado da importação de cada registro ficará disponível na
              coluna &ldquo;Resultado da importação&rdquo;.
              <br />
              Se necessário, realize a correção das linhas com erro e clique em
              &ldquo;Importar dados&rdquo; novamente.
            </small>
          </p>
        </>
      );
      exibirAlerta("Resultado da importação:", mensagem);

      refGrid.current?.instance().refresh();
    }
    refGrid.current?.instance().endCustomLoading();
  }, [dadosDaGrid]);

  const fechar = useCallback(() => {
    setDadosDaGrid(undefined);
    props.onFechar();
  }, [props.onFechar]);

  return (
    <Modal
      titulo={props.titulo}
      onFechar={fechar}
      visivel={props.visivel}
      componentesAdicionais={() => [
        <ToolbarItem
          key={"importar-dados-importar"}
          widget="dxButton"
          toolbar="bottom"
          location="before"
          options={{
            text: "Importar dados",
            type: "success",
            icon: "upload",
            stylingMode: "contained",
            onClick: ImportarDados,
          }}
        ></ToolbarItem>,

        <ToolbarItem
          key={"importar-dados-cancelar"}
          widget="dxButton"
          toolbar="bottom"
          location="before"
          options={{
            text: "Cancelar",
            type: "normal",
            icon: "close",
            stylingMode: "contained",
            onClick: fechar,
          }}
        ></ToolbarItem>,
      ]}
    >
      <FileUploader
        id="file-uploader"
        selectButtonText="Selecionar arquivo"
        accept=".xlsx"
        labelText="Ou arraste aqui..."
        showFileList={false}
        uploadMode="useForm"
        onValueChanged={onSelectedFilesChanged}
      />
      {!dadosDaGrid && (
        <div style={{ fontSize: "14px", margin: "12px" }}>
          <p>Para importar registros a partir de um arquivo Excel:</p>
          <ol>
            <li>
              Baixe a planilha modelo{" "}
              <a href={props.modeloUrl}>clicando aqui</a>.
            </li>
            <li>
              Preencha a planilha com seus dados. As colunas com cabeçalho
              marcado com * são de preenchimento obrigatório.
            </li>
            <li>
              Clique em &ldquo;Selecionar arquivo&rdquo; para selecionar o
              arquivo a ser importado (formato aceito: .xlsx).
            </li>
            <li>
              Após selecionar o arquivo, será realizada uma validação prévia dos
              dados e o resultado será apresentado em tela. Caso exista algum
              erro de preenchimento, a linha ficará destacada em vermelho, sendo
              possível editar e corrigir a inconsistência, ou excluir a linha
              com problema.
            </li>
            <li>
              Quando considerar que os dados estão prontos para importação,
              clique em &ldquo;Importar dados&rdquo;.
            </li>
            <li>
              A coluna &ldquo;Estado da importação&rdquo; será atualizada,
              indicando, em vermelho, as linhas no estado &ldquo;Erro ao
              importar&rdquo;, ou verde para as linhas no estado
              &ldquo;Importado com sucesso&rdquo;.
            </li>
            <li>
              O resultado da importação de cada registro ficará disponível na
              coluna &ldquo;Resultado da importação&rdquo; ou clicando na linha.
              Se necessário, realize a correção das linhas com erro e clique em
              &ldquo;Importar dados&rdquo; novamente.
            </li>
          </ol>
        </div>
      )}
      <DataGrid
        ref={refGrid}
        focusedRowEnabled={false}
        keyExpr="linha"
        visible={!!dadosDaGrid}
        onCellClick={(e) => {
          // Abrir o popover com detalhes dos erros
          if (e.column?.allowSorting && e.data?.erros?.length > 0) {
            refPopover.current?.instance().show(e.cellElement);
            // Necessário fazer assim, pois com useState o elemento da devExtreme bugava a posição.
            const errosDaLinha = document.getElementById(
              "errosDeImportacaoDaLinha"
            );
            if (errosDaLinha) {
              errosDaLinha.innerText = e.data.erros;
            }
          }
        }}
        onRowPrepared={onRowPrepared}
        onContentReady={(e) => {
          // Traz a coluna de ações pro começo
          e.component.columnOption("command:edit", "visibleIndex", -1);
        }}
        onEditorPreparing={(e) => {
          e.editorOptions.onFocusOut = function () {
            // Valia as alterações
            if (e.row) {
              validarValor(e.row.data);

              const rowElement = e.editorElement.parentElement?.parentElement;
              if (rowElement) {
                PintarErros(e.row.data, rowElement);
              }
            }
          };
        }}
        customizeColumns={customizeColumns}
        repaintChangesOnly={true}
        allowColumnResizing={true}
        height={"calc(80vh - (150px)"}
        columnResizingMode="widget"
        showColumnLines={true}
        onToolbarPreparing={(e) => {
          e.toolbarOptions.items?.push({
            location: "after",
            widget: "dxButton",
            visible: true,
            options: {
              icon: "ic-material-symbols-outlined ic-restore-page",
              hint: "Restaurar tela para as definições padrões",
              onClick: () => {
                refGrid.current?.instance()?.clearFilter();
                refGrid.current?.instance()?.clearSorting();
              },
            },
          });
        }}
        width={"100%"}
        columnAutoWidth={true}
        showBorders={true}
        twoWayBindingEnabled={true}
        dataSource={dadosDaGrid}
        noDataText="Nenhum registro encontrado."
        onExporting={(e) =>
          exportarGrid(e, props.nomeDaPlanilha, props.nomeDaPlanilha)
        }
      >
        <HeaderFilter visible={true} />
        <LoadPanel enabled={true} showPane={true} showIndicator={true} />
        <Export enabled={true} allowExportSelectedData={true}>
          <ExportTexts
            exportAll="Exportar todos os dados para o Excel"
            exportSelectedRows="Exportar os dados selecionados para o Excel"
          />
        </Export>
        <Scrolling
          useNative={true}
          rowRenderingMode="standard"
          columnRenderingMode="standard"
        />
        <Selection
          mode="multiple"
          selectAllMode="allPages"
          showCheckBoxesMode="always"
        />
        <Paging key="Paging" defaultPageSize={200} enabled={true} />
        <Pager
          visible={true}
          displayMode="full"
          showInfo
          showNavigationButtons
        />
        <Editing
          mode="row"
          texts={{
            deleteRow:
              "Tem certeza de que deseja excluir este registro da lista de importação?",
          }}
          allowUpdating={podeAlterarLinha}
          allowDeleting={podeAlterarLinha}
          startEditAction="dblClick"
        />
      </DataGrid>
      <Popover
        ref={refPopover}
        position={"top"}
        width={300}
        deferRendering={false}
        restorePosition={false}
      >
        <div className={"tooltipContent"}>
          <b>Erros:</b> <br />
          <div id="errosDeImportacaoDaLinha"></div>
        </div>
      </Popover>
    </Modal>
  );
}
