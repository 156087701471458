import NecessitaOpcionaisPopup from "../../../components/dialogos/necessita-opcionais";
import ModalRequerPermissao from "../../../components/dialogos/requer-permissao";
import { ComponentAsyncLoader } from "../../utils/load-on-demand";

export default function AgregacaoDeModais() {
  return (
    <ComponentAsyncLoader>
      <ModalRequerPermissao />
      <NecessitaOpcionaisPopup />
    </ComponentAsyncLoader>
  );
}
