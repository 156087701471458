import { UseFormReturn } from "react-hook-form";
import FormTextArea from "../../../../../../components/formularios/textarea";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import TabContainer from "../../../../../../components/layout/tab-container";
import { OperacaoDaOrdemDeProducaoRequestDTO } from "../../../models/operacao-de-ordem-de-producao";

interface OperacaoDaOrdemDeProducaoAbaObservacoesProps {
  hookForms: UseFormReturn<OperacaoDaOrdemDeProducaoRequestDTO>;
}
export default function OperacaoDaOrdemDeProducaoAbaObservacoes(
  props: OperacaoDaOrdemDeProducaoAbaObservacoesProps
) {
  const { control } = props.hookForms;
  return (
    <TabContainer>
      <Linha>
        <Coluna md={12}>
          <FormTextArea
            name="observacoes"
            titulo={"Observações"}
            valor={""}
            control={control}
            height={17}
          />
        </Coluna>
      </Linha>
    </TabContainer>
  );
}
