import { ReactNode } from "react";
import AuditavelDTO from "../../../models/api/comum/auditavel-dto";
import RodapeAuditoria from "../rodape-auditoria";

interface ToolbarMxpProps {
  children: ReactNode;
  dadosAuditoria?: AuditavelDTO;
}

export default function ToolbarMxp(props: ToolbarMxpProps) {
  return (
    <div
      className="dx-toolbar dx-widget dx-visibility-change-handler dx-collection"
      role="toolbar"
    >
      <div className="dx-toolbar-items-container">
        <div
          className="dx-toolbar-before"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div style={{ display: "flex", gap: "5px" }}>{props.children}</div>
        </div>
        <div
          className="dx-toolbar-after"
          style={{ display: "flex", alignItems: "center" }}
        >
          {props.dadosAuditoria && (
            <div>
              <RodapeAuditoria auditoria={props.dadosAuditoria} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
