import {
  DataSourceFiltragem,
  DataSourceRawFilter,
} from "../../../../utils/grid/data-source-factory";
import { PedidoDeVendaGridModel } from "../models/pedidos-de-venda.api";
import { EstadoPedidoDeVenda } from "../models/pedidos-de-venda.enum";

export class FiltrosGridPedidoVenda {
  static readonly pedidoNaoFinalizado: DataSourceFiltragem<PedidoDeVendaGridModel>[] =
    [
      {
        campo: "estado",
        operador: "<>",
        valor: EstadoPedidoDeVenda.Faturado,
      },
      {
        campo: "estado",
        operador: "<>",
        valor: EstadoPedidoDeVenda.Cancelado,
      },
    ];

  static readonly pedidoNaoFinalizadoRaw: DataSourceRawFilter<PedidoDeVendaGridModel> =
    [
      ["estado", "<>", EstadoPedidoDeVenda.Faturado],
      "and",
      ["estado", "<>", EstadoPedidoDeVenda.Cancelado],
    ];
}
