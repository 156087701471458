import { useEffect, useState } from "react";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import BotaoCopiarMxp from "../../../../../components/botoes/botao-copiar-mxp";
import { Modal } from "../../../../../components/layout/modal";
import PainelCarregamento from "../../../../../components/layout/painel-carregamento";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/store.hooks";
import { ocultarModalGerenciamentoTokensAcessoUsuario } from "../../../../../store/usuario/usuario.slice";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import { TiposTokenAcesso } from "../../../login/models/login.enums";
import API from "../../servicos/tokens-acesso.service";
import BotaoGerarToken from "../botoes/botao-gerar-token";
import BotaoRevogarToken from "../botoes/botao-revogar-token";
import { ContainerTokenAcessoUsuario } from "./styles";

export default function ModalTokenAcessoUsuario() {
  const estado = useAppSelector((state) => {
    return {
      visivel:
        state.estadoTelaUsuario.modalGerenciamentoTokensAcessoUsuario.visivel,
      idUsuario:
        state.estadoTelaUsuario.modalGerenciamentoTokensAcessoUsuario.dadosToken
          .idUsuario,
      tipo: state.estadoTelaUsuario.modalGerenciamentoTokensAcessoUsuario
        .dadosToken.tipo,
    };
  });

  const [token, setToken] = useState<string>();
  const [carregando, setCarregando] = useState(false);

  const tokenGerado = token ? true : false;

  useEffect(() => {
    if (!estado.visivel) {
      return;
    }

    carregarTela();
  }, [estado.visivel]);

  const dispatch = useAppDispatch();

  async function carregarTela() {
    try {
      setCarregando(true);
      const resposta = await API.Obter(estado.idUsuario, estado.tipo);
      checarResponse(resposta);

      if (resposta.model) {
        setToken(resposta.model?.token);
      }
    } catch (erro) {
      tratarErroApi(erro, () =>
        dispatch(ocultarModalGerenciamentoTokensAcessoUsuario())
      );
    } finally {
      setCarregando(false);
    }
  }

  async function onClickGerar() {
    try {
      let gerarRecriar = true;

      if (tokenGerado) {
        gerarRecriar = await exibirConfirmacao(
          "Confirmar recriação?",
          "Já existe um token para este usuário, deseja o sobrescrever?"
        );
      }

      if (!gerarRecriar) {
        return;
      }

      setCarregando(true);
      const resposta = await API.Gerar({
        usuarioTokenId: estado.idUsuario,
        tipo: estado.tipo,
      });
      checarResponse(resposta);

      if (resposta.model) {
        setToken(resposta.model?.token);
      }
    } catch (erro) {
      tratarErroApi(erro, () =>
        dispatch(ocultarModalGerenciamentoTokensAcessoUsuario())
      );
    } finally {
      setCarregando(false);
    }
  }

  async function onClickRevogar() {
    try {
      const revogar = await exibirConfirmacao(
        "Confirmar revogação?",
        "Tem certeza de que deseja revogar este token? Certifique-se de que ele não está mais sendo utilizado."
      );

      if (!revogar) {
        return;
      }

      setCarregando(true);
      const resposta = await API.Revogar(estado.idUsuario, estado.tipo);
      checarResponse(resposta);

      if (resposta.model) {
        setToken(undefined);
      }
    } catch (erro) {
      tratarErroApi(erro, () =>
        dispatch(ocultarModalGerenciamentoTokensAcessoUsuario())
      );
    } finally {
      setCarregando(false);
    }
  }

  function onClickCancelar() {
    setToken(undefined);
    dispatch(ocultarModalGerenciamentoTokensAcessoUsuario());
  }

  const titulo: Record<TiposTokenAcesso, Partial<string>> = {
    "1": "Token de acesso API GraphQL",
  };

  return (
    <>
      <Modal
        titulo={titulo[estado.tipo]}
        visivel={estado.visivel}
        onFechar={onClickCancelar}
        larguraMaxima={"700px"}
      >
        {carregando && <PainelCarregamento />}

        <ContainerTokenAcessoUsuario>
          {token ?? "O usuário não possui um token de acesso."}
        </ContainerTokenAcessoUsuario>

        <ToolbarMxp>
          <BotaoGerarToken
            tokenGerado={tokenGerado}
            handleClick={onClickGerar}
          />
          <BotaoRevogarToken
            tokenGerado={tokenGerado}
            handleClick={onClickRevogar}
          />
          <BotaoCopiarMxp valor={token} visivel={tokenGerado} />
          <BotaoCancelarMxp handleClick={onClickCancelar} />
        </ToolbarMxp>
      </Modal>
    </>
  );
}
