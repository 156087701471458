import { ReactNode, useState } from "react";
import { ItemContextMenuMxp } from "../../../utils/context-menu/context-menu-utils";
import ContextoMenus from "../contexto-menus";

interface PovedorMenusProps {
  children: ReactNode;
}

export default function ProvedorMenus({ children }: PovedorMenusProps) {
  const [itensMenu, setItensMenu] = useState<ItemContextMenuMxp[]>([]);

  return (
    <ContextoMenus.Provider value={{ itensMenu, setItensMenu }}>
      {children}
    </ContextoMenus.Provider>
  );
}
