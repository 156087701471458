import { Button } from "devextreme-react";

interface BotaoRecuperarMinhaSenhaProps {
  handleClick: () => void;
}

export default function BotaoRecuperarMinhaSenha(
  props: BotaoRecuperarMinhaSenhaProps
) {
  return (
    <Button
      type="success"
      text={"Recuperar minha senha"}
      onClick={props.handleClick}
    />
  );
}
