export function formatarCNPJ(cnpj: string): string {
  const numerosCNPJ = cnpj.replace(/\D/g, "");

  if (numerosCNPJ.length === 14) {
    return numerosCNPJ.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  } else {
    return cnpj;
  }
}

export function formatarCPF(cpf: string): string {
  const numerosCPF = cpf.replace(/\D/g, "");

  if (numerosCPF.length === 11) {
    return numerosCPF.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  } else {
    return cpf;
  }
}

export function formatarCpfCnpj(cpfCnpj: string): string {
  const numeros = cpfCnpj.replace(/\D/g, "");

  if (numeros.length == 11) {
    return formatarCPF(cpfCnpj);
  } else if (numeros.length == 14) {
    return formatarCNPJ(cpfCnpj);
  } else {
    return cpfCnpj;
  }
}
