import { Column } from "devextreme-react/data-grid";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { MxpGrid } from "../../../../components/grid";
import {
  useEditarRegistroGrid,
  useExcluirRegistroGrid,
  useNovoRegistroGrid,
  usePropagarReferenciaGrid,
} from "../../../../hooks/grid.hooks";
import { useAppDispatch } from "../../../../hooks/store.hooks";
import { LinkAjudaGridModel } from "../../../../models/api/ajuda/link-ajuda";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import { IGridSelecao } from "../../../../models/shared/ui/formularios";
import { EditFormLinkAjudaPagina } from "../../../../pages/ajuda/ajuda-paginas/edit-form";
import API from "../../../../services/ajuda/link-ajuda.service";
import { bloquearUI, desbloquearUI } from "../../../../store/ui/ui.slice";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../utils/api/api-utils";
import criarNameof from "../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../utils/common/nomes-telas";
import { exibirConfirmacao } from "../../../../utils/dialogos";
import obterConfiguracaoColuna from "../../../../utils/grid/padroes-colunas";

interface GridAjudaPaginasProps {
  codigo: string;
}

const nameOfGridHandler = criarNameof<LinkAjudaGridModel>();
const colunas = [
  <Column
    key={nameOfGridHandler("textoExibicao")}
    dataField={nameOfGridHandler("textoExibicao")}
    {...obterConfiguracaoColuna("stringGG")}
    caption="Texto de exibição"
  />,
  <Column
    key={nameOfGridHandler("slug")}
    dataField={nameOfGridHandler("slug")}
    {...obterConfiguracaoColuna("stringXXG")}
    caption="Link da ajuda"
  />,
  <Column
    key={nameOfGridHandler("ordemExibicao")}
    dataField={nameOfGridHandler("ordemExibicao")}
    {...obterConfiguracaoColuna("codigoNumerico")}
    caption="Ordem"
  />,
];

export const GridAjudaPaginas = forwardRef(
  (props: GridAjudaPaginasProps, ref) => {
    const gridRef = useRef<IGridSelecao>(null);
    useImperativeHandle(ref, () => gridRef.current as IGridSelecao);

    const [modalEdicaoVisivel, setModalEdicaoVisivel] = useState(false);
    const [registroEmEdicao, setRegistroEmEdicao] = useState<number>(NaN);

    const dispatch = useAppDispatch();

    const dataSource = API.getGridSource(props.codigo);

    usePropagarReferenciaGrid(ref, gridRef);

    function handleAtualizarGrid() {
      if (gridRef.current?.atualizarGrid) {
        gridRef.current?.atualizarGrid();
      }
    }

    const handleExcluirRegistro = useExcluirRegistroGrid(
      async (registro: LinkAjudaGridModel) => {
        const excluir = await exibirConfirmacao(
          "Confirmar exclusão",
          obterMensagemExclusao(registro)
        );

        if (excluir) {
          try {
            dispatch(bloquearUI());
            const resposta = await API.excluirPorId(registro.id);
            checarResponseExibeMensagemExclusaoDeSucesso(resposta);
            handleAtualizarGrid();
          } catch (erro) {
            tratarErroApi(erro, () => {
              handleAtualizarGrid();
            });
          } finally {
            dispatch(desbloquearUI());
          }
        }
      }
    );

    function obterMensagemExclusao(registro: LinkAjudaGridModel) {
      return `Tem certeza que deseja excluir o registro ${registro.slug}?`;
    }

    const handleNovoRegistro = useNovoRegistroGrid(() => {
      setRegistroEmEdicao(0);
      setModalEdicaoVisivel(true);
    });

    const handleEditarRegistro = useEditarRegistroGrid((id: number) => {
      setRegistroEmEdicao(id);
      setModalEdicaoVisivel(true);
    });

    const handleModalCallBack = useCallback((info: CallBackModal) => {
      setRegistroEmEdicao(NaN);
      setModalEdicaoVisivel(false);

      if (info.precisaAtualizar) {
        handleAtualizarGrid();
      }
    }, []);

    return (
      <>
        <MxpGrid<LinkAjudaGridModel>
          id={"links-ajuda"}
          dataSource={dataSource}
          ref={gridRef}
          colunas={colunas}
          novoRegistro={handleNovoRegistro}
          editarRegistro={handleEditarRegistro}
          excluirRegistro={handleExcluirRegistro}
          nomeDoArquivoAoExportar={NomesTelas.linkAjudaPaginaMaster}
        />
        <EditFormLinkAjudaPagina
          codigo={props.codigo}
          visivel={modalEdicaoVisivel}
          idRegistroEdicao={registroEmEdicao}
          configuracoesModal={{
            larguraMaxima: "max(50vw, 800px)",
            altura: "min(30vh, 400px)",
          }}
          callBackFecharModal={handleModalCallBack}
        />
      </>
    );
  }
);
