import { CfopGridModel } from "../../../../features/fiscal/cfop/models/cfop";
import {
  EstadoDecodificado,
  TipoDecodificado,
} from "../../../../features/fiscal/cfop/models/cfop.enums";
import criarNameof from "../../../common/cria-name-of";

export default class FiltrosGridCfop {
  static readonly cfopAtivoETipoNacional: any[] = [
    [
      criarNameof<CfopGridModel>()("estadoFormatado"),
      "=",
      EstadoDecodificado.Ativo,
    ],
    "and",
    [
      criarNameof<CfopGridModel>()("tipoFormatado"),
      "=",
      TipoDecodificado.Nacional,
    ],
  ];
}
