import { LoadPanel } from "devextreme-react";
import { useAppSelector } from "../../../hooks/store.hooks";
import {
  obterPermissoesFaltantesFormatada,
  verificaSeUsuarioPossuiPermissoes,
} from "../../../utils/common/permissoes-utils";
import PartialPermissoesFaltando from "../../dialogos/requer-permissao/parts/listagem-permissoes-faltantes";

interface RequerPermissaoProps {
  codigoPermissoes: string[];
  modo?: "todas" | "alguma";
  ignoraSeForMaster?: boolean;
  children: React.ReactNode;
}

export default function RequerPermissao({
  codigoPermissoes,
  modo = "todas",
  ignoraSeForMaster = false,
  children,
}: RequerPermissaoProps) {
  const master = useAppSelector(
    (state) => state.sessao.dadosSessao?.usuario.isUsuarioMaster
  );
  const carregandoDados = useAppSelector(
    (state) =>
      state.sessao.dadosSessao == null && state.sessao.usuarioAutenticado
  );

  const possuiPermissoes =
    (ignoraSeForMaster && master) ||
    verificaSeUsuarioPossuiPermissoes(codigoPermissoes, modo);

  if (possuiPermissoes) {
    return <>{children}</>;
  }

  if (carregandoDados) {
    return <LoadPanel message={"Checando permissoes..."} />;
  }

  const permissoesFaltantes =
    obterPermissoesFaltantesFormatada(codigoPermissoes);

  return (
    <PartialPermissoesFaltando
      permissoesFormatadas={permissoesFaltantes}
      alinhamentoTexto="center"
    />
  );
}
