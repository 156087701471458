import { Control } from "react-hook-form";
import { FormTextBox } from "../../../../../../components/formularios";
import FormTextArea from "../../../../../../components/formularios/textarea";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import TabContainer from "../../../../../../components/layout/tab-container";
import { CentroDeTrabalhoRequestDTO } from "../../../models/centro-de-trabalho.api";
import CentroDeTrabalhoContantes from "../../../models/centro-de-trabalho.constantes";

interface RoteiroItemProps {
  control: Control<CentroDeTrabalhoRequestDTO, any>;
}

export default function CentroDeTrabalhoAbaRoteiroItem({
  control,
}: RoteiroItemProps) {
  return (
    <TabContainer>
      <Linha>
        <Coluna md={6}>
          <FormTextBox
            name="operacaoDescricaoPreferencial"
            titulo="Descrição da operação"
            control={control}
            tamanhoMaximo={
              CentroDeTrabalhoContantes.OperacaoDescricaoPreferencialTamanhoMaximo
            }
            tipo="text"
          />
        </Coluna>
        <Coluna md={6}>
          <FormTextArea
            name="operacaoDescricaoComplementarPreferencial"
            titulo={"Descrição complementar da operação"}
            valor={""}
            control={control}
            tamanhoMaximo={
              CentroDeTrabalhoContantes.OperacaoDescricaoComplementarPreferencialTamanhoMaximo
            }
            height={25}
          />
        </Coluna>
      </Linha>
    </TabContainer>
  );
}
