import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  FormNumberBox,
  FormSelectBoxLazy,
} from "../../../../../components/formularios";
import FormBase from "../../../../../components/layout/form-base";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import { useAppDispatch } from "../../../../../hooks/store.hooks";
import { IdUFBanco } from "../../../../../models/api/mdfe/mdfe-enums";
import { MDFePercursoDTO } from "../../../../../models/api/mdfe/mdfe-request-response";
import {
  SelectValueEnumIdUFBanco,
  divisasValidas,
} from "../../../../../models/const/dicionario-combos/mdfe";
import { AdicionarPercursoViewModel } from "../../../../../models/viewmodels/vendas/mdfe/mdfe-edit-form-view-model";
import APIUf from "../../../../../services/uf/uf.service";
import store from "../../../../../store";
import { adicionarPercurso } from "../../../../../store/mdfe/mdfe.slice";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { obterFormatStringNumero } from "../../../../../utils/formatadores/formatador-de-numeros";
import {
  FormataDescricao,
  FormatadoresSelectBox,
} from "../../../../../utils/formatadores/formatador-de-selectbox";

interface ModalAdicionarPercursoProps {
  visivel: boolean;
  fecharCallback: () => void;
}

const exibeUf = (c: any) => {
  if (c) {
    return FormataDescricao(
      FormatadoresSelectBox.CodigoDescricaoParenteses,
      c.Descricao,
      c.Sigla
    );
  }

  return "";
};

const ufExpressaoDeBusca = ["Descricao", "Sigla"];

export default function ModalAdicionarPercurso({
  fecharCallback,
  visivel,
}: ModalAdicionarPercursoProps) {
  const dataSourceUf = APIUf.getDataSourceSelectBoxLazy({
    camposRetorno: ["Id", "Sigla", "Descricao"],
    camposOrdenacao: [
      {
        nomeCampo: "Descricao",
        desc: false,
      },
    ],
  });

  const dataSourceUfConsulta = APIUf.getDataSourceSelectBoxLazy({
    camposRetorno: ["Id", "Sigla", "Descricao"],
    camposOrdenacao: [
      {
        nomeCampo: "Descricao",
        desc: false,
      },
    ],
  });

  const dispatch = useAppDispatch();

  const [carregando, setCarregando] = useState(false);

  useEffect(() => {
    reset();
    let novaOrdem =
      Math.max(
        ...store.getState().mdfe.documentoAtual.percursos.map((x) => x.ordem)
      ) + 1;

    novaOrdem = novaOrdem == Infinity * -1 ? 1 : novaOrdem;

    setValue("ordem", novaOrdem);
  }, [visivel]);

  async function handleSalvar() {
    try {
      setCarregando(true);
      const model = getValues();

      dataSourceUfConsulta.filter(["Id", "=", model.idUf]);
      await dataSourceUfConsulta.load();
      const dado = dataSourceUfConsulta.items()[0];

      const addmodel: MDFePercursoDTO = {
        id: 0,
        idUf: model.idUf,
        ordem: model.ordem,
        estado: {
          uf: dado.Sigla,
          nome: dado.Descricao,
        },
      };

      dispatch(adicionarPercurso(addmodel));
      exibirNotificacaoToast({
        mensagem: `UF ${addmodel.estado.nome} adicionado com sucesso. Clique em "Salvar" para confirmar a operação.`,
        tipo: TipoNotificacao.Advertencia,
      });
      fecharCallback();
    } finally {
      setCarregando(false);
    }
  }

  function validaDivisa(ufOrigem: IdUFBanco, ordemAtual: number) {
    const state = store.getState();
    const percursos = state.mdfe.documentoAtual.percursos;

    const ordensAbaixo = percursos
      .filter((percurso) => percurso.ordem < ordemAtual)
      .map((percurso) => percurso.ordem);

    const ordensAcima = percursos
      .filter((percurso) => percurso.ordem > ordemAtual)
      .map((percurso) => percurso.ordem);

    const ordemMaisProximaAbaixo =
      ordensAbaixo.length > 0 ? Math.max(...ordensAbaixo, -Infinity) : null;
    const ufAbaixo =
      ordemMaisProximaAbaixo != null
        ? percursos
            .filter((percurso) => percurso.ordem === ordemMaisProximaAbaixo)
            .map((percurso) => percurso.idUf)[0] || null
        : null;

    const ordemMaisProximaAcima =
      ordensAcima.length > 0 ? Math.min(...ordensAcima, Infinity) : null;
    const ufAcima =
      ordemMaisProximaAcima != null
        ? percursos
            .filter((percurso) => percurso.ordem === ordemMaisProximaAcima)
            .map((percurso) => percurso.idUf)[0] || null
        : null;

    if (
      ufAbaixo == null &&
      state.mdfe.documentoAtual.idUfInicio > 0 &&
      !divisasValidas[ufOrigem].includes(state.mdfe.documentoAtual.idUfInicio)
    ) {
      const mensagem = verificaEstadoFazDivisaOutroEstado(
        state.mdfe.documentoAtual.idUfInicio,
        ufOrigem
      );
      if (mensagem) {
        return mensagem;
      }
    }

    if (ufAbaixo != null && !divisasValidas[ufOrigem].includes(ufAbaixo)) {
      const mensagem = verificaEstadoFazDivisaOutroEstado(ufAbaixo, ufOrigem);
      if (mensagem) {
        return mensagem;
      }
    }

    if (ufAcima != null && !divisasValidas[ufOrigem].includes(ufAcima)) {
      const mensagem = verificaEstadoFazDivisaOutroEstado(ufAcima, ufOrigem);
      if (mensagem) {
        return mensagem;
      }
    }
  }

  function verificaEstadoFazDivisaOutroEstado(
    ufComparacao: number,
    ufOrigem: IdUFBanco
  ): string {
    if (ufComparacao > 0 && !divisasValidas[ufOrigem].includes(ufComparacao)) {
      const IdUFBancoelecionado = SelectValueEnumIdUFBanco.find(
        (item) => item.valor === ufComparacao
      );
      if (IdUFBancoelecionado) {
        const divisas = divisasValidas[IdUFBancoelecionado.valor as IdUFBanco];
        if (divisas && divisas.length > 0) {
          const divisasFormatadas = divisas.map(
            (divisa) =>
              SelectValueEnumIdUFBanco.find((item) => item.valor === divisa)
                ?.descricao
          );

          return `A UF informada não possui divisa com a UF ${
            IdUFBancoelecionado.descricao
          }, informe uma das UFs válidas: ${divisasFormatadas.join(", ")}.`;
        }
      }
    }

    return "";
  }

  const schema = yup.object().shape({
    idUf: yup
      .number()
      .required()
      .positive()
      .integer()
      .transform((v) => (v ? v : null))
      .test({
        message:
          "A UF informada já existe no percurso ou está definido como UF de origem/destino.",
        test: (v) => {
          const state = store.getState();

          return (
            state.mdfe.documentoAtual.percursos.every((x) => x.idUf != v) &&
            state.mdfe.documentoAtual.idUfFim != v &&
            state.mdfe.documentoAtual.idUfInicio != v
          );
        },
      })
      .test({
        name: "custom",
        test: function (value) {
          const mensagem = validaDivisa(value as IdUFBanco, this.parent.ordem);
          if (mensagem) {
            return this.createError({ message: mensagem, path: "idUf" });
          }

          return true;
        },
      }),
    ordem: yup
      .number()
      .required()
      .integer()
      .moreThan(0)
      .max(255)
      .test({
        message: "A ordem já existe na lista de percurso.",
        test: (v) => {
          return store
            .getState()
            .mdfe.documentoAtual.percursos.every((x) => x.ordem != v);
        },
      }),
  });

  const { getValues, setValue, reset, control, handleSubmit } =
    useForm<AdicionarPercursoViewModel>({
      mode: "all",
      reValidateMode: "onChange",
      resolver: yupResolver(schema),
    });

  let form: HTMLFormElement | null;

  return (
    <>
      <FormBase
        formId="edit-form-add-percurso"
        carregando={carregando}
        titulo="Adicionar percurso"
        modal
        onClickSalvar={() => form?.requestSubmit()}
        onClickCancelar={() => fecharCallback()}
      >
        <form ref={(ref) => (form = ref)} onSubmit={handleSubmit(handleSalvar)}>
          <Linha>
            <Coluna md={9}>
              <FormSelectBoxLazy
                name="idUf"
                titulo="UF"
                toolTip="UF"
                control={control}
                dataSource={dataSourceUf}
                nomeCampoChave="Id"
                nomeCampoExibicao={exibeUf}
                expressaoDeBusca={ufExpressaoDeBusca}
              />
            </Coluna>
            <Coluna md={3}>
              <FormNumberBox
                name="ordem"
                titulo="Ordem"
                toolTip="Ordem"
                control={control}
                minimo={1}
                maximo={255}
                formato={obterFormatStringNumero(0)}
              />
            </Coluna>
          </Linha>
        </form>
      </FormBase>
    </>
  );
}
