import styled from "styled-components";
import { CoresChip } from "./cores";

interface MxpChipProps {
  color: CoresChip;
}

export const MxpChip = styled.div<MxpChipProps>`
  background-color: color-mix(in srgb, currentColor 15%, transparent);
  color: ${(props) => props.color};
  border: 1px solid transparent;
  border-radius: 4px;
  padding-inline: 6px;
  display: inline;
  font-weight: 500;
  :hover {
    border: 1px solid;
  }
`;
