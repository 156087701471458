import { HTMLElement, parse } from "node-html-parser";
import ApiProxy from "../../services/ajuda/proxy-help-externo.service";

const UrlHome = process.env.REACT_APP_HELP_HOME as string;
const MinutosExpiracaoCache = parseInt(
  process.env.REACT_APP_HELP_CACHE_TIME_MINUTES as string
);
const Seletor = process.env.REACT_APP_HELP_ATTR_DATA as string;
const SeletorAttrGrid = process.env.REACT_APP_HELP_ATTR_GRID_DATA as string;
const BaseCacheKey = "ToolTip_Cache@";

interface CacheToolTip {
  url: string;
  dataHora: number;
  itens: CacheItem[];
}

interface CacheItem {
  id: string;
  html: string;
}

export class ProvedorToolTip {
  static async criarProvedorToolTip(slug: string) {
    const provedor = new ProvedorToolTip(slug);
    await provedor.carregar();
    return provedor;
  }

  static limparCache() {
    for (const key in localStorage) {
      if (key.startsWith(BaseCacheKey)) {
        localStorage.removeItem(key);
      }
    }
  }

  private url: string;
  private cacheKey: string;
  private cache: CacheToolTip;

  private constructor(slug: string) {
    this.url = UrlHome + slug;
    this.cacheKey = BaseCacheKey + UrlHome + slug;
    this.cache = {
      url: this.url,
      dataHora: 0,
      itens: [],
    };
  }

  get expirado() {
    return Date.now() - this.cache.dataHora > MinutosExpiracaoCache * 60 * 1000;
  }

  public obterTexto(campoId: string) {
    let texto = this.cache.itens.find((item) => item.id === campoId)?.html;

    if (!texto) {
      return null;
    }

    texto += this.gerarLinkMaisAjuda();

    return texto;
  }

  public async carregar() {
    const json = localStorage.getItem(this.cacheKey);

    if (json) {
      this.cache = JSON.parse(json) as CacheToolTip;

      if (this.expirado) {
        await this.carregarOnLine();
      }
    } else {
      await this.carregarOnLine();
    }
  }

  public async carregarOnLine() {
    try {
      const html = await ApiProxy.obter(this.url);

      if (!html) {
        return;
      }

      const doc = parse(html);
      const itens = doc.querySelectorAll(`li[${Seletor}]`);
      const itensDaColunaDaGrid = doc.querySelectorAll(
        `li[${SeletorAttrGrid}]`
      );

      const cacheObj: CacheToolTip = {
        url: this.url,
        dataHora: Date.now(),
        itens: [],
      };

      if (itens) {
        for (const node of itens) {
          //Pega a tag <a> do tooltip se existir
          this.tratarLinksDoTooltip(node);

          cacheObj.itens.push({
            id: node.attributes[Seletor],
            html: node.innerHTML,
          });
        }
      }

      if (itensDaColunaDaGrid) {
        for (const node of itensDaColunaDaGrid) {
          //Pega a tag <a> do tooltip se existir
          this.tratarLinksDoTooltip(node);

          const id = node.attributes[SeletorAttrGrid];

          if (cacheObj.itens.some((x) => x.id == id)) {
            continue;
          }

          cacheObj.itens.push({
            id: id,
            html: node.innerHTML,
          });
        }
      }

      const json = JSON.stringify(cacheObj);

      if (cacheObj.itens.length > 0) {
        localStorage.setItem(this.cacheKey, json);
      }

      this.cache = cacheObj;
    } catch (erro) {
      console.error(erro);
    }
  }

  private tratarLinksDoTooltip(node: HTMLElement) {
    const links = node.querySelectorAll("a");
    links.forEach((link) => {
      //Adiciona o atributo target para abrir o link em uma nova aba
      link.setAttribute("target", "_blank");
      const linkClique = link.getAttribute("href") ?? "";
      const texto = linkClique.startsWith("#")
        ? this.url.split("#")[0] + linkClique
        : linkClique;

      //Adiciona o atributo href com o link certo
      link.setAttribute("href", texto);
    });
  }

  private gerarLinkMaisAjuda() {
    return `<br /><br /><small><a href="${this.url}" target="_blank">Mais informações</a></small>`;
  }
}
