import {
  tipoCarroceriaDecodificado,
  tipoProprietarioDecodificado,
  tipoRodadoDecodificado,
  tipoUnidadeTransporteDecodificado,
} from "../../../features/vendas/veiculo/models/veiculo.enums";
import { gerarSelectItemDoDicionario } from "../../../utils/common/enum-utils";
import SelectItem from "../../shared/ui/select-item";

export const SelectItemEnumTipoCarroceria: SelectItem[] =
  gerarSelectItemDoDicionario(tipoCarroceriaDecodificado);

export const SelectItemEnumTipoProprietario: SelectItem[] =
  gerarSelectItemDoDicionario(tipoProprietarioDecodificado);

export const SelectItemEnumTipoRodado: SelectItem[] =
  gerarSelectItemDoDicionario(tipoRodadoDecodificado);

export const SelectItemEnumTipoUnidadeTransporte: SelectItem[] =
  gerarSelectItemDoDicionario(tipoUnidadeTransporteDecodificado);
