import { Item, Toolbar } from "devextreme-react/toolbar";
import {
  ReactNode,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import AuditavelDTO from "../../../models/api/comum/auditavel-dto";
import { IFormBase } from "../form-base";
import Pagina from "../pagina";
import { IPainelCarregando, PainelCarregando } from "../painel-carregamento";
import RodapeAuditoria from "../rodape-auditoria";

interface FormularioAvancadoProps {
  callBackModel?: any;
  children?: React.ReactNode;
  modal?: boolean;
  titulo?: string;
  auditoria?: AuditavelDTO;
  carregando?: boolean;
  toolbarItens?: ReactNode[];
}

const FormAvancado = forwardRef(
  (
    {
      children,
      auditoria,
      carregando,
      toolbarItens,
      modal,
      titulo,
    }: FormularioAvancadoProps,
    ref
  ) => {
    const painelCarregando = useRef<IPainelCarregando>();
    const [dadosAuditoria, setDadosAuditoria] = useState(auditoria);

    const itens = toolbarItens ?? [];

    useImperativeHandle(
      ref,
      (): IFormBase => ({
        setDadosAuditoria: (auditoria) => {
          setDadosAuditoria(auditoria);
        },
        setCarregando: (carregando) => {
          painelCarregando.current?.setCarregando(carregando);
        },
      })
    );

    const content = (
      <>
        <PainelCarregando
          visivel={carregando ?? false}
          ref={painelCarregando}
        />
        {children}
        <Toolbar key={itens.map((x) => (x as any).key).join("")}>
          {itens.map((item, ix) => (
            <Item key={`tb-${ix}`} location="before">
              {item}
            </Item>
          ))}
          {dadosAuditoria && (
            <Item location="after">
              <RodapeAuditoria auditoria={dadosAuditoria} />
            </Item>
          )}
          {auditoria && (
            <Item location="after">
              <RodapeAuditoria auditoria={auditoria} />
            </Item>
          )}
        </Toolbar>
      </>
    );

    return modal ? (
      <>{content}</>
    ) : (
      <Pagina id={null} titulo={titulo}>
        {content}
      </Pagina>
    );
  }
);

export default FormAvancado;
