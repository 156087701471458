import { Column } from "devextreme-react/data-grid";
import { forwardRef, useRef, useState } from "react";
import { MxpGrid } from "../../../components/grid";
import { obterTituloTela } from "../../../components/layout/form-base2";
import { Modal } from "../../../components/layout/modal";
import {
  useEditarRegistroGrid,
  useExcluirRegistroGrid,
  useNovoRegistroGrid,
  usePropagarReferenciaGrid,
} from "../../../hooks/grid.hooks";
import CondutorSelecaoGridModel from "../../../models/api/usuario/condutor-selecao-grid";
import { CallBackModal } from "../../../models/shared/ui/callback-modal";
import { IGridSelecao } from "../../../models/shared/ui/formularios";
import APIUsuario from "../../../services/usuario/usuario.service";
import criarNameof from "../../../utils/common/cria-name-of";
import NomesModais from "../../../utils/common/nomes-modais";
import NomesTelas from "../../../utils/common/nomes-telas";
import { exibirConfirmacao } from "../../../utils/dialogos";
import obterConfiguracaoColuna from "../../../utils/grid/padroes-colunas";

const dataSource = APIUsuario.ObterGridSelecaoCondutorSource();

const nameOfGridHandler = criarNameof<CondutorSelecaoGridModel>();

const colunas = [
  <Column
    key={nameOfGridHandler("apelido")}
    dataField={nameOfGridHandler("apelido")}
    {...obterConfiguracaoColuna("apelido")}
  />,
  <Column
    key={nameOfGridHandler("nome")}
    dataField={nameOfGridHandler("nome")}
    {...obterConfiguracaoColuna("razaoSocial")}
    caption="Nome"
    width={400}
  />,
  <Column
    key={nameOfGridHandler("cpfCnpj")}
    dataField={nameOfGridHandler("cpfCnpj")}
    {...obterConfiguracaoColuna("cpfCnpj")}
    caption="CPF"
  />,
];

export const GridSelecaoCondutor = forwardRef((props, ref) => {
  const gridRef = useRef<IGridSelecao>(null);
  const [modalEdicaoVisivel, setModalEdicaoVisivel] = useState(false);
  const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);

  function handleModalCallback(info: CallBackModal) {
    setModalEdicaoVisivel(false);
    setIdRegistroEdicao(NaN);

    if (info.precisaAtualizar) {
      handleAtualizarGrid();
    }
  }

  usePropagarReferenciaGrid(ref, gridRef);

  function handleAtualizarGrid() {
    if (gridRef.current?.atualizarGrid) {
      gridRef.current?.atualizarGrid();
    }
  }

  const handleNovoRegistro = useNovoRegistroGrid(() => {
    setIdRegistroEdicao(0);
    setModalEdicaoVisivel(true);
  });

  const handleEditarRegistro = useEditarRegistroGrid((id: number) => {
    setIdRegistroEdicao(id);
    setModalEdicaoVisivel(true);
  });

  const handleExcluirRegistro = useExcluirRegistroGrid(() => {
    exibirConfirmacao("Atenção", "Funcionalidade não implementada");
  });

  return (
    <>
      <MxpGrid<CondutorSelecaoGridModel>
        id={"condutor"}
        ref={gridRef}
        dataSource={dataSource}
        colunas={colunas}
        nomeDoArquivoAoExportar={NomesTelas.condutores}
        novoRegistro={handleNovoRegistro}
        editarRegistro={handleEditarRegistro}
        excluirRegistro={handleExcluirRegistro}
      />
      <Modal
        titulo={obterTituloTela(
          idRegistroEdicao == 0 ? "criar" : "editar",
          NomesModais.condutor
        )}
        visivel={modalEdicaoVisivel}
        altura="auto"
        largura={"max(50vw, 800px)"}
        onFechar={() =>
          handleModalCallback({ concluido: false, precisaAtualizar: false })
        }
      >
        <div>Funcionalidade não implementada</div>
      </Modal>
    </>
  );
});
