import DataGrid, { Column, DataGridRef } from "devextreme-react/cjs/data-grid";
import { useCallback, useMemo, useRef } from "react";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { VinculoDaOrdemDeProducaoGridModel } from "../../../../../models/api/vinculo/vinculo-da-ordem-de-producao-grid-model";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import criarNameof from "../../../../../utils/common/cria-name-of";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import { OrdemDeProducaoService } from "../../servicos/ordem-de-producao.service";

const service = new OrdemDeProducaoService();
const nameOfGridHandler = criarNameof<VinculoDaOrdemDeProducaoGridModel>();

interface GridEmbutidaVinculosDaOrdemDeProducaoProps
  extends GridMxpProps<VinculoDaOrdemDeProducaoGridModel> {
  ordemDeProducaoId: number;
}

export default function GridEmbutidaVinculosDaOrdemDeProducao(
  props: GridEmbutidaVinculosDaOrdemDeProducaoProps
) {
  const gridRef = useRef<DataGridRef>(null);

  const dataSource = service.ObterDataSourceParaGridVinculos(
    props.ordemDeProducaoId,
    props.filtrosNoServidor
  );

  const gridController = useMemo(
    () =>
      new GridController<VinculoDaOrdemDeProducaoGridModel>(() =>
        gridRef.current?.instance()
      ),
    []
  );

  const handleAtualizarGrid = useCallback(() => {
    gridController.atualizar();
  }, [gridController]);

  useRegistrarAtalhosGrid({
    controller: gridController,
    // Adicionar handlers de manipulação se/quando forem criados.
  });

  const configuracoesGrid = useMemo(() => {
    const isGridInterna = false;
    return GridBuilder.criar(
      "vinculos-op",
      () => gridRef?.current?.instance(),
      isGridInterna,
      props.filtrosNoCliente
    )
      .definirStyles(props.style)
      .definirDataSource(dataSource)
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirFiltros()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao("Vínculos da Ordem de Produção")
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao("none")
      .definirOrdenacao()
      .build();
  }, [props.filtrosNoCliente, props.style, dataSource, handleAtualizarGrid]);

  return (
    <DataGrid ref={gridRef} {...configuracoesGrid}>
      <Column
        key={nameOfGridHandler("vinculoPara")}
        dataField={nameOfGridHandler("vinculoPara")}
        {...obterConfiguracaoColuna("stringXG")}
        width={480}
        allowFiltering={false}
      />
      <Column
        key={nameOfGridHandler("apelidoClientePedidoDeVenda")}
        dataField={nameOfGridHandler("apelidoClientePedidoDeVenda")}
        {...obterConfiguracaoColuna("stringGG")}
        caption="Cliente"
      />
      <Column
        key={nameOfGridHandler("dataDeEntregaItemPedidoDeVenda")}
        dataField={nameOfGridHandler("dataDeEntregaItemPedidoDeVenda")}
        {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
        caption="Data de entrega"
        width={150}
      />
      <Column
        key={nameOfGridHandler("quantidade")}
        dataField={nameOfGridHandler("quantidade")}
        {...obterConfiguracaoColuna("quantidade")}
        caption="Quantidade"
      />
      <Column
        key={nameOfGridHandler("codigoItem")}
        dataField={nameOfGridHandler("codigoItem")}
        {...obterConfiguracaoColuna("stringG")}
        caption="Item"
      />
      <Column
        key={nameOfGridHandler("descricaoItem")}
        dataField={nameOfGridHandler("descricaoItem")}
        {...obterConfiguracaoColuna("stringXG")}
        caption="Descrição do item"
      />
      {GetColunasDeAuditoria(false, false, [
        { ordenarPor: "criacaoData", ordem: "desc" },
      ])}
    </DataGrid>
  );
}
