import { CheckBox, SelectBox } from "devextreme-react";
import { ToolbarItem } from "devextreme-react/cjs/autocomplete";
import { SelectBoxRef } from "devextreme-react/cjs/select-box";
import DataSource from "devextreme/data/data_source";
import { useEffect, useRef, useState } from "react";
import { Coluna, Linha } from "../../../../components/layout/grid-system";
import { Modal } from "../../../../components/layout/modal";
import { TrocaEmpresaLoginRequest } from "../../../../features/seguranca/login/models/login.api";
import TokenService from "../../../../features/seguranca/login/servicos/token.servico";
import { useAppDispatch, useAppSelector } from "../../../../hooks/store.hooks";
import { MinhaEmpresaListaRequest } from "../../../../models/api/empresa/minhas-empresas/minhas-empresas-request";
import {
  AssinanteClienteDaMaxiprod,
  MinhaEmpresaItemResponse,
} from "../../../../models/api/empresa/minhas-empresas/minhas-empresas-response";
import Sessao from "../../../../models/dto/sessao/sessao";
import apiEmpresa from "../../../../services/empresa/empresa.service";
import {
  bloquearUI,
  definirEstadoRedirecionando,
  desbloquearUI,
} from "../../../../store/ui/ui.slice";
import { aguardar } from "../../../../utils/common/common-utils";
import criarNameof from "../../../../utils/common/cria-name-of";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";
import { GravarSessaoReduxELocalStorage } from "../../../../utils/oauth/oauth-utils";

interface IProps {
  visivel: boolean;
  closeCallback: () => void;
}

const minhaEmpresaItemResponseNameOfHandler =
  criarNameof<MinhaEmpresaItemResponse>();

const assinanteClienteDaMaxiprodNameOfHandler =
  criarNameof<AssinanteClienteDaMaxiprod>();

const tokenService = new TokenService();

export default function TrocaEmpresa({ visivel, closeCallback }: IProps) {
  const idEmpresa = useAppSelector(
    (state) => state.sessao.dadosSessao?.empresa.id
  );
  const assinanteId = useAppSelector(
    (state) => state.sessao.dadosSessao?.empresa.assinanteId
  );
  const [minhasEmpresas, setMinhasEmpresas] = useState<
    MinhaEmpresaItemResponse[]
  >([]);
  const [assinantes, setAssinantes] = useState<DataSource | undefined>();
  const [idEmpresaSelecionada, setIdEmpresaSelecionada] = useState(idEmpresa);
  const [assinanteIdSelecionado, setAssinanteIdSelecionado] =
    useState(assinanteId);
  const [mostrarEmpresasInativas, setMostrarEmpresasInativas] = useState(true);

  const isUsuarioMaster = useAppSelector(
    (state) => state.sessao.dadosSessao?.usuario.isUsuarioMaster
  );

  const selectBoxRef = useRef<SelectBoxRef>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!visivel) {
      return;
    }
    TrocarEmpresaPopUp();
  }, [visivel, mostrarEmpresasInativas]);

  useEffect(() => {
    if (!visivel) {
      return;
    }
    carregarEmpresas();
  }, [assinanteIdSelecionado]);

  async function TrocarEmpresaPopUp() {
    dispatch(bloquearUI("Logando..."));
    try {
      carregarEmpresas();
      carregarAssinantes();
    } finally {
      dispatch(desbloquearUI());
    }
  }

  const hidePopup = () => {
    closeCallback();
  };

  async function TrocarEmpresa() {
    dispatch(definirEstadoRedirecionando(true));
    dispatch(bloquearUI("Trocando de empresa..."));

    try {
      const trocaEmpresaLoginRequest: TrocaEmpresaLoginRequest = {
        idEmpresa: Number(idEmpresaSelecionada),
      };

      const resposta = await tokenService.TrocaEmpresaLogin(
        trocaEmpresaLoginRequest
      );

      if (resposta) {
        const dadosSessaoResposta = await tokenService.getDadosSessao(resposta);
        const sessao: Sessao = {
          dadosSessao: dadosSessaoResposta,
        };
        GravarSessaoReduxELocalStorage(sessao, resposta);
        await aguardar(500);
        window.location.reload();
      } else {
        exibirNotificacaoToast({
          mensagem: "Não foi possível trocar de empresa",
          tipo: TipoNotificacao.Erro,
        });
      }
    } finally {
      dispatch(desbloquearUI());
      closeCallback();
      await aguardar(1000);
      dispatch(definirEstadoRedirecionando(false));
    }
  }

  const onValueChanged = () => {
    setMostrarEmpresasInativas(!mostrarEmpresasInativas);
  };

  const onEmpresaChanged = (e: any) => {
    setIdEmpresaSelecionada(e.value);
    const valor = e.value;
    if (selectBoxRef.current?.instance().option("value") != valor) {
      selectBoxRef.current?.instance().option("value", valor);
    }
  };

  const onAssinanteChanged = (e: any) => {
    if (idEmpresaSelecionada != e?.selectedItem?.MinhaEmpresaId) {
      setAssinanteIdSelecionado(
        e?.selectedItem?.AssinanteId != null ? e.selectedItem.AssinanteId : ""
      );

      setIdEmpresaSelecionada(
        e?.selectedItem?.MinhaEmpresaId != null
          ? e.selectedItem.MinhaEmpresaId
          : ""
      );
    }
  };

  function carregarAssinantes() {
    setAssinantes(
      apiEmpresa.getAssinantesDosClientesDaMaxiprod(
        {
          camposRetorno: [
            assinanteClienteDaMaxiprodNameOfHandler("AssinanteId"),
            assinanteClienteDaMaxiprodNameOfHandler("NomeCompostoAssinante"),
            assinanteClienteDaMaxiprodNameOfHandler("Ativo"),
            assinanteClienteDaMaxiprodNameOfHandler("MinhaEmpresaId"),
          ],
          camposOrdenacao: [
            {
              nomeCampo: assinanteClienteDaMaxiprodNameOfHandler(
                "NomeCompostoAssinante"
              ),
              desc: false,
            },
          ],
        },
        mostrarEmpresasInativas
      )
    );
  }

  async function carregarEmpresas() {
    const assinanteId = assinanteIdSelecionado;
    const minhaEmpresaListaRequest: MinhaEmpresaListaRequest = {
      campoDeBusca: "",
      incluirInativas: mostrarEmpresasInativas,
      ...(assinanteId && { assinanteId: assinanteId }),
    };

    const empresas = await apiEmpresa.getMinhasEmpresas(
      minhaEmpresaListaRequest
    );

    if (empresas) {
      setMinhasEmpresas(empresas);
    }
  }

  return (
    <Modal
      titulo={"Empresas"}
      visivel={visivel}
      arrastarAtivado={true}
      larguraMaxima={700}
      onFechar={hidePopup}
      componentesAdicionais={() => (
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={{
            text: "Trocar de empresa",
            stylingMode: "contained",
            onClick: TrocarEmpresa,
          }}
        ></ToolbarItem>
      )}
    >
      {isUsuarioMaster && (
        <Linha>
          <Coluna md={12}>
            <label htmlFor="assinantes">
              <span className="dx-field-item-label-content">
                <span className="dx-field-item-label-text">Assinantes</span>
              </span>
            </label>
            <SelectBox
              ref={selectBoxRef}
              dataSource={assinantes}
              displayExpr={assinanteClienteDaMaxiprodNameOfHandler(
                "NomeCompostoAssinante"
              )}
              valueExpr={assinanteClienteDaMaxiprodNameOfHandler(
                "MinhaEmpresaId"
              )}
              defaultValue={idEmpresa}
              onSelectionChanged={onAssinanteChanged}
              searchEnabled={true}
              showDataBeforeSearch={true}
            />
          </Coluna>
        </Linha>
      )}
      <Linha>
        <Coluna md={12}>
          <label htmlFor="empresas">
            <span className="dx-field-item-label-content">
              <span className="dx-field-item-label-text">Empresas</span>
            </span>
          </label>
          <SelectBox
            dataSource={minhasEmpresas}
            displayExpr={minhaEmpresaItemResponseNameOfHandler("nomeComposto")}
            valueExpr={minhaEmpresaItemResponseNameOfHandler("id")}
            value={idEmpresaSelecionada}
            onValueChanged={onEmpresaChanged}
            searchEnabled={true}
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={12}>
          <CheckBox
            defaultValue={mostrarEmpresasInativas}
            text="Filtrar apenas empresas ativas"
            elementAttr={{ style: "margin-top: 25px" }}
            onValueChanged={onValueChanged}
          />
        </Coluna>
      </Linha>
    </Modal>
  );
}
