import styled from "styled-components";

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;

  .dx-popup-content & {
    width: calc(100% + 32px);
    height: calc(100% + 32px);
    inset: -16px -16px;
  }
`;
