import { ContextMenu } from "devextreme-react";
import { useState } from "react";
import { v4 } from "uuid";
import {
  ItemContextMenu,
  eventoTooltipContextMenu,
} from "../../../utils/context-menu/context-menu-utils";
import { Container, LinkButton } from "./styles";

interface CelulaControleEdicaoProps {
  editar?: () => void;
  excluir?: () => void;
  itens?: ItemContextMenu[];
}

export default function CelulaControleEdicao({
  editar,
  excluir,
  itens,
}: CelulaControleEdicaoProps) {
  const [contextMenuVisivel, setContextMenuVisivel] = useState(false);
  const linkId = v4();

  return (
    <Container>
      {editar && (
        <LinkButton
          onClick={editar}
          title="Editar"
          className="btn-editar-linha"
        >
          <i className="ic-material-symbols-outlined ic-edit icone-linha-grid"></i>
        </LinkButton>
      )}
      {excluir && (
        <LinkButton
          onClick={excluir}
          title="Excluir"
          className="btn-excluir-linha"
        >
          <i className="ic-material-symbols-outlined ic-delete icone-linha-grid"></i>
        </LinkButton>
      )}
      {itens
        ?.filter((element) => element.mostraNaColunaDeAcoes)
        .map((element, index) => {
          return (
            <LinkButton
              key={`${index}-${element.text}`}
              onClick={element.onClick}
              title={element.hint}
            >
              <i className={element.icon}></i>
            </LinkButton>
          );
        })}

      {itens?.some(
        (e) => !e.mostraNaColunaDeAcoes && !e.ocultarNoMaisOpcoes
      ) && (
        <>
          <LinkButton
            id={`opc-row-${linkId}`}
            onClick={() => setContextMenuVisivel(!contextMenuVisivel)}
            title="Mais opções"
          >
            <i className="ic-material-symbols-outlined ic-vertical icone-linha-grid"></i>
          </LinkButton>
          <ContextMenu
            items={itens.filter((element) => !element.ocultarNoMaisOpcoes)}
            target={`#opc-row-${linkId}`}
            visible={contextMenuVisivel}
            onHidden={() => setContextMenuVisivel(false)}
            onItemRendered={eventoTooltipContextMenu}
          />
        </>
      )}
    </Container>
  );
}
