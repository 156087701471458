import { HttpStatusCode } from "axios";
import { SortDescriptor } from "devextreme/data";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import fileDownload from "js-file-download";
import {} from "react-router-dom";
import { ErroApi, TipoErro } from "../../models/api/comum/erros";
import {
  NecessitaOpcionaisResponse,
  NotificationsResponse,
  ResponseBase,
} from "../../models/api/comum/response-base";
import { SelectBoxLazyOpcoes } from "../../models/api/comum/selectboxlazy-options";
import store from "../../store";
import {
  exibirAccordionNotifications,
  exibirNecessitaOpcionais,
} from "../../store/ui/ui.slice";
import exibirNotificacaoToast, {
  JanelasDeNotificacaoTitulos,
  MensagensParaNotificacao,
  TipoNotificacao,
} from "../common/notificacoes-utils";
import { exibirAlerta, exibirConfirmacao } from "../dialogos";
import { renderToStringClient } from "../react/react-utils";
import { configuracoesErros } from "./api-utils.configuracoes-erro";

export async function lerDadosResponseErro(
  error: any
): Promise<NotificationsResponse | undefined> {
  if (!error.response) {
    return;
  }

  if (error.response?.data instanceof Blob) {
    const blob = error.response.data as Blob;
    return JSON.parse(await blob.text()) as NotificationsResponse;
  } else {
    return error.response.data as NotificationsResponse;
  }
}

export function tratarErroApi(
  error: any,
  callBackUnprocessableEntity?: () => void
) {
  tratarErroApiInterno(error, false, callBackUnprocessableEntity);
}

export function tratarErroApiComoModal(
  error: any,
  callBackUnprocessableEntity?: () => void
) {
  tratarErroApiInterno(error, true, callBackUnprocessableEntity);
}

function tratarErroApiInterno(
  error: any,
  exibirErroComoModal: boolean,
  callBackUnprocessableEntity?: () => void
) {
  if (error.response) {
    if (error.response.status === HttpStatusCode.Forbidden) {
      exibirAlerta(
        "Atenção",
        `<span style="white-space: pre-line">${
          error.response.data.mensagem || "Erro ao verificar as permissões."
        }</span>`
      );
      return;
    }

    // Requisição feita com resposta do servidor com status code diferente de 2xx

    if (
      error.response.status == HttpStatusCode.PaymentRequired &&
      !(error.response.data instanceof Blob)
    ) {
      const model = error.response.data as NecessitaOpcionaisResponse;
      store.dispatch(exibirNecessitaOpcionais(model.model));
      return;
    }

    if (error.response.data instanceof Blob) {
      const blob = error.response.data as Blob;
      blob.text().then((str) => {
        const obj = JSON.parse(str);
        checarResponse(obj, exibirErroComoModal);
      });
    } else {
      checarResponse(error.response.data, exibirErroComoModal);
    }

    if (
      (error.response.status == HttpStatusCode.UnprocessableEntity ||
        // 404 para compatibilidade com antes do não encontrado retornar isso.
        // anteriormente o erro de objeto não encontrado gerava um 422.
        error.response.status === HttpStatusCode.NotFound) &&
      callBackUnprocessableEntity
    ) {
      callBackUnprocessableEntity();
    }
  } else if (error.request) {
    // Requisição feita sem resposta do servidor
    console.log(error.request);
  } else {
    // Outros erros
    console.log("Error", error.message);
  }
}

export function params(obj: any) {
  const params = new URLSearchParams();
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== undefined && obj[key] !== null) {
      params.append(key, obj[key]);
    }
  });
  return params;
}

function obterErrosConfigurados<T extends ResponseBase>(response: T) {
  const notificacoes = response as unknown as NotificationsResponse;
  if (response.sucesso) {
    return [];
  }

  if (notificacoes.erros && notificacoes.erros.length > 0) {
    return notificacoes.erros.filter((x) => x.codigoErro in configuracoesErros);
  }

  return [];
}

export function verificarSePossuiErroApiPorCodigo(
  response: any,
  codigoEsperado: ErroApi["codigoErro"]
) {
  const resposta = response.response?.data as ResponseBase;

  return verificarSePossuiErroPorCodigo(resposta, codigoEsperado);
}

export function verificarSePossuiErroPorCodigo<T extends ResponseBase>(
  response: T,
  codigoEsperado: ErroApi["codigoErro"]
) {
  return (obterErrosPorCodigo(response, codigoEsperado)?.length ?? 0) > 0;
}

export function obterErrosPorCodigo<T extends ResponseBase>(
  response: T,
  codigoEsperado: ErroApi["codigoErro"]
) {
  if (!response || response.sucesso) {
    return undefined;
  }

  const notificacoes = response as unknown as NotificationsResponse;

  if (notificacoes.erros.length == 0) {
    return undefined;
  }

  return notificacoes.erros.filter((x) => x.codigoErro == codigoEsperado);
}

export async function checarErrosDeConfirmacaoResponse<T extends ResponseBase>(
  response: T
) {
  const errosComFormatador = obterErrosConfigurados(response);

  // Se tem mais erros que os erros com formatador ou algum não é um erro de confirmação
  // então ocorreram erros de verdade.
  if (
    response.erros.length > errosComFormatador.length ||
    errosComFormatador.some(
      (x) => configuracoesErros[x.codigoErro]?.tipo !== TipoErro.Confirmacao
    )
  ) {
    checarResponse(response);
    return false;
  }

  for (const erro of errosComFormatador) {
    const config = configuracoesErros[erro.codigoErro];
    if (config?.tipo === TipoErro.Confirmacao) {
      const confirmacao = await exibirConfirmacao(
        config.titulo ?? "Atenção",
        renderToStringClient(
          config.formatador ? config.formatador(erro as any) : erro.mensagem
        )
      );

      if (!confirmacao) {
        return false;
      }
    }
  }

  return true;
}

export function checarResponse<T extends ResponseBase>(
  response: T,
  exibirErroComoModal?: boolean
): boolean {
  const notificacoes = response as unknown as NotificationsResponse;
  if (response.sucesso) {
    return true;
  }

  // Tratar os erros no novo formato

  const errosComFormatador = obterErrosConfigurados(response);
  if (errosComFormatador.length > 0) {
    for (const erro of errosComFormatador) {
      const config = configuracoesErros[erro.codigoErro];
      if (config?.tipo === TipoErro.Popup) {
        exibirAlerta(
          config.titulo ?? "Atenção",
          renderToStringClient(
            config.formatador ? config.formatador(erro as any) : erro.mensagem
          )
        );
      }

      if (config?.tipo === TipoErro.Toast) {
        exibirNotificacaoToast({
          tipo: config.tipoToast ?? TipoNotificacao.Erro,
          mensagem: config.formatador
            ? config.formatador(erro as any)
            : erro.mensagem,
        });
      }
    }
  }

  // A partir daqui: Tratamento para erros legado ou não configurados.

  const errosEstaoNoNovoFormato = notificacoes.erros.length > 0;
  notificacoes.erros = notificacoes.erros.filter(
    (x) => !(x.codigoErro in configuracoesErros)
  );

  if (!notificacoes?.model || notificacoes?.model?.length == 0) {
    if (exibirErroComoModal) {
      let html: string = "";
      if (errosEstaoNoNovoFormato) {
        // Exibir alerta com elementos individuais para cada erro
        // que não foi configurado.
        // Se todas já tiverem sido mostradas por estarem configuradas,
        // não fazemos nada.
        if (notificacoes.erros && notificacoes.erros.length > 0) {
          const ul = document.createElement("ul");
          for (const erro of notificacoes.erros) {
            const li = document.createElement("li");
            li.innerText = erro.mensagem;
            ul.appendChild(li);
          }
          html = ul.outerHTML;
        }
      } else {
        // Exibir erros na modalidade antiga, com todos concatenados
        // e divididos em linhas individuais.
        html = quebrarLinhasMensagem(response.mensagem);
      }
      if (html !== "") {
        exibirAlerta("Atenção", html);
      }
    } else {
      if (errosEstaoNoNovoFormato) {
        // Exibir notificações individuais para os novos erros
        // que não foram configurados.
        // Se todas já tiverem sido mostradas por estarem configuradas,
        // não fazemos nada.
        if (notificacoes.erros && notificacoes.erros.length > 0) {
          for (const erro of notificacoes.erros) {
            exibirNotificacaoToast({
              mensagem: erro.mensagem,
              tipo: TipoNotificacao.Erro,
            });
          }
        }
      } else {
        // Exibir notificações no modelo antigo, com todas em um único toast.
        exibirNotificacaoToast({
          mensagem: response.mensagem,
          tipo: TipoNotificacao.Erro,
        });
      }
    }
  } else if (
    notificacoes.model &&
    notificacoes?.model.length != 0 &&
    !ehItemOpcionalAssinaturaModel(notificacoes)
  ) {
    // Tratar erros de accordion, passando para o redux que vai
    // fazer o elemento de accordio na raiz renderizar.
    if (store) {
      notificacoes.titulo = JanelasDeNotificacaoTitulos.Atencao;
      store.dispatch(exibirAccordionNotifications(notificacoes));
    }
  } else if (
    notificacoes.model &&
    notificacoes?.model.length != 0 &&
    ehItemOpcionalAssinaturaModel(notificacoes)
  ) {
    if (store) {
      const dados = notificacoes as unknown as NecessitaOpcionaisResponse;
      store.dispatch(exibirNecessitaOpcionais(dados.model));
    }
  }

  return false;
}

function ehItemOpcionalAssinaturaModel(response: any) {
  const objeto = response?.model[0];

  return "codigo" in objeto && "descricao" in objeto && "resumo" in objeto;
}

export function checarResponseExibeMensagemExclusaoDeSucesso<
  T extends ResponseBase
>(response: T): boolean {
  const isSucesso = checarResponse(response);

  if (isSucesso) {
    exibirNotificacaoToast({
      mensagem: MensagensParaNotificacao.ExcluidoComSucesso,
      tipo: TipoNotificacao.Sucesso,
    });
  }

  return isSucesso;
}

export function checarResponseExibeMensagemExecutadoComSucesso<
  T extends ResponseBase
>(response: T): boolean {
  const isSucesso = checarResponse(response);

  if (isSucesso) {
    exibirNotificacaoToast({
      mensagem: response.mensagem,
      tipo: TipoNotificacao.Sucesso,
    });
  }

  return isSucesso;
}

export async function checarResponseBaixarArquivo(
  response: Blob | ResponseBase,
  nomeArquivo: string,
  extensaoArquivo?: string
): Promise<boolean> {
  const notificacoes = response as unknown as NotificationsResponse;
  const nomeCompletoArquivo = [nomeArquivo, extensaoArquivo].join(".");
  let sucesso = true;

  if (notificacoes.model && notificacoes?.model.length != 0) {
    if (store) {
      notificacoes.titulo = JanelasDeNotificacaoTitulos.Atencao;
      store.dispatch(exibirAccordionNotifications(notificacoes));
    }
    sucesso = false;
  } else if (notificacoes.sucesso === false && notificacoes.mensagem) {
    exibirNotificacaoToast({
      mensagem: notificacoes.mensagem,
      tipo: TipoNotificacao.Erro,
    });

    sucesso = false;
  } else {
    const blob = response as Blob;

    if (blob.type == "application/problem+json") {
      const text = await blob.text();
      console.error(JSON.parse(text));
      sucesso = false;
      exibirNotificacaoToast({
        mensagem: "Erro de execução.",
        tipo: TipoNotificacao.Erro,
      });
    } else if (blob.type == "application/json") {
      const objetoRetorno = JSON.parse(await blob.text());
      return checarResponse(objetoRetorno);
    } else {
      fileDownload(blob, nomeCompletoArquivo);
    }
  }

  return sucesso;
}

export function criarDataSourceSelectBoxLazy(
  dataSource: CustomStore,
  opcoes: SelectBoxLazyOpcoes
) {
  const ds = new DataSource({
    store: dataSource,
    paginate: true,
    pageSize: opcoes.quantidadeRegistros ?? 50,
  });

  if (opcoes.filtros) {
    ds.filter(opcoes.filtros.map((x) => [x.nomeCampo, x.operador, x.valor]));
  }

  const camposOrdenacao: SortDescriptor<any>[] = [];

  opcoes.camposOrdenacao.map((x) => {
    camposOrdenacao.push({ selector: x.nomeCampo, desc: x.desc });
  });

  ds.sort(camposOrdenacao);

  return ds;
}

function quebrarLinhasMensagem(conteudo: string) {
  const linhas = conteudo.split("\n");
  const elemento = document.createElement("span");

  for (let i = 0; i < linhas.length; i++) {
    if (i != 0) {
      elemento.appendChild(document.createElement("br"));
    }

    elemento.appendChild(document.createTextNode(linhas[i]));
  }

  return elemento.innerHTML;
}
