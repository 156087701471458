import { AxiosRequestConfig } from "axios";
import getApi from "../../configs/api";
import { ResponseBaseArquivo } from "../../models/api/comum/response-base";
import criarDatasourceGrid from "../../utils/grid/back-end-grid-utils";
import { NomesEndpoints } from "../comum/nomesEndpoints";

const nomeEndpoint: string = NomesEndpoints.Usuario;

function ObterGridSelecaoCondutorSource() {
  return criarDatasourceGrid(`${nomeEndpoint}/condutor-selecao-grid`);
}

async function Imprimir(loadOptions: string) {
  const api = getApi();

  const resquestConfig: AxiosRequestConfig = {
    responseType: "blob",
  };

  const response = await api.get<ResponseBaseArquivo>(
    `${nomeEndpoint}/Imprimir?${loadOptions}`,
    resquestConfig
  );

  return response;
}

export default {
  ObterGridSelecaoCondutorSource,
  Imprimir,
};
