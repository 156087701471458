import Form, { GroupItem } from "devextreme-react/form";
import { useEffect, useState } from "react";
import GitInfo from "react-git-info/macro";
import logo from "../../../assets/img/logo_menu.svg";
import { useAppDispatch, useAppSelector } from "../../../hooks/store.hooks";
import { BuildInformacoes } from "../../../models/api/versao/versao";
import APIBase from "../../../services/versao/versao.service";
import { ocultarModalSobre } from "../../../store/ui/ui.slice";
import { formatarDataHoraComFormato } from "../../../utils/formatadores/formatador-de-datas";
import { FormTextBoxSimples } from "../../formularios";
import { Modal } from "../../layout/modal";

const gitInfo = GitInfo();

const detalhesDaVersaoInicial: BuildInformacoes = {
  branchComUltimoCommit: "",
  dataHora: "",
};

export default function ModalSobre() {
  const dispatch = useAppDispatch();

  const modalVisivel = useAppSelector(
    (state) => state.estadoUI.modalSobre.visivel
  );

  const [detalhesVersao, setDetalhesVersao] = useState<BuildInformacoes>(
    detalhesDaVersaoInicial
  );

  useEffect(() => {
    if (!modalVisivel) {
      return;
    }

    const fetchData = async () => {
      try {
        const result = await APIBase.obterVersao();
        setDetalhesVersao(result.model);
      } catch (error) {
        setDetalhesVersao(detalhesDaVersaoInicial);
      }
    };

    fetchData();
  }, [modalVisivel]);

  return (
    <>
      <Modal
        visivel={modalVisivel}
        arrastarAtivado={false}
        fecharAoClicarFora={false}
        titulo="Sobre"
        larguraMaxima={"max(30vw, 550px)"}
        altura="auto"
        onFechar={() => dispatch(ocultarModalSobre())}
      >
        <div
          style={{
            display: "flex",
            alignContent: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={logo}
            style={{
              margin: "5px",
              height: "60px",
            }}
            alt={"maxiprod_logo"}
          />
          <p
            style={{
              fontSize: "12pt",
              fontWeight: "bold",
              textAlign: "center",
              fontStyle: "italic",
            }}
          >
            MAXIPROD
          </p>
        </div>
        <Form colCount={2}>
          <GroupItem caption="Back-end">
            <FormTextBoxSimples
              valor={detalhesVersao.branchComUltimoCommit}
              titulo="Versão"
              somenteLeitura={true}
            />
            <FormTextBoxSimples
              valor={detalhesVersao.dataHora}
              titulo="Data"
              somenteLeitura
            />
          </GroupItem>
          <GroupItem caption="Front-end">
            <FormTextBoxSimples
              valor={`${gitInfo.branch} (${gitInfo.commit.shortHash})`}
              titulo="Versão"
              somenteLeitura={true}
            />
            <FormTextBoxSimples
              valor={formatarDataHoraComFormato(
                gitInfo.commit.date,
                "dd/MM/yy HH:mm"
              )}
              titulo="Data"
              somenteLeitura
            />
          </GroupItem>
        </Form>
      </Modal>
    </>
  );
}
