import styled from "styled-components";

export const ContainerLinks = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 2em;
`;

export const ContainerLogo = styled.div`
  display: flex;
  justify-content: space-around;
  padding-bottom: 1em;
`;

export const MensagemErro = styled.span`
  color: red;
`;

export const ContainerBotaoOauthInvalido = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 2em;
  align-items: flex-end;
`;

export const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  vertical-align: middle;
  display: inline-flex;
  padding: 1px;

  &:not(:last-of-type) {
    margin-right: 2px;
  }
`;
