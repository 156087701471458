export default interface DocumentoFiscalSelecaoMdfeGridModel {
  id: number;
  numero: string;
  serie: string;
  chaveAcesso: string;
  idDestinatario: number;
  destinatario: string;
  idMunicipioDestino: number;
  municipioDestino: string;
  idUfDestino: number;
  ufDestino: string;
  valor: number;
  pesoLiquido: number;
  pesoBruto: number;
  dataEmissao: string;
  tipoDocumentoFormatado: string;
  idNotaFiscal: number;
}

export const novoRegistroDocumentoFiscalSelecaoMdfeGrid: DocumentoFiscalSelecaoMdfeGridModel =
  {
    id: 0,
    chaveAcesso: "",
    numero: "",
    serie: "",
    dataEmissao: "",
    idDestinatario: 0,
    destinatario: "",
    idUfDestino: NaN,
    ufDestino: "",
    idMunicipioDestino: 0,
    municipioDestino: "",
    valor: 0,
    pesoBruto: 0,
    pesoLiquido: 0,
    tipoDocumentoFormatado: "",
    idNotaFiscal: 0,
  };
