import { useEffect } from "react";
import { useAppSelector } from "../../hooks/store.hooks";
import {
  RemoverSessaoReduxELocalStorage,
  VerificaSeRotaPertenceAoLoginOAuth,
} from "../../utils/oauth/oauth-utils";
import { atualizarToken } from "../api";
import RotasAnonimo from "./anonimo.routes";
import RotasAutenticado from "./autenticado.routes";

export default function Rotas() {
  const autenticado = useAppSelector(
    (state) => state.sessao.usuarioAutenticado
  );

  useEffect(() => {
    if (!autenticado) {
      return;
    }

    // Evita que redirects entre MXP1 <-> MXP2 façam refresh e obtenha dados da sessão
    if (VerificaSeRotaPertenceAoLoginOAuth(window.location.pathname)) {
      return;
    }

    try {
      atualizarToken("atualizando", {}, false);
    } catch (error) {
      RemoverSessaoReduxELocalStorage(true);
    }
  }, [autenticado]);

  return autenticado ? <RotasAutenticado /> : <RotasAnonimo />;
}
