import { Button } from "devextreme-react";
import parseToHtml from "html-react-parser";
import { Notificacao } from "../../models/api/notificacoes/notificacao";

import "./render-alerta.scss";

interface AlertaProps {
  notificacaoSlide?: Notificacao;
  setNotificacaoSlide: (x: Notificacao | undefined) => void;
}

export default function AlertaSlide({
  notificacaoSlide,
  setNotificacaoSlide,
}: AlertaProps) {
  const alerta =
    notificacaoSlide != undefined ? (
      <div className={"mxp-alerta"}>
        <div className={"mxp-alerta-texto"}>
          {parseToHtml(notificacaoSlide.mensagem)}
        </div>
        <Button
          icon={
            "ic-material-symbols-outlined alerta-icone " +
            (notificacaoSlide.lido ? "ic-visibility" : "ic-visibility-off")
          }
          stylingMode="text"
          onClick={() => {
            setNotificacaoSlide({
              ...notificacaoSlide,
              lido: true,
            });
          }}
        ></Button>
      </div>
    ) : (
      <></>
    );

  return alerta;
}
