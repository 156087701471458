import styled, { keyframes } from "styled-components";

const expandirComParametros = (
  filhos?: number,
  filhosAnteriores?: number
) => keyframes`
    0% {
      height: ${
        filhosAnteriores != undefined ? `${filhosAnteriores * 18}px` : "auto"
      };
    }
    100% {
      height: ${filhos != undefined ? `${filhos * 18}px` : "auto"};
    }
`;

const expandir = keyframes`
    0% {
      opacity: 0;
      height: 0px;
      margin-bottom: 0rem;
    }
    50% {
      opacity: 0;
      height: 0px;
      margin-bottom: 1rem;
    }
    100% {
      opacity: 1;
      height:  1rem;
      margin-bottom: 0rem;
    }
`;

export const LabelErro = styled.small<{
  $filhos?: number;
  $filhosAnteriores?: number;
}>`
  overflow: hidden;
  display: block;
  color: red;
  list-style-type: none;
  animation: ${(props) =>
      expandirComParametros(props.$filhos, props.$filhosAnteriores)}
    0.25s ease-in-out forwards;
  li {
    animation: ${expandir} 0.5s ease-in-out forwards;
  }
`;
