import { DataGrid } from "devextreme-react";
import {
  Column,
  DataGridRef,
  DataGridTypes,
} from "devextreme-react/cjs/data-grid";
import { RowPreparedEvent } from "devextreme/ui/data_grid";
import { useCallback, useEffect, useMemo, useRef } from "react";
import GridColunaAcoes from "../../../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { ModalMxp } from "../../../../../../../components/layout/modal-mxp";
import { useRegistrarAtalhosGrid } from "../../../../../../../hooks/atalhos.hooks";
import { useControlarFormDeEdicao } from "../../../../../../../hooks/form.hooks";
import { useMenuDeContextosGrid } from "../../../../../../../hooks/menus.hooks";
import { useParametroId } from "../../../../../../../hooks/route.hooks";
import { ContaDeCustosDaEcfOperacao } from "../../../../../../../models/api/conta-de-custos-ecf/conta-de-custos-ecf";
import { PermissoesInformativoComposicaoCustosLucroReal } from "../../../../../../../models/permissoes/fiscal/informativo-composicao-custos-lucro-real/permissoes-informativo-composicao-lucro-real";
import { ResultadoAcaoFormulario } from "../../../../../../../models/shared/ui/formularios";
import GetColunasDeAuditoria from "../../../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import criarNameof from "../../../../../../../utils/common/cria-name-of";
import NomesModais from "../../../../../../../utils/common/nomes-modais";
import NormalizaTituloModal from "../../../../../../../utils/common/normaliza-titulo";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../../../utils/common/permissoes-utils";
import { ItemContextMenuMxp } from "../../../../../../../utils/context-menu/context-menu-utils";
import { GestorEventoClickUnicaLinha } from "../../../../../../../utils/context-menu/gestor-evento-click";
import { formatarNumero } from "../../../../../../../utils/formatadores/formatador-de-numeros";
import GridBuilder from "../../../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../../../utils/grid/padroes-colunas";
import UrlUtils from "../../../../../../../utils/url/url-utils";
import { LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfGridModel } from "../../../../models/linha-apuracao-informativo-composicao-custos-ecf";
import LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfService from "../../../../servicos/linha-apuracao-informativo-composicao-custos-ecf";
import ModalEdicaoValorLinhaApuracaoInformativoComposicaoCustos from "../modal";

const nameOfGridHandler =
  criarNameof<LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfGridModel>();
const service =
  new LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfService();

interface GridInternaLinhaApuracaoInformativoComposicaoCustosEcfProps {
  idApuracaoDoDoInformativoDaComposicaoDeCustosDaEcf: number;
}

function formatarLinhasGrid(e: RowPreparedEvent<any, any>) {
  if (e.rowType != "data") {
    return;
  }

  const dados: LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfGridModel =
    e.data;

  if (
    dados.operacao == ContaDeCustosDaEcfOperacao.Incremento ||
    dados.operacao == ContaDeCustosDaEcfOperacao.Decremento
  ) {
    return;
  }

  if (dados.operacao == ContaDeCustosDaEcfOperacao.Separador) {
    e.rowElement.setAttribute("style", "background-color: #dcdcdc;");
  } else if (dados.operacao == ContaDeCustosDaEcfOperacao.Totalizador) {
    e.rowElement.setAttribute("style", "font-weight: bold;");
  }
}

export default function GridInternaGridInternaLinhaApuracaoInformativoComposicaoCustosEcf(
  props: GridInternaLinhaApuracaoInformativoComposicaoCustosEcfProps
) {
  const gridRef = useRef<DataGridRef>(null);
  const parametroId = useParametroId();

  const dataSource = service.GetGridSourceDaApuracao(
    props.idApuracaoDoDoInformativoDaComposicaoDeCustosDaEcf
  );

  const {
    idRegistroEmEdicao,
    setIdRegistroEmEdicao,
    modalVisivel,
    encerrarEdicao,
  } = useControlarFormDeEdicao(NaN);

  const gridController =
    new GridController<LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfGridModel>(
      () => gridRef.current?.instance()
    );

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  const handleEditarRegistro = useCallback(
    async (
      registro: LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfGridModel
    ) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesInformativoComposicaoCustosLucroReal.InserirEditar,
        ])
      ) {
        return;
      }

      setIdRegistroEmEdicao(registro.id);
    },
    []
  );

  const acoesDeContexto: ItemContextMenuMxp[] = [
    {
      text: "Editar",
      icon: "ic-material-symbols-outlined ic-edit",
      hint: "Editar",
      exibirNaLinhaDaGrid: "sempre",
      exibirNoMenuPrincipal: false,
      atalho: { tecla: "E", ctrl: true },
      gestorEventoClick: new GestorEventoClickUnicaLinha(
        handleEditarRegistro,
        () => gridController
      ),
      visivel: (
        registro: LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfGridModel
      ) =>
        registro.operacao == ContaDeCustosDaEcfOperacao.Incremento ||
        registro.operacao == ContaDeCustosDaEcfOperacao.Decremento,
    },
  ];

  useMenuDeContextosGrid(acoesDeContexto);

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "grid-linha-apuracao-informativo-composicao-custos-ecf-editform-apuracao",
      () => gridRef.current?.instance(),
      true
    )
      .definirStyles({ height: "100%" })
      .definirDataSource(dataSource)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirMenuDeContexto(acoesDeContexto)
      .definirFormatadorDeLinhas(formatarLinhasGrid)
      .build();
  }, []);

  useEffect(() => {
    if (parametroId) {
      setIdRegistroEmEdicao(parametroId);
    }
  }, []);

  useRegistrarAtalhosGrid<LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfGridModel>(
    {
      controller: gridController,
      handleEditar: handleEditarRegistro,
    }
  );

  const handleCallbackFormulario = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      encerrarEdicao();

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }

      UrlUtils.RemoverParametroId(parametroId);
    },
    [parametroId]
  );

  return (
    <>
      <DataGrid ref={gridRef} {...configuracoesGrid}>
        {GridColunaAcoes<LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfGridModel>(
          {}
        )}
        <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />

        <Column
          key={nameOfGridHandler("codigo")}
          dataField={nameOfGridHandler("codigo")}
          {...obterConfiguracaoColuna("stringP")}
          caption="Código"
          width={70}
        />
        <Column
          key={nameOfGridHandler("descricao")}
          dataField={nameOfGridHandler("descricao")}
          {...obterConfiguracaoColuna("stringXXG")}
          caption="Descrição"
          width={600}
        />
        <Column
          key={nameOfGridHandler("valor")}
          dataField={nameOfGridHandler("valor")}
          {...obterConfiguracaoColuna("monetario")}
          caption="Valor"
          cellRender={(data: DataGridTypes.ColumnCellTemplateData) => {
            const dadosLinha: LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfGridModel =
              data.row.data;

            const valorFormatado =
              dadosLinha.valor != undefined
                ? formatarNumero(dadosLinha.valor, 2)
                : "";

            if (dadosLinha.valorEstaFixo) {
              return (
                <>
                  {valorFormatado}
                  <span
                    style={{ fontSize: "16px", marginLeft: "2px" }}
                    className={`ic-material-symbols-outlined ic-keep`}
                    title="O valor está fixo"
                  ></span>
                </>
              );
            }

            return <>{valorFormatado}</>;
          }}
        />
        {GetColunasDeAuditoria()}
      </DataGrid>

      <ModalMxp
        titulo={NormalizaTituloModal.Normalizar(
          idRegistroEmEdicao,
          NomesModais.valorDaLinhaDoInformativoDeComposicaoDeCustos
        )}
        visivel={modalVisivel}
        handleFechar={encerrarEdicao}
        largura={"max(30vw, 600px)"}
      >
        <ModalEdicaoValorLinhaApuracaoInformativoComposicaoCustos
          idRegistroEmEdicao={idRegistroEmEdicao}
          handleCallback={handleCallbackFormulario}
        />
      </ModalMxp>
    </>
  );
}
