import getApi from "../../configs/api";
import { ListaSimplesResponse } from "../../models/api/comum/response-base";
import { NomesEndpoints } from "../comum/nomesEndpoints";

export default class UnidadeMedidaService {
  private _nomeEndpoint: NomesEndpoints;

  constructor() {
    this._nomeEndpoint = NomesEndpoints.UnidadeMedida;
  }

  public async ObterListaSimples() {
    const response = await getApi().get<ListaSimplesResponse>(
      `${this._nomeEndpoint}/lista-simples`
    );

    return response.data;
  }
}
