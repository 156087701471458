import { TextBox } from "devextreme-react";
import { LabelMode } from "devextreme/common";
import { TextBoxType } from "devextreme/ui/text_box";
import IconeAjuda from "../../ajuda/icone-ajuda";
import LinkAjuda from "../../ajuda/link-ajuda";

interface FormTextBoxSimplesProps {
  idAjuda?: string;
  tabIndex?: number;
  titulo: string;
  valor: string;
  toolTip?: string;
  tipo?: TextBoxType;
  placeholder?: string;
  tipoPlaceholder?: LabelMode;
  exibirBotaoLimpar?: boolean;
  somenteLeitura?: boolean;
  desabilitado?: boolean;
  requerido?: boolean;
  tamanhoMaximo?: number;
  mask?: string;
  onEnter?: (e: any) => void;
}

export default function FormTextBoxSimples(props: FormTextBoxSimplesProps) {
  return (
    <div>
      <label>
        <span className="dx-field-item-label-content">
          <span className="dx-field-item-label-text">{props.titulo}</span>
          {props.requerido && (
            <span className="dx-field-item-required-mark">&nbsp;*</span>
          )}
          {props.idAjuda && (
            <LinkAjuda keyAjuda={props.idAjuda}>
              <IconeAjuda />
            </LinkAjuda>
          )}
        </span>
      </label>
      <TextBox
        tabIndex={props.tabIndex}
        hint={props.toolTip}
        mode={props.tipo}
        label={props.placeholder}
        labelMode={props.tipoPlaceholder}
        readOnly={props.somenteLeitura}
        disabled={props.desabilitado}
        showClearButton={props.exibirBotaoLimpar}
        maxLength={props.tamanhoMaximo}
        value={props.valor}
        mask={props.mask}
        onEnterKey={props.onEnter}
        inputAttr={{
          id: props.idAjuda,
        }}
      />
    </div>
  );
}
