import { Accordion } from "devextreme-react";
import { Modal } from "../../layout/modal";
import { AccordionModel } from "./modal-accordion";
import { AccordionWrapper } from "./style";

/**
 * Propriedades dos modais do tipo accordion.
 *
 * @interface ModalAccordionProps
 * @member {boolean} modalAccordionVisivel Variável de controle utilizada para exibir ou esconder o modal.
 * @member {() => void} handlerFecharModalAccordion Função executada ao clicar no botão de fechar do modal.
 * @member {AccordionModel[] | undefined} dataSource Lista com os dados para serem exibidos no accordion.
 * @member {string?} modalTitulo Título do modal do accordion.
 * @member {string} accordionId String de identificação do elemento HTML do accordion.
 * @member {string} accordionGrupoTituloTamanhoFonte Tamanho da fonte do título dos itens do accordion que agrupam as mensagens.
 * @member {string} accordionGrupoTituloPesoFonte Peso da fonte do título dos itens do accordion que agrupam as mensagens.
 * @member {number | string} itemDoAccordionAltura? Altura dos itens do accordion que agrupam as mensagens.
 */
export interface ModalAccordionProps {
  modalAccordionVisivel: boolean;
  handlerFecharModalAccordion: () => void;
  dataSource: AccordionModel[] | undefined;
  modalTitulo?: string;
  accordionId: string;
  accordionGrupoTituloTamanhoFonte?: string;
  accordionGrupoTituloPesoFonte?: string;
  itemDoAccordionAltura?: number | string;
}

export default function ModalAccordion({
  modalAccordionVisivel,
  handlerFecharModalAccordion,
  dataSource,
  modalTitulo,
  accordionId,
  accordionGrupoTituloTamanhoFonte: tituloTamanhoFonte,
  accordionGrupoTituloPesoFonte: tituloPesoFonte,
  itemDoAccordionAltura,
}: ModalAccordionProps) {
  const customTitleRender = (item: AccordionModel) => {
    return (
      <div
        style={{
          fontSize: tituloTamanhoFonte ?? "13px",
          fontWeight: tituloPesoFonte ?? "500",
        }}
      >
        {item.grupoDeMensagensTitulo}
      </div>
    );
  };

  const customItemRender = (item: AccordionModel) => {
    return item.mensagens.map((item, ix) => (
      <p key={`p-${ix}`}>&#x2022; {item}</p>
    ));
  };

  return (
    <Modal
      visivel={modalAccordionVisivel}
      arrastarAtivado={false}
      fecharAoClicarFora={false}
      titulo={modalTitulo ?? "Atenção"}
      altura="auto"
      largura={"max(50vw, 750px)"}
      onFechar={() => handlerFecharModalAccordion()}
    >
      <AccordionWrapper itemDoAccordionAltura={itemDoAccordionAltura ?? 32}>
        <Accordion
          dataSource={dataSource}
          collapsible={true}
          multiple={false}
          animationDuration={150}
          itemTitleRender={customTitleRender}
          itemRender={customItemRender}
          id={accordionId}
        />
      </AccordionWrapper>
    </Modal>
  );
}
