import Decimal from "decimal.js";
import AuditavelDTO from "../../../../models/api/comum/auditavel-dto";
import AuditavelGridModel from "../../../../models/api/comum/auditavel-grid";
import { CadastrarEmMassaExcelBase } from "../../../../models/api/comum/cadastrar-varios";
import { ErroApi } from "../../../../models/api/comum/erros";
import { SelecionarEstoqueDestinoRequest } from "../../../estoque/estoque-movimentacao/componentes/partes/selecionar-estoque-destino/selecionar-estoque-destino";
import { EstoqueMovimentacaoTipo } from "../../../estoque/estoque-movimentacao/models/estoque-movimentacao.enums";
import {
  RastreabilidadePorLoteRequest,
  RastreabilidadePorNumeroDeSerieRequest,
} from "../../../estoque/lote/componentes/formulario-cadastro-de-rastreabilidade";
import { EstocagemTipo } from "../../../itens/item/models/item.enums";
import { ArquivoBaseModel } from "../../../sistema/arquivo/models/arquivo.api";
import { OrdemDeProducaoEstado } from "../utils/enums/ordem-de-producao-enums";
import { RetiradaDeMaterialValorTipo } from "../utils/enums/retirada-de-material.enums";

export interface OrdemDeProducaoGridModel extends AuditavelGridModel {
  id: number;
  numero?: number | null;
  quantidade: number;
  quantidadeConcluida: number;
  quantidadeAConcluir: number;
  necessidadeData: Date;
  itemId?: number;
  itemCodigo?: string | null;
  itemDescricao?: string | null;
  itemDescricaoComplementar?: string | null;
  itemUnidadePrincipalCodigo?: string | null;
  estadoDecodificado: string;
  grupoCodigo?: string | null;
  estadoConfiguravel?: string | null;
  grupoDescricao?: string | null;
  itemDesenho: string;
  itemDesenhoRevisao?: string | null;
  quantidadeDePerdaPrevista: number;
  quantidadeBruta: number;
  quantidadeNaUnidadeDeVenda: number;
  itemUnidadeDeVendaCodigo?: string | null;
  itemPesoLiquidoUnitario?: number | null;
  pesoLiquidoTotal?: number | null;
  itemPesoBrutoUnitario?: number | null;
  pesoBrutoTotal?: number | null;
  emissaoData?: Date | null;
  emitidoPorApelido?: string | null;
  quantidadeSobra: number;
  origemDecodificada: string;
  inicioPrevistoOuRealizadoData?: Date | null;
  fimPrevistoOuRealizadoData?: Date | null;
  tempoRealizadoEmHoras?: number | null;
  observacoes?: string | null;
  tempoPrevistoEmHoras?: number | null;
  quantidadeOuTempoRealizadoEmPercentual: number;
  quantidadeConcluidaEmPercentual: number;
  maoDeObraCusto: number;
  insumosDisponibilidadeDecodificada: string;
  insumosBaixasEstadoDecodificado: string;
  itemPontoDeRessuprimento: number;
}

export interface OrdemDeProducaoRequest {
  id: number;
  numero: number;
  itemId: number | null;
  quantidade: number;
  fixarQuantidade: boolean;
  quantidadeDePerdaPrevista?: number | null;
  quantidadeBruta?: number | null;
  necessidadeData: Date;
  fixarNecessidadeData: boolean;
  estado: OrdemDeProducaoEstado;
  estocagemPor?: EstocagemTipo;
  estadoConfiguravelId?: number | null;
  observacoes: string;
  arquivos: Array<ArquivoBaseModel>;
}

export interface OrdemDeProducaoCadastrarVariosExcelRequest
  extends CadastrarEmMassaExcelBase {
  codigoItem: string;
  quantidade: number;
  necessidadeData: Date;
  quantidadeDePerdaPrevista: number | null;
  observacoes: string | null;
}

export interface OrdemDeProducaoResponse
  extends OrdemDeProducaoRequest,
    AuditavelDTO {
  numero: number;
  itemDescricao: string;
}

export const quantidadeDePerdaPrevistaMinima: Decimal = new Decimal(0);
export const quantidadeDePerdaPrevistaMaxima: Decimal = new Decimal(
  9_999_999_999.99999
);

export interface ConcluirOrdemDeProducaoRequest
  extends SelecionarEstoqueDestinoRequest,
    RastreabilidadePorLoteRequest,
    RastreabilidadePorNumeroDeSerieRequest {
  idOrdemDeProducao: number;
  itemId: number;
  quantidade: number;
  itemEstocagemTipo: EstocagemTipo;
  itemUsaQuantidadeInteira: boolean;
  fecharOp: boolean;
  destino: EstoqueMovimentacaoTipo;
  idContaContabilDestino?: number;
  idDono?: number;
  idPosse?: number;
  forcarConclusao: boolean;
  itemUtilizaPrefixo?: boolean;
  rastreabilidadeLoteId?: number;
  idEnderecoDeEstoque?: number;
  codigoOrdemDeProducao: string;
  codigoItem: string;
}

export interface RetiradaDeMaterialRequest
  extends SelecionarEstoqueDestinoRequest,
    RastreabilidadePorLoteRequest {
  id: number;
  idOrdemDeProducao: number | null;
  operacaoDeOrdemDeProducaoId: number | null;
  quantidade: number;
  valorUnitario: number;
  idInsumoOrigemValor?: number | null;
  valorUnitarioTipoOrigem: RetiradaDeMaterialValorTipo;
  itemId: number | null;
}

export interface DadosItemDeRetiradaDeMaterial {
  operacaoDeOrdemDeProducaoId: number | null;
  idContaContabilDestino: number | null;
}

export interface AcaoBaseOrdemDeProducaoResponse {
  indiceDoRegistro: number;
  ordemDeProducaoNumero: string;
  listaDeErros: ErroApi[];
  valido: boolean;
}

export type SuspenderOrdemDeProducaoResponse = AcaoBaseOrdemDeProducaoResponse;

export type ConfirmarOrdemDeProducaoResponse = AcaoBaseOrdemDeProducaoResponse;

export type CancelarOrdemDeProducaoResponse = AcaoBaseOrdemDeProducaoResponse;
