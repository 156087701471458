import styled from "styled-components";

export const Header = styled.header.attrs((props) => {
  props.className = "sub-header-component";
})`
  display: inline-flex;
  flex: 0 0 auto;
  width: 100%;
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.1);

  .dx-menu .dx-menu-item .dx-menu-item-content {
    padding: 4px 6px;
    font-weight: 500;
    align-items: center;
  }
`;

export const TituloContainer = styled.div`
  margin: 4px 20px;
  opacity: 0.7;
`;

export const TituloWrapper = styled.div`
  display: flex;
`;

export const Titulo = styled.h3`
  font-weight: 500 !important;
  font-size: 18px !important;
  margin: 0px;
  padding-right: 0.3em;
`;

export const Area = styled.p`
  font-weight: 300;
  font-size: 10px;
  margin: 0px;
  margin-bottom: -6px;
`;

export const Conteudo = styled.div`
  padding: 5px 15px;
`;

export const Separador = styled.div`
  font-weight: 300;
  font-size: 24px;
  opacity: 0.2;
  padding-right: 20px;
  align-self: center;
`;
