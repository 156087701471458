import getApi from "../../configs/api";
import {
  LinkAjudaRequestDTO,
  LinkAjudaResponseDTO,
} from "../../models/api/ajuda/link-ajuda";
import {
  ItemListaSimplesStringDTO,
  ResponseBase,
  ResponseModel,
} from "../../models/api/comum/response-base";
import { criarDatasourceGridComParametros } from "../../utils/grid/back-end-grid-utils";

const nomeEndpoint: string = "LinkDaAjuda";

function getGridSource(codigo: string) {
  return criarDatasourceGridComParametros(`${nomeEndpoint}/grid`, { codigo });
}

async function obter(codigo: string) {
  const api = getApi();
  const response = await api.get<ResponseModel<LinkAjudaResponseDTO>>(
    `${nomeEndpoint}?codigo=${codigo}`
  );

  return response.data;
}

async function obterPorId(id: number) {
  const api = getApi();
  const response = await api.get<ResponseModel<LinkAjudaResponseDTO>>(
    `${nomeEndpoint}/por-id?id=${id}`
  );

  return response.data;
}

async function inserirOuAtualizar(model: LinkAjudaRequestDTO) {
  const api = getApi();
  const response = await api.post<ResponseModel<LinkAjudaResponseDTO>>(
    nomeEndpoint,
    model
  );

  return response.data;
}

async function inserirPorId(model: LinkAjudaRequestDTO) {
  const api = getApi();
  const response = await api.post<ResponseModel<LinkAjudaResponseDTO>>(
    `${nomeEndpoint}/por-id`,
    model
  );

  return response.data;
}

async function alterarPorId(model: LinkAjudaRequestDTO) {
  const api = getApi();
  const response = await api.put<ResponseModel<LinkAjudaResponseDTO>>(
    `${nomeEndpoint}/por-id`,
    model
  );

  return response.data;
}

async function excluir(codigo: string) {
  const api = getApi();
  const response = await api.delete<ResponseBase>(
    `${nomeEndpoint}?codigo=${codigo}`
  );

  return response.data;
}

async function excluirPorId(id: number) {
  const api = getApi();
  const response = await api.delete<ResponseBase>(
    `${nomeEndpoint}/por-id?id=${id}`
  );

  return response.data;
}

async function obterListaSimples() {
  const api = getApi();
  const response = await api.get<ResponseModel<ItemListaSimplesStringDTO[]>>(
    `${nomeEndpoint}/lista-simples`
  );

  return response.data;
}

async function obterListaLinksComCache(renovar: boolean) {
  const api = getApi();
  const response = await api.get<ResponseModel<LinkAjudaResponseDTO[]>>(
    `${nomeEndpoint}/lista-links-com-cache?renovar=${renovar}`
  );

  return response.data;
}

export default {
  obter,
  obterPorId,
  inserirOuAtualizar,
  inserirPorId,
  alterarPorId,
  excluir,
  excluirPorId,
  obterListaSimples,
  obterListaLinksComCache,
  getGridSource,
};
