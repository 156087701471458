import styled from "styled-components";

export const LinkButtonStyleComponent = styled.a<{ $sublinhado?: boolean }>`
  text-decoration: ${(props) => (props.$sublinhado ? "underline" : "none")};
  cursor: pointer;
  vertical-align: middle;
  display: inline-flex;
  padding: 1px;

  &:not(:last-of-type) {
    margin-right: 2px;
  }
`;
