import TabContainer from "../../../../../../components/layout/tab-container";
import GridEmbutidaTarefasEAnotacoes from "../../../../../producao/ordem-de-producao/componentes/grid-embutida/tarefas-e-anotacoes";

interface TarefaOuAnotacaoAbaTarefasEAnotacoesProps {
  tarefaGeradoraId: number;
}
export default function TarefaOuAnotacaoAbaTarefasEAnotacoes(
  props: TarefaOuAnotacaoAbaTarefasEAnotacoesProps
) {
  return (
    <TabContainer>
      <GridEmbutidaTarefasEAnotacoes
        tarefaGeradoraId={props.tarefaGeradoraId}
      />
    </TabContainer>
  );
}
