import { Button } from "devextreme-react";

interface BotaoRevogarTokenProps {
  tokenGerado: boolean;
  handleClick: () => void;
}

export default function BotaoRevogarToken(props: BotaoRevogarTokenProps) {
  return (
    <Button
      type="normal"
      text={"Revogar"}
      icon={"trash"}
      onClick={props.handleClick}
      disabled={!props.tokenGerado}
    />
  );
}
