import DataGrid, { Column, DataGridRef } from "devextreme-react/cjs/data-grid";
import { useCallback, useEffect, useMemo, useRef } from "react";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useControlarFormDeEdicao } from "../../../../../hooks/form.hooks";
import { useParametroId } from "../../../../../hooks/route.hooks";
import { useSeletorGridMxp } from "../../../../../hooks/seletor.hooks";
import { decodificaBooleanoEmSimNao } from "../../../../../models/api/comum/decodificadores";
import { PermissoesContaParteB } from "../../../../../models/permissoes/contabilidade/conta-parte-b/permissoes-conta-parte-b";
import { ResultadoAcaoFormulario } from "../../../../../models/shared/ui/formularios";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesModais from "../../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import NormalizaTituloModal from "../../../../../utils/common/normaliza-titulo";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import UrlUtils from "../../../../../utils/url/url-utils";
import { ContaDaParteBGridModel } from "../../models/conta-parte-b";
import { ContaParteBService } from "../../servicos/conta-parte-b.service";
import EditFormContaParteB from "../formulario";

const service = new ContaParteBService();

const dataSource = service.ObterDataSourceParaGrid<ContaDaParteBGridModel>();

const nameOfGridHandler = criarNameof<ContaDaParteBGridModel>();

export default function GridContaDaParteB(
  props: GridMxpProps<ContaDaParteBGridModel>
) {
  const gridRef = useRef<DataGridRef>(null);
  const parametroId = useParametroId();

  const {
    idRegistroEmEdicao,
    setIdRegistroEmEdicao,
    modalVisivel,
    encerrarEdicao,
  } = useControlarFormDeEdicao(NaN);

  useSeletorGridMxp(() => gridRef.current, props.filtrosNoCliente);

  const handleNovoRegistro = useCallback(() => {
    if (
      !verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesContaParteB.InserirEditar,
      ])
    ) {
      return;
    }

    setIdRegistroEmEdicao(0);
  }, []);

  const handleEditarRegistro = useCallback(
    (registro: ContaDaParteBGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesContaParteB.InserirEditar,
        ])
      ) {
        return;
      }

      setIdRegistroEmEdicao(registro.id);
    },
    []
  );

  const handleExcluirRegistro = useCallback(
    async (registro: ContaDaParteBGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesContaParteB.Excluir,
        ])
      ) {
        return;
      }

      const excluir = await exibirConfirmacao(
        "Confirmar exclusão",
        obterMensagemExclusao(registro)
      );

      try {
        if (excluir) {
          const resposta = await service.Excluir(registro.id);

          if (resposta) {
            checarResponseExibeMensagemExclusaoDeSucesso(resposta);
            handleAtualizarGrid();
          }
        }
      } catch (erro) {
        tratarErroApi(erro);
      }
    },
    []
  );

  function obterMensagemExclusao(registro: ContaDaParteBGridModel) {
    return `Tem certeza que deseja excluir o registro ${registro.descricao}?`;
  }

  const gridController = new GridController<ContaDaParteBGridModel>(() =>
    gridRef.current?.instance()
  );

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "conta-parte-b",
      () => gridRef.current?.instance(),
      false,
      props.filtrosNoCliente
    )
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.contaDaParteB)
      .definirBotaoNovo(handleNovoRegistro)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .definirDuploCliqueLinha(handleEditarRegistro)
      .build();
  }, [props.filtrosNoCliente]);

  useEffect(() => {
    if (parametroId) {
      setIdRegistroEmEdicao(parametroId);
    }
  }, []);

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  useRegistrarAtalhosGrid<ContaDaParteBGridModel>({
    controller: gridController,
    handleNovo: handleNovoRegistro,
    handleEditar: handleEditarRegistro,
    handleExcluir: handleExcluirRegistro,
  });

  const handleCallbackFormulario = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      encerrarEdicao();

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }

      UrlUtils.RemoverParametroId(parametroId);
    },
    [parametroId]
  );

  const handleFecharModal = useCallback(() => {
    encerrarEdicao();
    UrlUtils.RemoverParametroId(parametroId);
  }, [parametroId]);

  return (
    <>
      <ProvedorAjuda id={"tooltips-grid-conta-parte-b"}>
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          {GridColunaAcoes<ContaDaParteBGridModel>({
            handleEditar: handleEditarRegistro,
            handleExcluir: handleExcluirRegistro,
          })}
          <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
          <Column
            key={nameOfGridHandler("codigo")}
            dataField={nameOfGridHandler("codigo")}
            {...obterConfiguracaoColuna("stringG")}
            caption="Código"
          />
          <Column
            key={nameOfGridHandler("descricao")}
            dataField={nameOfGridHandler("descricao")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Descrição"
          />
          <Column
            key={nameOfGridHandler("tipoTributoDecodificado")}
            dataField={nameOfGridHandler("tipoTributoDecodificado")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Tipo do tributo"
          />
          <Column
            key={nameOfGridHandler("dataCriacaoDaConta")}
            dataField={nameOfGridHandler("dataCriacaoDaConta")}
            {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
            caption="Data de criação da conta"
            width={200}
          />
          <Column
            key={nameOfGridHandler("codigoPadraoDaParteB")}
            dataField={nameOfGridHandler("codigoPadraoDaParteB")}
            {...obterConfiguracaoColuna("stringG")}
            caption="Código padrão da parte B"
            width={220}
          />
          <Column
            key={nameOfGridHandler("ativo")}
            dataField={nameOfGridHandler("ativo")}
            {...obterConfiguracaoColuna("boolSimNao")}
            caption="Ativo"
            cellRender={({ data }) => {
              const dados = data as ContaDaParteBGridModel;
              return decodificaBooleanoEmSimNao(dados.ativo);
            }}
          />
          {GetColunasDeAuditoria()}
        </DataGrid>
      </ProvedorAjuda>

      <ModalMxp
        titulo={NormalizaTituloModal.Normalizar(
          idRegistroEmEdicao,
          NomesModais.contaParteB,
          true
        )}
        visivel={modalVisivel}
        handleFechar={handleFecharModal}
        largura={"max(40vw, 600px)"}
        altura={"auto"}
      >
        <EditFormContaParteB
          idRegistroEmEdicao={idRegistroEmEdicao}
          handleCallback={handleCallbackFormulario}
        />
      </ModalMxp>
    </>
  );
}
