import { Button } from "devextreme-react";
import { Toolbar, Item } from "devextreme-react/toolbar";
import React, {
  ReactNode,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import AuditavelDTO from "../../../models/api/comum/auditavel-dto";
import Pagina from "../pagina";
import RodapeAuditoria from "../rodape-auditoria";
import { IPainelCarregando, PainelCarregando } from "../painel-carregamento";
import { ColunaFlex } from "../coluna-flex";

interface FormularioProps {
  onClickSalvar?: () => void;
  onClickCancelar?: () => void;
  botaoSalvar?: {
    texto?: string;
    icon?: string;
  };
  botaoCancelar?: {
    texto?: string;
    icon?: string;
  };
  callBackModel?: any;
  children?: React.ReactNode;
  modal?: boolean;
  titulo?: string;
  carregando?: boolean;
  formId: string;
  auditoria?: AuditavelDTO;
  botoesAdicionais?: ReactNode[];
  onContentReady?: () => void;
  omitirLinksDeAjuda?: boolean;
}

export interface IFormBase {
  setDadosAuditoria: (auditoria: AuditavelDTO | undefined) => void;
  setCarregando: (carregando: boolean) => void;
}

const FormBase = forwardRef(
  (
    {
      children,
      modal,
      titulo,
      auditoria,
      botaoCancelar,
      botaoSalvar,
      onClickSalvar,
      onClickCancelar,
      botoesAdicionais,
      onContentReady,
      carregando,
      formId,
      omitirLinksDeAjuda,
    }: FormularioProps,
    ref
  ) => {
    const painelCarregando = useRef<IPainelCarregando>();
    const [dadosAuditoria, setDadosAuditoria] = useState(auditoria);

    useEffect(() => {
      setDadosAuditoria(auditoria);
    }, [auditoria]);

    useImperativeHandle(
      ref,
      (): IFormBase => ({
        setDadosAuditoria: (auditoria) => {
          setDadosAuditoria(auditoria);
        },
        setCarregando: (carregando) => {
          painelCarregando.current?.setCarregando(carregando);
        },
      })
    );

    const content = (
      <ColunaFlex id={formId}>
        <PainelCarregando
          visivel={carregando ?? false}
          ref={painelCarregando}
        />
        <div
          style={{
            overflow: "auto",
            padding: "0px 15px",
            margin: "0px -15px",
            flexGrow: 2,
            flexShrink: 2,
          }}
        >
          {children}
        </div>
        <Toolbar onContentReady={onContentReady}>
          {onClickSalvar && (
            <Item location="before">
              <Button
                type="success"
                text={botaoSalvar?.texto ?? "Salvar"}
                icon={botaoSalvar?.icon ?? "save"}
                onClick={onClickSalvar}
              />
            </Item>
          )}
          {botoesAdicionais &&
            botoesAdicionais.map((item, ix) => (
              <Item key={`tb-${ix}`} location="before">
                {item}
              </Item>
            ))}
          <Item location="before">
            <Button
              type="normal"
              text={botaoCancelar?.texto ?? "Cancelar"}
              icon={botaoCancelar?.icon ?? "close"}
              onClick={onClickCancelar}
            />
          </Item>

          {dadosAuditoria && (
            <Item location="after">
              <RodapeAuditoria auditoria={dadosAuditoria} />
            </Item>
          )}
        </Toolbar>
      </ColunaFlex>
    );

    return modal ? (
      <>{content}</>
    ) : (
      <Pagina id={null} titulo={titulo} omitirLinksDeAjuda={omitirLinksDeAjuda}>
        {content}
      </Pagina>
    );
  }
);

export default FormBase;
