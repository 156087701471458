import { Body } from "../../atencao/index.styles";

export interface PartialPermissoesFaltandoProps {
  permissoesFormatadas: string[];
  alinhamentoTexto?: "left" | "center" | "right";
}

export default function PartialPermissoesFaltando({
  permissoesFormatadas,
  alinhamentoTexto,
}: PartialPermissoesFaltandoProps) {
  return (
    <Body style={{ textAlign: alinhamentoTexto }}>
      <p>
        <b>Você não possui as permissões de:</b>
      </p>
      {permissoesFormatadas.map((registro, index) => (
        <p key={index}>{`${registro}`}</p>
      ))}
    </Body>
  );
}
