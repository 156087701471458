export enum TipoDeAcesso {
  Normal = "N",
  Vendas = "V",
  Apontamento = "A",
}

export function decodificarTipoDeAcesso(tipo: TipoDeAcesso): string | null {
  switch (tipo) {
    case TipoDeAcesso.Normal:
      return "Geral";
    case TipoDeAcesso.Vendas:
      return "Exclusivo de vendas";
    case TipoDeAcesso.Apontamento:
      return "Exclusivo de apontamento de operações";
    default:
      return null;
  }
}
