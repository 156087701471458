import TabContainer from "../../../../../../../components/layout/tab-container";
import ProvedorMenus from "../../../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../../../components/seguranca/requer-permissao";
import { PermissoesEstoqueMovimentacao } from "../../../../../../../models/direitos/estoque/estoque-movimentacao/permissoes-estoque-movimentacao";
import GridEmbutidaConslusoesDeOp from "../../../../../../estoque/estoque-movimentacao/componentes/grids/grid-conclusoes-op";

interface OrdemDeProducaoAbaConclusoesProps {
  ordemDeProducaoId: number;
}

export default function OrdemDeProducaoAbaConclusoes(
  props: OrdemDeProducaoAbaConclusoesProps
) {
  return (
    <TabContainer>
      <ProvedorMenus>
        <RequerPermissao
          codigoPermissoes={[PermissoesEstoqueMovimentacao.Consultar]}
        >
          <GridEmbutidaConslusoesDeOp {...props} style={{ height: "100%" }} />
        </RequerPermissao>
      </ProvedorMenus>
    </TabContainer>
  );
}
