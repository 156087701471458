import { LinkButtonStyleComponent } from "./styles";

interface LinkButtonProps {
  sublinhado?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
}

export default function LinkButton(props: LinkButtonProps) {
  return (
    <LinkButtonStyleComponent
      $sublinhado={props.sublinhado}
      onClick={props.onClick}
    >
      {props.children}
    </LinkButtonStyleComponent>
  );
}
