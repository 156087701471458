import {
  criarDataSourceSelectBoxLazy,
} from "../../../utils/api/api-utils";
import criarDatasourceGrid from "../../../utils/grid/back-end-grid-utils";
import { SelectBoxLazyOpcoes } from "../../../models/api/comum/selectboxlazy-options";

const nomeEndpoint: string = "GrupoDeCentroDeTrabalhoCalendario";

function getGridSource() {
  return criarDatasourceGrid(`${nomeEndpoint}/grid`);
}

function getDataSourceSelectBoxLazy(opcoes: SelectBoxLazyOpcoes) {
  const camposSelect = JSON.stringify(opcoes.camposRetorno);

  const dataSource = criarDatasourceGrid(`${nomeEndpoint}/grid?select=${camposSelect}`);

  return criarDataSourceSelectBoxLazy(dataSource, opcoes);
}

export default {
  getGridSource,
  getDataSourceSelectBoxLazy,
};
