import { Button } from "devextreme-react";

interface BotaoCancelarMxpProps {
  handleClick: () => void;
}

export default function BotaoCancelarMxp(props: BotaoCancelarMxpProps) {
  return (
    <Button
      type={"normal"}
      text={"Cancelar"}
      icon={"close"}
      onClick={props.handleClick}
    />
  );
}
