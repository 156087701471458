import { ServicoCriptografia } from "../criptografia/servico-criptografia";

type TiposDadosStorage = string | number | boolean | object;

export enum ChavesLocalstorage {
  DadosSessao = "session",
}

const prefixo =
  (process.env.REACT_APP_SECURE_LOCAL_STORAGE_PREFIX as string) + ".";

export default class GestorLocalStorage {
  static LerItem<T extends TiposDadosStorage>(
    chave: ChavesLocalstorage
  ): T | null {
    const dadosCript = localStorage.getItem(this.GerarChave(chave));
    const json = ServicoCriptografia.Instancia.descriptografar(dadosCript);
    return json ? (JSON.parse(json) as T) : null;
  }

  static GravarItem<T extends TiposDadosStorage>(
    chave: ChavesLocalstorage,
    valor: T
  ): void {
    const json = JSON.stringify(valor);
    const dadosCript = ServicoCriptografia.Instancia.criptografar(json);
    if (dadosCript) {
      localStorage.setItem(this.GerarChave(chave), dadosCript);
    } else {
      localStorage.removeItem(this.GerarChave(chave));
    }
  }

  static RemoverItem(chave: ChavesLocalstorage): void {
    localStorage.removeItem(this.GerarChave(chave));
  }

  static RemoverTodosItens(): void {
    Object.keys(localStorage)
      .filter((x) => x.startsWith(prefixo))
      .forEach(function (x) {
        localStorage.removeItem(x);
      });
  }

  static VerificaSePossuiChave(chave: ChavesLocalstorage): boolean {
    return !!localStorage.getItem(this.GerarChave(chave));
  }

  private static GerarChave(chave: string) {
    return `${prefixo}${chave}`;
  }
}
