import SelectItem from "../../../../models/shared/ui/select-item";
import { gerarSelectItemDoDicionario } from "../../../../utils/common/enum-utils";

export enum EstoqueTipo {
  Normal = 78,
  EmProducao = 80,
  Consumido = 67,
  ObjetoDeManutencao = 79,
}

// Classe que encapsula o enum e suas descrições
export class EstoqueTipoHelper {
  // Criando um "dicionário" com Map
  public static estoqueTipoDescricao: {
    [key in EstoqueTipo]: string;
  } = {
    [EstoqueTipo.Normal]: "Normal",
    [EstoqueTipo.EmProducao]: "Em produção",
    [EstoqueTipo.Consumido]: "Consumido",
    [EstoqueTipo.ObjetoDeManutencao]: "ObjetoDeManutencao",
  };

  // Função para obter a descrição
  public static getDescricao(tipo: EstoqueTipo): string {
    return (
      EstoqueTipoHelper.estoqueTipoDescricao[tipo] || "Descrição desconhecida"
    );
  }

  public static AsSelectItems(): SelectItem[] {
    return gerarSelectItemDoDicionario(EstoqueTipoHelper.estoqueTipoDescricao);
  }
}
