export enum IndicadorDoLancamento {
  CR = 1,
  DB = 2,
  PF = 3,
  BC = 4,
}

export const indicadorDoLancamentoParteBDecodificado: {
  [key in IndicadorDoLancamento]: string;
} = {
  [IndicadorDoLancamento.CR]: "CR (Crédito)",
  [IndicadorDoLancamento.DB]: "DB (Débito)",
  [IndicadorDoLancamento.PF]: "PF (Prejuízo do exercício)",
  [IndicadorDoLancamento.BC]: "BC (Base de cálculo negativa da CSLL)",
};
