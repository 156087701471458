import styled from "styled-components";

export const Background = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-image: url("/img/login-background.svg");
  background-position: center;
`;

export const HomeBackground = styled.div`
  width: 100vw;
  height: calc(100vh - var(--header-menu-height));
  background-size: cover;

  @media screen and (orientation: portrait) {
    background-image: url("/img/home-bg-mobile.webp");
    background-position: center;
  }
  @media screen and (orientation: landscape) {
    background-image: url("/img/home-bg.webp");
    background-position: right;
  }
`;

export const Container = styled.div`
  width: calc(100% - 68px);
  @media (min-width: 568px) {
    width: 480px;
  }
`;
