import getApi from "../../../configs/api";
import { ResponseModel } from "../../../models/api/comum/response-base";

const nomeEndpoint: string = "Mrp";

async function executarMrp() {
  const api = getApi();
  const response = await api.get<ResponseModel<any>>(
    `${nomeEndpoint}/ExecutarMrp`
  );

  return response.data;
}

export default {
  executarMrp,
};
