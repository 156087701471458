import PaginaMxp from "../../../../../components/layout/pagina-mxp";
import ProvedorMenus from "../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../components/seguranca/requer-permissao";
import { PermissoesVeiculo } from "../../../../../models/permissoes/vendas/veiculo/permissoes-veiculo";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import GridVeiculo from "../../componentes/grid";

export default function VeiculoPage() {
  return (
    <ProvedorMenus>
      <PaginaMxp
        id="veiculo-page"
        titulo={NomesTelas.veiculos}
        area={["Fiscal", "Cadastros"]}
      >
        <RequerPermissao codigoPermissoes={[PermissoesVeiculo.Consultar]}>
          <GridVeiculo />
        </RequerPermissao>
      </PaginaMxp>
    </ProvedorMenus>
  );
}
