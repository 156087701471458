import { Column } from "devextreme-react/data-grid";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { MxpGrid } from "../../../../../components/grid";
import {
  useExcluirRegistroGrid,
  usePropagarReferenciaGrid,
} from "../../../../../hooks/grid.hooks";
import { useSeletorGridMxp } from "../../../../../hooks/seletor.hooks";
import { GrupoDeCentroDeTrabalhoCalendarioGridModel } from "../../../../../models/api/grupo-de-centro-de-trabalho-calendario/grupo-de-centro-de-trabalho-calendario";
import { IGridSelecao } from "../../../../../models/shared/ui/formularios";
import API from "../../../../../services/producao/grupo-de-centro-de-trabalho-calendario/grupo-de-centro-de-trabalho-calendario.service";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { GridBaseProps } from "../../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";

const dataSource = API.getGridSource();
const nameOfGridHandler =
  criarNameof<GrupoDeCentroDeTrabalhoCalendarioGridModel>();
const colunas = [
  <Column
    key={nameOfGridHandler("codigo")}
    dataField={nameOfGridHandler("codigo")}
    {...obterConfiguracaoColuna("stringP")}
    caption="Código"
  />,
  <Column
    key={nameOfGridHandler("descricao")}
    dataField={nameOfGridHandler("descricao")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Descrição"
  />,
];

export const GridGrupoDeCentroDeTrabalhoCalendario = forwardRef(
  (_: GridBaseProps, ref) => {
    const gridRef = useRef<IGridSelecao>(null);
    useImperativeHandle(ref, () => gridRef.current as IGridSelecao);
    usePropagarReferenciaGrid(ref, gridRef);

    useSeletorGridMxp(() => gridRef.current?.getGridRef());

    const handleExcluirRegistro = useExcluirRegistroGrid(async () => {
      exibirNotificacaoToast({
        mensagem: "Funcionalidade não implementada.",
        tipo: TipoNotificacao.Informacao,
      });

      return;
    });

    return (
      <>
        <MxpGrid<GrupoDeCentroDeTrabalhoCalendarioGridModel>
          dataSource={dataSource}
          id={"grupo-de-centro-de-trabalho-calendario"}
          ref={gridRef}
          colunas={colunas}
          exibirIconeEditarMenuAcoes={false}
          excluirRegistro={handleExcluirRegistro}
          nomeDoArquivoAoExportar={NomesTelas.gruposCentrosTrabalho}
        />
      </>
    );
  }
);
