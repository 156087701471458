import {
  abrirModalMxpAntigo,
  DadosLink,
} from "../../../../utils/common/menu-utils";
import { exibirAlerta, exibirConfirmacao } from "../../../../utils/dialogos";

export async function abrirModalDetalhesDoLoteMxp1(id?: number) {
  if (!id) {
    return;
  }

  await exibirAlerta(
    "Aviso",
    "A funcionalidade de impressão de lotes ainda não foi adaptada para a nova versão do MAXIPROD.<br />" +
      ' Ao clicar em "Ok", você será direcionado à tela de edição dos lotes na versão antiga, onde poderá realizar a impressão.'
  );

  const dadosLink: DadosLink = {
    rota: "_Lote_DetalhesDoLote",
    tipo: "javaScriptMxpAntigo",
    paramFunction: () => `(${id.toString()})`,
  };

  const abrirEmNovaAba = true;
  abrirModalMxpAntigo(dadosLink, abrirEmNovaAba);
}

export async function criarBoletimDeInspecao(id?: number, lote?: any) {
  if (!id) {
    return;
  }

  const confirmacao = await exibirConfirmacao(
    "Criar boletim de inspeção?",
    `Deseja criar um novo boletim de inspeção para o lote ${lote}?`
  );

  if (confirmacao) {
    const dadosLink: DadosLink = {
      rota: "_Lote_CriarBIM",
      tipo: "javaScriptMxpAntigo",
      paramFunction: () => `(${id.toString()})`,
    };

    const abrirEmNovaAba = true;
    abrirModalMxpAntigo(dadosLink, abrirEmNovaAba);
  }
}
