export enum AmbienteMDFeOpcoes {
  Producao = 1,
  Homologacao = 2,
}

export interface DadosConfiguracoesMdfe {
  ambiente: AmbienteMDFeOpcoes | null;
}

export interface DadosInicializacaoFiltros {
  idEmpresaInicializacao: number | null;
  numeroMaximoLinhasImportacaoPlanilha: number;
}

export interface DadosConfiguracoesEstoque {
  loteDatasRequerido: boolean | null;
  controleLoteFabricacao: boolean | null;
}
