import { yupResolver } from "@hookform/resolvers/yup";
import { FilterDescriptor } from "devextreme/data";
import { ValueChangedEvent } from "devextreme/ui/check_box";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../components/botoes/botao-salvar-mxp";
import {
  FormCheckBox,
  FormGrupo,
  FormNumberBox,
  FormSelectBox,
  FormSelectBoxLazyMxp,
  FormTextBoxSimples,
} from "../../../../../components/formularios";
import { ItemSelectionChangedType } from "../../../../../components/formularios/selectbox-lazy-mxp";
import FormTextArea from "../../../../../components/formularios/textarea";
import FormMxp from "../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import AuditavelDTO from "../../../../../models/api/comum/auditavel-dto";
import { EstoqueGridModel } from "../../../../../models/api/estoque/estoque";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../models/shared/ui/formularios";
import { GridEstoque } from "../../../../../parts/estoque/estoque/grids/grid-padrao";
import { EstoqueMovimentacaoService } from "../../../../../services/estoque-movimentacoes/estoque-movimentacoes-service";
import { EstoqueService } from "../../../../../services/estoque/endereco-estoque/estoque/estoque";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import { quantidadeValorMaximo } from "../../../../../utils/common/constantes";
import criarNameof from "../../../../../utils/common/cria-name-of";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { exibirAlerta } from "../../../../../utils/dialogos";
import { obterFormatStringNumero } from "../../../../../utils/formatadores/formatador-de-numeros";
import ComboItemMxp from "../../../../itens/item/componentes/select-box-lazy";
import { ItemGridModel } from "../../../../itens/item/models/item.api";
import { ProcedenciaDecodificadaTipo } from "../../../../itens/item/models/item.enums";
import ItensFiltrosHelpers from "../../../../itens/item/utils/filtros/itens-filtros-helpers";
import PrefixoESufixoDeLoteService from "../../../../itens/prefixo-e-sufixo-de-lote/servicos/prefixo-e-sufixo-de-lote.servico";
import ComboOperacaoPorOpMxp from "../../../operacao-da-ordem-de-producao/componentes/select-box-operacao-por-op";
import { OrdemDeProducaoResponse } from "../../../ordem-de-producao/models/ordem-de-producao.api";
import { OrdemDeProducaoService } from "../../../ordem-de-producao/servicos/ordem-de-producao.service";
import { formataExibicaoOrdemProducao } from "../../../ordem-de-producao/utils/formatacoes/formatacoes";
import {
  InsumoDaOrdemDeProducaoRequest,
  InsumoDaOrdemDeProducaoResponseDTO,
  InsumosEstadoDaTerceirizacao,
  InsumosTipoEstoque,
} from "../../models/insumo-da-ordem-de-producao";
import { InsumoDaOrdemDeProducaoService } from "../../servicos/insumo-da-ordem-de-producao";
import {
  EstadoDaTerceirizacao,
  OrigemInsumo,
  TipoDoEstoqueInsumo,
} from "../../utils/enum/insumo-da-ordem-de-producao-enums";

let dadosAuditoria: AuditavelDTO | undefined;

const service = new InsumoDaOrdemDeProducaoService();
const serviceOrdemDeProducao = new OrdemDeProducaoService();
const serviceEstoque = new EstoqueService();
const serviceNumeroDeSerie = new PrefixoESufixoDeLoteService();
const serviceEstoqueMovimentacao = new EstoqueMovimentacaoService();

const [dataSourceEstoque, configuracaoExibicaoEBuscaEstoque] =
  serviceEstoque.GetDadosSelectBoxEstoque();

interface FormularioInsumosProps extends IFormularioEditavelBase {
  ordemDeProducaoId: number;
}

export default function FormInsumoDaOrdemDeProducao(
  props: FormularioInsumosProps
) {
  const [carregando, setCarregando] = useState(false);
  const [filtroEstoqueCodigoItem, setfiltroEstoqueCodigoItem] =
    useState<Array<any>>();
  const [ordemDeProducao, setOrdemDeProducao] =
    useState<OrdemDeProducaoResponse>();
  const [itemDoEstoqueId, setItemDoEstoqueId] = useState<number>();
  const [procedenciaDoItem, setProcedenciaDoItem] = useState<string>();
  const [dataSourceNumeroDeSerie, configuracaoExibicaoEBuscaNumeroDeSerie] =
    serviceNumeroDeSerie.GetDadosSelectBoxPrefixoESufixoDeLote();
  const [insumoPossuiMovimentacoes, setInsumoPossuiMovimentacoes] =
    useState<boolean>();
  const isFormularioEdicao = props.idRegistroEmEdicao > 0;

  const novoRegistro: InsumoDaOrdemDeProducaoRequest = {
    id: 0,
    ordemDeProducaoId: props.ordemDeProducaoId,
    operacaoId: NaN,
    itemId: NaN,
    quantidadeLiquida: 0,
    observacoes: null,
    ativo: true,
    origem: OrigemInsumo.Manual,
    ordem: 0,
    perdaEmPercentual: 0,
    estoqueId: null,
    numeroDeSerieId: null,
    tipoDoEstoque: null,
    estadoDaTerceirizacao: EstadoDaTerceirizacao.SemTerceirizacao,
    baixarEstoque: false,
  };

  async function carregarOrdemDeProducao() {
    try {
      const resposta = await serviceOrdemDeProducao.obterPorIdComItem(
        props.ordemDeProducaoId
      );
      checarResponse(resposta);
      setOrdemDeProducao(resposta.model);
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  const schema = yup.object().shape({
    operacaoId: yup.number().required().positive().integer(),
    itemId: yup.number().required().positive().integer(),
    quantidadeLiquida: yup
      .number()
      .required()
      .positive()
      .moreThan(0)
      .max(quantidadeValorMaximo.toNumber()),
    estoqueId: yup
      .number()
      .nullable()
      .test("estoqueValido", "Campo obrigatório.", function (value, contexto) {
        if (contexto.parent.baixarEstoque && !value) {
          return false;
        }
        return true;
      }),
    // numeroDeSerieId: yup
    //   .number()
    //   .nullable()
    //   .test(
    //     "numeroDeSerieId",
    //     "Campo obrigatório.",
    //     function (value, contexto) {
    //       if (contexto.parent.baixarEstoque && !value) {
    //         return false;
    //       }
    //       return true;
    //     }
    //   ),
    tipoDoEstoque: yup
      .mixed<TipoDoEstoqueInsumo>()
      .nullable()
      .transform((v) => (v ? v : null))
      .oneOf(
        Object.values(TipoDoEstoqueInsumo).map((x) => x as number),
        "Valor inválido"
      )
      .test(
        "numeroDeSerieId",
        "Campo obrigatório.",
        function (value, contexto) {
          if (contexto.parent.baixarEstoque && !value) {
            return false;
          }
          return true;
        }
      ),
    baixarEstoque: yup.bool().required(),
    estadoDaTerceirizacao: yup
      .mixed<EstadoDaTerceirizacao>()
      .required()
      .transform((v) => (v >= 0 ? v : null))
      .oneOf(
        Object.values(EstadoDaTerceirizacao).map((x) => x as number),
        "Valor inválido"
      ),
  });

  const hookForm = useForm<InsumoDaOrdemDeProducaoRequest>({
    resolver: yupResolver(schema),
  });

  const { control, handleSubmit, getValues, register, setValue, watch, reset } =
    hookForm;

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();
  }, [props.idRegistroEmEdicao]);

  async function preencherTela() {
    //Limpa tela para inserir os valores default no form
    limparTela();

    await carregarOrdemDeProducao();

    if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    }
  }

  async function carregarRegistroDoId() {
    try {
      setCarregando(true);
      const [resposta, insumoPossuiMovimentacao] = await Promise.all([
        service.ObterPorIdComDadosAuditoria<InsumoDaOrdemDeProducaoResponseDTO>(
          props.idRegistroEmEdicao
        ),
        serviceEstoqueMovimentacao.VerificaSeInsumoPossuiMovimentacoes(
          props.idRegistroEmEdicao
        ),
      ]);

      checarResponse(resposta);

      dadosAuditoria = resposta.model;
      reset(resposta.model);
      setInsumoPossuiMovimentacoes(insumoPossuiMovimentacao);
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  function limparTela() {
    dadosAuditoria = undefined;
    reset(novoRegistro);
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  async function handleSalvar() {
    setCarregando(true);

    if (watch("baixarEstoque") && getValues("itemId") != itemDoEstoqueId) {
      exibirNotificacaoToast({
        mensagem:
          "O item selecionado e o item da baixa de estoque devem ser iguais.",
        tipo: TipoNotificacao.Advertencia,
      });

      setCarregando(false);
      return;
    }

    const model = getValues();

    if (isFormularioEdicao) {
      // Sempre que um insumo é atualizado manualmente, mesmo que tenha sido criada pelo sistema
      // o campo Origem deve ser manual.
      model.origem = OrigemInsumo.Manual;
    }

    try {
      const resposta = isFormularioEdicao
        ? await service.Atualizar(model)
        : await service.Cadastrar(model);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
      fechar(ResultadoAcaoFormulario.AcaoConcluida);
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function callBackUnprocessableEntity() {
    fechar(ResultadoAcaoFormulario.AcaoComErro);
  }

  const handleOnChangeItem = useCallback(
    async (value: ItemSelectionChangedType<ItemGridModel>) => {
      const codigoItem = value?.selectedItem?.codigo;

      if (!value?.selectedItem?.procedencia) {
        return;
      }

      setProcedenciaDoItem(value?.selectedItem?.procedencia);
      let filtroEstoqueCodigoItem: FilterDescriptor = {};
      let filtroGridEstoqueCodigoItem: Array<any> = [];

      if (
        await verificarItemConjuntoEBaixarEstoqueMarcado(
          value?.selectedItem?.procedencia
        )
      ) {
        return;
      }

      if (codigoItem) {
        filtroEstoqueCodigoItem = ["codigoItem", "=", codigoItem];
        filtroGridEstoqueCodigoItem = [
          criarNameof<EstoqueGridModel>()("codigoItem"),
          "=",
          codigoItem,
        ];
      }

      setfiltroEstoqueCodigoItem(filtroGridEstoqueCodigoItem);

      dataSourceEstoque?.filter(filtroEstoqueCodigoItem);
      dataSourceEstoque?.load();
    },
    []
  );

  const handleOnChangeEstoque = useCallback(
    (value: ItemSelectionChangedType<EstoqueGridModel>) => {
      const itemId = value?.selectedItem?.idItem;

      if (!itemId) {
        return;
      }

      setItemDoEstoqueId(itemId);
      if (itemId != getValues("itemId")) {
        setValue("itemId", itemId);
      }
    },
    []
  );

  const handleOnValueChangeBaixarEstoque = useCallback(
    async (value: ValueChangedEvent) => {
      if (
        !getValues("itemId") ||
        (await verificarItemConjuntoEBaixarEstoqueMarcado())
      ) {
        return;
      }

      if (value && procedenciaDoItem != ProcedenciaDecodificadaTipo.Conjunto) {
        setValue("tipoDoEstoque", TipoDoEstoqueInsumo.ConsumirEstoque);
      }
    },
    []
  );

  async function verificarItemConjuntoEBaixarEstoqueMarcado(
    procedencia?: string
  ) {
    procedencia = procedencia ?? procedenciaDoItem;
    if (
      getValues("baixarEstoque") &&
      procedencia == ProcedenciaDecodificadaTipo.Conjunto
    ) {
      await exibirAlerta(
        "Atenção",
        `Não é possível baixar estoques deste item devido à sua procedência definida como 'Conjunto' no cadastro.<br/><br/>
          Itens de procedência conjunto não possuem estoque, pois este representa um conjunto de itens.`
      );

      setValue("estoqueId", null);
      setValue("numeroDeSerieId", null);
      setValue("tipoDoEstoque", null);
      setValue("baixarEstoque", false);

      return true;
    }

    return false;
  }

  return (
    <>
      <ContainerFormMxp>
        <FormMxp carregando={carregando}>
          <ProvedorAjuda id="edit-form-insumo-da-ordem-de-producao">
            <input type="hidden" {...register("id")} />
            <input type="hidden" {...register("ordem")} />
            <input type="hidden" {...register("ordemDeProducaoId")} />
            <FormGrupo>
              <Linha>
                <Coluna md={5}>
                  <FormTextBoxSimples
                    titulo="Ordem de produção"
                    valor={formataExibicaoOrdemProducao(
                      ordemDeProducao?.numero,
                      ordemDeProducao?.itemDescricao
                    )}
                    somenteLeitura
                  />
                </Coluna>
                <Coluna md={4}>
                  <ComboOperacaoPorOpMxp
                    name="operacaoId"
                    titulo="Operação"
                    requerido
                    idOrdemDeProducao={props.ordemDeProducaoId}
                    control={control}
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={6}>
                  <ComboItemMxp
                    name="itemId"
                    titulo="Item"
                    control={control}
                    dataSourceOpcoes={
                      ItensFiltrosHelpers.FiltrarAtivosSemProcedenciaAgregadaOuManutencao
                    }
                    disabled={insumoPossuiMovimentacoes}
                    onSelectionChanged={handleOnChangeItem}
                  />
                </Coluna>
                <Coluna md={3}>
                  <FormNumberBox
                    name="quantidadeLiquida"
                    titulo="Quantidade"
                    formato={obterFormatStringNumero(5)}
                    maximo={quantidadeValorMaximo.toNumber()}
                    control={control}
                    requerido
                  />
                </Coluna>
                <Coluna md={3} centralizar>
                  {!isFormularioEdicao && (
                    <FormCheckBox
                      name="baixarEstoque"
                      titulo="Baixar estoque"
                      control={control}
                      onValueChanged={handleOnValueChangeBaixarEstoque}
                    />
                  )}
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={6}>
                  <FormSelectBox
                    name="estadoDaTerceirizacao"
                    control={control}
                    titulo="Estado da terceirização"
                    dataSource={InsumosEstadoDaTerceirizacao}
                    requerido
                    visivel={!isFormularioEdicao}
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={6}>
                  {watch("baixarEstoque") && (
                    <FormSelectBoxLazyMxp
                      name="estoqueId"
                      titulo="Estoque"
                      control={control}
                      configuracoesExibicaoEBusca={
                        configuracaoExibicaoEBuscaEstoque
                      }
                      dataSource={dataSourceEstoque}
                      requerido={watch("baixarEstoque")}
                      onSelectionChanged={handleOnChangeEstoque}
                      seletorConfig={{
                        modo: "selecaoUnica",
                        titulo: "Selecionar estoque",
                        componenteGrid: (
                          <GridEstoque
                            valorPadraoDoFiltro={filtroEstoqueCodigoItem}
                            sobreporFiltroSalvoComOFiltroPadrao={true}
                            limparFiltroAoTrocarFiltroPadrao={true}
                            ocultarBotaoNovo
                          />
                        ),
                      }}
                      labelSemDados="Sem dados"
                    />
                  )}
                </Coluna>
                <Coluna md={4}>
                  {watch("baixarEstoque") && (
                    <FormSelectBoxLazyMxp
                      name="numeroDeSerieId"
                      titulo="Numero de série"
                      control={control}
                      configuracoesExibicaoEBusca={
                        configuracaoExibicaoEBuscaNumeroDeSerie
                      }
                      dataSource={dataSourceNumeroDeSerie}
                      desabilitado
                      labelSemDados="Sem dados"
                      requerido={watch("baixarEstoque")}
                    />
                  )}
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={6}>
                  <FormSelectBox
                    name="tipoDoEstoque"
                    control={control}
                    titulo="Consumo do estoque"
                    dataSource={InsumosTipoEstoque}
                    requerido={watch("baixarEstoque")}
                    visivel={watch("baixarEstoque")}
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={12}>
                  <FormTextArea
                    name="observacoes"
                    titulo={"Observações"}
                    valor={""}
                    control={control}
                    height={15}
                  />
                </Coluna>
              </Linha>
            </FormGrupo>
          </ProvedorAjuda>
        </FormMxp>
        <ToolbarMxp dadosAuditoria={dadosAuditoria}>
          <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </>
  );
}
