interface GrupoFormProps {
  titulo?: string;
  tituloEmNegrito?: boolean;
  children: React.ReactNode;
}

export default function ({
  tituloEmNegrito,
  titulo,
  children,
}: GrupoFormProps) {
  return (
    <div className="dx-fieldset" style={{ margin: "0" }}>
      <div className="dx-fieldset-header">
        {tituloEmNegrito ? <b>{titulo}</b> : titulo}
      </div>
      {children}
    </div>
  );
}
