import { CaracterInvisivel } from "../../models/const/comum";

export const NUMERO_INTEIRO_FORMATADOR = "#";
const lang = "pt-BR";
const quantidadeCasasDecimaisPercentual = 4;

export function formatarNumero(
  numero: number,
  casasDecimaisMinima: number,
  casasDecimaisMaxima?: number
): string {
  return numero.toLocaleString(lang, {
    minimumFractionDigits: casasDecimaisMinima,
    maximumFractionDigits: casasDecimaisMaxima ?? casasDecimaisMinima,
  });
}

export function formatarNumeroMonetario(valor: number): string {
  return formatarNumero(valor, 2);
}

export function formatarNumeroQuantidade(valor: number): string {
  return formatarNumero(valor ?? 0, 5);
}

export function formatarPercentual(numero: number | null): string {
  return numero
    ? `${formatarNumero(numero, quantidadeCasasDecimaisPercentual)}%`
    : "";
}

export function formatarPercentualComCasasDecimaisPersonalizadas(
  numero: number | null,
  casasDecimais: number | null = null
): string {
  casasDecimais = casasDecimais ?? quantidadeCasasDecimaisPercentual;
  return numero != null ? `${formatarNumero(numero, casasDecimais)}%` : "";
}

export function obterFormatStringNumero(
  casasDecimais: number,
  exibirSeparadorMilhar: boolean = true
): string {
  const chars = Array(casasDecimais).fill("0").join("");
  return `${exibirSeparadorMilhar ? ",##0" : "#0"}.${chars}`;
}

export function formatarValorContabilComSufixo(valor: number): string {
  const numero = valor.toLocaleString(lang, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    signDisplay: "never",
  });

  return `${numero}${sufixoValorContabil(valor)}`;
}

function sufixoValorContabil(valor: number): string {
  if (valor > 0) {
    return " D";
  } else if (valor < 0) {
    return " C";
  }

  return `${CaracterInvisivel} ${CaracterInvisivel} ${CaracterInvisivel} ${CaracterInvisivel}`;
}
