import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import { useMemo, useRef } from "react";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import { useSeletorGridMxp } from "../../../../../hooks/seletor.hooks";
import { EnderecoEstoqueGridModel } from "../../../../../models/api/endereco-estoque/endereco-estoque";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import { EnderecoDeEstoqueService } from "../../service/endereco-estoque.service";

const service = new EnderecoDeEstoqueService();
const nameOfGridHandler = criarNameof<EnderecoEstoqueGridModel>();
const colunas = [
  <Column
    key={nameOfGridHandler("codigo")}
    dataField={nameOfGridHandler("codigo")}
    caption="Código"
    {...obterConfiguracaoColuna("stringM")}
  />,
  <Column
    key={nameOfGridHandler("descricao")}
    dataField={nameOfGridHandler("descricao")}
    caption="Descrição"
    {...obterConfiguracaoColuna("stringM")}
  />,
  ...GetColunasDeAuditoria(),
];

export default function GridEnderecoEstoque(
  props: GridMxpProps<EnderecoEstoqueGridModel>
) {
  const gridRef = useRef<DataGridRef>(null);

  const dataSource = service.ObterDataSourceParaGrid<EnderecoEstoqueGridModel>(
    props.filtrosNoServidor
  );

  useSeletorGridMxp(() => gridRef.current, props.filtrosNoCliente);

  const gridController = new GridController<EnderecoEstoqueGridModel>(() =>
    gridRef.current?.instance()
  );

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "endereco-de-estoque",
      () => gridRef.current?.instance(),
      false,
      props.filtrosNoCliente
    )
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.enderecoEstoque)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .build();
  }, [props.filtrosNoCliente]);

  return (
    <ProvedorAjuda id={"tooltips-grid-endereco-de-estoque"}>
      <DataGrid ref={gridRef} {...configuracoesGrid}>
        {colunas}
      </DataGrid>
    </ProvedorAjuda>
  );
}
