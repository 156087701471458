import { Accordion, TabPanel } from "devextreme-react";
import { AccordionRef } from "devextreme-react/cjs/accordion";
import NestedOption from "devextreme-react/cjs/core/nested-option";
import ArrayStore from "devextreme/data/array_store";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styled, { CSSProperties } from "styled-components";
import { ComponentAsyncLoader } from "../../utils/load-on-demand";
import "./showhide.scss";

interface AccordionItemPropsBase {
  children: React.ReactNode;
  style?: CSSProperties;
  aberto?: boolean;
  text: string;
  carregarApenasQuandoAberto?: boolean;
  index?: number;
}

interface IShowHideItemProps extends AccordionItemPropsBase {
  disabled?: boolean;
  html?: string;
  icon?: string;
}

interface AccordionItemProps extends AccordionItemPropsBase {
  usaAba?: boolean;
}

class ShowHideItem extends React.PureComponent<IShowHideItemProps> {
  static OptionName = "showHideItem";
  static IsCollectionItem = true;
  static TemplateProps = [
    {
      tmplOption: "templateOption",
      render: "renderFunction",
      component: "componentReference",
      keyFn: "keyFunction",
    },
  ];

  public render() {
    return <NestedOption<IShowHideItemProps> {...this.props}></NestedOption>;
  }
}

interface ShowHideProps {
  id: string;
  idRegistroEmEdicao: number;
  children: React.ReactNode;
  usarAba?: boolean;
  height?: number | string | (() => number | string);
}

const AccordionTitle = styled.div`
  font-size: 13px;
  font-weight: 500;
`;

const AccordionItem = (props: AccordionItemProps) => {
  if (props.usaAba) {
    return (
      <>
        {(props.aberto || props.carregarApenasQuandoAberto != true) &&
          props.children}
      </>
    );
  }
  return (
    <div
      className="dx-item-content showhide-item"
      data-text={props.text}
      data-index={props.index}
      style={{
        ...props.style,
      }}
    >
      {(props.aberto || props.carregarApenasQuandoAberto != true) &&
        props.children}
    </div>
  );
};

const CriarShowHideItem = (
  props: IShowHideItemProps,
  usaAba: boolean | undefined
) => {
  return (
    <AccordionItem
      style={props.style}
      carregarApenasQuandoAberto={props.carregarApenasQuandoAberto}
      aberto={props.aberto}
      text={props.text}
      usaAba={usaAba}
      index={props.index}
    >
      <ComponentAsyncLoader>{props.children}</ComponentAsyncLoader>
    </AccordionItem>
  );
};

const arrayVazio = new ArrayStore({
  key: "text",
  data: [],
});

export interface IShowHideRef {
  handleSubmitErrors: (errors: object) => void;
}

const itemTitleRender = (e: IShowHideItemProps) => {
  return <AccordionTitle>{e.text}</AccordionTitle>;
};

const itemKeyExpr = (e: IShowHideItemProps) => e.text;

interface MostrarItensProps {
  textKey: string;
  index: number;
}

const ShowHide = forwardRef<IShowHideRef, ShowHideProps>((props, ref) => {
  const [selectedItems, setSelectedItems] = useState([] as string[]);
  const [itensData, setItensData] = useState<ArrayStore>(arrayVazio);
  const [carregar] = useState([] as boolean[]);
  const accordionRef = useRef<AccordionRef>(null);

  useImperativeHandle(
    ref,
    (): IShowHideRef => ({
      handleSubmitErrors: abrirShowhidesComErros,
    })
  );

  const abrirShowhidesComErros = (errors: object) => {
    const nomesElementos = Object.keys(errors);

    if (!accordionRef.current || nomesElementos.length === 0) {
      return;
    }

    const elemento = accordionRef.current.instance().element();

    const filhos = Array.from(
      elemento.querySelectorAll('.showhide-item[data-text]:not([data-text=""])')
    ) as HTMLElement[];

    const keysParaAbrir = filhos
      .filter((filho) => {
        const labels = filho.getElementsByTagName("label");
        return Array.from(labels).some((label) => {
          const forValue = label.getAttribute("for");
          return forValue ? nomesElementos.includes(forValue) : false;
        });
      })
      .map(
        (filho) =>
          ({
            textKey: filho.dataset.text,
            index: Number(filho.dataset.index),
          } as MostrarItensProps)
      );

    if (keysParaAbrir.length === 0) {
      return;
    }

    setSelectedItems((prevItems) =>
      Array.from(
        new Set([...prevItems, ...keysParaAbrir.map((x) => x.textKey)])
      )
    );
  };

  const selectedItemKeysChange = useCallback(async (e: any[]) => {
    setSelectedItems(e);
  }, []);

  useEffect(() => {
    carregar.length = 0;
    selectedItems.length = 0;
  }, [props.idRegistroEmEdicao]);

  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    carregarItens();
  }, [props.children]);

  async function carregarItens() {
    const itensAbertos = [] as string[];
    const elementos = React.Children.map(props.children, (child, index) => {
      if (!React.isValidElement<IShowHideItemProps>(child)) {
        return undefined;
      }

      const elementChild = child.props;
      if (elementChild.aberto) {
        itensAbertos.push(elementChild.text);
      }

      carregar[index] =
        elementChild.carregarApenasQuandoAberto != true ||
        itensAbertos.includes(elementChild.text);

      return { ...elementChild, index: index };
    })?.filter((x) => x != undefined) as IShowHideItemProps[];

    const dataSouce = new ArrayStore({
      key: "text",
      data: elementos,
    });

    setItensData(dataSouce);
    accordionRef.current?.instance().option("dataSource", dataSouce);
    accordionRef.current?.instance().option("selectedItemKeys", itensAbertos);
  }

  const itemRender = useCallback(
    (e: IShowHideItemProps) => {
      return CriarShowHideItem(e, props.usarAba);
    },
    [props.usarAba]
  );
  const mostrarItem = useCallback(
    (e: any) => {
      if (carregar[e.itemIndex]) {
        return;
      }
      carregar[e.itemIndex] = true;
      const obj = {
        ...e.itemData,
        aberto: carregar[e.itemIndex],
      };
      if (props.usarAba) {
        itensData.push([{ type: "update", data: obj, key: obj.text }]);
      } else {
        itensData.update(obj.text, obj);
      }
    },
    [itensData]
  );

  if (props.usarAba) {
    return (
      <TabPanel
        id={props.id}
        className="showhide"
        deferRendering={false}
        showNavButtons
        repaintChangesOnly={true}
        swipeEnabled={false}
        onTitleClick={mostrarItem}
        dataSource={itensData}
        itemRender={itemRender}
        itemTitleRender={itemTitleRender}
        height={props.height}
      />
    );
  }
  return (
    <Accordion
      ref={accordionRef}
      id={props.id}
      className="showhide"
      collapsible
      multiple
      repaintChangesOnly={true}
      dataSource={itensData}
      selectedItemKeys={selectedItems}
      keyExpr={itemKeyExpr}
      onItemClick={mostrarItem}
      onSelectedItemKeysChange={selectedItemKeysChange}
      deferRendering={false}
      itemRender={itemRender}
      itemTitleRender={itemTitleRender}
    />
  );
});

export default ShowHide;
export { ShowHideItem };
