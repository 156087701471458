import SelectItem from "../../models/shared/ui/select-item";

export function aguardar(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function obterDescricaoSelectItem(
  valor: string | number,
  itens: SelectItem[]
) {
  return itens.find((item) => item.valor === valor)?.descricao;
}

export function primeiraLetraCaixaAlta(input: string): string {
  return input.charAt(0).toUpperCase() + input.slice(1);
}

export function geraNomeDeArquivoValido(input: string | undefined): string {
  if (!input) {
    return "grade";
  }
  return input.replace(/[\\/:"*?<>|]+/, " ");
}

export function previneDigitacaoDeCaracteres(e: any, regex: RegExp) {
  const keyValue = e.event.originalEvent.key;

  if (keyValue.length > 1 && keyValue != "Dead") {
    return true;
  }

  if (keyValue == "Dead") {
    e.event.originalEvent.preventDefault();
    return false;
  }

  if (regex.test(keyValue)) {
    return true;
  }

  e.event.originalEvent.preventDefault();
  return false;
}

export function parseBoolean(value: string | null | undefined) {
  switch (value?.toLowerCase()) {
    case "true":
      return true;
    case "false":
      return false;
    default:
      return undefined;
  }
}
