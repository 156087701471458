import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import { ColumnCellTemplateData } from "devextreme/ui/data_grid";
import {
  memo,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ModalAccordion from "../../../../../components/dialogos/modal-accordion";
import { AccordionDataSource } from "../../../../../components/dialogos/modal-accordion/modal-accordion";
import ImportadorDeRegistros, {
  colunaParaNumero,
  colunaUtcParaData,
  MxpSchemaImportar,
} from "../../../../../components/formularios/importador";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import { MxpChip } from "../../../../../components/mxp/chip";
import { MenuEdicaoOrdemDeProducao } from "../../../../../components/ordem-de-producao/menu-edicao-op";
import { ProvedorMenuEdicaoOrdemDeProducao } from "../../../../../components/ordem-de-producao/provedor-menu-op";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useMenuDeContextosGrid } from "../../../../../hooks/menus.hooks";
import { useParametroId } from "../../../../../hooks/route.hooks";
import { useSeletorGridMxp } from "../../../../../hooks/seletor.hooks";
import { PermissoesOrdemDeProducao } from "../../../../../models/permissoes/producao/ordem-de-producao/OrdemDeProducaoPermissoes";
import { ResultadoAcaoFormulario } from "../../../../../models/shared/ui/formularios";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import {
  checarResponseBaixarArquivo,
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import {
  quantidadeValorMaximo,
  quantidadeValorMinimo,
} from "../../../../../utils/common/constantes";
import criarNameof from "../../../../../utils/common/cria-name-of";
import { navegarParaMxpAntigo } from "../../../../../utils/common/menu-utils";
import NomesModais from "../../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import NormalizaTituloModal from "../../../../../utils/common/normaliza-titulo";
import exibirNotificacaoToast, {
  JanelasDeNotificacaoTitulos,
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { ItemContextMenuMxp } from "../../../../../utils/context-menu/context-menu-utils";
import {
  GestorEventoClickMultiplasLinhas,
  GestorEventoClickRelatorioFiltrado,
  GestorEventoClickSimples,
  GestorEventoClickUnicaLinha,
} from "../../../../../utils/context-menu/gestor-evento-click";
import { exibirAlerta, exibirConfirmacao } from "../../../../../utils/dialogos";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import { renderToStringClient } from "../../../../../utils/react/react-utils";
import UrlUtils from "../../../../../utils/url/url-utils";
import yupPt from "../../../../../utils/validacao/validacao";
import FormIncluirReservaOrdemProducao from "../../../../estoque/reserva/componentes/formulario/form-incluir-reserva-ordem-producao";
import { podeReservarOp } from "../../../../estoque/reserva/utils/reserva.helpers";
import { GerarRelatorioPersonalizadoRequestDTO } from "../../../../relatorio/relatorio-personalizado/models/relatorio-personalizado.api";
import RelatorioPersonalizadoService from "../../../../relatorio/relatorio-personalizado/servicos/relatorio-personalizado.service";
import {
  OrdemDeProducaoCadastrarVariosExcelRequest,
  OrdemDeProducaoGridModel,
  quantidadeDePerdaPrevistaMaxima,
  quantidadeDePerdaPrevistaMinima,
} from "../../models/ordem-de-producao.api";
import { OrdemDeProducaoService } from "../../servicos/ordem-de-producao.service";
import {
  EstadoDecodificado,
  OrdemDeProducaoEstadoHelper,
} from "../../utils/enums/ordem-de-producao-enums";
import FormOrdemDeProducao from "../formularios";
import FormConcluirOrdemDeProducao from "../formularios/concluir-ordem-de-producao";
import { ModalDesconclirOrdemDeProducao } from "../modal-desfazer-conclusao";
import ContextoOrdemDeProducaoGrid from "../provedor-contexto-ordem-de-producao/contexto-ordem-de-producao-grid";
import { ProvedorOrdemDeProducaoGrid } from "../provedor-contexto-ordem-de-producao/provedor-ordem-de-producao-grid";
import { cancelarOrdensDeProducaoHandler } from "./cancelamento-ordem-de-producao-helper";
import { confirmarOrdensDeProducaoHandler } from "./confirmacao-ordem-de-producao-helper";
import { suspenderOrdensDeProducaoHandler } from "./suspensao-ordem-de-producao-helper";

const service = new OrdemDeProducaoService();
const serviceRelatorio = new RelatorioPersonalizadoService();

const schema: MxpSchemaImportar<OrdemDeProducaoCadastrarVariosExcelRequest> = {
  "Item *": {
    prop: "codigoItem",
    type: String,
    dataType: "string",
  },
  "Quantidade *": {
    prop: "quantidade",
    type: colunaParaNumero,
    dataType: "number",
  },
  "Necessidade *": {
    prop: "necessidadeData",
    type: colunaUtcParaData,
    dataType: "date",
  },
  "Perda prevista": {
    prop: "quantidadeDePerdaPrevista",
    type: colunaParaNumero,
    dataType: "number",
  },
  Observações: {
    prop: "observacoes",
    type: String,
    dataType: "string",
  },
};

const schemaYup = yupPt.object({
  codigoItem: yupPt.string().required().label("Item"),
  quantidade: yupPt
    .number()
    .required()
    .moreThan(quantidadeValorMinimo.toNumber())
    .lessThan(quantidadeValorMaximo.toNumber())
    .label("Quantidade"),
  necessidadeData: yupPt.date().required().label("Necessidade"),
  quantidadeDePerdaPrevista: yupPt
    .number()
    .notRequired()
    .min(quantidadeDePerdaPrevistaMinima.toNumber())
    .lessThan(quantidadeDePerdaPrevistaMaxima.toNumber())
    .label("Perda prevista"),
});

const nameOfGridHandler = criarNameof<OrdemDeProducaoGridModel>();

function OrdemDeProducaoEstadoAsCellRender({
  data: { estadoDecodificado },
}: ColumnCellTemplateData) {
  return (
    <MxpChip
      color={
        OrdemDeProducaoEstadoHelper.coresPorEstado[
          estadoDecodificado as EstadoDecodificado
        ]
      }
    >
      {estadoDecodificado}
    </MxpChip>
  );
}

export default function GridOrdemDeProducao(
  props: GridMxpProps<OrdemDeProducaoGridModel>
) {
  const gridRef = useRef<DataGridRef>(null);
  const parametroId = useParametroId();

  const gridController = useMemo(
    () =>
      new GridController<OrdemDeProducaoGridModel>(() =>
        gridRef.current?.instance()
      ),
    []
  );

  const handleAtualizarGrid = useCallback(() => {
    gridController.atualizar();
  }, [gridController]);

  const [idConcluirOp, setIdConcluirOp] = useState(NaN);
  const [modalImportacaoVisivel, setModalImportacaoVisivel] = useState(false);
  const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);
  const [ordemDeProducaoDaReserva, setOrdemDeProducaoDaReserva] = useState<
    OrdemDeProducaoGridModel | undefined
  >(undefined);
  const [
    modalAcaoOrdensDeProducaoAccordionVisivel,
    setModalAcaoOrdensDeProducaoAccordionVisivel,
  ] = useState(false);
  const [
    detalhamentoAcaoOrdensDeProducaoAccordion,
    setDetalhamentoAcaoOrdensDeProducaoAccordion,
  ] = useState<AccordionDataSource>();

  useEffect(() => {
    if (parametroId) {
      setIdRegistroEdicao(parametroId);
    }
  }, [parametroId]);

  const handleNovoRegistro = useCallback(() => setIdRegistroEdicao(0), []);

  const handleEditarRegistro = useCallback(
    (registro: OrdemDeProducaoGridModel) => setIdRegistroEdicao(registro.id),
    []
  );

  const handleImportarExcelClick = useCallback(
    () => setModalImportacaoVisivel(true),
    []
  );

  const handleConcluirClick = useCallback(
    async (registro: OrdemDeProducaoGridModel) => {
      if (
        verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesOrdemDeProducao.ConcluirEDesconcluir,
        ])
      ) {
        if (registro.estadoDecodificado !== EstadoDecodificado.AProduzir) {
          exibirAlerta(
            "Atenção",
            'Somente ordens de produção no estado "A produzir" podem ser concluídas.'
          );
          return;
        }

        setIdConcluirOp(registro.id);
      }
    },
    []
  );

  const fecharConcluirOp = useCallback(async () => {
    setIdConcluirOp(NaN);
  }, []);

  const handleReservarClick = useCallback(
    async (registro: OrdemDeProducaoGridModel) => {
      if (await podeReservarOp(registro.id)) {
        setOrdemDeProducaoDaReserva(registro);
      }
    },
    []
  );

  const handleCancelarClick = useCallback(
    async (registros: OrdemDeProducaoGridModel[]) => {
      const result = await cancelarOrdensDeProducaoHandler(
        service,
        registros,
        handleAtualizarGrid
      );

      if (result) {
        const { accordionVisivel, mensagem } = result;
        setModalAcaoOrdensDeProducaoAccordionVisivel(accordionVisivel);
        setDetalhamentoAcaoOrdensDeProducaoAccordion({
          model: mensagem,
        });
      }
    },
    [handleAtualizarGrid]
  );

  const handleModalReservaCallback = useCallback(() => {
    setOrdemDeProducaoDaReserva(undefined);
  }, [setOrdemDeProducaoDaReserva]);

  const handleCallbackFormularioEdicao = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      setIdRegistroEdicao(NaN);

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }

      UrlUtils.RemoverParametroId(parametroId);
    },
    [parametroId, handleAtualizarGrid]
  );

  const handleFecharModal = useCallback(() => {
    setIdRegistroEdicao(NaN);
    UrlUtils.RemoverParametroId(parametroId);
  }, [parametroId]);

  const handleImportarExcel = useCallback(
    async (registros: OrdemDeProducaoCadastrarVariosExcelRequest[]) => {
      const resultado = await service.CadastrarEmMassaExcel(registros);
      handleAtualizarGrid();
      return resultado;
    },
    [handleAtualizarGrid]
  );

  const handleSuspenderClick = useCallback(
    async (
      registros: OrdemDeProducaoGridModel[],
      exibeConfirmacao: boolean
    ) => {
      const result = await suspenderOrdensDeProducaoHandler(
        service,
        registros,
        handleAtualizarGrid,
        exibeConfirmacao
      );

      if (result) {
        const { accordionVisivel, mensagem } = result;
        setModalAcaoOrdensDeProducaoAccordionVisivel(accordionVisivel);
        setDetalhamentoAcaoOrdensDeProducaoAccordion({
          model: mensagem,
        });
      }
    },
    [handleAtualizarGrid]
  );

  const handleConfirmarClick = useCallback(
    async (
      registros: OrdemDeProducaoGridModel[],
      exibeConfirmacao: boolean
    ) => {
      if (
        verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesOrdemDeProducao.InserirEditar,
        ])
      ) {
        const result = await confirmarOrdensDeProducaoHandler(
          service,
          registros,
          handleAtualizarGrid,
          exibeConfirmacao
        );

        if (result) {
          const { accordionVisivel, mensagem } = result;
          setModalAcaoOrdensDeProducaoAccordionVisivel(accordionVisivel);
          setDetalhamentoAcaoOrdensDeProducaoAccordion({
            model: mensagem,
          });
        }
      }
    },
    [handleAtualizarGrid]
  );

  const handleFecharModalAccordion = useCallback(
    (
      setModalAccordionVisivel: (value: React.SetStateAction<boolean>) => void,
      setDetalhamentoModalAccordion: (
        value: React.SetStateAction<AccordionDataSource | undefined>
      ) => void
    ) => {
      setModalAccordionVisivel(false);
      setDetalhamentoModalAccordion(undefined);
    },
    []
  );

  return (
    <ProvedorOrdemDeProducaoGrid>
      <GridInternaOrdemDeProducao
        {...props}
        gridRef={gridRef}
        gridController={gridController}
        onInserirRegistroClick={handleNovoRegistro}
        onEditarRegistroClick={handleEditarRegistro}
        onImportacaoExcelClick={handleImportarExcelClick}
        onConfirmarClick={handleConfirmarClick}
        onConcluirClick={handleConcluirClick}
        onReservarClick={handleReservarClick}
        onCancelarClick={handleCancelarClick}
        onSuspenderClick={handleSuspenderClick}
      />

      {/*Modal Formulário*/}
      <ProvedorMenuEdicaoOrdemDeProducao>
        <ModalMxp
          titulo={NormalizaTituloModal.Normalizar(
            idRegistroEdicao,
            NomesModais.ordemDeProducao,
            true
          )}
          visivel={!Number.isNaN(idRegistroEdicao)}
          handleFechar={handleFecharModal}
          largura={"99vw"}
          altura={"99vh"}
          alturaMaxima={"92vh"}
          larguraMaxima={"98vw"}
          componentesAdicionais={
            idRegistroEdicao !== 0 ? MenuEdicaoOrdemDeProducao : undefined
          }
        >
          <FormOrdemDeProducao
            idRegistroEmEdicao={idRegistroEdicao}
            handleCallback={handleCallbackFormularioEdicao}
          />
        </ModalMxp>
      </ProvedorMenuEdicaoOrdemDeProducao>

      <ModalDesconclirOrdemDeProducao />

      <ModalMxp
        titulo={"Nova reserva"}
        visivel={!!ordemDeProducaoDaReserva}
        handleFechar={handleModalReservaCallback}
      >
        <FormIncluirReservaOrdemProducao
          idOrdemDeProducao={ordemDeProducaoDaReserva?.id ?? 0}
          codigoOrdemDeProducao={ordemDeProducaoDaReserva?.numero ?? undefined}
          ordemDeProducaoEhSugerida={
            ordemDeProducaoDaReserva?.estadoDecodificado ==
            EstadoDecodificado.Sugerida
          }
          idItem={ordemDeProducaoDaReserva?.itemId ?? 0}
          handleCallback={handleModalReservaCallback}
        />
      </ModalMxp>

      <ModalMxp
        titulo={`Concluir ${NomesModais.ordemDeProducao}`}
        visivel={!Number.isNaN(idConcluirOp)}
        handleFechar={fecharConcluirOp}
        largura={"max(50vw, 800px)"}
        altura={"auto"}
      >
        <FormConcluirOrdemDeProducao
          idRegistroEmEdicao={idConcluirOp}
          handleCallback={fecharConcluirOp}
        />
      </ModalMxp>

      <ModalAccordion
        modalAccordionVisivel={modalAcaoOrdensDeProducaoAccordionVisivel}
        handlerFecharModalAccordion={() =>
          handleFecharModalAccordion(
            setModalAcaoOrdensDeProducaoAccordionVisivel,
            setDetalhamentoAcaoOrdensDeProducaoAccordion
          )
        }
        dataSource={detalhamentoAcaoOrdensDeProducaoAccordion?.model}
        modalTitulo={
          detalhamentoAcaoOrdensDeProducaoAccordion?.accordionTitulo ??
          JanelasDeNotificacaoTitulos.Atencao
        }
        accordionId="accordion-acao-ordens-de-producao"
        itemDoAccordionAltura="auto"
      />

      <ImportadorDeRegistros
        titulo="Importar ordens de produção de arquivo Excel"
        onFechar={() => setModalImportacaoVisivel(false)}
        nomeDaPlanilha="Ordens de Produção"
        visivel={modalImportacaoVisivel}
        onImportar={handleImportarExcel}
        modeloUrl={
          "../modelosXlsx/Planilha_Modelo_Ordens_de_Produção_MAXIPROD.xlsx"
        }
        schema={schema}
        schemaYup={schemaYup}
      />
    </ProvedorOrdemDeProducaoGrid>
  );
}

interface GridInternaOrdemDeProducaoProps
  extends GridMxpProps<OrdemDeProducaoGridModel> {
  gridRef: RefObject<DataGridRef>;
  gridController: GridController<OrdemDeProducaoGridModel>;

  onInserirRegistroClick(): void;
  onEditarRegistroClick(registro: OrdemDeProducaoGridModel): void;
  onExcluirRegistroClick?(registro: OrdemDeProducaoGridModel): void;
  onImportacaoExcelClick(): void;
  onConfirmarClick(
    registro: OrdemDeProducaoGridModel[],
    exibeConfirmacao: boolean
  ): void;
  onConcluirClick(registros: OrdemDeProducaoGridModel): void;
  onReservarClick(registro: OrdemDeProducaoGridModel): void;
  onCancelarClick(registro: OrdemDeProducaoGridModel[]): void;
  onSuspenderClick(
    registros: OrdemDeProducaoGridModel[],
    exibeConfirmacao: boolean
  ): Promise<void>;
}

function obterMensagemExclusao(registro: OrdemDeProducaoGridModel) {
  return (
    <div>
      Tem certeza de que deseja excluir a ordem de produção{" "}
      {registro.numero ?? "sem número"}?<br />
      Ao excluir uma ordem de produção, os registros a seguir que forem
      vinculados a ela também serão excluídos: reservas, tarefas e anotações,
      números de série e anexos.
    </div>
  );
}

const GridInternaOrdemDeProducao = memo(
  ({
    filtrosNoCliente,
    filtrosNoServidor,
    style,
    gridRef,
    gridController,
    onInserirRegistroClick,
    onEditarRegistroClick,
    onExcluirRegistroClick,
    onImportacaoExcelClick,
    onConcluirClick,
    onConfirmarClick,
    onReservarClick,
    onCancelarClick,
    onSuspenderClick,
  }: GridInternaOrdemDeProducaoProps) => {
    const dataSource = useMemo(
      () =>
        service.ObterDataSourceParaGrid<OrdemDeProducaoGridModel>(
          filtrosNoServidor
        ),
      [filtrosNoServidor]
    );

    const {
      definirAtualizaGridDeOrdemDeProducao,
      definfirOrdemDeProducaoDesfazerConclusao,
    } = useContext(ContextoOrdemDeProducaoGrid);

    const handleAtualizarGrid = useCallback(() => {
      gridController.atualizar();
    }, [gridController]);

    useEffect(() => {
      definirAtualizaGridDeOrdemDeProducao(() => handleAtualizarGrid);
    }, [definirAtualizaGridDeOrdemDeProducao, handleAtualizarGrid]);

    const handleNovoRegistro = useCallback(() => {
      if (
        verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesOrdemDeProducao.InserirEditar,
        ])
      ) {
        onInserirRegistroClick();
      }
    }, [onInserirRegistroClick]);

    const handleEditarRegistro = useCallback(
      (registro: OrdemDeProducaoGridModel) => {
        if (
          verificaComNotificacaoSeUsuarioPossuiPermissoes([
            PermissoesOrdemDeProducao.InserirEditar,
          ])
        ) {
          onEditarRegistroClick(registro);
        }
      },
      [onEditarRegistroClick]
    );

    const handleExcluirRegistro = useCallback(
      async (registro: OrdemDeProducaoGridModel) => {
        if (
          !verificaComNotificacaoSeUsuarioPossuiPermissoes([
            PermissoesOrdemDeProducao.Excluir,
          ])
        ) {
          return;
        }

        const mensagem = renderToStringClient(
          <>{obterMensagemExclusao(registro)}</>
        );
        const excluir = await exibirConfirmacao("Confirmar exclusão", mensagem);

        if (!excluir) {
          return;
        }

        if (onExcluirRegistroClick) {
          onExcluirRegistroClick(registro);
        }

        try {
          const resposta = await service.Excluir(registro.id);

          if (resposta) {
            checarResponseExibeMensagemExclusaoDeSucesso(resposta);
            handleAtualizarGrid();
          }
        } catch (erro) {
          tratarErroApi(erro);
        }
      },
      [handleAtualizarGrid, onExcluirRegistroClick]
    );

    const consultarOperacoesRoteiroGantt = useCallback(
      async (registro: OrdemDeProducaoGridModel) => {
        if (registro.id) {
          const rota = `/OperacaoOrdemProducao/Index?of=${registro.id}`;
          navegarParaMxpAntigo(rota);
        }
      },
      []
    );

    const consultarApontamentosDeOperacoes = useCallback(
      async (registro: OrdemDeProducaoGridModel) => {
        const rota = `/ApontamentoHora/Index?IdOp=${registro.id}`;
        navegarParaMxpAntigo(rota);
      },
      []
    );

    const consultarItem = useCallback(
      async (registro: OrdemDeProducaoGridModel) => {
        if (registro.itemCodigo) {
          const rota = `/Item/PorCodigo?cod=${registro.itemCodigo}`;
          navegarParaMxpAntigo(rota);
        }
      },
      []
    );

    const suspenderOrdemDeProducaoItemDoMenuDeContexto = useCallback(
      (exibeConfirmacao: boolean) => {
        const gestorEventoClick = exibeConfirmacao
          ? new GestorEventoClickMultiplasLinhas(
              (registros) => onSuspenderClick(registros, exibeConfirmacao),
              () => gridController
            )
          : new GestorEventoClickUnicaLinha(
              (registro) => onSuspenderClick([registro], exibeConfirmacao),
              () => gridController
            );

        return {
          text: "Suspender",
          icon: "ic-material-symbols-outlined ic-block",
          hint: "Suspender a ordem de produção",
          exibirNoMenuPrincipal: exibeConfirmacao,
          gestorEventoClick: gestorEventoClick,
        };
      },
      [gridController, onSuspenderClick]
    );

    const emitirRelatorioRegistrosFiltrados = useCallback(
      async (filtroString: string) => {
        const request: GerarRelatorioPersonalizadoRequestDTO = {
          filtroGrid: filtroString,
          ids: null,
          relatorioId: 1,
          pdf: false,
          dimensionamento: null,
        };
        const { response, nomeArquivo, extensao } =
          await serviceRelatorio.gerarRelatorio(request);

        const sucesso = await checarResponseBaixarArquivo(
          response,
          nomeArquivo ?? "relatório",
          extensao
        );

        if (sucesso) {
          exibirNotificacaoToast({
            mensagem: "Relatório gerado com sucesso.",
            tipo: TipoNotificacao.Sucesso,
          });
        }
      },
      []
    );

    const emitirRelatorioRegistrosSelecionados = useCallback(
      async (registros: OrdemDeProducaoGridModel[]) => {
        const request: GerarRelatorioPersonalizadoRequestDTO = {
          filtroGrid: null,
          ids: registros.map((x) => x.id).join(";"),
          relatorioId: 1,
          pdf: false,
          dimensionamento: null,
        };

        const { response, nomeArquivo, extensao } =
          await serviceRelatorio.gerarRelatorio(request);

        const sucesso = await checarResponseBaixarArquivo(
          response,
          nomeArquivo ?? "relatório",
          extensao
        );

        if (sucesso) {
          exibirNotificacaoToast({
            mensagem: "Relatório gerado com sucesso.",
            tipo: TipoNotificacao.Sucesso,
          });
        }
      },
      []
    );

    const confirmarOrdemDeProducaoItemDoMenuDeContexto = useCallback(
      (exibeConfirmacao: boolean) => {
        const gestorEventoClick = exibeConfirmacao
          ? new GestorEventoClickMultiplasLinhas(
              (registros) => onConfirmarClick(registros, exibeConfirmacao),
              () => gridController
            )
          : new GestorEventoClickUnicaLinha(
              (registro) => onConfirmarClick([registro], exibeConfirmacao),
              () => gridController
            );

        return {
          text: "Confirmar",
          icon: "ic-material-symbols-outlined ic-check-circle",
          hint: "Confirmar a ordem de produção",
          exibirNoMenuPrincipal: exibeConfirmacao,
          gestorEventoClick: gestorEventoClick,
        };
      },
      [gridController, onConfirmarClick]
    );

    const cancelarOrdemDeProducaoItemDoMenuDeContexto = useCallback(() => {
      return {
        text: "Cancelar",
        icon: "ic-material-symbols-outlined ic-cancel",
        hint: "Cancelar a ordem de produção",
        exibirNoMenuPrincipal: false,
        gestorEventoClick: new GestorEventoClickMultiplasLinhas(
          (registros) => onCancelarClick(registros),
          () => gridController
        ),
      };
    }, [gridController, onCancelarClick]);

    const desfazerConclusaoItemDoMenuDeContexto = {
      text: "Desfazer conclusão",
      icon: "ic-material-symbols-outlined ic-remove",
      gestorEventoClick: new GestorEventoClickUnicaLinha(
        definfirOrdemDeProducaoDesfazerConclusao,
        () => gridController
      ),
    };

    const itensMenuDeContexto: ItemContextMenuMxp[] = useMemo(
      () => [
        {
          ...confirmarOrdemDeProducaoItemDoMenuDeContexto(false),
          exibirNaLinhaDaGrid: "menuDeContexto",
        },
        {
          ...suspenderOrdemDeProducaoItemDoMenuDeContexto(false),
          exibirNaLinhaDaGrid: "menuDeContexto",
        },
        {
          ...cancelarOrdemDeProducaoItemDoMenuDeContexto(),
          exibirNaLinhaDaGrid: "menuDeContexto",
        },
        {
          text: "Concluir",
          exibirNaLinhaDaGrid: "menuDeContexto",
          icon: "ic-material-symbols-outlined ic-check",
          gestorEventoClick: new GestorEventoClickUnicaLinha(
            (registros) => onConcluirClick(registros),
            () => gridController
          ),
        },
        {
          ...desfazerConclusaoItemDoMenuDeContexto,
          exibirNaLinhaDaGrid: "menuDeContexto",
        },
        {
          text: "Reservar",
          icon: "ic-material-symbols-outlined ic-keep",
          exibirNaLinhaDaGrid: "menuDeContexto",
          gestorEventoClick: new GestorEventoClickUnicaLinha(
            onReservarClick,
            () => gridController
          ),
        },
        {
          text: "Consultar",
          icon: "ic-material-symbols-outlined ic-manage-search",
          hint: "Menu com opções de consulta",
          exibirNaLinhaDaGrid: "menuDeContexto",
          exibirNoMenuPrincipal: true,
          items: [
            {
              text: "Operações: roteiro/gantt",
              icon: "dx-icon-bulletlist",
              gestorEventoClick: new GestorEventoClickUnicaLinha(
                consultarOperacoesRoteiroGantt,
                () => gridController
              ),
            },
            {
              text: "Apontamento de operações",
              icon: "ic-material-symbols-outlined ic-pending-actions",
              gestorEventoClick: new GestorEventoClickUnicaLinha(
                consultarApontamentosDeOperacoes,
                () => gridController
              ),
            },
            {
              text: "Item",
              icon: "ic-material-symbols-outlined ic-app-registration",
              gestorEventoClick: new GestorEventoClickUnicaLinha(
                consultarItem,
                () => gridController
              ),
            },
          ],
        },
        {
          text: "Relatórios (teste)",
          exibirNaLinhaDaGrid: "menuDeContexto",
          exibirNoMenuPrincipal: true,
          items: [
            {
              text: "Emitir registros filtrados",
              gestorEventoClick: new GestorEventoClickRelatorioFiltrado(
                emitirRelatorioRegistrosFiltrados,
                () => gridController
              ),
            },
            {
              text: "Emitir registros selecionados",
              gestorEventoClick: new GestorEventoClickMultiplasLinhas(
                emitirRelatorioRegistrosSelecionados,
                () => gridController
              ),
            },
          ],
        },
        {
          text: "Ações",
          icon: "ic-material-symbols-outlined ic-vertical",
          hint: "Menu com opções de ação",
          exibirNoMenuPrincipal: true,
          items: [
            {
              text: "Importar ordens de produção de arquivo Excel",
              icon: "ic-material-symbols-outlined ic-file-upload",
              gestorEventoClick: new GestorEventoClickSimples(
                onImportacaoExcelClick
              ),
            },
            {
              ...confirmarOrdemDeProducaoItemDoMenuDeContexto(true),
            },
            {
              ...suspenderOrdemDeProducaoItemDoMenuDeContexto(true),
            },
            {
              ...cancelarOrdemDeProducaoItemDoMenuDeContexto(),
            },
            {
              text: "Concluir",
              icon: "ic-material-symbols-outlined ic-check",
              gestorEventoClick: new GestorEventoClickUnicaLinha(
                (registros) => onConcluirClick(registros),
                () => gridController
              ),
            },
            {
              ...desfazerConclusaoItemDoMenuDeContexto,
            },
          ],
        },
      ],
      [
        consultarApontamentosDeOperacoes,
        consultarItem,
        consultarOperacoesRoteiroGantt,
        gridController,
        onImportacaoExcelClick,
        onConcluirClick,
        onReservarClick,
        suspenderOrdemDeProducaoItemDoMenuDeContexto,
        confirmarOrdemDeProducaoItemDoMenuDeContexto,
        cancelarOrdemDeProducaoItemDoMenuDeContexto,
      ]
    );

    useMenuDeContextosGrid(itensMenuDeContexto);

    const configuracoesGrid = useMemo(() => {
      return GridBuilder.criar(
        "ordem-de-producao",
        () => gridRef.current?.instance(),
        false,
        filtrosNoCliente
      )
        .definirStyles(style)
        .definirDataSource(dataSource)
        .definirFiltros()
        .definirRolagem()
        .configurarSelecionadorDeColunas()
        .definirGravacaoPreferenciasGrid()
        .definirPaginacao()
        .configurarExportacao(NomesTelas.ordensProducao)
        .definirBotaoNovo(handleNovoRegistro)
        .definirBotaoRefresh(handleAtualizarGrid)
        .definirSelecao()
        .definirOrdenacao()
        .definirMenuDeContexto(itensMenuDeContexto)
        .definirDuploCliqueLinha(handleEditarRegistro)
        .build();
    }, [
      dataSource,
      filtrosNoCliente,
      gridRef,
      handleAtualizarGrid,
      handleEditarRegistro,
      handleNovoRegistro,
      itensMenuDeContexto,
      style,
    ]);

    useSeletorGridMxp(() => gridRef.current, filtrosNoCliente);

    useRegistrarAtalhosGrid<OrdemDeProducaoGridModel>({
      controller: gridController,
      handleNovo: handleNovoRegistro,
      handleEditar: handleEditarRegistro,
      handleExcluir: handleExcluirRegistro,
    });

    useMenuDeContextosGrid(itensMenuDeContexto);

    return (
      <DataGrid ref={gridRef} {...configuracoesGrid}>
        <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
        {GridColunaAcoes<OrdemDeProducaoGridModel>({
          handleEditar: onEditarRegistroClick,
          handleExcluir: handleExcluirRegistro,
        })}
        <Column
          key={nameOfGridHandler("numero")}
          dataField={nameOfGridHandler("numero")}
          {...obterConfiguracaoColuna("codigo")}
          caption="Nº"
          alignment="right"
          sortOrder="desc"
        />
        <Column
          key={nameOfGridHandler("itemCodigo")}
          dataField={nameOfGridHandler("itemCodigo")}
          {...obterConfiguracaoColuna("stringG")}
          caption="Item"
        />
        <Column
          key={nameOfGridHandler("itemDescricao")}
          dataField={nameOfGridHandler("itemDescricao")}
          {...obterConfiguracaoColuna("stringXG")}
          caption="Descrição"
        />
        <Column
          key={nameOfGridHandler("itemDescricaoComplementar")}
          dataField={nameOfGridHandler("itemDescricaoComplementar")}
          {...obterConfiguracaoColuna("stringXG")}
          caption="Descrição complementar"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("grupoCodigo")}
          dataField={nameOfGridHandler("grupoCodigo")}
          {...obterConfiguracaoColuna("stringG")}
          caption="Grupo"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("grupoDescricao")}
          dataField={nameOfGridHandler("grupoDescricao")}
          {...obterConfiguracaoColuna("stringXG")}
          caption="Descrição do grupo"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("itemDesenho")}
          dataField={nameOfGridHandler("itemDesenho")}
          {...obterConfiguracaoColuna("stringG")}
          caption="Desenho"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("itemDesenhoRevisao")}
          dataField={nameOfGridHandler("itemDesenhoRevisao")}
          {...obterConfiguracaoColuna("stringG")}
          caption="Revisão do desenho"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("quantidade")}
          dataField={nameOfGridHandler("quantidade")}
          {...obterConfiguracaoColuna("quantidade")}
        />
        <Column
          key={nameOfGridHandler("quantidadeDePerdaPrevista")}
          dataField={nameOfGridHandler("quantidadeDePerdaPrevista")}
          {...obterConfiguracaoColuna("quantidade")}
          caption="Qt de perda prevista"
          visible={false}
          width={180}
        />
        <Column
          key={nameOfGridHandler("quantidadeBruta")}
          dataField={nameOfGridHandler("quantidadeBruta")}
          {...obterConfiguracaoColuna("quantidade")}
          caption="Qt bruta"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("itemUnidadePrincipalCodigo")}
          dataField={nameOfGridHandler("itemUnidadePrincipalCodigo")}
          {...obterConfiguracaoColuna("stringPP")}
          caption="Un"
          width={60}
        />
        <Column
          key={nameOfGridHandler("quantidadeNaUnidadeDeVenda")}
          dataField={nameOfGridHandler("quantidadeNaUnidadeDeVenda")}
          {...obterConfiguracaoColuna("quantidade")}
          caption="Qt na un de venda"
          visible={false}
          width={150}
        />
        <Column
          key={nameOfGridHandler("itemUnidadeDeVendaCodigo")}
          dataField={nameOfGridHandler("itemUnidadeDeVendaCodigo")}
          {...obterConfiguracaoColuna("stringP")}
          caption="Un de venda"
          visible={false}
          width={120}
        />
        <Column
          key={nameOfGridHandler("necessidadeData")}
          dataField={nameOfGridHandler("necessidadeData")}
          {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
          caption="Necessidade"
        />
        <Column
          key={nameOfGridHandler("estadoDecodificado")}
          dataField={nameOfGridHandler("estadoDecodificado")}
          {...obterConfiguracaoColuna("stringM")}
          cellRender={OrdemDeProducaoEstadoAsCellRender}
          caption="Estado"
        />
        <Column
          key={nameOfGridHandler("estadoConfiguravel")}
          dataField={nameOfGridHandler("estadoConfiguravel")}
          {...obterConfiguracaoColuna("stringMG")}
          caption="Estado configurável"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("quantidadeConcluida")}
          dataField={nameOfGridHandler("quantidadeConcluida")}
          {...obterConfiguracaoColuna("quantidade")}
          caption="Qt concluída"
        />
        <Column
          key={nameOfGridHandler("itemPesoLiquidoUnitario")}
          dataField={nameOfGridHandler("itemPesoLiquidoUnitario")}
          {...obterConfiguracaoColuna("peso")}
          caption="Peso líq unitário (kg)"
          visible={false}
          width={180}
        />
        <Column
          key={nameOfGridHandler("pesoLiquidoTotal")}
          dataField={nameOfGridHandler("pesoLiquidoTotal")}
          {...obterConfiguracaoColuna("peso")}
          caption="Peso líq total (kg)"
          visible={false}
          width={180}
        />
        <Column
          key={nameOfGridHandler("itemPesoBrutoUnitario")}
          dataField={nameOfGridHandler("itemPesoBrutoUnitario")}
          {...obterConfiguracaoColuna("peso")}
          caption="Peso bruto unitário (kg)"
          visible={false}
          width={180}
        />
        <Column
          key={nameOfGridHandler("pesoBrutoTotal")}
          dataField={nameOfGridHandler("pesoBrutoTotal")}
          {...obterConfiguracaoColuna("peso")}
          caption="Peso bruto total (kg)"
          visible={false}
          width={180}
        />
        <Column
          key={nameOfGridHandler("quantidadeAConcluir")}
          dataField={nameOfGridHandler("quantidadeAConcluir")}
          {...obterConfiguracaoColuna("quantidade")}
          caption="Qt a concluir"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("emissaoData")}
          dataField={nameOfGridHandler("emissaoData")}
          {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
          caption="Emissão"
          visible={false}
          width={100}
        />
        <Column
          key={nameOfGridHandler("emitidoPorApelido")}
          dataField={nameOfGridHandler("emitidoPorApelido")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Emitido por"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("quantidadeSobra")}
          dataField={nameOfGridHandler("quantidadeSobra")}
          {...obterConfiguracaoColuna("quantidade")}
          caption="Qt sobra"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("origemDecodificada")}
          dataField={nameOfGridHandler("origemDecodificada")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Origem"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("inicioPrevistoOuRealizadoData")}
          dataField={nameOfGridHandler("inicioPrevistoOuRealizadoData")}
          {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
          caption="Início"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("fimPrevistoOuRealizadoData")}
          dataField={nameOfGridHandler("fimPrevistoOuRealizadoData")}
          {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
          caption="Fim"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("tempoRealizadoEmHoras")}
          dataField={nameOfGridHandler("tempoRealizadoEmHoras")}
          {...obterConfiguracaoColuna("quantidade")}
          caption="Tempo realizado em horas"
          visible={false}
          width={220}
        />
        <Column
          key={nameOfGridHandler("tempoPrevistoEmHoras")}
          dataField={nameOfGridHandler("tempoPrevistoEmHoras")}
          {...obterConfiguracaoColuna("quantidade")}
          caption="Tempo previsto em horas"
          visible={false}
          width={220}
        />
        <Column
          key={nameOfGridHandler("quantidadeOuTempoRealizadoEmPercentual")}
          dataField={nameOfGridHandler(
            "quantidadeOuTempoRealizadoEmPercentual"
          )}
          {...obterConfiguracaoColuna("percentual")}
          caption="Qt ou tempo realizado %"
          visible={false}
          width={200}
        />
        <Column
          key={nameOfGridHandler("quantidadeConcluidaEmPercentual")}
          dataField={nameOfGridHandler("quantidadeConcluidaEmPercentual")}
          {...obterConfiguracaoColuna("percentual")}
          caption="Qt concluída em %"
          visible={false}
          width={150}
        />
        <Column
          key={nameOfGridHandler("maoDeObraCusto")}
          dataField={nameOfGridHandler("maoDeObraCusto")}
          {...obterConfiguracaoColuna("monetario")}
          caption="Custo da mão de obra"
          visible={false}
          width={180}
        />
        <Column
          key={nameOfGridHandler("observacoes")}
          dataField={nameOfGridHandler("observacoes")}
          {...obterConfiguracaoColuna("stringXXG")}
          caption="Observações"
          visible={false}
        />
        <Column
          key={nameOfGridHandler("insumosDisponibilidadeDecodificada")}
          dataField={nameOfGridHandler("insumosDisponibilidadeDecodificada")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Disponibilidade dos insumos"
          visible={false}
          width={230}
        />
        <Column
          key={nameOfGridHandler("insumosBaixasEstadoDecodificado")}
          dataField={nameOfGridHandler("insumosBaixasEstadoDecodificado")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Estado das baixas dos insumos"
          visible={false}
          width={230}
        />
        <Column
          key={nameOfGridHandler("itemPontoDeRessuprimento")}
          dataField={nameOfGridHandler("itemPontoDeRessuprimento")}
          {...obterConfiguracaoColuna("quantidade")}
          caption="Ponto de ressuprimento"
          visible={false}
          width={180}
        />
        {GetColunasDeAuditoria(true, false, [
          { ordenarPor: "criacaoData", ordem: "desc" },
        ])}
      </DataGrid>
    );
  }
);
