import PaginaMxp from "../../../../../components/layout/pagina-mxp";
import ProvedorMenus from "../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../components/seguranca/requer-permissao";
import { PermissoesOperacaoDeOrdemDeProducao } from "../../../../../models/permissoes/producao/operacao-de-ordem-de-producao/permissoes-operacao-de-ordem-de-producao";
import NomesDasCategoriasDoMenuPrincipal from "../../../../../utils/common/nome-categorias-menu-principal";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import GridOperacaoDaOrdemDeProducao from "../../componentes/grid";
import FiltrosGridOperacaoDeOrdemDeProducao from "../../utils/filtros/filtros-grid-operacao-ordem-de-producao";

export default function OperacaoDeOrdemDeProducaoPage() {
  return (
    <ProvedorMenus>
      <PaginaMxp
        id="operacao-de-ordem-de-producao-page"
        titulo={NomesTelas.operacoesDeOrdensDeProducao}
        area={[NomesDasCategoriasDoMenuPrincipal.producao]}
      >
        <RequerPermissao
          codigoPermissoes={[PermissoesOperacaoDeOrdemDeProducao.Consultar]}
        >
          <GridOperacaoDaOrdemDeProducao
            filtrosNoCliente={
              FiltrosGridOperacaoDeOrdemDeProducao.estadoDaOperacaoEEstadoDaOrdemDeProducao
            }
          />
        </RequerPermissao>
      </PaginaMxp>
    </ProvedorMenus>
  );
}
