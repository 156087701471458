import { EditorStyle } from "devextreme/common";
import "../../../../themes/personalizacoes/grid/number-box/number-box-grid-edit.css";
import { obterFormatStringNumero } from "../../../../utils/formatadores/formatador-de-numeros";
import { selecionarInputAoFocarNoElemento } from "../../../../utils/inputs/inputs-utils";

export const GridNumberBoxProps = {
  onFocusIn: selecionarInputAoFocarNoElemento,
  className: "grid-edit-number-box",
  format: obterFormatStringNumero(5),
  stylingMode: "outlined" as EditorStyle,
};
