import { format } from "date-fns";

export function formatarDataHoraComFormato(
  data: Date | string | null,
  formato: string
): string {
  if (!data) {
    return "";
  }
  const d = data instanceof Date ? data : new Date(data);
  return format(d, formato);
}

export function formatarDataHora(data: Date | string | null): string {
  return formatarDataHoraComFormato(data, "dd/MM/yyyy HH:mm:ss");
}

export function formatarData(data: Date | string | null): string {
  return formatarDataHoraComFormato(data, "dd/MM/yyyy");
}

export function formatarHora(data: Date | string | null): string {
  return formatarDataHoraComFormato(data, "HH:mm:ss");
}
