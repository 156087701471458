import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import { useCallback, useEffect, useMemo, useRef } from "react";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useControlarFormDeEdicao } from "../../../../../hooks/form.hooks";
import { useParametroId } from "../../../../../hooks/route.hooks";
import { PrefixoESufixoDeLotePermissoes } from "../../../../../models/permissoes/itens/prefixo-e-sufixo-de-lote/PrefixoESufixoDeLotePermissoes";
import { ResultadoAcaoFormulario } from "../../../../../models/shared/ui/formularios";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesModais from "../../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import NormalizaTituloModal from "../../../../../utils/common/normaliza-titulo";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import UrlUtils from "../../../../../utils/url/url-utils";
import { PrefixoESufixoDeLoteGridModel } from "../../models/prefixo-e-sufixo-de-lote";
import PrefixoESufixoDeLoteService from "../../servicos/prefixo-e-sufixo-de-lote.servico";
import EditFormPrefixoESufixoDeLote from "../formulario";

const service = new PrefixoESufixoDeLoteService();
const dataSource =
  service.ObterDataSourceParaGrid<PrefixoESufixoDeLoteGridModel>();

const nameOfGridHandler = criarNameof<PrefixoESufixoDeLoteGridModel>();

export default function GridPrefixoESufixoDeLote() {
  const gridRef = useRef<DataGridRef>(null);
  const parametroId = useParametroId();

  const {
    idRegistroEmEdicao,
    setIdRegistroEmEdicao,
    modalVisivel,
    encerrarEdicao,
  } = useControlarFormDeEdicao(NaN);

  const handleNovoRegistro = useCallback(() => {
    if (
      verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PrefixoESufixoDeLotePermissoes.InserirEditar,
      ])
    ) {
      setIdRegistroEmEdicao(0);
    }
  }, []);

  const handleEditarRegistro = useCallback(
    (registro: PrefixoESufixoDeLoteGridModel) => {
      if (
        verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PrefixoESufixoDeLotePermissoes.InserirEditar,
        ])
      ) {
        setIdRegistroEmEdicao(registro.id);
      }
    },
    []
  );

  const handleExcluirRegistro = useCallback(
    async (registro: PrefixoESufixoDeLoteGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PrefixoESufixoDeLotePermissoes.Excluir,
        ])
      ) {
        return;
      }

      const excluir = await exibirConfirmacao(
        "Confirmar exclusão",
        obterMensagemExclusao(registro)
      );

      if (excluir) {
        try {
          const resposta = await service.Excluir(registro.id);

          if (resposta) {
            checarResponseExibeMensagemExclusaoDeSucesso(resposta);
            handleAtualizarGrid();
          }
        } catch (erro) {
          tratarErroApi(erro);
        }
      }
    },
    []
  );

  function obterMensagemExclusao(registro: PrefixoESufixoDeLoteGridModel) {
    return `Tem certeza de que deseja excluir o prefixo de lotes e números de série ${registro.prefixo}?`;
  }

  const gridController = new GridController<PrefixoESufixoDeLoteGridModel>(() =>
    gridRef.current?.instance()
  );

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar("prefixo-e-sufixo-de-lote", () =>
      gridRef.current?.instance()
    )
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.prefixoESufixoDeLote)
      .definirBotaoNovo(handleNovoRegistro)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .definirDuploCliqueLinha(handleEditarRegistro)
      .build();
  }, []);

  useEffect(() => {
    if (parametroId) {
      setIdRegistroEmEdicao(parametroId);
    }
  }, []);

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  useRegistrarAtalhosGrid<PrefixoESufixoDeLoteGridModel>({
    controller: gridController,
    handleNovo: handleNovoRegistro,
    handleEditar: handleEditarRegistro,
    handleExcluir: handleExcluirRegistro,
  });

  const handleCallbackFormulario = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      encerrarEdicao();

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }

      UrlUtils.RemoverParametroId(parametroId);
    },
    [parametroId]
  );

  const handleFecharModal = useCallback(() => {
    encerrarEdicao();
    UrlUtils.RemoverParametroId(parametroId);
  }, [parametroId]);

  return (
    <>
      <ProvedorAjuda id={"tooltips-grid-prefixo-e-sufixo-de-lote"}>
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          {GridColunaAcoes<PrefixoESufixoDeLoteGridModel>({
            handleEditar: handleEditarRegistro,
            handleExcluir: handleExcluirRegistro,
          })}
          <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
          <Column
            key={nameOfGridHandler("prefixo")}
            dataField={nameOfGridHandler("prefixo")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Prefixo"
          />
          <Column
            key={nameOfGridHandler("sufixo")}
            dataField={nameOfGridHandler("sufixo")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Sufixo"
          />
          <Column
            key={nameOfGridHandler("proximoSequencial")}
            dataField={nameOfGridHandler("proximoSequencial")}
            {...obterConfiguracaoColuna("codigoNumerico")}
            width={160}
            caption="Próximo sequencial"
          />
          <Column
            key={nameOfGridHandler("proximoPrefixoESufixoDeLote")}
            dataField={nameOfGridHandler("proximoPrefixoESufixoDeLote")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Próximo prefixo e sufixo"
          />
          {GetColunasDeAuditoria()}
        </DataGrid>
      </ProvedorAjuda>

      <ModalMxp
        titulo={NormalizaTituloModal.Normalizar(
          idRegistroEmEdicao,
          NomesModais.prefixoESufixoDeLote
        )}
        visivel={modalVisivel}
        handleFechar={handleFecharModal}
        largura={"max(50vw, 800px)"}
      >
        <EditFormPrefixoESufixoDeLote
          idRegistroEmEdicao={idRegistroEmEdicao}
          handleCallback={handleCallbackFormulario}
        />
      </ModalMxp>
    </>
  );
}
