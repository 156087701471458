import store from "../../store";
import { primeiraLetraCaixaAlta } from "./common-utils";

/**
 * Verifica se o usuário possui as opcionais informadas
 * @param codigoOpcionais array com os códigos dos opcionais
 * @param modo se deve validar todas as permissões ou apenas alguma
 * @returns
 */
export function verificaSeUsuarioPossuiOpcionaisAssinatura(
  codigoOpcionais: string[],
  modo: "todas" | "alguma" = "todas"
): boolean {
  const opcionaisStore =
    store.getState().sessao.dadosSessao?.assinante.opcionaisContratados ?? [];

  let possuiOpcionais: boolean = false;

  switch (modo) {
    case "todas":
      possuiOpcionais = codigoOpcionais.every((x) =>
        opcionaisStore.includes(x)
      );
      break;
    case "alguma":
      possuiOpcionais = codigoOpcionais.some((x) => opcionaisStore.includes(x));

      break;
  }

  return possuiOpcionais;
}

/**
 * Obtém uma lista de mensagem formatada, dos opcionais que estão faltando. A formatação
 * é no seguinte formato "- Descricao do opcional"
 * @param opcionaisATestar array dos opcionais para testar quais delas estão faltando
 * @returns
 */
export function obterOpcionaisAssinaturaFaltantesFormatada(
  opcionaisATestar: string[]
) {
  const opcionaisStore =
    store.getState().sessao.dadosSessao?.assinante.opcionaisContratados ?? [];

  const opcionaisFaltantes = opcionaisATestar.filter(
    (x) => !opcionaisStore.includes(x)
  );

  const detalhesPermissoes =
    store.getState().estadoUI.detalhesOpcionaisAssinatura;

  return opcionaisFaltantes.map(
    (registro) =>
      `- ${primeiraLetraCaixaAlta(
        detalhesPermissoes.find((x) => x.codigo == registro)?.descricao ?? ""
      )}`
  );
}
