import { FieldValues, UseControllerProps } from "react-hook-form";
import { SelectBoxLazyFiltros } from "../../../../models/api/comum/selectboxlazy-options";
import { ContaContabilTipo } from "../../../../models/api/conta-contabil/conta-contabil";
import { GridContaContabil } from "../../../../parts/contabilidade/conta-contabil/grid";
import { ContaContabilService } from "../../../../services/contabilidade/conta-contabil/conta-contabil.service";
import { FormSelectBoxLazy } from "../../../formularios";

interface ComboContaContabilMxpProps<T extends FieldValues>
  extends UseControllerProps<T> {
  titulo: string;
  onSelectionChanged?: (e: any) => void;
}

const contaContabilService = new ContaContabilService();

const filtrosPadroes: SelectBoxLazyFiltros[] = [
  {
    nomeCampo: "Tipo",
    operador: "=",
    valor: ContaContabilTipo.Analitica,
  },
  { nomeCampo: "Ativo", operador: "=", valor: true },
];

const dataSource = contaContabilService.GetDataSourceSelectBoxLazy({
  camposRetorno: [
    "Id",
    "Codigo",
    "Descricao",
    "Classificacao",
    "ObrigatoriedadeCentroDeCustos",
  ],
  camposOrdenacao: [
    {
      nomeCampo: "Classificacao",
      desc: false,
    },
  ],
  filtros: filtrosPadroes,
});

const exibicaoContaContabil = (c: any) => {
  if (c) {
    return c.Descricao == null
      ? `${c.Codigo}`
      : `${c.Classificacao} ${c.Descricao} (${c.Codigo})`;
  }

  return "";
};

const contaContabilExpessaoBusca = ["Codigo", "Descricao", "Classificacao"];

export default function ComboContaContabilMxp<T extends FieldValues>(
  props: ComboContaContabilMxpProps<T>
) {
  return (
    <FormSelectBoxLazy
      name={props.name}
      titulo={props.titulo}
      control={props.control}
      requerido
      nomeCampoChave="Id"
      nomeCampoExibicao={exibicaoContaContabil}
      expressaoDeBusca={contaContabilExpessaoBusca}
      dataSource={dataSource}
      lupaConfig={{
        modo: "selecaoUnica",
        titulo: "Selecionar conta contábil",
        componente: (r) => (
          <GridContaContabil
            ref={r}
            filtrosRealizadosNoServidor={filtrosPadroes}
          />
        ),
      }}
      labelSemDados="Sem dados"
      onSelectionChanged={props.onSelectionChanged}
    />
  );
}
