import PaginaMxp from "../../../../../../components/layout/pagina-mxp";
import ProvedorMenus from "../../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../../components/seguranca/requer-permissao";
import { PermissoesEfdReinf } from "../../../../../../models/permissoes/fiscal/efd-reinf/permissoes-efd-reinf";
import NomesTelas from "../../../../../../utils/common/nomes-telas";
import GridEfdReinf4020 from "../../../componentes/grid/efd-reinf-4020";

export default function EfdReinf4020Page() {
  return (
    <ProvedorMenus>
      <PaginaMxp
        id="efd-reinf-4020-page"
        titulo={NomesTelas.efdReinf4020}
        area={["Fiscal"]}
      >
        <RequerPermissao codigoPermissoes={[PermissoesEfdReinf.Consultar]}>
          <GridEfdReinf4020 />
        </RequerPermissao>
      </PaginaMxp>
    </ProvedorMenus>
  );
}
