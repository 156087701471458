import { Column } from "devextreme-react/cjs/data-grid";
import { ReactNode, useContext } from "react";
import { v4 } from "uuid";
import { ItemContextMenuMxp } from "../../../utils/context-menu/context-menu-utils";
import obterConfiguracaoColuna from "../../../utils/grid/padroes-colunas";
import ContextoMenus from "../../menus/contexto-menus";
import CelulaControleEdicaoMxp from "../../templates-celulas-grid/celula-controle-edicao-mxp";

interface GridColunaAcoesProps<T> {
  handleEditar?: (registro: T) => void;
  handleExcluir?: (registro: T) => void;
  gerarBotoesAdicionais?: (registro: T) => ReactNode[];
}

function calculaLarguraDaColunaDeAcoes(itens?: ItemContextMenuMxp[]) {
  const larguraPadrao = 65;

  if (!itens) {
    return larguraPadrao;
  }

  return Math.max(
    larguraPadrao,
    10 +
      (itens.filter((x) => x.exibirNaLinhaDaGrid == "sempre").length +
        (itens.some((x) => !x.exibirNaLinhaDaGrid) ? 1 : 0)) *
        20
  );
}

export default function GridColunaAcoes<T>(props: GridColunaAcoesProps<T>) {
  const { itensMenu } = useContext(ContextoMenus);

  return (
    <Column
      key="ColunaDeAcoes"
      name="acoes"
      {...obterConfiguracaoColuna("acoes")}
      minWidth={calculaLarguraDaColunaDeAcoes(itensMenu)}
      cellRender={({ data }) => {
        return (
          <CelulaControleEdicaoMxp<T>
            key={`ctrlEdt-${v4()}`}
            dados={data}
            itens={itensMenu}
            editar={props.handleEditar}
            excluir={props.handleExcluir}
            gerarBotoesAdicionais={props.gerarBotoesAdicionais}
          />
        );
      }}
    />
  );
}
