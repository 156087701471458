import "../../../themes/personalizacoes/devexpress-overrides.css";
import "../../../themes/personalizacoes/grid/filter-header.override.css";
import "../../../themes/personalizacoes/grid/filter-panel.override.css";
import "../../../themes/personalizacoes/grid/grid.override.css";
import "../../../themes/personalizacoes/inputs/input.override.css";

/**
 * Componente que permite a sobreposição de estilos CSS.
 * Ver <https://app.clickup.com/t/9007115994/SL-51286> (Corrigir botões e campos no MXP2 para ficarem em minúsculo | SL-51286)
 */
interface SobrescritorDeCssProps {
  children: React.ReactNode;
}

/**
 * Componente que permite a sobreposição de estilos CSS.
 *
 * @param children Os elementos filhos a serem renderizados.
 * @returns O componente de sobreposição de estilos CSS.
 */
const SobrescritorDeCss = ({ children }: SobrescritorDeCssProps) => {
  return <>{children}</>;
};

export default SobrescritorDeCss;
