import { yupResolver } from "@hookform/resolvers/yup";
import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/cjs/accordion";
import DataSource from "devextreme/data/data_source";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../components/botoes/botao-salvar-mxp";
import {
  FormCheckBox,
  FormDateBox,
  FormNumberBox,
  FormSelectBoxLazyMxp,
  FormTextBox,
} from "../../../../../components/formularios";
import { assertConfiguracaoExibicaoEBuscaType } from "../../../../../components/formularios/selectbox-lazy-mxp";
import FormMxp from "../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import AuditavelDTO from "../../../../../models/api/comum/auditavel-dto";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../models/shared/ui/formularios";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import FiltrosGridCentroDeTrabalho from "../../../../../utils/filtros-grid/producao/centro-de-trabalho/filtros-grid-centro-de-trabalho";
import { obterFormatStringNumero } from "../../../../../utils/formatadores/formatador-de-numeros";
import GridCentroDeTrabalho from "../../../centro-de-trabalho/componentes/grid";
import { CentroDeTrabalhoGridModel } from "../../../centro-de-trabalho/models/centro-de-trabalho.api";
import {
  TipoCentroDeTrabalho,
  TipoCentroDeTrabalhoHelper,
  TipoSequenciamento,
} from "../../../centro-de-trabalho/models/centro-de-trabalho.enums";
import CentroDeTrabalhoServico from "../../../centro-de-trabalho/servicos/centro-de-trabalho.servico";
import GridEstacaoDeTrabalho from "../../../estacao-de-trabalho/componentes/grid";
import { EstacaoDeTrabalhoServico } from "../../../estacao-de-trabalho/servicos/estacao-de-trabalho";
import FiltrosGridEstacaoDeTrabalho from "../../../estacao-de-trabalho/utils/filtros/filtros-grid-estacao-de-trabalho";
import GridOrdemDeProducao from "../../../ordem-de-producao/componentes/grid-padrao";
import { OrdemDeProducaoService } from "../../../ordem-de-producao/servicos/ordem-de-producao.service";
import FiltrosGridOrdemDeProducao from "../../../ordem-de-producao/utils/filtros/filtros-grid-ordem-de-producao";
import {
  OperacaoDaOrdemDeProducaoGridModel,
  OperacaoDaOrdemDeProducaoRequestDTO,
  OperacaoDaOrdemDeProducaoResponseDTO,
  tamanhoMaximo,
  tamanhoMaximoDescricao,
  tamanhoMaximoLeadTimeEFolgaPrevistaEmDias,
  tamanhoMaximoTempoPrevistoEmHoras,
  tamanhoMinimo,
} from "../../models/operacao-de-ordem-de-producao";
import { OperacaoEstado } from "../../models/operacao-de-ordem-de-producao-enums";
import { OperacaoDeOrdemDeProducaoServico } from "../../servicos/operacao-de-ordem-de-producao-service";
import OperacaoDaOrdemDeProducaoAbaCapacidadeFinita from "../abas-formulario/capacidade-finita";
import OperacaoDaOrdemDeProducaoAbaObservacoes from "../abas-formulario/observacoes";

let dadosAuditoria: AuditavelDTO | undefined = undefined;

const service = new OperacaoDeOrdemDeProducaoServico();
const ordemDeProducaoService = new OrdemDeProducaoService();
const centroDeTrabalhoService = new CentroDeTrabalhoServico();
const operacaoService = new OperacaoDeOrdemDeProducaoServico();
const estacaoDeTrabalhoServico = new EstacaoDeTrabalhoServico();

const [dataSourceOrdemDeProducao, configuracoesExibicaoEBuscaOrdemDeProducao] =
  ordemDeProducaoService.GetDadosSelectBoxOrdemDeProducao();

const [
  dataSourceCentroDeTrabalho,
  configuracoesExibicaoEBuscaCentroDeTrabalho,
] = centroDeTrabalhoService.GetDadosSelectBoxCentroDeTrabalho();

const [
  dataSourceEstacaoDeTrabalho,
  configuracoesExibicaoEBuscaEstacaoDeTrabalho,
] = estacaoDeTrabalhoServico.GetDadosSelectBoxEstacaoDeTrabalho();

const configuracoesExibicaoEBuscaProximaOperacaoDaOrdemDeProducao =
  assertConfiguracaoExibicaoEBuscaType<OperacaoDaOrdemDeProducaoGridModel>({
    nomeCampoChave: "id",
    expressaoDeBusca: ["numero", "descricao"],
    nomeCampoExibicao: (c) => {
      if (c) {
        return c.descricao == null
          ? `${c.numero}`
          : `${c.numero} (${c.descricao})`;
      }

      return "";
    },
  });

const novoRegistro: OperacaoDaOrdemDeProducaoRequestDTO = {
  id: 0,
  idOrdemDeProducaoOuManutencao: NaN,
  idItemPaiDaOperacao: NaN,
  idCentroDeTrabalho: NaN,
  idEstacaoDeTrabalho: NaN,
  estacaoDeTrabalhoEstaFixa: false,
  numeroDaOperacao: NaN,
  descricao: "",
  idOperacaoPosterior: NaN,
  quantidadePrevista: 0,
  leadTimePrevistoEmDias: 0,
  folgaPrevistaEmDias: 0,
  tempoPrevistoEmHoras: 0,
  tempoPrevistoEstaFixo: true,
  inicioPrevistoData: null,
  fimPrevistoData: null,
  estacaoDeTrabalhoOrdemEmCapacidadeFinita: null,
  observacoes: null,
  estado: OperacaoEstado.NaoIniciada,
  idOperacaoDoItem: NaN,
  tempoDePreparacaoPrevistoEmHoras: null,
  preparacaoPrevistaNaCapacidadeFinitaData: null,
  tempoRealizadoEmHoras: null,
  inicioRealizadoData: null,
  fimRealizadoData: null,
  quantidadeRealizada: null,
};

export default function FormOperacaoDaOrdemDeProducao(
  props: IFormularioEditavelBase
) {
  const [carregando, setCarregando] = useState(false);
  const [centroDeTrabalho, setCentroDeTrabalho] =
    useState<CentroDeTrabalhoGridModel>();
  const [dataSourceProximasOperacoes, setdataSourceProximasOperacoes] =
    useState<DataSource>(new DataSource<any>([]));
  const isFormularioEdicao = props.idRegistroEmEdicao != 0;

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    idOrdemDeProducaoOuManutencao: yup
      .number()
      .required()
      .moreThan(-1)
      .integer(),
    idCentroDeTrabalho: yup.number().required().moreThan(-1).integer(),
    numeroDaOperacao: yup
      .number()
      .required()
      .moreThan(-1)
      .max(tamanhoMaximo.toNumber())
      .integer(),
    leadTimePrevistoEmDias: yup.number().moreThan(-1),
    folgaPrevistaEmDias: yup.number().moreThan(-1),
    inicioPrevistoData: yup.date().required(),
    fimPrevistoData: yup
      .date()
      .required()
      .test(
        "fim_previsto_data_valida",
        "Fim previsto deve ser posterior ao início previsto.",
        function (valor, contexto) {
          if (valor && contexto.parent.inicioPrevistoData) {
            const inicioPrevistoData = new Date(
              contexto.parent.inicioPrevistoData
            );
            const fimPrevisto = new Date(valor);
            return inicioPrevistoData <= fimPrevisto;
          }
          return true;
        }
      ),
  });

  const hookForms = useForm<OperacaoDaOrdemDeProducaoRequestDTO>({
    resolver: yupResolver(schema),
  });

  const { control, handleSubmit, getValues, reset, register, setValue, watch } =
    hookForms;

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (
      Number.isNaN(props.idRegistroEmEdicao) ||
      props.idRegistroEmEdicao === 0
    ) {
      //Limpa tela para inserir os valores default no form definido no 'novoRegistro'
      limparTela();

      const dataAtual = new Date();
      //Sempre insere as datas como a data atual no formulário de inserção
      setValue("fimPrevistoData", dataAtual);
      setValue("inicioPrevistoData", dataAtual);

      return;
    }

    preencherTela();

    return () => limparTela();
  }, [props.idRegistroEmEdicao]);

  async function preencherTela() {
    if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    }
  }

  async function carregarRegistroDoId() {
    try {
      setCarregando(true);
      const resposta =
        await service.ObterPorIdComDadosAuditoria<OperacaoDaOrdemDeProducaoResponseDTO>(
          props.idRegistroEmEdicao
        );
      checarResponse(resposta);
      dadosAuditoria = resposta.model;
      reset(resposta.model);
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();

    try {
      const resposta =
        props.idRegistroEmEdicao > 0
          ? await service.Atualizar(model)
          : await service.Cadastrar(model);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function callBackUnprocessableEntity() {
    fechar(ResultadoAcaoFormulario.AcaoConcluida);
  }

  function limparTela() {
    dadosAuditoria = undefined;
    reset(novoRegistro);
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  async function onSelectionChangeOrdemDeProducao(ordemDeProducao: any) {
    let itemId = 0;
    let quantidadeDaOp = 0;

    if (ordemDeProducao.selectedItem) {
      itemId = ordemDeProducao.selectedItem.itemId;
      quantidadeDaOp = ordemDeProducao.selectedItem.quantidade;
      setdataSourceProximasOperacoes(
        await carregaProximasOperacoesDaOrdemDeProducao(
          ordemDeProducao.selectedItem.id
        )
      );
    }

    setValue("quantidadePrevista", quantidadeDaOp);
    setValue("idItemPaiDaOperacao", itemId);
  }

  async function onSelectionChangeCentroDeTrabalho(valor: any) {
    const centroDeTrabalhoSelecionado = valor.selectedItem ?? null;

    if (centroDeTrabalhoSelecionado?.operacaoDescricaoPreferencial) {
      setValue(
        "descricao",
        centroDeTrabalhoSelecionado?.operacaoDescricaoPreferencial
      );
    }

    setCentroDeTrabalho(centroDeTrabalhoSelecionado);
  }

  async function carregaProximasOperacoesDaOrdemDeProducao(
    ordemDeProducaoId: number
  ) {
    return operacaoService.ObterDataSourceParaSelectBoxLazy<OperacaoDaOrdemDeProducaoGridModel>(
      {
        camposRetorno: ["id", "numero", "descricao"],
        camposOrdenacao: [
          {
            campo: "numero",
            desc: false,
          },
        ],
        camposFiltro: [
          {
            campo: "ordemDeProducaoId",
            operador: "=",
            valor: ordemDeProducaoId,
          },
        ],
      }
    );
  }

  function isCapacidadeFinitaHabilitado() {
    return (
      centroDeTrabalho != null &&
      centroDeTrabalho.tipoSequenciamento == TipoSequenciamento.Fixo &&
      getValues("idEstacaoDeTrabalho") != null &&
      watch("estacaoDeTrabalhoEstaFixa")
    );
  }

  function isEstacaoDeTrabalhoHabilitado() {
    return (
      centroDeTrabalho?.tipo !=
      TipoCentroDeTrabalhoHelper.getDescricao(TipoCentroDeTrabalho.ComEstacoes)
    );
  }

  return (
    <>
      <ContainerFormMxp>
        <FormMxp carregando={carregando}>
          <ProvedorAjuda id="edit-form-operacap-da-ordem-de-producao">
            <input type="hidden" {...register("id")} defaultValue={0} />
            <input type="hidden" {...register("estado")} />
            <input type="hidden" {...register("idItemPaiDaOperacao")} />
            <Linha>
              <Coluna md={6}>
                <FormSelectBoxLazyMxp
                  name="idOrdemDeProducaoOuManutencao"
                  titulo="Ordem de producão"
                  control={control}
                  requerido
                  dataSource={dataSourceOrdemDeProducao}
                  onSelectionChanged={onSelectionChangeOrdemDeProducao}
                  configuracoesExibicaoEBusca={
                    configuracoesExibicaoEBuscaOrdemDeProducao
                  }
                  labelSemDados="Sem dados"
                  seletorConfig={{
                    modo: "selecaoUnica",
                    titulo: "Selecionar ordem de produção",
                    componenteGrid: (
                      <GridOrdemDeProducao
                        filtrosNoCliente={
                          FiltrosGridOrdemDeProducao.ordemDeProducaoEstadoAProduzir
                        }
                      />
                    ),
                  }}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={6}>
                <FormSelectBoxLazyMxp
                  name="idCentroDeTrabalho"
                  titulo="Centro de trabalho"
                  control={control}
                  requerido
                  onSelectionChanged={onSelectionChangeCentroDeTrabalho}
                  dataSource={dataSourceCentroDeTrabalho}
                  configuracoesExibicaoEBusca={
                    configuracoesExibicaoEBuscaCentroDeTrabalho
                  }
                  labelSemDados="Sem dados"
                  seletorConfig={{
                    modo: "selecaoUnica",
                    titulo: "Selecionar centro de trabalho",
                    componenteGrid: (
                      <GridCentroDeTrabalho
                        filtrosNoCliente={
                          FiltrosGridCentroDeTrabalho.centroDeTrabalhoAtivo
                        }
                      />
                    ),
                  }}
                />
              </Coluna>
              <Coluna md={5}>
                <FormSelectBoxLazyMxp
                  name="idEstacaoDeTrabalho"
                  titulo="Estação de trabalho"
                  control={control}
                  somenteLeitura={isEstacaoDeTrabalhoHabilitado()}
                  dataSource={dataSourceEstacaoDeTrabalho}
                  configuracoesExibicaoEBusca={
                    configuracoesExibicaoEBuscaEstacaoDeTrabalho
                  }
                  labelSemDados="Sem dados"
                  seletorConfig={{
                    modo: "selecaoUnica",
                    titulo: "Selecionar estação de trabalho",
                    componenteGrid: (
                      <GridEstacaoDeTrabalho
                        filtrosNoCliente={
                          FiltrosGridEstacaoDeTrabalho.estacaoDeTrabalhoAtivo
                        }
                      />
                    ),
                  }}
                />
              </Coluna>
              <Coluna md={1} centralizar>
                <FormCheckBox
                  name="estacaoDeTrabalhoEstaFixa"
                  titulo="Fixar"
                  desabilitado={
                    Number.isNaN(watch("idEstacaoDeTrabalho")) ||
                    watch("idEstacaoDeTrabalho") == null
                  }
                  defaultValue={false}
                  control={control}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={3}>
                <FormNumberBox
                  name="numeroDaOperacao"
                  titulo="Operação"
                  control={control}
                  minimo={tamanhoMinimo.toNumber()}
                  maximo={tamanhoMaximo.toNumber()}
                  requerido
                  formato={obterFormatStringNumero(0, false)}
                  quantidadeIncrementarDecrementar={1}
                />
              </Coluna>
              <Coluna md={9}>
                <FormTextBox
                  name="descricao"
                  titulo="Descrição"
                  control={control}
                  tamanhoMaximo={tamanhoMaximoDescricao.toNumber()}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={3}>
                <FormSelectBoxLazyMxp
                  name="idOperacaoPosterior"
                  titulo="Próxima operação"
                  control={control}
                  dataSource={dataSourceProximasOperacoes}
                  configuracoesExibicaoEBusca={
                    configuracoesExibicaoEBuscaProximaOperacaoDaOrdemDeProducao
                  }
                  labelSemDados="Sem dados"
                />
              </Coluna>
              <Coluna md={3}>
                <FormNumberBox
                  name="quantidadePrevista"
                  titulo="Quantidade da operação"
                  control={control}
                  minimo={tamanhoMinimo.toNumber()}
                  somenteLeitura
                />
              </Coluna>
              <Coluna md={3}>
                <FormNumberBox
                  name="leadTimePrevistoEmDias"
                  titulo="Lead time em dias"
                  control={control}
                  minimo={tamanhoMinimo.toNumber()}
                  maximo={tamanhoMaximoLeadTimeEFolgaPrevistaEmDias.toNumber()}
                  formato={obterFormatStringNumero(0, false)}
                  quantidadeIncrementarDecrementar={1}
                />
              </Coluna>
              <Coluna md={3}>
                <FormNumberBox
                  name="folgaPrevistaEmDias"
                  titulo="Folga em dias"
                  control={control}
                  minimo={tamanhoMinimo.toNumber()}
                  maximo={tamanhoMaximoLeadTimeEFolgaPrevistaEmDias.toNumber()}
                  formato={obterFormatStringNumero(0, false)}
                  quantidadeIncrementarDecrementar={1}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={5}>
                <FormNumberBox
                  name="tempoPrevistoEmHoras"
                  titulo="Tempo total previsto em horas"
                  control={control}
                  minimo={tamanhoMinimo.toNumber()}
                  maximo={tamanhoMaximoTempoPrevistoEmHoras.toNumber()}
                  formato={obterFormatStringNumero(4)}
                  somenteLeitura={
                    !watch("tempoPrevistoEstaFixo") && isFormularioEdicao
                  }
                  quantidadeIncrementarDecrementar={1}
                />
              </Coluna>
              <Coluna md={1} centralizar>
                <FormCheckBox
                  name="tempoPrevistoEstaFixo"
                  titulo="Fixar"
                  defaultValue={true}
                  desabilitado={!isFormularioEdicao}
                  control={control}
                />
              </Coluna>
              <Coluna lg={3}>
                <FormDateBox
                  name="inicioPrevistoData"
                  titulo="Inicio previsto"
                  requerido
                  control={control}
                  exibirBotaoLimpar
                  tipo="datetime"
                  aceitaValorCustomizado={true}
                  formatoExibicao="dd/MM/yy hh:mm:ss"
                  aceitaDigitacaoComMascara={true}
                />
              </Coluna>
              <Coluna md={3}>
                <FormDateBox
                  name="fimPrevistoData"
                  titulo="Fim previsto"
                  requerido
                  control={control}
                  exibirBotaoLimpar
                  tipo="datetime"
                  aceitaValorCustomizado={true}
                  formatoExibicao="dd/MM/yy hh:mm:ss"
                  aceitaDigitacaoComMascara={true}
                />
              </Coluna>
            </Linha>
            <TabPanel
              key={props.idRegistroEmEdicao}
              deferRendering={false}
              showNavButtons
              swipeEnabled={false}
              itemTitleRender={(item) => item.text}
              height={"43%"}
            >
              <Item text="Configurações de capacidade finita">
                <OperacaoDaOrdemDeProducaoAbaCapacidadeFinita
                  hookForms={hookForms}
                  isSomenteLeitura={isCapacidadeFinitaHabilitado()}
                />
              </Item>
              <Item text="Observações">
                <OperacaoDaOrdemDeProducaoAbaObservacoes
                  hookForms={hookForms}
                />
              </Item>
            </TabPanel>
          </ProvedorAjuda>
        </FormMxp>
        <ToolbarMxp dadosAuditoria={dadosAuditoria}>
          <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </>
  );
}
