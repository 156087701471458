import { mesPorExtenso } from "../../../../utils/common/date-utils";
import { arredondar2Casas } from "../../../../utils/common/number-utils";
import { TipoContaContabil } from "../../conta-contabil/models/conta-contabil.enum";
import {
  CompetenciaRegistroBalanceteComparativo,
  RegistroBalanceteComparativo,
} from "../models/balancete-comparativo.api";
import {
  ChavesBalanceteComparativoGridModel,
  PrefixosBalanceteComparativoGridModel,
  TipoAgrupamentoBalanceteComparativo,
} from "../models/balancete-comparativo.enums";
import {
  BalanceteComparativoGridViewModel,
  DefinicaoColunaBalancete,
} from "../models/balancete-comparativo.viewmodel";

export default class BalanceteComparativoAdapter {
  public static registroBalanceteComparativoToBalanceteComparativoGridViewModel(
    balanceteComparativo: RegistroBalanceteComparativo[]
  ) {
    const view: BalanceteComparativoGridViewModel = {
      competencias: this.gerarDefinicoesColunas(
        balanceteComparativo.length > 0
          ? balanceteComparativo[0].competencias
          : [],
        balanceteComparativo.length > 0
          ? balanceteComparativo[0].competencias[0].agrupamento
          : TipoAgrupamentoBalanceteComparativo.Mensal
      ),
      dados: balanceteComparativo.map((x) => this.mapInterno(x)),
    };

    return view;
  }

  private static mapInterno(item: RegistroBalanceteComparativo): any {
    const dado: any = {};
    dado[ChavesBalanceteComparativoGridModel.Id] = item.contaContabilId;
    dado[ChavesBalanceteComparativoGridModel.Codigo] = item.codigo;
    dado[ChavesBalanceteComparativoGridModel.Classificacao] =
      item.classificacao;
    dado[ChavesBalanceteComparativoGridModel.Descricao] = item.descricao;
    dado[ChavesBalanceteComparativoGridModel.Nivel] = item.nivel;
    dado[ChavesBalanceteComparativoGridModel.Sintetica] =
      item.tipo == TipoContaContabil.Sintetica;

    for (const competencia of item.competencias) {
      const posFixo = this.normalizaPosfixoCompetencia(competencia.competencia);
      dado[`${PrefixosBalanceteComparativoGridModel.SaldoInicial}${posFixo}`] =
        arredondar2Casas(competencia.saldoInicial);
      dado[`${PrefixosBalanceteComparativoGridModel.SaldoFinal}${posFixo}`] =
        arredondar2Casas(competencia.saldoFinal);
      dado[`${PrefixosBalanceteComparativoGridModel.Debitos}${posFixo}`] =
        arredondar2Casas(competencia.debito);
      dado[`${PrefixosBalanceteComparativoGridModel.Creditos}${posFixo}`] =
        arredondar2Casas(competencia.credito);
      dado[`${PrefixosBalanceteComparativoGridModel.Liquido}${posFixo}`] =
        arredondar2Casas(competencia.liquido);
    }

    return dado;
  }

  private static gerarDefinicoesColunas(
    competencias: CompetenciaRegistroBalanceteComparativo[],
    agrupamento: TipoAgrupamentoBalanceteComparativo
  ) {
    return competencias.map((x) =>
      this.gerarDefinicaoColuna(x.competencia, agrupamento)
    );
  }
  private static gerarDefinicaoColuna(
    competencia: string,
    agrupamento: TipoAgrupamentoBalanceteComparativo
  ): DefinicaoColunaBalancete {
    return {
      chave: this.normalizaPosfixoCompetencia(competencia),
      descricao: this.formatarCompetencia(competencia, agrupamento),
    };
  }

  private static normalizaPosfixoCompetencia(competencia: string) {
    return competencia.replace("/", "_").replace("-", "_");
  }

  private static formatarCompetencia(
    competencia: string,
    agrupamento: TipoAgrupamentoBalanceteComparativo
  ) {
    switch (agrupamento) {
      case TipoAgrupamentoBalanceteComparativo.Mensal: {
        const fragmentosMensal = competencia.split("/");
        const mes = fragmentosMensal[0];
        const ano = fragmentosMensal[1];
        return `${mesPorExtenso(mes)}/${ano}`;
      }

      case TipoAgrupamentoBalanceteComparativo.Trimestral: {
        const fragmentosTrimestre = competencia.split("-");
        const anoTrimestre = fragmentosTrimestre[0];
        const trimestre = fragmentosTrimestre[1];
        return `${trimestre}º trimestre/${anoTrimestre}`;
      }

      default: {
        return competencia;
      }
    }
  }
}
