import SelectItem from "../../../../models/shared/ui/select-item";
import { gerarSelectItemDoDicionario } from "../../../../utils/common/enum-utils";

export enum TipoCarroceria {
  NaoAplicavel = 78,
  Aberta = 65,
  FechadaBau = 70,
  Granelera = 71,
  PortaContainer = 67,
  Sider = 83,
}

export const tipoCarroceriaDecodificado: {
  [key in TipoCarroceria]: string;
} = {
  [TipoCarroceria.NaoAplicavel]: "Não aplicável",
  [TipoCarroceria.Aberta]: "Aberta",
  [TipoCarroceria.FechadaBau]: "Fechada/baú",
  [TipoCarroceria.Granelera]: "Granelera",
  [TipoCarroceria.PortaContainer]: "Porta container",
  [TipoCarroceria.Sider]: "Sider",
};

export enum TipoProprietario {
  TACAgregado = 65,
  TACIndependente = 73,
  Outros = 79,
}

export const tipoProprietarioDecodificado: {
  [key in TipoProprietario]: string;
} = {
  [TipoProprietario.TACAgregado]:
    "Transportador autônomo de cargas (TAC) agregado",
  [TipoProprietario.TACIndependente]:
    "Transportador autônomo de cargas (TAC) independente",
  [TipoProprietario.Outros]: "Outros",
};

export enum TipoRodado {
  Truck = 82,
  Toco = 84,
  CavaloMecanico = 67,
  Van = 86,
  Utilitario = 85,
  Outros = 79,
}

export const tipoRodadoDecodificado: {
  [key in TipoRodado]: string;
} = {
  [TipoRodado.Truck]: "Truck",
  [TipoRodado.Toco]: "Toco",
  [TipoRodado.CavaloMecanico]: "Cavalo mecânico",
  [TipoRodado.Van]: "Van",
  [TipoRodado.Utilitario]: "Utilitário",
  [TipoRodado.Outros]: "Outros",
};

export enum TipoUnidadeTransporte {
  RodoviarioTracao = 84,
  RodoviarioReboque = 82,
  Navio = 78,
  Balsa = 66,
  Aeronave = 65,
  Vagao = 86,
  Outros = 79,
}

export const tipoUnidadeTransporteDecodificado: {
  [key in TipoUnidadeTransporte]: string;
} = {
  [TipoUnidadeTransporte.RodoviarioTracao]: "Rodoviário tração",
  [TipoUnidadeTransporte.RodoviarioReboque]: "Rodoviário reboque",
  [TipoUnidadeTransporte.Navio]: "Navio",
  [TipoUnidadeTransporte.Balsa]: "Balsa",
  [TipoUnidadeTransporte.Aeronave]: "Aeronave",
  [TipoUnidadeTransporte.Vagao]: "Vagão",
  [TipoUnidadeTransporte.Outros]: "Outros",
};

export const SelectItemEnumTipoCarroceria: SelectItem[] =
  gerarSelectItemDoDicionario(tipoCarroceriaDecodificado);

export const SelectItemEnumTipoProprietario: SelectItem[] =
  gerarSelectItemDoDicionario(tipoProprietarioDecodificado);

export const SelectItemEnumTipoRodado: SelectItem[] =
  gerarSelectItemDoDicionario(tipoRodadoDecodificado);

export const SelectItemEnumTipoUnidadeTransporte: SelectItem[] =
  gerarSelectItemDoDicionario(tipoUnidadeTransporteDecodificado);
