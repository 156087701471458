import { Titulo, Container } from "./style";

interface TituloGridProps {
  texto: string;
}

export default function TituloGrid({ texto }: TituloGridProps) {
  return (
    <Container>
      <Titulo>{texto}</Titulo>
    </Container>
  );
}
