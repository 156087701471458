import { NumberBox } from "devextreme-react";
import { LabelMode } from "devextreme/common";
import { Format } from "devextreme/localization";
import { NumberBoxType } from "devextreme/ui/number_box";
import IconeAjuda from "../../ajuda/icone-ajuda";
import LinkAjuda from "../../ajuda/link-ajuda";

interface FormNumberBoxProps {
  valor?: number;
  tabIndex?: number;
  titulo: string;
  toolTip?: string;
  tipo?: NumberBoxType;
  placeholder?: string;
  tipoPlaceholder?: LabelMode;
  exibirBotaoLimpar?: boolean;
  exibirSetasIncrementarDecrementar?: boolean;
  quantidadeIncrementarDecrementar?: number;
  somenteLeitura?: boolean;
  desabilitado?: boolean;
  requerido?: boolean;
  formato?: Format;
  maximo?: number;
  minimo?: number;
  exibirLinkAjuda?: boolean;
  onChange?: (e: number) => void;
  valueChangeEvent?: string;
  onEnter?: (e: any) => void;
  idAjuda?: string;
}

export default function FormNumberBoxSimples(props: FormNumberBoxProps) {
  return (
    <>
      <label>
        <span className="dx-field-item-label-content">
          <span className="dx-field-item-label-text">{props.titulo}</span>
          {props.requerido && (
            <span className="dx-field-item-required-mark">&nbsp;*</span>
          )}
          {props.idAjuda && (
            <LinkAjuda keyAjuda={props.idAjuda}>
              <IconeAjuda />
            </LinkAjuda>
          )}
        </span>
      </label>
      <NumberBox
        tabIndex={props.tabIndex}
        hint={props.toolTip}
        mode={props.tipo ?? "number"}
        label={props.placeholder}
        labelMode={props.tipoPlaceholder}
        showSpinButtons={props.exibirSetasIncrementarDecrementar}
        step={props.quantidadeIncrementarDecrementar}
        format={props.formato}
        readOnly={props.somenteLeitura}
        disabled={props.desabilitado}
        showClearButton={props.exibirBotaoLimpar}
        max={props.maximo}
        min={props.minimo}
        valueChangeEvent={props.valueChangeEvent ?? "blur"}
        onEnterKey={props.onEnter}
        onValueChange={(value) => {
          if (props.onChange) {
            props.onChange(value);
          }
        }}
        value={props.valor}
        inputAttr={{ id: props.idAjuda }}
      />
    </>
  );
}
