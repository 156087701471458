import { gerarSelectItemDoDicionario } from "../../../utils/common/enum-utils";
import {
  IdUFBanco,
  ambienteMDFeDecodificado,
  modalidadeTransporteMDFeDecodificado,
  situacaoMDFeDecodificada,
  tipoCarregamentoMDFeDecodificado,
  tipoEmissaoMDFeDecodificado,
  tipoEmitenteMDFeDecodificado,
  tipoTransportadorMDFeDecodificado,
  unidadeMedidaPesoBrutoMDFeDecodificado,
} from "../../api/mdfe/mdfe-enums";
import SelectItem from "../../shared/ui/select-item";

export const SelectItemEnumAmbienteMDFe: SelectItem[] =
  gerarSelectItemDoDicionario(ambienteMDFeDecodificado);

export const SelectItemEnumSituacaoMDFe: SelectItem[] =
  gerarSelectItemDoDicionario(situacaoMDFeDecodificada);

export const SelectItemEnumTipoEmissaoMDFe: SelectItem[] =
  gerarSelectItemDoDicionario(tipoEmissaoMDFeDecodificado);

export const SelectItemEnumTipoEmitenteMDFe: SelectItem[] =
  gerarSelectItemDoDicionario(tipoEmitenteMDFeDecodificado);

export const SelectItemEnumTipoTransportadorMDFe: SelectItem[] =
  gerarSelectItemDoDicionario(tipoTransportadorMDFeDecodificado);

export const SelectItemEnumModalidadeTransporteMDFe: SelectItem[] =
  gerarSelectItemDoDicionario(modalidadeTransporteMDFeDecodificado);

export const SelectItemEnumTipoCarregamentoMDFe: SelectItem[] =
  gerarSelectItemDoDicionario(tipoCarregamentoMDFeDecodificado);

export const SelectItemEnumUnidadeMedidaPesoBrutoMDFe: SelectItem[] =
  gerarSelectItemDoDicionario(unidadeMedidaPesoBrutoMDFeDecodificado);

export const SelectValueEnumIdUFBanco: SelectItem[] = Object.keys(
  IdUFBanco
).map((key) => ({
  valor: IdUFBanco[key as keyof typeof IdUFBanco],
  descricao: key,
}));

export const divisasValidas = {
  [IdUFBanco.AC]: [IdUFBanco.AM, IdUFBanco.RO],
  [IdUFBanco.AL]: [IdUFBanco.SE, IdUFBanco.PE, IdUFBanco.BA],
  [IdUFBanco.AM]: [
    IdUFBanco.AC,
    IdUFBanco.RO,
    IdUFBanco.MT,
    IdUFBanco.PA,
    IdUFBanco.RR,
  ],
  [IdUFBanco.AP]: [IdUFBanco.PA],
  [IdUFBanco.BA]: [
    IdUFBanco.ES,
    IdUFBanco.MG,
    IdUFBanco.GO,
    IdUFBanco.TO,
    IdUFBanco.PI,
    IdUFBanco.SE,
    IdUFBanco.AL,
    IdUFBanco.PE,
  ],
  [IdUFBanco.CE]: [IdUFBanco.PI, IdUFBanco.PE, IdUFBanco.PB, IdUFBanco.CE],
  [IdUFBanco.DF]: [IdUFBanco.GO, IdUFBanco.MG],
  [IdUFBanco.ES]: [IdUFBanco.RJ, IdUFBanco.MG, IdUFBanco.BA],
  [IdUFBanco.GO]: [
    IdUFBanco.MS,
    IdUFBanco.MT,
    IdUFBanco.TO,
    IdUFBanco.DF,
    IdUFBanco.BA,
    IdUFBanco.MG,
  ],
  [IdUFBanco.MA]: [IdUFBanco.PI, IdUFBanco.TO, IdUFBanco.PA],
  [IdUFBanco.MG]: [
    IdUFBanco.SP,
    IdUFBanco.RJ,
    IdUFBanco.ES,
    IdUFBanco.BA,
    IdUFBanco.GO,
    IdUFBanco.MS,
    IdUFBanco.DF,
  ],
  [IdUFBanco.MS]: [
    IdUFBanco.PR,
    IdUFBanco.SP,
    IdUFBanco.MG,
    IdUFBanco.GO,
    IdUFBanco.MT,
  ],
  [IdUFBanco.MT]: [
    IdUFBanco.MS,
    IdUFBanco.GO,
    IdUFBanco.TO,
    IdUFBanco.PA,
    IdUFBanco.AM,
    IdUFBanco.RO,
  ],
  [IdUFBanco.PA]: [
    IdUFBanco.MT,
    IdUFBanco.TO,
    IdUFBanco.MA,
    IdUFBanco.AP,
    IdUFBanco.RR,
    IdUFBanco.AM,
  ],
  [IdUFBanco.PB]: [IdUFBanco.PE, IdUFBanco.CE, IdUFBanco.RN],
  [IdUFBanco.PE]: [
    IdUFBanco.BA,
    IdUFBanco.AL,
    IdUFBanco.PB,
    IdUFBanco.CE,
    IdUFBanco.PI,
  ],
  [IdUFBanco.PI]: [
    IdUFBanco.BA,
    IdUFBanco.PE,
    IdUFBanco.CE,
    IdUFBanco.MA,
    IdUFBanco.TO,
  ],
  [IdUFBanco.PR]: [IdUFBanco.SC, IdUFBanco.MS, IdUFBanco.SP],
  [IdUFBanco.RJ]: [IdUFBanco.SP, IdUFBanco.MG, IdUFBanco.ES],
  [IdUFBanco.RN]: [IdUFBanco.PB, IdUFBanco.CE],
  [IdUFBanco.RO]: [IdUFBanco.MT, IdUFBanco.AM, IdUFBanco.AC],
  [IdUFBanco.RR]: [IdUFBanco.AM, IdUFBanco.PA],
  [IdUFBanco.RS]: [IdUFBanco.SC],
  [IdUFBanco.SC]: [IdUFBanco.RS, IdUFBanco.PR],
  [IdUFBanco.SE]: [IdUFBanco.AL, IdUFBanco.BA],
  [IdUFBanco.SP]: [IdUFBanco.PR, IdUFBanco.MS, IdUFBanco.RJ, IdUFBanco.MG],
  [IdUFBanco.TO]: [
    IdUFBanco.MT,
    IdUFBanco.GO,
    IdUFBanco.BA,
    IdUFBanco.MA,
    IdUFBanco.PA,
    IdUFBanco.PI,
  ],
};
