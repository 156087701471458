import styled from "styled-components";

export const ContainerHeaderGrid = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const IconeHeaderGrid = styled.div`
  flex: 1;
  text-align: right;
  display: none;
  margin-left: 5px;
`;

export const TextoHeaderGrid = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;
