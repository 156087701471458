import { Column } from "devextreme-react/data-grid";
import ArrayStore from "devextreme/data/array_store";
import { forwardRef, useMemo, useRef, useState } from "react";
import { MxpGrid } from "../../../../../components/grid";
import { Modal } from "../../../../../components/layout/modal";
import TituloGrid from "../../../../../components/texto/titulo-grid";
import { usePropagarReferenciaGrid } from "../../../../../hooks/grid.hooks";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/store.hooks";
import { IGridSelecao } from "../../../../../models/shared/ui/formularios";
import { GridCarregamentoViewModel } from "../../../../../models/viewmodels/vendas/mdfe/mdfe-edit-form-view-model";
import { removerCarregamento } from "../../../../../store/mdfe/mdfe.slice";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import exibirNotificacaoToast, {
  MensagensParaNotificacao,
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { GridProps } from "../../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import ModalAdicionarCarregamento from "./modal-adicionar-carregamento";

const gridId = "mdfe-lista-carregamentos";
const colunas = [
  <Column
    key="ordem"
    dataField="ordem"
    caption="Ordem"
    dataType="number"
    allowResizing
    width={70}
  />,
  <Column
    key="municipio"
    dataField="municipio"
    caption="Município do carregamento"
    allowResizing
    width={70}
    {...obterConfiguracaoColuna("stringG")}
  />,
  <Column key="uf" dataField="uf" caption="UF" allowResizing width={70} />,
];

export const GridCarregamentos = forwardRef((props: GridProps, ref) => {
  const [modalAddCarregamentoVisivel, setModalAddCarregamentoVisivel] =
    useState(false);

  const modelCarregamentos = useAppSelector((state) => {
    return state.mdfe.documentoAtual.carregamentos.map((c) => {
      return {
        id: c.id,
        municipio: c.municipio?.nome ?? "",
        idMunicipio: c.idMunicipio,
        ordem: c.ordem,
        uf: c.municipio?.uf ?? "",
      } as GridCarregamentoViewModel;
    });
  });

  const dataSourceCarregamentos = useMemo(
    () => new ArrayStore({ data: modelCarregamentos, key: "municipioId" }),
    [modelCarregamentos]
  );

  const gridRef = useRef<IGridSelecao>(null);
  usePropagarReferenciaGrid(ref, gridRef);

  const dispatch = useAppDispatch();

  function novoRegistroCarregamento() {
    setModalAddCarregamentoVisivel(true);
  }

  function excluirRegistro(
    carregamento: GridCarregamentoViewModel | undefined
  ) {
    if (carregamento) {
      dispatch(removerCarregamento(carregamento));

      exibirNotificacaoToast({
        mensagem: MensagensParaNotificacao.ExcluidoComSucesso,
        tipo: TipoNotificacao.Sucesso,
      });
    }
  }

  return (
    <>
      <TituloGrid texto="Dados do carregamento" />
      <MxpGrid<GridCarregamentoViewModel>
        id={gridId}
        ref={gridRef}
        dataSource={dataSourceCarregamentos}
        colunas={colunas}
        excluirRegistro={excluirRegistro}
        exibirIconeEditarMenuAcoes={false}
        style={props.style}
        novoRegistro={
          props.somenteLeitura ? undefined : novoRegistroCarregamento
        }
        nomeDoArquivoAoExportar={NomesTelas.empresas}
        isGridInterno
      />

      <Modal
        titulo="Adicionar carregamento"
        largura={"70%"}
        altura={"auto"}
        visivel={modalAddCarregamentoVisivel}
        onFechar={() => setModalAddCarregamentoVisivel(false)}
      >
        <ModalAdicionarCarregamento
          visivel={modalAddCarregamentoVisivel}
          fecharCallback={() => {
            setModalAddCarregamentoVisivel(false);
          }}
        />
      </Modal>
    </>
  );
});
