import { useEffect, useState } from "react";

import { Button, List, Popover, Popup, Tooltip } from "devextreme-react";
import { ClickEvent } from "devextreme/ui/button";
import { ItemRenderedEvent } from "devextreme/ui/list";
import parseToHtml from "html-react-parser";
import { useAppSelector } from "../../../hooks/store.hooks";
import { Notificacao } from "../../../models/api/notificacoes/notificacao";
import { NotificacaoNivel } from "../../../models/api/notificacoes/notificacao-enums";
import API from "../../../services/notificacoes/notificacoes-service";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../utils/common/notificacoes-utils";
import "./notificacoes.scss";

interface NotificacoesIconeProps {
  notificacaoSlide?: Notificacao;
  setNotificacaoSlide: (x: Notificacao | undefined) => void;
}

export default function NotificacoesIcone({
  notificacaoSlide,
  setNotificacaoSlide,
}: NotificacoesIconeProps) {
  const notificacoesCacheKey = "notificacoes-cache-key";
  const [notificacoes, setNotificacoes] = useState<Notificacao[]>([]);
  const [notificacaoPopUp, setNotificacaoPopUp] = useState<
    Notificacao | undefined
  >(undefined);
  const [quantidadeNotificacoesNaoLidas, setQuantidadeNotificacoesNaoLidas] =
    useState(0);

  const empresaId = useAppSelector(
    (state) => state.sessao?.dadosSessao?.empresa.id
  );
  const UM_MINUTO = 60000;
  const DOIS_MINUTOS = UM_MINUTO * 2;

  useEffect(() => {
    // Checa notificações de 1 em 1 minuto
    ChecaNotificacoes();
    const interval = setInterval(ChecaNotificacoes, DOIS_MINUTOS);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (notificacaoSlide && notificacaoSlide.lido) {
      LerNotificacao(notificacaoSlide.id);
    }
  }, [notificacaoSlide?.lido]);

  async function ChecaNotificacoes() {
    if (empresaId) {
      //Carrega as notificações da memória
      let dados = JSON.parse(
        localStorage.getItem(notificacoesCacheKey) ?? "[]"
      ) as Notificacao[];

      const datasDasNotificacoes = dados.map((x) => new Date(x.data));

      // Adiciona semana passada nas datas.
      // Isso é feito para não buscar notificações antigas demais.
      const semanaPassada = new Date();
      semanaPassada.setHours(0, 0, 0, 0);
      semanaPassada.setDate(semanaPassada.getDate() - 7);
      datasDasNotificacoes.push(semanaPassada);
      // Pega data da ultima notificação
      const dataUltimaNotificacao = datasDasNotificacoes.reduce(function (
        prev,
        current
      ) {
        return prev && prev > current ? prev : current;
      });

      const novasNotificacoes = await API.obterNotificacoes(
        empresaId,
        dataUltimaNotificacao
      );

      // Atualiza os dados locais
      dados = dados
        .filter((i) => !novasNotificacoes.find((f) => f.id === i.id))
        .concat(novasNotificacoes)
        .sort((a, b) => (a.data > b.data ? -1 : 1));

      AtualizaNotificacoes(dados);
    }
  }

  function limpaNotificacoesVisiveis(dados: Notificacao[]) {
    setNotificacaoPopUp(undefined);
    const notificacaoSlideLocal = dados.find(
      (el) => el.id === notificacaoSlide?.id
    );
    if (
      notificacaoSlideLocal &&
      notificacaoSlide &&
      (notificacaoSlideLocal.data > notificacaoSlide.data ||
        notificacaoSlideLocal.lido)
    ) {
      setNotificacaoSlide(undefined);
    }
  }

  function mostrarNotificacoes(dados: Notificacao[]) {
    limpaNotificacoesVisiveis(dados);

    const agora = new Date();
    dados.forEach((notificacao) => {
      if (
        !notificacao.ativo ||
        notificacao.lido == true ||
        !(
          agora >= new Date(notificacao.inicioDataHora) &&
          agora <= new Date(notificacao.fimDataHora)
        )
      ) {
        return;
      }

      if (notificacao.nivelNotificacao == NotificacaoNivel.Baixa) {
        exibirNotificacaoToast({
          mensagem: notificacao.mensagem,
          tipo: TipoNotificacao.Informacao,
        });
        notificacao.nivelNotificacao = NotificacaoNivel.Nenhuma;
      }
      if (
        notificacao.nivelNotificacao == NotificacaoNivel.Media &&
        notificacao.id != notificacaoSlide?.id
      ) {
        notificacaoSlide = notificacao;
        setNotificacaoSlide(notificacao);
      }

      if (
        notificacao.nivelNotificacao == NotificacaoNivel.Alta &&
        notificacao.id != notificacaoPopUp?.id
      ) {
        setNotificacaoPopUp(notificacao);
      }
    });

    // Salva na memória novamente para não ficar ficar mostrando os toasts várias vezes.
    localStorage.setItem(notificacoesCacheKey, JSON.stringify(dados));
  }

  function AtualizaNotificacoes(dados: Notificacao[]) {
    const ativas = dados.filter((x) => x.ativo);
    setNotificacoes(ativas);
    const naoLidas = ativas.filter((x) => !x.lido);
    setQuantidadeNotificacoesNaoLidas(naoLidas.length);

    mostrarNotificacoes(ativas);
  }

  function LerNotificacao(id?: string) {
    notificacoes[notificacoes.findIndex((el) => el.id === id)].lido = true;
    AtualizaNotificacoes(notificacoes);
  }

  function ItemTemplate(item: Notificacao) {
    return (
      <div
        className={
          "mxp-lista-notificacao " +
          (item.lido ? "" : "mxp-notificacoes-nao-lida")
        }
        onClick={() => setNotificacaoPopUp(item)}
      >
        <div className={"mxp-texto-notificacao"}>
          {parseToHtml(item.mensagem)}
        </div>
        <Button
          icon={
            "ic-material-symbols-outlined " +
            (item.lido ? "ic-visibility" : "ic-visibility-off")
          }
          stylingMode="text"
          onClick={(e: ClickEvent) => {
            LerNotificacao(item.id);
            e.event?.stopPropagation();
          }}
        ></Button>
      </div>
    );
  }

  function itemRendered(i: ItemRenderedEvent<Notificacao, any>) {
    if (i.itemElement.firstElementChild) {
      (i.itemElement.firstElementChild as any).style.padding = "0px";
    }
  }

  return (
    <div>
      <div id="menu-notificacoes-icone" style={{ margin: "5px" }}>
        <Button icon="ic-material-symbols-outlined ic-notifications ic-2x" />
        {quantidadeNotificacoesNaoLidas > 0 && (
          <span
            id="mxp-notificacoes-badge"
            key={quantidadeNotificacoesNaoLidas}
            className={"mxp-notificacoes-badge animate-gelatine"}
          >
            {quantidadeNotificacoesNaoLidas}
          </span>
        )}
      </div>

      <Tooltip
        target="#menu-notificacoes-icone"
        showEvent="mouseenter"
        hideEvent="mouseleave"
        hideOnOutsideClick={false}
      >
        <div>Notificações</div>
      </Tooltip>

      <Popover
        className="dx-swatch-revert-base"
        id="notificacoes-popover"
        target="#menu-notificacoes-icone"
        showEvent="click"
        width={400}
        maxWidth={"100%"}
        maxHeight={"50%"}
        showTitle={true}
        title="Notificações"
        onContentReady={(args: any) => {
          const html = args.component.content();
          html.style.padding = 0;
          // Mostra flecha do popover
          args.component._$arrow[0].classList.add("notificacoes-popover");
        }}
      >
        <List
          dataSource={notificacoes}
          height="100%"
          itemRender={ItemTemplate}
          onItemRendered={itemRendered}
        />
      </Popover>

      <Popup
        visible={notificacaoPopUp != undefined}
        onHiding={() => {
          LerNotificacao(notificacaoPopUp?.id);
        }}
        hideOnOutsideClick={true}
        showTitle={true}
        title="Notificação"
        showCloseButton
        container=".dx-viewport"
        height={"auto"}
        width={"60%"}
      >
        <div>{parseToHtml(notificacaoPopUp?.mensagem ?? "")}</div>
      </Popup>
    </div>
  );
}
