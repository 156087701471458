import TabContainer from "../../../../../../../components/layout/tab-container";
import ProvedorMenus from "../../../../../../../components/menus/provedor-menus";
import { DataSourceFiltragem } from "../../../../../../../utils/grid/data-source-factory";
import GridEmbutidaInsumoDaOrdemDeProducao from "../../../../../insumo-da-ordem-de-producao/componentes/grid-embutida/insumo-da-ordem-de-producao-grid-embutida";
import { ProvedorInsumo } from "../../../../../insumo-da-ordem-de-producao/componentes/provedor-insumo";
import { InsumoDaOrdemDeProducaoGridModel } from "../../../../../insumo-da-ordem-de-producao/models/insumo-da-ordem-de-producao";
import { OrdemDeProducaoEstado } from "../../../../utils/enums/ordem-de-producao-enums";

interface OrdemDeProducaoAbaInsumosProps {
  idRegistro: number;
  ordemDeProducaoEstado: OrdemDeProducaoEstado | undefined;
}

export default function OrdemDeProducaoAbaInsumos({
  idRegistro,
  ordemDeProducaoEstado,
}: OrdemDeProducaoAbaInsumosProps) {
  const filtroInsumo: DataSourceFiltragem<InsumoDaOrdemDeProducaoGridModel>[] =
    [
      {
        campo: "ordemDeProducaoId",
        operador: "=",
        valor: idRegistro,
      },
    ];

  return (
    <TabContainer>
      <ProvedorInsumo>
        <ProvedorMenus>
          <GridEmbutidaInsumoDaOrdemDeProducao
            ordemDeProducaoId={idRegistro}
            ordemDeProducaoEstado={ordemDeProducaoEstado}
            filtrosNoServidor={filtroInsumo}
            style={{ height: "100%" }}
          />
        </ProvedorMenus>
      </ProvedorInsumo>
    </TabContainer>
  );
}
